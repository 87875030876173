import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles(theme => ({
  bottom: {
    color: '#bbbbbb',
  },
  top: {
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));
function CircularProgressWithLabel(props) {
  const classes = useStylesFacebook();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        size={60}
        thickness={4}
        className={classes.bottom}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        size={60}
        thickness={4}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularProgressLabel({ isLoaded = false, initialPercent = 0 }) {
  const [progress, setProgress] = React.useState(initialPercent);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => {
        const random = Math.random() * (5 - 1) + 1;
        const progress = prevProgress + random;
        return progress >= 100 ? 100 : progress;
      });
    }, 2000);
    return () => {
      setProgress(0);
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (isLoaded) setProgress(100);
    return () => {
      setProgress(0);
    };
  }, [isLoaded]);

  return <CircularProgressWithLabel value={progress} />;
}
