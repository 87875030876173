import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CustomIcon from './atoms/Icons/CustomIcon/CustomIcon';
import { checkIfCan } from '../helpers';

const drawerWidth = 256;

const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
    fontWeight: '600',
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
    fontWeight: '600',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  divider_close: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 50,
    [theme.breakpoints.up('sm')]: {
      width: 50,
    },
  },
});

function Navigator(props) {
  const { classes, goTo, openKeywordTracker, onClose, removeDialog, onRemoveDialog } = props;

  const open = props.open;

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <List disablePadding className="navigation" style={{ flexGrow: 1 }}>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
          style={{ paddingTop: 9, paddingBottom: 9 }}
        >
          <div style={{ display: 'inline-flex' }}>
            <Typography variant="h6" color="inherit" noWrap>
              <img
                src="/assets/imgs/cypherly-logo-transparent.png"
                alt=" Kashing"
                className="logo-app"
              />
            </Typography>
            <div>
              <IconButton onClick={onClose} style={{ color: '#fff', marginLeft: '5.5rem' }}>
                <ArrowBackIosIcon style={{ fontSize: '16px' }} />
              </IconButton>
            </div>
          </div>
        </ListItem>

        <ListItem
          button
          key={'home'}
          onClick={() => goTo('/products/prefilter')}
          className={clsx(classes.item, classes.itemCategory)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <CustomIcon width={20} path="/assets/icons/menu/home.png" alt="Finder" />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Home
          </ListItemText>
        </ListItem>

        <React.Fragment key={'Research'}>
          {open && (
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                My Tools
              </ListItemText>
            </ListItem>
          )}

          {!open && (
            <ListItem
              button
              key={'home'}
              onClick={() => goTo('/products/prefilter')}
              className={clsx(classes.item, classes.itemCategory)}
            >
              <ListItemIcon className={classes.itemIcon}>
                <CustomIcon width={20} path="/assets/icons/menu/home.png" alt="Finder" />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                Home
              </ListItemText>
            </ListItem>
          )}

          {checkIfCan(props.currentUser.email) &&
            <ListItem
              key={"Upload products"}
              button
              onClick={() => goTo('/products/upload')}
              className={clsx(classes.item, false && classes.itemActiveItem)}
            >
              <ListItemIcon className={classes.itemIcon}>
                <CustomIcon width={20} path="/assets/icons/menu/opportunities.png" alt="Finder" />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                {"Upload products"}
              </ListItemText>
            </ListItem>
          }

          <ListItem
            key={"Today's Opportunities"}
            button
            onClick={() => goTo('/products/prefilter')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/opportunities.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {"Today's Opportunities"}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Product Finder'}
            button
            onClick={() => goTo('/products/finder')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/finder.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Product Finder'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Product Tracker'}
            button
            onClick={() => goTo('/products/tracker')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/tracker.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Product Tracker'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Listing Creator'}
            button
            onClick={() => goTo('/products/listing')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/listing.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Listing Creator'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Keywords Research'}
            button
            onClick={() => goTo('/keywords/research')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/keyword-research.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Keyword Research'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Keywords Tracker'}
            button
            onClick={openKeywordTracker}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/keyword-tracker.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Keyword Tracker'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Profitability Calculator'}
            button
            onClick={() => goTo('/calculator')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/calculator.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Profitability Calculator'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Review Intelligence'}
            button
            onClick={() => goTo('/review/intelligence')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/review.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Review Intelligence'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Listing Logs'}
            button
            onClick={() => goTo('/product/history')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/listing-log.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Listing Logs'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Brandname Checker'}
            button
            onClick={() => goTo('/brand/check')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/brandname.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Brandname Checker'}
            </ListItemText>
          </ListItem>
          <ListItem
            key={'Chrome Extension'}
            button
            onClick={() => goTo('/extension')}
            className={clsx(classes.item, false && classes.itemActiveItem)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <CustomIcon width={20} path="/assets/icons/menu/extension.png" alt="Finder" />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {'Chrome Extension'}
            </ListItemText>
          </ListItem>
          <Dialog
            open={removeDialog.open}
            onClose={() => {
              onRemoveDialog(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Remove keyword Tag'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" color="secondary">
                Are you sure to remove this tag and untrack all keywords from the database?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onRemoveDialog(false);
                }}
                variant="outlined"
                color="default"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onRemoveDialog(true);
                }}
                variant="outlined"
                color="default"
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          {/*<Collapse in={open_tracker} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{display: other.open ? 'block':'none'}}>
              {
                keyword_tags.map((tag) => {
                  return (
                    <ListItem button key={tag} className={classes.nested} onClick={() => goTo('/keywords/tracker/'+tag)}>
                      <ListItemIcon>
                        <StarBorder />
                      </ListItemIcon>
                      <ListItemText inset primary={tag} />
                      <Icon className={classNames('icon-table','fas fa-times')}
                         onClick={() => {openConfirmDelete(tag)} } style={{ fontSize: 15 }}/>
                    </ListItem>
                  )
                })
              }
            </List>
          </Collapse>*/}
        </React.Fragment>
      </List>
      <List
        disablePadding
        className="navigation"
        style={{ padding: '10px 0px', borderTop: '0.3px solid #404854' }}
      >
        <ListItem
          key={'Settings'}
          button
          onClick={() => goTo('/settings')}
          className={clsx(classes.item, false && classes.itemActiveItem)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <CustomIcon width={20} path="/assets/icons/menu/settings.png" alt="Finder" />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Settings
          </ListItemText>
        </ListItem>
        <ListItem
          key={'Logout'}
          button
          onClick={() => goTo('/login')}
          className={clsx(classes.item, false && classes.itemActiveItem)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <CustomIcon width={20} path="/assets/icons/menu/log-out.png" alt="Finder" />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Logout
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
  goTo: PropTypes.func,
  openKeywordTracker: PropTypes.func,
  onClose: PropTypes.func,
  removeDialog: PropTypes.shape({ open: PropTypes.bool }),
  onRemoveDialog: PropTypes.func,
  open_tracker: PropTypes.bool,
  keyword_tags: PropTypes.array,
  openConfirmDelete: PropTypes.func,
  openVideo: PropTypes.func,
};

export default withStyles(styles)(Navigator);
