import React from 'react';
import PropTypes from 'prop-types';
import { auth } from '../../../config/firebase';
import { withFirebase } from 'firekit-provider';
import globals from '../../../config/globals';
import { GetRequest } from '../../../utils';
import { setLocale } from 'react-redux-i18n';
import { set } from '../../../redux/actions/auth';
import { connect } from 'react-redux';
import getFireDBService from '../../../utils/fireDBService';

const fireDBService = getFireDBService();

class FastLogin extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }
  render() {
    return <div>Redirect...</div>;
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.user = {};
    this.state = {
      errorMessage: null,
    };
  }

  componentDidMount() {
    auth
      .signOut()
      .then(() => {
        this.removeAuth();
      })
      .catch(error => {
        console.log(error);
      });
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('user');
    let pass = params.get('pass');
    const _email = atob(email);
    const _password = atob(pass);
    auth
      .signInWithEmailAndPassword(_email, _password)
      .then(result => {
        if (result) {
          this.startSession(result);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  async startSession(result) {
    const user = await fireDBService.getUser(result.user.uid);

    if (user) {
      user.group = await fireDBService.getGroup(user.gid);
      const suscription_service = `${globals.apiSecondary}/api/groups/${user.gid}/products/update`;
      GetRequest(suscription_service)
        .then(data => {})
        .catch(error => {
          console.log('error on task tracker', error);
        });
      this.goToStart(user);
    }
  }

  goToStart(user) {
    this.context.store.dispatch(setLocale(user.lang || 'en'));
    this.context.store.dispatch(set(user));
    this.context.router.history.push('/');
  }
}
const mapStateToProps = (state, props) => {
  return {};
};
export default connect(mapStateToProps, {})(withFirebase(FastLogin));
