/* eslint-disable indent */
import React, { useReducer } from 'react';
import ProductFiltersContext from './ProductFiltersContext';
import PropTypes from 'prop-types';

const SET_MIN_FILTER = 'SET_MIN_FILTER';
const SET_MAX_FILTER = 'SET_MAX_FILTER';
const SET_ASIN = 'SET_ASIN';
const SET_CATEGORY = 'SET_CATEGORY';
const SET_INCLUDED_KEYWORDS = 'SET_INCLUDED_KEYWORDS';
const SET_EXCLUDED_KEYWORDS = 'SET_EXCLUDED_KEYWORDS';
const RESET = 'RESET';
const TOGGLE_FILTER = 'TOGGLE_FILTER';
const initialState = {
  range: {
    bsr: {
      min: '',
      max: '',
    },
    price: {
      min: '',
      max: '',
    },
    monthly_units: {
      min: '',
      max: '',
    },
    monthly_sales: {
      min: '',
      max: '',
    },
    reviews: {
      min: '',
      max: '',
    },
    rating: {
      min: '',
      max: '',
    },
  },
  bsr: {
    min: '',
    max: '',
  },
  price: {
    min: '',
    max: '',
  },
  monthly_units: {
    min: '',
    max: '',
  },
  monthly_sales: {
    min: '',
    max: '',
  },
  reviews: {
    min: '',
    max: '',
  },
  rating: {
    min: '',
    max: '',
  },
  included_keywords: '',
  excluded_keywords: '',
  categories: [],
  asin: '',
  isExpandedFilter: true,
  isReset: false,
};

const filtersReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_FILTER:
      return { ...state, isExpandedFilter: !state.isExpandedFilter };
    case SET_MIN_FILTER:
      return {
        ...state,
        range: {
          ...state.range,
          [action.payload.key]: {
            ...state.range[action.payload.key],
            min: action.payload.value ? parseFloat(action.payload.value) : '',
          },
        },
      };
    case SET_MAX_FILTER:
      return {
        ...state,
        range: {
          ...state.range,
          [action.payload.key]: {
            ...state.range[action.payload.key],
            max: action.payload.value ? parseFloat(action.payload.value) : '',
          },
        },
      };
    case SET_ASIN:
      return {
        ...state,
        asin: action.payload,
      };
    case SET_CATEGORY:
      return {
        ...state,
        categories: [...action.payload],
      };
    case SET_EXCLUDED_KEYWORDS:
      return {
        ...state,
        excluded_keywords: action.payload,
      };
    case SET_INCLUDED_KEYWORDS:
      return {
        ...state,
        included_keywords: action.payload,
      };
    case RESET:
      return { ...initialState, isReset: true };
  }
};

const ProductFiltersProvider = ({ children }) => {
  const [filters, dispatch] = useReducer(filtersReducer, initialState);

  const onChangeInputMin = (name, value) => {
    dispatch({ type: 'SET_MIN_FILTER', payload: { key: name, value } });
  };

  const onChangeInputMax = (name, value) => {
    dispatch({ type: 'SET_MAX_FILTER', payload: { key: name, value } });
  };

  const onChangeCategory = item => {
    if (item) dispatch({ type: SET_CATEGORY, payload: item });
  };

  const onChangeAsin = asin => {
    dispatch({ type: SET_ASIN, payload: asin });
  };

  const onChangeIncludedKeywords = keywords => {
    dispatch({ type: SET_INCLUDED_KEYWORDS, payload: keywords });
  };

  const onChangeExcludedKeywords = keywords => {
    dispatch({ type: SET_EXCLUDED_KEYWORDS, payload: keywords });
  };

  const onResetFilters = () => {
    dispatch({ type: RESET });
  };

  const onToggleFilter = () => {
    dispatch({ type: TOGGLE_FILTER });
  };

  return (
    <ProductFiltersContext.Provider
      value={{
        filters,
        onChangeInputMin,
        onChangeInputMax,
        onChangeCategory,
        onChangeAsin,
        onChangeIncludedKeywords,
        onChangeExcludedKeywords,
        onResetFilters,
        onToggleFilter,
      }}
    >
      {children}
    </ProductFiltersContext.Provider>
  );
};

ProductFiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ProductFiltersProvider;
