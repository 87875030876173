import React, { Component } from 'react';

class WistiaEmbed extends Component {
  constructor(props) {
    super(props);
    const { hashedId, ...embedOptions } = { ...this.props };
    window._wq = window._wq || [];
    window._wq.push({
      id: hashedId,
      options: embedOptions,
    });
  }

  render() {
    return (
      <div className="wistia_responsive_padding" style={{ paddingTop: 20, paddingBottom: 20 }}>
        <div
          className={`wistia_embed wistia_async_${this.props.hashedId} videoFoam=true`}
          style={{ height: '100%', width: '100%' }}
        />
      </div>
    );
  }

  componentDidMount() {
    if (!document.getElementById('wistia_script')) {
      let wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    window._wq = window._wq || [];
    window._wq.push({
      id: this.props.hashedId,
      onEmbedded: video => {
        this.handle = video;
      },
    });
  }

  componentWillUnmount() {
    this.handle && this.handle.remove();
  }
}

export default WistiaEmbed;
