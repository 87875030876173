import React from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import PropTypes from 'prop-types';
import Kashing from '../../../components/_kashing';
import Header from '../../../components/Header';
import { setRegion } from '../../../redux/actions/auth';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import moment from 'moment';

import Divider from '../../../components/atoms/Divider';
import TextFieldSearch from '../../../components/TextFieldSearch';
import CalculatorIcon from '../../../components/atoms/Icons/CalculatorIcon';
import Text from '../../../components/atoms/Text';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import DialogVideo from '../../../components/molecules/DialogVideo';
import Rating from '../../../components/atoms/Rating';
import NewCalculator from '../components/NewCalculator';
import { getProductByAsin } from '../../../utils/productsServices';
import { LinearProgress } from '@material-ui/core';

const styles = {
  card: {
    minHeight: '8vw',
    marginBottom: '12px',
    flex: 1,
  },
};

function EstSaleSold(props) {
  const est_sale_sold_result = props.est_sale_sold_result;
  return (
    <Typography className="text-primary-color" component="h2">
      {est_sale_sold_result}
    </Typography>
  );
}

function EstSaleResult(props) {
  const est_sale_result = props.est_sale_result;
  return (
    <Typography className="text-primary-color" component="h2">
      {est_sale_result}
    </Typography>
  );
}

class DedicatedCalculator extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.auth = this.props.firebaseApp.auth();
    this.state = {
      video: '',
      open: false,
      isSearching: false,
      asin: '',
      pending_loading: false,
      pieBreakdown: [],
      showToolTip: false,
      activeIndex: -1,
    };
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  searchProduct = async asin => {
    this.setState({ product: null, asin, isLoading: true });

    const product = await getProductByAsin(asin, this.props.auth.region.toUpperCase());
    if (product && !product.asin) {
      this.setState({
        isLoading: false,
        isProductEmpty: true,
      });
      return;
    }

    this.setState({ product, isLoading: false, isProductEmpty: false, asin });
  };

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  onChangeAsin = value => {
    this.setState({ asin: value });
  };

  pendingLoading(prod) {
    if (this.state.pending_loading) {
      return true;
    }

    if (
      !prod.sales_estimate ||
      !prod.sales_estimate.last_update ||
      !prod.sales_estimate.last_update.seconds
    ) {
      this.setState({ pending_loading: true });

      return true;
    }

    const diff = moment().diff(moment.unix(prod.sales_estimate.last_update.seconds), 'days');
    if (diff >= 1) {
      this.setState({ pending_loading: true });

      return true;
    }
    return false;
  }

  handleChangeValues = values => {
    console.log('values: ', values);
    const pieBreakdown = [
      {
        name: `Selling on Amazon Fee`,
        value: parseFloat(values.sellingFee.toFixed(2)),
        color: 'black',
      },
      { name: 'Storage Cost', value: parseFloat(values.storageCost), color: '#dce7c5' },
      {
        name: 'Cost of Goods Sold',
        value: parseFloat(values.cogsValue),
        color: '#E6ECEF',
      },
      {
        name: 'Fullfillment by Amazon Fees',
        value: values.fulFillmentFee,
        color: '#e3a51a',
      },
    ];
    this.setState({ pieBreakdown });
  };

  renderActiveShape = props => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name}`}</text>
        <text
          fontSize={12}
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`$${payload.value}  (${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  render() {
    const { classMain, onDrawerToggle, open } = this.props;
    const product = this.state.product;

    if (product) {
      let est_sale_sold_result = 'N/A';
      let est_sale_result = 'N/A';

      if (
        product.sales_estimate.estimate &&
        product.sales_estimate.estimate !== '' &&
        product.sales_estimate.estimate !== '0'
      ) {
        const estimate = parseInt(product.sales_estimate.estimate, 10);
        if (estimate >= 1000) {
          est_sale_sold_result =
            this.thusandsFormat(product.sales_estimate.estimate, 1) +
            this.thusandsFormatLetter(product.sales_estimate.estimate, false) +
            '/mo';
        } else if (estimate > 0) {
          est_sale_sold_result =
            this.thusandsFormat(product.sales_estimate.estimate, 0) +
            this.thusandsFormatLetter(product.sales_estimate.estimate, false) +
            '/mo';
        } else {
          est_sale_sold_result = 'N/A';
        }
      } else {
        est_sale_sold_result = 'No sales estimate';
      }

      if (product.sales_estimate.estimate && product.offer_price) {
        let estimate = product.sales_estimate.estimate;
        let offer_priceMoney = this.money(product.offer_price.amount, '2');
        if (offer_priceMoney === 'N/A') {
          est_sale_result = 'N/A';
        } else if (estimate > 0) {
          let offer_price = offer_priceMoney.toString();
          offer_price = offer_price.substring(1, offer_price.length);
          let total = this.thusandsFormat(parseFloat(offer_price) * parseFloat(estimate), 1);
          est_sale_result =
            this.symbol(product.region) +
            total +
            this.thusandsFormatLetter(parseFloat(offer_price) * parseFloat(estimate), false) +
            '/mo';
        } else {
          est_sale_result = 'N/A';
        }
      } else {
        est_sale_result = 'No sales estimate';
      }

      let indicator = '';
      let porc_indicator = 0;
      if (
        product.bsr < 6000 &&
        product.reviews_count < 1000 &&
        product.list_price.amount >= 1500 &&
        product.list_price.amount <= 6500
      ) {
        indicator = 'Great Opportunity';
        porc_indicator = 0.9;
      } else if (
        product.bsr >= 6000 &&
        product.bsr >= 10000 &&
        product.reviews_count < 1500 &&
        product.list_price.amount >= 1500 &&
        product.list_price.amount <= 6500
      ) {
        indicator = 'Average';
        porc_indicator = 0.5;
      } else {
        indicator = 'Not Great';
        porc_indicator = 0.2;
      }

      return (
        <>
          <Header
            goTo={this.props.goTo}
            open={open}
            onChangeRegion={this.onChangeRegion}
            region={this.props.auth.region}
            onDrawerToggle={onDrawerToggle}
            title="Profitability Calculator"
            handleClickOpen={this.handleClickOpen.bind(this)}
          />

          <main className={`${classMain} main`}>
            <div className="page-scroll">
              <DialogVideo
                open={this.state.open}
                onClose={this.handleClose.bind(this)}
                videoHashedId={this.state.video}
              />

              <Box
                flexDirection={'row'}
                display={'flex'}
                marginTop={1}
                marginBottom={3}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <TextFieldSearch
                  label="Calculate"
                  placeholder="Enter your brand name to begin"
                  onHandleButtonSearch={this.searchProduct}
                />
              </Box>
              {this.state.isProductEmpty && <DataNotFound />}
              {this.state.isLoading && <LinearProgress style={{ marginTop: 20 }} />}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Box display="flex" component="span" m={1} ml={0}>
                        <CalculatorIcon width={30} />
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{
                            fontSize: '1.09rem',
                            marginTop: '10px',
                            marginBottom: '8px',
                            marginLeft: 10,
                          }}
                        >
                          Profitability Calculator
                        </Typography>
                      </Box>
                      <Box paddingLeft={2}>
                        <NewCalculator product={product} onChangeValues={this.handleChangeValues} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'block', fontSize: 10 }}>
                  <Grid container spacing={2}>
                    <Grid key="k1" item xs={12} sm={4} style={{ display: 'block' }}>
                      <Card style={{ ...styles.card, height: 210 }}>
                        <CardContent>
                          <Typography variant="subtitle2" gutterBottom>
                            ASIN
                          </Typography>
                          <Typography className="text-primary-color">
                            {product.asin || 'NA'}
                          </Typography>
                          <Divider margin="10px 0px" />
                          <Typography variant="subtitle2" gutterBottom>
                            Price
                          </Typography>
                          <Typography className="text-primary-color">
                            ${product.offer_price.amount / 100}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid key="k2" item xs={12} sm={4} style={{ display: 'block' }}>
                      <Card style={{ ...styles.card, height: 210 }}>
                        <CardContent>
                          <Typography variant="subtitle2" gutterBottom>
                            Main Category
                          </Typography>
                          <Typography className="text-primary-color">
                            {product.main_category}
                          </Typography>
                          <Divider margin="10px 0px" />
                          <Typography variant="subtitle2" gutterBottom>
                            Categories
                          </Typography>
                          <Typography className="text-primary-color">
                            {product.binding || product.categories
                              ? product.categories.join('\n')
                              : 'N/A'}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid key="k3" item xs={12} sm={4} style={{ display: 'block' }}>
                      <Card style={{ ...styles.card, height: 210 }}>
                        <CardContent>
                          <Typography variant="subtitle2" gutterBottom>
                            BSR
                          </Typography>
                          <Typography className="text-primary-color" component="h2">
                            {this.format(product.bsr, 0) || 'NA'}
                          </Typography>
                          <Divider margin="10px 0px" />
                          <Typography variant="subtitle2" gutterBottom>
                            Reviews & Rating
                          </Typography>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              style={{ marginRight: 5 }}
                              className="text-primary-color"
                              component="h2"
                            >
                              {product.reviews_count
                                ? this.format(product.reviews_count, 0)
                                : 'N/A'}
                            </Typography>
                            {product.rating ? <Rating rating={product.rating} /> : 'N/A'}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} style={{ display: 'block' }}>
                      <Card style={{ ...styles.card }}>
                        <CardContent>
                          <Typography variant="subtitle2" gutterBottom>
                            Monthly Units Sold
                          </Typography>
                          <EstSaleSold
                            pending_loading={this.state.pending_loading}
                            est_sale_sold_result={est_sale_sold_result}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: 'block' }}>
                      <Card style={{ ...styles.card }}>
                        <CardContent>
                          <Typography variant="subtitle2" gutterBottom>
                            Monthly Revenue
                          </Typography>
                          <EstSaleResult
                            pending_loading={this.state.pending_loading}
                            est_sale_result={est_sale_result}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} style={{ display: 'block' }}>
                      <Card style={{ ...styles.card }}>
                        <CardContent>
                          <Text variant="h6" weight="semibold">
                            Breakdown
                          </Text>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <PieChart width={500} height={400}>
                              <Pie
                                data={this.state.pieBreakdown}
                                dataKey="value"
                                cx={250}
                                cy={180}
                                outerRadius={90}
                                labelLine={false}
                                label={this.renderActiveShape}
                              >
                                {this.state.pieBreakdown.map((entry, index) => {
                                  return <Cell key={`slice-${index}`} fill={entry.color} />;
                                })}
                              </Pie>
                            </PieChart>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </main>
        </>
      );
    } else {
      return (
        <>
          <Header
            goTo={this.props.goTo}
            open={open}
            onChangeRegion={this.onChangeRegion}
            region={this.props.auth.region}
            onDrawerToggle={onDrawerToggle}
            title="Profitability Calculator"
            handleClickOpen={this.handleClickOpen.bind(this)}
          />
          <main className={`${classMain} main`}>
            <div className="page-scroll">
              <DialogVideo
                open={this.state.open}
                onClose={this.handleClose.bind(this)}
                videoHashedId={this.state.video}
              />

              <Box
                flexDirection={'row'}
                display={'flex'}
                marginTop={1}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <TextFieldSearch
                  label="Calculate"
                  placeholder="Enter a product ASIN to get started"
                  onHandleButtonSearch={this.searchProduct}
                />
              </Box>
              {this.state.isProductEmpty && <DataNotFound />}

              {this.state.isLoading && <LinearProgress style={{ marginTop: 20 }} />}
            </div>
          </main>
        </>
      );
    }
  }
}

const DataNotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
      }}
    >
      <Text variant="span" weight="semibold">
        ASIN is wrong or the product could not be found
      </Text>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(DedicatedCalculator));
