import React from 'react';
import PropTypes from 'prop-types';
import { setLocale } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
// settings
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//settings
import globals from '../../config/globals';
import { Request, Form } from '../../utils/';

import { set } from '../../redux/actions/auth';
import getFireDBService from '../../utils/fireDBService';
const fireDBService = getFireDBService();

const TAX_RATE = 0.07;
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
});

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(id, desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { id, desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [['Access plan', 1, 10.99]].map((row, id) => createRow(id, ...row));

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

function SpanningTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Desc</TableCell>
          <TableCell align="right">Qty.</TableCell>
          <TableCell align="right">Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.id}>
            <TableCell>
              <strong>{row.desc}</strong>
            </TableCell>
            <TableCell align="right">{row.qty}</TableCell>
            <TableCell align="right">{ccyFormat(row.price)}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell />
          <TableCell align="right">
            <strong>Total</strong>
          </TableCell>
          <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

SpanningTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TheTable = withStyles(styles)(SpanningTable);

class Review extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.initBtnLabel = 'Create account';
    this.state = {
      name: '',
      email: '',
      password: '',
      loader: false,
      error: null,
      btnLabel: this.initBtnLabel,
    };
  }

  finish() {
    this.props.onProcessing();
    let source = this.props.auth.tempData.source.id;
    let customer = this.props.auth.user.group.payment.customer.id;
    let gid = this.props.auth.user.gid;
    let user = this.props.auth.user;
    this.setSource(source, user, customer, gid).then(user => {
      setTimeout(() => {
        this.props.onFinish(user);
      }, 6000);
    });
  }
  async setSource(source, user, cid, gid) {
    return new Promise(async (resolve, reject) => {
      let source_service = `${globals.api}/api/groups/${gid}/payments/profile/${cid}`;
      let form = Form({ source: source });
      const group = await fireDBService.getGroup(gid);
      Request(source_service, form).then(async customer => {
        group.payment.customer = customer.response;
        await fireDBService.updateGroup(gid, { payment: group.payment }, async error => {
          if (!error) {
            resolve(user);
          }
        });
      });
    });
  }

  goToStart(user) {
    this.context.store.dispatch(setLocale(user.lang || 'en'));
    this.context.store.dispatch(set(user));
    this.context.router.history.push('/');
  }

  render() {
    return (
      <React.Fragment>
        <br />
        <br />
        <TheTable />

        <br />
        <br />
        <FormControl fullWidth margin="normal">
          <Button
            type="submit"
            color="primary"
            fullWidth={true}
            disabled={this.props.loader}
            onClick={this.finish.bind(this)}
            variant="contained"
          >
            Finish
          </Button>
        </FormControl>
      </React.Fragment>
    );
  }
}

Review.propTypes = {
  firebaseApp: PropTypes.object.isRequired,
  onProcessing: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loader: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  let auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(Review));
