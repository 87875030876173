import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import Settings from '../routes/settings/actions';
import MainContainer from '../components/MainContainer';
import MembershipRequest from '../components/MembershipRequest';
import { set } from '../redux/actions/auth';

import { auth } from '../config/firebase';
import getFireDBService from '../utils/fireDBService';
import { getSubscription } from '../utils/fastpring';

const fireDBService = getFireDBService();

class Main extends React.Component {
  static get contextTypes() {
    return {
      store: PropTypes.object.isRequired,
      router: PropTypes.object.isRequired,
      subscription: PropTypes.object,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return state;
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;

    this.state = {
      showPaymentForm: false,
      step: 1,
      source: null,
      loader: false,
      stepsRoot: {
        width: '100%',
        padding: 0,
      },
      stepsLabels: ['General Info', 'Payment details'],
      memberShipRequestDialogTitle: '',
      desactiveUser: false,
    };
  }

  componentDidMount() {
    document.body.className = '';
    const days = 3;
    auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        let sessionTimeout = null;
        if (firebaseUser === null) {
          // User is logged out.
          // Clear the session timeout.
          sessionTimeout && clearTimeout(sessionTimeout);
          sessionTimeout = null;
        } else {
          // User is logged in.
          // Fetch the decoded ID token and create a session timeout which signs the user out.
          firebaseUser.getIdTokenResult().then(idTokenResult => {
            // Make sure all the times are in milliseconds!
            const authTime = idTokenResult.claims.auth_time * 1000;
            const sessionDuration = 1000 * 60 * 60 * 24 * days;
            const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
            sessionTimeout = setTimeout(() => this.toLogin(), millisecondsUntilExpiration);
          });
        }

        const user = await fireDBService.getUser(firebaseUser.uid);
        if (!user) {
          return this.toLogin();
        }
        user.group = await fireDBService.getGroup(user.gid);
        this.store.dispatch(set(user));

        if (!user.group) {
          return this.toLogin();
        }

        if (!user.group.payment || !user.group.payment.subscription) {
          this.context.router.history.push('/settings');
          return false;
        }

        // Check if this is special user has flag skipSubscription=true
        if (!user.skipSubscription) {
          const { subscription } = this.store.getState().subscription;
          if (!subscription) return this.manageSubscription(user.group.payment.subscription);
          this.manageNavSettingsBySubsState(subscription);
        }
      } else {
        this.toLogin();
      }
    });
  }

  toLogin() {
    if (this.context.router.history.location.pathname !== '/login') {
      this.context.router.history.push('/login');
    }
  }

  toSettings() {
    if (this.context.router.history.location.pathname !== '/settings') {
      this.context.router.history.push('/settings');
    }
  }

  manageNavSettingsBySubsState(subscription) {
    if (
      !subscription.state ||
      subscription.state === 'deactivated' ||
      subscription.state === 'canceled'
    ) {
      this.toSettings();
    }
  }

  async manageSubscription(id) {
    try {
      const subscription = await getSubscription(id);
      const subscriptionData = subscription.data;
      this.manageNavSettingsBySubsState(subscriptionData);
    } catch (error) {
      this.toSettings();
    }
  }

  onShowPayment(result) {}

  onUpdate(o) {
    console.log(o);
  }

  onProcessing() {
    this.setState({ loader: true });
  }

  onProcessFinished() {
    this.setState({ loader: false });
  }

  next() {
    let step = this.state.step + 1;
    setTimeout(() => {
      this.setState({ step: step, loader: false });
    }, 6000);
  }

  onPaymentSourceSuccess(result) {
    this.setState({ source: result, step: 2 });
  }

  onFinish(user) {
    this.setState({ showPaymentForm: false });
  }

  render() {
    if (this.props.auth.user) {
      const subscription = this.store.getState().subscription.subscription;
      const user = this.store.getState().auth.user;
      if (
        subscription &&
        subscription.state !== 'active' &&
        subscription.state !== 'trial' &&
        !user.skipSubscription
      ) {
        return (
          <div className="h-100 app" ref="container">
            <MembershipRequest
              title={this.state.memberShipRequestDialogTitle}
              step={this.state.step}
              show={this.state.showPaymentForm}
              next={this.next.bind(this)}
              loader={this.state.loader}
              onClose={this.onShowPayment.bind(this)}
              onUpdate={this.onUpdate.bind(this)}
              onProcessing={this.onProcessing.bind(this)}
              onFinish={this.onFinish.bind(this)}
              onPaymentSourceSuccess={this.onPaymentSourceSuccess.bind(this)}
            />
            <MainContainer auth={this.props.auth}>
              <Settings {...this.props} {...this.states} />
            </MainContainer>
          </div>
        );
      }
      return (
        <div className="h-100 app" ref="container">
          <MembershipRequest
            title={this.state.memberShipRequestDialogTitle}
            step={this.state.step}
            show={this.state.showPaymentForm}
            next={this.next.bind(this)}
            loader={this.state.loader}
            onClose={this.onShowPayment.bind(this)}
            onUpdate={this.onUpdate.bind(this)}
            onProcessing={this.onProcessing.bind(this)}
            onFinish={this.onFinish.bind(this)}
            onPaymentSourceSuccess={this.onPaymentSourceSuccess.bind(this)}
          />
          <MainContainer auth={this.props.auth}>
            <this.props.component {...this.props} {...this.states} />
          </MainContainer>
        </div>
      );
    } else {
      return null;
    }
  }
}

Main.propTypes = {
  firebaseApp: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};

export default connect(mapStateToProps, {})(withFirebase(Main));
