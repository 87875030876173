/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import Icon from '@material-ui/core/Icon';

import Kashing from '../../../components/_kashing';
import MainTable from '../../../components/MainTable';
import TableData from '../components/TableData';

import classNames from 'classnames';

import Header from '../../../components/Header';
import { setRegion } from '../../../redux/actions/auth';

import globals from '../../../config/globals';
import WistiaEmbed from '../../../utils/wistiaEmbed';
import { GetRequest } from '../../../utils';
import getFireDBService from '../../../utils/fireDBService';
import getFirestoreService from '../../../utils/firestoreService';
import ProductHeaders from '../../../utils/products_headers.json';
import { manageSalesEstimateByProduct } from '../../../utils/productsServices';
import { getEstimateTotal, manageProductImages } from '../utils';
import DialogVideo from '../../../components/molecules/DialogVideo';
const fireDBService = getFireDBService();
const firestoreService = getFirestoreService();

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  let a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const InputTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    color: '#000',
  },

  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          height: 1,
        },
        borderBottom: '3px solid #000',
        '&:after': {
          borderBottom: '1px solid #000',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000',
        fontSize: '1em',
        '&$focused': {
          color: '#000',
          fontWeight: 'bold',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '#000',
        fontSize: '1.2em',
      },
    },
  },
});

export const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

/*eslint-enable*/

class ProductsHistoryPage extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.auth = this.props.firebaseApp.auth();

    this.state = {
      asins: '',
      asin: '',
      products: [],
      table: [],
      item: null,
      showFilters: false,
      searchResults: [],
      orderBy: { field: 'bsr', order: 'desc', startAt: 0 },
      asisnsInputDisabled: false,
      selectedRowId: null,
      removeDialog: {
        open: false,
      },
      filter: {
        category: '',
      },
      advancedWindow: false,
      alertDialog: {
        open: false,
      },
      tableProductHeaders: ProductHeaders,
      search_index: null,
      endAt: null,
      open: false,
      video: '',
    };
  }

  selectRow(row) {
    if (row.status === 'db') {
      let id = row.asin + '-' + row.region;

      this.context.router.history.push('/tracker/' + id);
    }
  }

  async componentDidMount() {
    this.validateSuscription(this.props.auth.user);
    this.watchProducts();
    fireDBService.subscribeVideos(snapshot => {
      const data = snapshot.val();
      this.setState({ video: data.product_tracker });
    });
    const user = this.props.auth.user;

    const gids = await firestoreService.getDocs('gids_products', user.gid);
    if (gids && gids.date) {
      const diff = moment().diff(moment.unix(gids.date.seconds), 'days');
      if (diff >= 1) {
        this.requestGidsProducts(user);
      }
    } else {
      this.requestGidsProducts(user);
    }
  }

  requestGidsProducts(user) {
    const suscription_service = `${globals.api}/api/groups/${user.gid}/products/update`;
    GetRequest(suscription_service)
      .then(data => {})
      .catch(error => {
        console.log('error on task tracker', error);
      });
  }

  componentWillUnmount() {
    // this.unsubscribe()
  }

  manageSalesEstimateProducts(rows) {
    return rows.map(async row => {
      const categories = row.categories;
      let mainCategory = row.main_category;
      if (categories && categories.length > 0 && !mainCategory) {
        mainCategory = categories[0];
      }

      if (!mainCategory) {
        mainCategory = row.binding.split(' > ')[0];
      }

      row.main_category = mainCategory;
      const estimate = await manageSalesEstimateByProduct(row, this.props.auth.region);

      return {
        ...row,
        sales_estimate: {
          ...row.sales_estimate,
          estimate: estimate == 0 ? row.sales_estimate.estimate : estimate,
        },
      };
    });
  }

  async watchProducts() {
    let state = this.store.getState();
    let region = state.auth.region.toUpperCase();
    let products = [];
    this.setState({ products: products });

    const row = await firestoreService.getDocs('gids_products', state.auth.user.gid);
    let product_ids = [];
    if (row) {
      product_ids = row.products;
    }

    const productsPromise = product_ids.map(product_id =>
      firestoreService.getDocs('products', product_id),
    );
    const productDocs = await Promise.all(productsPromise);

    productDocs.map(async productDoc => {
      if (productDoc && productDoc.asin && productDoc.region === region) {
        productDoc.status = 'db';
        if (productDoc.product_group && productDoc.product_group.split('>').length > 0) {
          productDoc.product_group = productDoc.product_group.split('>')[0];
        } else {
          productDoc.product_group = productDoc.main_category;
        }

        productDoc.show = true;
        products.push(productDoc);
        this.getEstimated(productDoc);
      }

      let table = this.setFilter(products);

      const salesEstimatePromises = await this.manageSalesEstimateProducts(table);
      table = await Promise.all(salesEstimatePromises);
      table = getEstimateTotal(table);
      table = manageProductImages(table);

      table = table.filter(item => item.sales_estimate.estimate > 0);

      table = _.orderBy(table, [this.state.orderBy.field], [this.state.orderBy.order]);

      this.setState({ table: table, products: products });
      const rowsToUpdate = table.filter(it => it.pending);
      rowsToUpdate.forEach(it => this.getEstimate(it, this, 'table'));
    });
  }

  getEstimated(row) {
    let region = this.props.auth.region.toUpperCase();
    let uri = `${globals.api}/api/products/sales_estimate/${row.asin}/region/${region}`;
    if (row.sales_estimate) {
      let last = moment(row.sales_estimate.last_update, 'YYYY-MM-DD');
      let current = moment().startOf('day');
      let diference = last.diff(current, 'days');
      if (diference > 2) {
        this.getRequest(uri);
      }
    } else {
      this.getRequest(uri);
    }
  }

  setFilter(dataset) {
    let results = dataset;
    if (this.state.filters) {
      const fields = {
        bsr: 'bsr',
        rating: 'rating',
        reviews: 'reviews_count',
        list_price: 'list_price.amount',
        offer_price: 'offer_price.amount',
        sales_estimate: 'sales_estimate.estimate',
        sales_estimate_total: 'sales_estimate.total',
      };
      let currencies = ['list_price', 'offer_price', 'sales_estimate', 'sales_estimate_total'];
      for (let key in this.state.filters) {
        let field = fields[key];
        results = _.filter(results, product => {
          let value = this.getValueFromData(field, product);
          let min =
            currencies.indexOf(key) >= 0
              ? this.state.filters[key].min * 100
              : this.state.filters[key].min;
          if (this.state.filters[key].max > 0) {
            let max =
              currencies.indexOf(key) >= 0
                ? this.state.filters[key].max * 100
                : this.state.filters[key].max;

            if (value >= min && value <= max) {
              return product;
            }
          } else {
            if (value >= min) {
              return product;
            }
          }
        });
      }
    } else {
      results = dataset;
    }
    return results;
  }

  getValueFromData(str, product) {
    let parts = str.split('.');
    let o = null;
    if (product[parts[0]]) {
      o = product[parts[0]];
      for (let i in parts) {
        let key = parts[i];
        if (o[key]) {
          o = o[key];
        }
      }
    }
    return o;
  }

  resetForm() {
    this.setState({ asins: '', asisnsInputDisabled: false });
  }

  async onRemoveDialog(response) {
    if (response === true) {
      let state = this.store.getState();
      let product_id = this.state.selectedRowId;
      let gid = state.auth.user.gid;

      const gidProductsDoc = await firestoreService.getDocs('gids_products', gid);
      let products = gidProductsDoc.products;
      let index = products.indexOf(product_id);
      products.splice(index, 1);

      await firestoreService.setDocs('gids_products', gid, { products }, { merge: true });

      this.setState({
        selectedRowId: null,
        removeDialog: {
          open: false,
        },
      });
      this.watchProducts();
    } else {
      this.setState({
        removeDialog: {
          open: false,
        },
      });
    }
  }

  openConfirmDelete(row) {
    let pid = `${row.asin}-${row.region}`;
    this.setState({
      selectedRowId: pid,
      removeDialog: {
        open: true,
      },
    });
  }

  sortTable(o) {
    this.setState({ orderBy: o });
    setTimeout(() => {
      this.watchProducts();
    }, 100);
  }

  onRemoveAlertDialog() {
    this.setState({ alertDialog: { open: false, message: '' } });
  }

  addProductFromAdvancedSearch(row) {
    let newresults = _.remove(this.state.searchResults, obj => {
      return obj.asin !== row.asin;
    });
    this.setState({ searchResults: newresults, item: row }, () => {
      this.addProduct();
    });
  }

  async trackHistory(row) {
    let trackMonitor = row.trackMonitor || false;
    trackMonitor = !trackMonitor;

    await firestoreService.updateDocs('products', row.asin + '-' + row.region.toUpperCase(), {
      trackMonitor,
    });
    this.watchProducts();
  }

  getCatalog(field) {
    let items = this.state.table.map((item, index) => {
      let element = Object.byString(item, field);
      return element;
    });
    return _.uniq(items);
  }

  onSelectFilterCategory(e) {
    let table = _.filter(this.state.table, item => {
      if (e.target.value === '') {
        item.show = true;
        return item;
      } else {
        if (item.product_group === e.target.value) {
          item.show = true;
          return item;
        } else {
          item.show = false;
          return item;
        }
      }
    });
    // console.log('table', table);
    this.setState({ table: table, filter: { category: e.target.value } });
  }

  componentDidUpdate(props, state) {
    if (props.auth.region !== this.props.auth.region) {
      this.watchProducts();
    }
  }

  toggleDrawer = open => () => {
    this.setState({
      showFilters: open,
    });
  };

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  onChangeASIN = e => {
    this.setState({ asin: e.target.value });
  };

  onChangeData(row) {
    let table = this.state.table;
    const fr = table.find(item => item.asin === row.asin);
    const i = table.indexOf(fr);
    table[i] = row;
    this.setState({ table: table });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  render() {
    let categories = this.getCatalog('product_group');
    const { classMain, onDrawerToggle, open } = this.props;

    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Listing Logs"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain} main`}>
          <div className="table-scroll">
            <DialogVideo
              open={this.state.open}
              onClose={this.handleClose.bind(this)}
              videoHashedId={this.state.video}
            />

            <Dialog
              fullWidth={true}
              maxWidth={'md'}
              open={this.state.alertDialog.open}
              onClose={this.onRemoveAlertDialog.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {this.state.alertDialog.title || 'Ups!'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" color="secondary">
                  {this.state.alertDialog.message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onRemoveAlertDialog.bind(this, false)}
                  variant="outlined"
                  color="default"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.removeDialog.open}
              onClose={this.onRemoveDialog.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle style={{ paddingBottom: 0 }} id="alert-dialog-title">
                {'Are you sure?'}
              </DialogTitle>
              <DialogContent>This will remove the product from your Listing Log.</DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onRemoveDialog.bind(this, false)}
                  variant="outlined"
                  color="default"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.onRemoveDialog.bind(this, true)}
                  variant="outlined"
                  color="default"
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>

            <div className="filter-container">
              <div className="filter-actions-container hide" style={{ display: 'none' }}>
                <Icon
                  className={classNames('filter-icon', 'fas fa-sliders-h')}
                  style={{ fontSize: 15 }}
                />
                <Icon
                  className={classNames('filter-icon', 'fas fa-filter')}
                  style={{ fontSize: 15 }}
                />
                <Icon
                  className={classNames('filter-icon', 'fas fa-search')}
                  style={{ fontSize: 15 }}
                />
                <Icon
                  className={classNames('filter-icon', 'fas fa-plus')}
                  style={{ fontSize: 15 }}
                />
              </div>
            </div>

            <MainTable
              headers={this.state.tableProductHeaders}
              orderBy={this.state.orderBy}
              sortTable={this.sortTable.bind(this)}
            >
              <TableData
                data={this.state.table}
                noActions={false}
                onChangeData={this.onChangeData.bind(this)}
                headers={this.state.tableProductHeaders}
                selectRow={this.selectRow.bind(this)}
                addProduct={this.addProductFromAdvancedSearch.bind(this)}
                trackHistory={this.trackHistory.bind(this)}
                openConfirmDelete={this.openConfirmDelete.bind(this)}
              />
            </MainTable>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(ProductsHistoryPage));
