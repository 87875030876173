import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
  semibold: {
    fontWeight: '600',
  },
  regular: {
    fontWeight: '400',
  },
  light: {
    fontWeight: '300',
  },
});

const Text = ({ className = '', variant, component, children, weight = 'regular' }) => {
  const classes = useStyles();

  return (
    <Typography
      className={`${className} ${classes[weight]}`}
      component={component}
      variant={variant}
    >
      {children}
    </Typography>
  );
};
Text.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
  ]),
  component: PropTypes.string,
  className: PropTypes.string,
  weight: PropTypes.oneOf(['semibold', 'regular', 'light']),
};
export default Text;
