import React from 'react';
import Header from '../../../components/Header';
import Kashing from '../../../components/_kashing';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import { Formik } from 'formik';
import * as Yup from "yup";
import { checkIfCan, saveProductsByFile } from '../../../helpers';
import { Redirect } from 'react-router-dom';

class ProductsUpload extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;

    this.state = {
      region: false,
      loading: false,
      error: '',
    };

    this.auth = this.props.firebaseApp.auth();
  }

  render() {
    const { classMain } = this.props;

    const initialValues = {
      file: '',
    }

    const validationSchema = Yup.object({
      file: Yup.string().required('File is required'),
    })

    const HandleSubmit = async (values, {resetForm}) => {
      await this.setState({loading: true})
      const response = await saveProductsByFile(values.file)

      if (typeof response === 'string') {
        await this.setState({error: response})
      }

      await this.setState({loading: false})
      resetForm()
    }

    if (!checkIfCan(this.auth.currentUser.email)) {
      return <Redirect push to="/" />
    }

    const handleChangeFile = (event, setFieldValue) => {
      let file = event.currentTarget.files[0];
      console.log({file});

      if(file.size > 13000000) {
        this.setState({error: `File size is too big. Max size is 12MB, actual size is ${(file.size / 1000000).toFixed(2)}MB`})
        event.target.value = ""
        return
      }

      setFieldValue('file', file)
    }

    return (
      <>
        <Header
          goTo={this.props.goTo}
          nameRegion={this.state.region}
          open={true}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={() => {}}
          title="Upload Products"
          handleClickOpen={() => {}}
        />

        <main className={`${classMain} main`}>
          <div className='row'>
            <div className='col-12'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={HandleSubmit}
              >
                {({ handleSubmit, setFieldValue, values, touched, errors }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group w-100">
                        <label htmlFor="customFileLang">File CSV</label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            onChange={(event) => handleChangeFile(event, setFieldValue)}
                            id="customFileLang" lang="en"/>
                          <label className="custom-file-label" htmlFor="customFileLang">
                            {values.file ? values.file.name : 'Select File'}
                          </label>
                        </div>
                        {touched.file && errors.file && (
                          <small className="text-danger">
                            {errors.file}
                          </small>
                        )}
                      </div>

                      <div>
                        {this.state.loading ? (
                          <p>Loading......</p>
                        ) : (
                          <button className="btn btn-secondary btn-sm" type="submit">Send</button>
                        )}
                      </div>
                    </form>

                    {this.state.error && (
                      <p className="text-danger">{this.state.error}</p>
                    )}
                  </>
                )}
              </Formik>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(ProductsUpload));
