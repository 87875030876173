import React, { Component } from 'react';
export default class Notfound extends Component {
  render() {
    return (
      <div className="page container h-100">
        <div
          style={{
            color: '#fff',
            height: '35%',
            fontSize: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {' '}
          <span className="fas fa-exclamation-circle" /> Something was wrong
        </div>
      </div>
    );
  }
}
