import React from 'react';
import CustomIcon from '../CustomIcon/CustomIcon';
import PropTypes from 'prop-types';

const AddIcon = ({ width = 50 }) => {
  return <CustomIcon path="/assets/icons/plus.png" alt="cart icon" width={width} />;
};

AddIcon.propTypes = {
  width: PropTypes.number,
};
export default AddIcon;
