import Kashing from '../../../components/_kashing';
import PropTypes from 'prop-types';
import { setRegion } from '../../../redux/actions/auth';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import Header from '../../../components/Header';
import Box from '@material-ui/core/Box';
import React from 'react';
import globals from '../../../config/globals';
import ReviewComponent from '../component';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import SnackBar from '../../../components/Snackbar';
import getFirestoreService from '../../../utils/firestoreService';
import TextFieldSearch from '../../../components/TextFieldSearch';
import DialogVideo from '../../../components/molecules/DialogVideo';

const firestoreService = getFirestoreService();

class ReviewIntelligenceFinder extends Kashing {
  setAlibabaLink(styles, productTitle) {
    if (this.state.reviews.length > 0) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            'https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=' +
            productTitle
          }
        >
          <img
            src="/assets/imgs/logo_alibaba.png"
            alt="Alibaba Logo"
            style={{
              verticalAlign: 'middle',
              width: '4rem',
              marginLeft: '10px',
              margin: '10px',
            }}
          />
        </a>
      );
    } else {
      return null;
    }
  }

  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.auth = this.props.firebaseApp.auth();
    this.pending_loading = false;
    this.state = {
      video: '',
      open: false,
      isSearching: false,
      reviews: {},
      isLoading: false,
      starts: [
        {
          start: 5,
          name: 'five_star',
        },
        {
          start: 4,
          name: 'four_star',
        },
        {
          start: 3,
          name: 'three_star',
        },
        {
          start: 2,
          name: 'two_star',
        },
        {
          start: 1,
          name: 'one_star',
        },
      ],
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      asin: '',
      product: {},
      product_rating: '',
    };
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  searchProduct = async value => {
    this.setState({ isLoading: true });
    const region = this.props.auth.region;
    const asin = this.state.asin || value;
    const path = asin + '-' + region.toUpperCase();
    const uri = `${globals.api}/api/keywords/reviews/${asin}/region/${region}`;
    const product = await firestoreService.getDocs('products', path);

    this.getRequest(uri)
      .then(async response => {
        let product_rating = [];
        if (response.summary && response.summary.rating) {
          const mod_rating = response.summary.rating % 2;
          const length = parseInt(response.summary.rating);
          for (let index = 0; index < length; index++) {
            product_rating.push(<StarIcon key={index} style={{ color: '#F4821E' }} />);
            if (mod_rating > 0 && index === length - 1) {
              product_rating.push(<StarHalfIcon key={index} style={{ color: '#F4821E' }} />);
            }
          }
        }
        this.setState({
          product_rating: product_rating,
          reviews: response,
          progressLine: { show: false },
          isLoading: false,
          product: product,
        });
      })
      .catch(error => {
        // Review data is not available code: PY001
        // show error
        this.showSnackBar('Review data is not available code: PY001', 'warning');
        this.setState({ isLoading: false, progressLine: { show: false } });
      });
  };

  showSnackBar(message, type = 'success') {
    this.setState({
      snackbar: {
        open: true,
        type: type,
        message: message,
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  onChangeAsin = asin => {
    this.setState({ asin });
  };

  render() {
    const { classMain, onDrawerToggle, open } = this.props;

    function getProduct(self, state) {
      if (state.isLoading) {
        return (
          <Typography variant="h5" component="h3">
            Please wait for a bit... We are digging deeper to get the data on this product
            <CircularProgress style={{ marginLeft: '20px' }} />
          </Typography>
        );
      }
      return (
        <>
          {!state.product.asin ? (
            <></>
          ) : (
            ReviewComponent.call(self, state.isLoading, state.product, state.product_rating)
          )}
        </>
      );
    }

    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Review Intelligence"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain} main`}>
          <div className="page-scroll">
            <div className="form-add-product-container">
              <SnackBar
                options={this.state.snackbar}
                onClose={this.handleSnackbarClose.bind(this)}
              />
              <DialogVideo
                open={this.state.open}
                onClose={this.handleClose.bind(this)}
                videoHashedId={this.state.video}
              />
            </div>

            <Box
              flexDirection={'row'}
              display={'flex'}
              marginBottom={3}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <TextFieldSearch
                label="Search"
                onHandleButtonSearch={value => {
                  this.searchProduct(value);
                }}
              />
            </Box>
            {getProduct(this, this.state)}
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(ReviewIntelligenceFinder));
