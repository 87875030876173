import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import WistiaEmbed from '../../../utils/wistiaEmbed';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import Shadow from '../../../components/atoms/Shadow';
const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#232f3e',
  },
  logo: {
    height: 25,
    width: 'auto',
    objectFit: 'contain',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: '#fff',
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  closeIcon: {
    color: 'white',
    fontSize: '.7rem',
  },
}));

function DialogVideo({ open, onClose, videoHashedId }) {
  const classes = useStyles();

  const handleClose = () => {
    if (onClose) onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img
            src="/assets/imgs/cypherly-logo-transparent.png"
            alt=" Kashing"
            className={classes.logo}
          />
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <Shadow style={{ backgroundColor: '#F4821E', borderRadius: '50%' }}>
              <CloseIcon className={classes.closeIcon} />
            </Shadow>
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div className="wistia-embed-wrapper">
          {/* You can include any Wistia embed options as props. See the full list at https://wistia.com/doc/embed-options#options_list */}
          <WistiaEmbed hashedId={videoHashedId} playerColor="#F4821E" autoPlay={true} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DialogVideo;
