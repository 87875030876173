import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  img: {
    objectFit: 'contain',
  },
});

const CustomIcon = ({ path, alt, width }) => {
  const classes = useStyles();

  return <img className={classes.img} width={width} src={path} alt={alt} />;
};

CustomIcon.propTypes = {
  path: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
};

export default CustomIcon;
