import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles, makeStyles } from '@material-ui/core';
import Label from '../Label';
import Divider from '../../atoms/Divider/Divider';

const OutlinedInputCustomized = withStyles(theme => ({
  root: {
    height: 34,
    width: '100%',
    borderRadius: 4,
    fontSize: '.9rem',
    border: '0.1px solid #f5eeee',
    backgroundColor: '#fff',
    color: '#bfbbbb',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  notchedOutline: { border: 'none' },

  input: {
    color: '#bfbbbb',
    fontWeight: 600,
    '&::placeholder': {
      color: '#bfbbbb',
      fontWeight: 600,
      opacity: 1,
    },
    padding: 0,
  },
}))(OutlinedInput);

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  label: {
    width: '70%',
  },
  wrapperInput: {
    padding: 2,
    backgroundColor: '#F5F7F8',
    '&:hover': {
      border: '2px solid rgba(244, 130, 30,0.6)',
      borderRadius: 4,
      padding: 0,
    },
  },
});

const InputField = ({
  label,
  help,
  icon,
  placeholder,
  value,
  defaultValue,
  onChange,
  style = {},
  type = 'text',
  inputProps = {},
  inputComponent = undefined,
  isShadow = true,
}) => {
  const classes = useStyles();
  const onHandleChange = e => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <div className={`${classes.container}`}>
      {label && <Label text={label} help={help} />}
      {label && <Divider margin={'4px 0px'} />}
      <div className={isShadow ? classes.wrapperInput : ''}>
        <OutlinedInputCustomized
          defaultValue={defaultValue}
          value={value}
          onChange={onHandleChange}
          placeholder={placeholder}
          aria-describedby="outlined-weight-helper-text"
          labelWidth={0}
          startAdornment={
            icon ? <InputAdornment position="start">{icon || null}</InputAdornment> : null
          }
          inputProps={inputProps}
          inputComponent={inputComponent}
          type={type}
          style={style}
        />
      </div>
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
};

export { InputField, OutlinedInputCustomized };
