import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Box, makeStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Text from '../../atoms/Text';
import Label from '../Label';
import Divider from '../../atoms/Divider';
const useStyles = makeStyles({
  input: {
    fontSize: '.9rem',
    textAlign: 'center',
    marginBottom: 5,
    '&::placeholder': {
      color: ' #b7b7b7',
      lineHeight: 'normal',
      fontSize: '.9rem',
      textAlign: 'center',
      opacity: 1,
      verticalAlign: 'top',
    },
  },
});

const InputRange = ({
  label,
  help,
  name,
  minValue,
  maxValue,
  onChangeInputMin,
  onChangeInputMax,
  isCurrency,
  type,
}) => {
  const classes = useStyles();
  const onHandleChangeInputMin = e => {
    if (onChangeInputMin) {
      const value = e.target.value;
      onChangeInputMin(name, value);
    }
  };

  const onHandleChangeInputMax = e => {
    if (onChangeInputMax) {
      const value = e.target.value;
      onChangeInputMax(name, value);
    }
  };

  let inputProps = {
    classes: { input: classes.input },
  };
  if (isCurrency) {
    type = 'text';
    inputProps = {
      ...inputProps,
      inputComponent: NumberFormatCustom,
    };
  }

  return (
    <Box width="100%">
      <Label weight="semibold" text={label} help={help} />
      <Divider margin={'20px 0px'} />
      <Box display={'flex'} justifyContent="flex-end" alignItems={'flex-end'}>
        <Text
          weight="semibold"
          component={'span'}
          variant={'body2'}
          className={'label-input-range'}
        >
          Min
        </Text>
        <Divider margin={'0px 10px'} />
        <TextField
          type={type}
          value={minValue}
          placeholder={'(Optional)'}
          onChange={onHandleChangeInputMin}
          name="min number"
          InputProps={inputProps}
          style={{ marginBottom: 2 }}
        />
        <Divider margin={'0px 10px'} />
        <Text component={'span'} variant={'body2'} className={'label-input-range'}>
          Max
        </Text>
        <Divider margin={'0px 10px'} />
        <TextField
          type={type}
          placeholder={'(Optional)'}
          value={maxValue}
          onChange={onHandleChangeInputMax}
          name="max number"
          InputProps={inputProps}
          style={{ marginBottom: 2 }}
        />
      </Box>
    </Box>
  );
};

export const NumberFormatCustom = props => {
  const { inputRef, onChange, thousandSeparator = ',', ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator="."
      isNumericString
      prefix="$"
    />
  );
};

export const NumberFormatSeparatorNull = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormatCustom
      inputRef={inputRef}
      onChange={onChange}
      {...other}
      thousandSeparator=""
      name="input"
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

InputRange.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeInputMin: PropTypes.func,
  onChangeInputMax: PropTypes.func,
  onBlurInputMin: PropTypes.func,
  onBlurInputMax: PropTypes.func,
  isCurrency: PropTypes.bool,
  type: PropTypes.string,
};
export default InputRange;
