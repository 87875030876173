import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import { setRegion } from '../../../redux/actions/auth';
import TableData from '../components/TableData';

import Kashing from '../../../components/_kashing';
import MainTable from '../../../components/MainTable';
import SnackBar from '../../../components/Snackbar';
import Header from '../../../components/Header';
import ProgressLine from '../../../components/ProgressLine';

import productHeadersTable from '../../../utils/products_headers';

import { manageSalesEstimateByProduct } from '../../../utils/productsServices';
import { getEstimateTotal, manageProductImages } from '../utils';
import DialogVideo from '../../../components/molecules/DialogVideo';

import {
  findProductsWithFilters,
  getFavoritesProducts,
  saveFavoritesProducts,
  setDbUser,
  setFavoriteProduct,
} from '../../../helpers';
import { withFirebase } from 'firekit-provider';

class ProductsPrefilterPage extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.store = this.context.store;

    this.state = {
      show: true,
      products: [],
      showFilters: false,
      table: [],
      item: null,
      orderBy: { field: 'bsr', order: 'desc' },
      selectedRowId: null,
      pre_filter: {
        bsr: { max: 6000, min: 0 },
        list_price: { max: 5000, min: 1500 },
        reviews: { max: 1000, min: 0 },
      },
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      tableProductHeaders: [...productHeadersTable],
      progressLine: {
        show: false,
      },
      trackerProducts: [],
      open: false,
      video: '',
    };

    this.initialQuery = {
      filters: [
        {reviews_count: {from: 100, to: 1000}},
        {bsr: {from: 1, to: 6000}},
        {sales_estimate: {from: 500, to: 5000}},
        {list_price: {from: 1500, to: 5000}},
      ]
    }
  }

  componentDidUpdate(props, state) {
    if (props.auth.region !== this.props.auth.region) {
      this.filterOpportunities()
    }
  }

  async componentDidMount() {
    await this.getTrackerProducts()
    await this.filterOpportunities()
  }

  async getTrackerProducts() {
    let state = this.store.getState();
    let trackerProducts = await getFavoritesProducts(state.auth.user.gid)

    this.setState({
      trackerProducts,
    });
  }

  async fetchPrefilter() {
    if (this.state.pre_filter) return;

    const results = [];
    let prefilters = {};

    this.setState({ pre_filter: prefilters }, () => {
      this.filterOpportunities(prefilters);
    });
  }

  sortTable(o) {
    console.log({o});
    let table = this.state.table;
    table.forEach(row => {
      row['est_sales'] = row.sales_estimate.estimate * row.offer_price.amount;
    });
    this.setState({ orderBy: o }, () => {
      let sort = _.orderBy(table, [this.state.orderBy.field], [this.state.orderBy.order]);
      this.setState({ table: sort });
    });
  }

  addProductFromAdvancedSearch = async row => {
    return new Promise((resolve, reject) => {
      this.setState({ item: row }, async () => {
        this.handleSnackbarClose();
        await this.addProduct();
        resolve('ok');
      });
    });
  };

  async addProduct() {
    let state = this.store.getState();
    this.setState({ show: false });

    if (this.state.trackerProducts.length >= 25) {
      this.setState({
        snackbar: {
          open: true,
          type: 'error',
          message: 'Remove some products and try again.',
          position: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
      });

      return;
    }

    let user = setDbUser(state.auth.user);

    let payload = {
      ...user,
      favorite: setFavoriteProduct(this.state.item),
      lastUpdatedAt: moment().toDate()
    }

    await saveFavoritesProducts(payload)

    this.setState((state) => {
      state.trackerProducts.push(setFavoriteProduct(state.item))

      return {
        trackerProducts: state.trackerProducts,
        table: this.filterOmitInTracker(state.table),
        snackbar: {
          open: true,
          type: 'success',
          message: 'This product has successfully been added to your Product Tracker',
          position: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
      }
    });
  }

  filterOmitInTracker = (products) => products.filter(product => !this.existInTracker(product));

  getCatalog(field) {
    let items = this.state.table.map((item) =>  Object.byString(item, field));
    return _.uniq(items);
  }

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  selectRow(row) {
    const table = this.state.table;
    const item = table.find(item => item.asin === row.asin);
    const idx = table.indexOf(item);
    item.isSelected = !item.isSelected;
    table[idx] = item;
    this.setState({ table: table });
  }

  openConfirmDelete(row) {}

  toggleDrawer = open => () => {
    this.setState({
      showFilters: open,
    });
  };

  manageSalesEstimateProducts = async rows => {
    return rows.map(async row => {
      const categories = row.categories;
      let mainCategory = row.main_category;
      if (categories && categories.length > 0 && !mainCategory) {
        mainCategory = categories[0];
      }

      if (!mainCategory && row.binding) {
        mainCategory = row.binding.split(' > ')[0];
      }

      row.main_category = mainCategory;
      const estimate = await manageSalesEstimateByProduct(row, this.props.auth.region);

      return {
        ...row,
        sales_estimate: {
          ...row.sales_estimate,
          estimate: estimate == 0 ? row.sales_estimate.estimate : estimate,
        },
      };
    });
  };

  handlerProducts = async (rows) => {
    rows = rows.map((row) => {
      if (row.asin) {
        const exist = this.existInTracker(row);

        if (!exist) {
          row.status = 'tmp';
          row.show = true;
          row.product_group = row.product_group ? row.product_group.split('>')[0] : 'N/A';
        }
      }
      return row
    })

    rows = rows.map(item => {
      item.title = item.title.replace('Amazon.com:', '').replace('Amazon.com : ', '').trim();
      return item;
    });

    const salesEstimatePromises = await this.manageSalesEstimateProducts(rows);

    rows = await Promise.all(salesEstimatePromises);
    rows = getEstimateTotal(rows);
    rows = manageProductImages(rows);

    rows = rows.filter(item => item.sales_estimate.estimate > 0);

    this.setState({
      table: rows,
      products: rows,
      progressLine: { show: false },
    });
  };

  async filterOpportunities() {
    this.setState({ progressLine: { show: true }, table: [] });
    const region = this.props.auth.region.toUpperCase();

    const todayDate = moment().format('YYYY-MM-DD')
    const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

    let initialQuery = this.initialQuery
    initialQuery.filters = [
      ...initialQuery.filters,
      {region},
      //{dateRange: {from: yesterdayDate, to: todayDate}}
    ]

    let results = await findProductsWithFilters(initialQuery)

    await this.handlerProducts(results);
  }

  existInTracker(row) {
    const trackerProducts = this.state.trackerProducts;
    return trackerProducts.find((item) => item.productId === row.productId);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  onChangeData(row) {
    let table = this.state.table;
    const fr = table.find(item => item.asin === row.asin);
    const i = table.indexOf(fr);
    table[i] = row;
    this.setState({ table: table });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
    this.setState({ region: region });
  };

  render() {
    const { classMain, onDrawerToggle, open } = this.props;

    return (
      <>
        <Header
          goTo={this.props.goTo}
          nameRegion={this.state.region}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Today's Opportunities"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain} main`}>
          <div className="table-scroll">
            <MainTable
              headers={this.state.tableProductHeaders}
              orderBy={this.state.orderBy}
              sortTable={this.sortTable.bind(this)}
            >
              <TableData
                data={this.state.table}
                onChangeData={this.onChangeData.bind(this)}
                headers={this.state.tableProductHeaders}
                selectRow={this.selectRow.bind(this)}
                addProduct={this.addProductFromAdvancedSearch}
                openConfirmDelete={this.openConfirmDelete.bind(this)}
              />
            </MainTable>
            <ProgressLine
              show={this.state.progressLine.show}
              style={{ width: '100%', marginTop: '-4px', backgroundColor: '#fff' }}
            />
          </div>

          <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />
          <DialogVideo
            open={this.state.open}
            onClose={this.handleClose.bind(this)}
            videoHashedId={this.state.video}
          />
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};

export default connect(mapStateToProps, {})(withFirebase(ProductsPrefilterPage));
