import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import { Card, Grid, CardContent, makeStyles, TextField } from '@material-ui/core';
import CarHeaderCustomized from '../../../components/molecules/CardHeader/CardHeader';
import Divider from '../../../components/atoms/Divider';
import CustomIcon from '../../../components/atoms/Icons/CustomIcon/CustomIcon';
import Text from '../../../components/atoms/Text';
import Button from '../../../components/atoms/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { sha256 } from 'js-sha256';
import md5 from 'react-native-md5';
import SnackBar from '../../../components/Snackbar';
import getFireDBService from '../../../utils/fireDBService';
import WistiaEmbed from '../../../utils/wistiaEmbed';

const fireDBService = getFireDBService();
const useStyles = makeStyles(theme => ({
  imageChromeExtension: {
    width: 380,
    height: 'auto',
    objectFit: 'contain',
  },
  textLinkChrome: {
    color: '#1a73e8',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  card: {
    flex: 1,
  },
  buttonAddChrome: {
    backgroundColor: '#1a73e8',
    color: 'white',
    '&:disabled': {
      color: 'white',
    },
  },
  buttonAddExtension: {
    backgroundColor: '#fff',
    border: '1px solid #000',
    color: '#1a73e8',
    '&:disabled': {
      color: '#1a73e8',
    },
  },
}));

const ChromeExtension = props => {
  const classes = useStyles();
  const { open, onDrawerToggle, onChangeRegion, classMain, auth, firebaseApp: firebase } = props;
  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: '',
    type: 'info',
    position: {
      vertical: 'bottom',
      horizontal: 'right',
    },
  });

  const [video, setVideo] = useState('');

  useEffect(() => {
    fireDBService.subscribeVideos(snapshot => {
      const data = snapshot.val();
      setVideo(data.extension);
    });
  }, []);

  const handleInstallExtension = () => {
    const url =
      'https://chrome.google.com/webstore/detail/cypherlyio-amazon-researc/bgippocgmbijocinfdclhcncpegjlifn?hl=en';
    window.open(url, '_blank');
  };

  const getToken = () => {
    let user = firebase.auth().currentUser;
    let authUser = auth.user;
    let hash = sha256.create();
    const token = authUser.gid + '+' + user.uid;
    hash.update(token);
    const prevToken = hash.hex();
    return md5.hex_md5(prevToken);
  };

  const handleCopiedApiKey = () => {
    setSnackbarOptions(so => {
      return { ...so, open: true, message: 'Api Key Copied to Clipboard!', type: 'info' };
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOptions(so => {
      return { ...so, open: false, message: '' };
    });
  };
  return (
    <>
      <Header
        goTo={props.goTo}
        open={open}
        onChangeRegion={onChangeRegion}
        region={props.auth.region}
        onDrawerToggle={onDrawerToggle}
        title="Chrome Extension"
        isVisibleRegion={false}
      />

      <main className={`${classMain} main`}>
        <div className="page-scroll">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card style={{ width: '100%' }}>
                <CarHeaderCustomized
                  avatar={<CustomIcon path="/assets/icons/chrome.png" width={40} />}
                  title="How to install the Chrome Extension"
                  style={{ borderbottom: '1px solid red' }}
                />
                <CardContent>
                  <Box display={'flex'}>
                    <img
                      className={classes.imageChromeExtension}
                      src="/assets/imgs/amazon-extension.png"
                    />
                    <Box marginLeft={4}>
                      <Box>
                        <Text weight="semibold" variant="subtitle2" component="span">
                          Step 1:
                        </Text>
                        <Text component="span" className="text-primary-color">
                          {` Make sure you are using Google Chrome (the extension only works on
                          Chrome). if you do not have chrome, you can download it for free at: `}
                          <a
                            className={classes.textLinkChrome}
                            href="https://www.google.com/chrome"
                            rel="noreferrer"
                            target="_blank"
                          >
                            https://www.google.com/chrome
                          </a>
                        </Text>
                      </Box>
                      <Divider margin={'10px 0px'} />
                      <Box>
                        <Text weight="semibold" variant="subtitle2" component="span">
                          Step 2:
                        </Text>
                        <Text component="span" className="text-primary-color">
                          {` Open up Google Chrome and click this button`}
                        </Text>
                        <Box width={200}>
                          <Button onClick={handleInstallExtension} type={'primary'}>
                            Install Chrome Extension
                          </Button>
                        </Box>
                      </Box>
                      <Divider margin={'10px 0px'} />

                      <Box>
                        <Text weight="semibold" variant="subtitle2" component="span">
                          Step 3:
                        </Text>
                        <Text component="span" className="text-primary-color">
                          {` Click on the "Add to Chrome" button that looks like this: `}
                        </Text>
                        <Box width={160}>
                          <Button
                            disabled
                            onClick={handleInstallExtension}
                            className={classes.buttonAddChrome}
                          >
                            <Text weight="semibold" variant="subtitle2">
                              Add to Chrome
                            </Text>
                          </Button>
                        </Box>
                      </Box>
                      <Divider margin={'10px 0px'} />
                      <Box>
                        <Text weight="semibold" variant="subtitle2" component="span">
                          Step 4:
                        </Text>
                        <Text component="span" className="text-primary-color">
                          {` When prompted, confirm that you wish to add the Cypherly extension by clicking on the "Add Extension" button that look like this: `}
                        </Text>
                        <Box width={160}>
                          <Button
                            disabled
                            onClick={handleInstallExtension}
                            className={classes.buttonAddExtension}
                          >
                            <Text weight="semibold" variant="subtitle2">
                              Add Extension
                            </Text>
                          </Button>
                        </Box>
                      </Box>
                      <Divider margin={'10px 0px'} />
                      <Box>
                        <Text weight="semibold" variant="subtitle2" component="span">
                          Step 5:
                        </Text>
                        <Text component="span" className="text-primary-color">
                          {` Go to Amazon.com or Amazon.co.uk and you will be prompted by Cypherly to login to the extension. Simply copy your API Key
                             below and paste it into the provided box. Now you can now start using the extension for product research directly on Amazon!  `}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Divider margin="20px 0px" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card className={classes.card}>
                <CarHeaderCustomized
                  avatar={<CustomIcon path="/assets/icons/video-tutorial.png" width={40} />}
                  title="Video tutorial"
                />
                <CardContent>
                  <WistiaEmbed hashedId={video} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card className={classes.card}>
                <CarHeaderCustomized
                  avatar={<CustomIcon path="/assets/icons/api-development.png" width={40} />}
                  title="API Credentials"
                />
                <CardContent>
                  <Text weight="semibold" variant="subtitle2" component="span">
                    Api Key
                  </Text>
                  <TextField
                    id="outlined-multiline-flexible"
                    multiline
                    fullWidth
                    maxRows="4"
                    value={auth.user.providerData.uid + '//' + getToken()} //{ this.getToken()}
                    margin="normal"
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                    InputProps={{
                      readOnly: true,
                      style: { color: '#f4821e' },
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div style={{ width: 'auto' }}>
                      <CopyToClipboard
                        onCopy={handleCopiedApiKey}
                        options={{
                          message: auth.user.providerData.uid + '//' + getToken(),
                        }}
                        text={auth.user.providerData.uid + '//' + getToken()}
                      >
                        <Button onClick={() => {}} type="primary">
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Divider margin="80px 0px" />
        </div>
        <SnackBar options={snackbarOptions} onClose={handleCloseSnackbar} />
      </main>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  console.log(auth);
  return { auth: auth };
};
export default connect(mapStateToProps, {})(withFirebase(ChromeExtension));
