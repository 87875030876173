import React from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import { Translate } from 'react-redux-i18n';
import classNames from 'classnames';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import Header from '../../../components/Header';
import Kashing from '../../../components/_kashing';
import TableKeywords from '../../../components/TableKeywords';
import ProgressLine from '../../../components/ProgressLine';
import { setRegion } from '../../../redux/actions/auth';
import globals from '../../../config/globals';
import { Link } from 'react-router-dom';
import { getAggregatedVolumes, getColumnKeyword } from '../utils';

const styles = {
  root: {
    flexGrow: 1,
  },
  label: {
    width: '100%',
    color: '#333',
    fontSize: '13px',
    marginBottom: '-20px',
    fontWeight: '500',
  },
  formControl: {
    width: '48%',
    marginBottom: '30px',
    marginRight: '2%',
  },
  paperContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paper: {
    height: 'auto',
    width: '100%',
    padding: '40px',
  },
  rowCols: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  col50: {
    width: '50%',
  },
  col100: {
    // width: '100%'
  },
  segment: {
    color: '#333',
    marginBottom: '15px',
  },
  segmentCol: {
    marginRight: '10px',
    fontWeight: '600',
    width: '140px',
    display: 'inline-block',
  },
  star: {
    color: '#fff',
  },
  starFilled: {
    color: '#FDD835',
  },
  heartFilled: {
    color: '#FF7043',
  },
  heart: {
    ':hover': {
      color: '#FF7043',
    },
  },
  card: {
    minHeight: '8vw',
    margin: '12px',
    flex: 1,
  },
  questionMark: {
    marginLeft: 5,
  },
};

class DetailsKeywordsPage extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;

    const keyword = this.props.computedMatch.params.id;
    this.state = {
      stories: [],
      orderBy: { field: 'volume', order: 'desc' },
      keywords: [],
      progressLine: { show: true },
      video: '',
      open: false,
      isSearching: false,
      keyword: keyword,
      frency_words: '',
    };
    this.searchKeyword();
  }

  async showResults(results) {
    if (results) {
      let suggestion = [];
      for (let x in results) {
        let node = results[x];
        for (let i in node) {
          let row = {};
          row = node[i];
          row.region = this.props.auth.region;
          row.keyword = row.string;
          suggestion.push(row);
        }
      }
      let newsuggestions = _.orderBy(
        suggestion,
        [this.state.orderBy.field],
        [this.state.orderBy.order],
      );

      newsuggestions = newsuggestions.filter(it => it.string && it.string.length > 0);
      const keywords = newsuggestions.map(keyword => this.onColUpdate(keyword));
      const aggregatedVolumes = getAggregatedVolumes(keywords);
      this.setState({
        keywords,
        aggregatedVolumes,
        inputDisabled: false,
        progressLine: { show: true },
      });
    }
  }

  searchKeyword = async () => {
    if (this.state.keyword !== '') {
      // set the input state
      //this.setState({ inputDisabled: true, progressLine: { show: true }, isLoading: true })
      // settings
      let region = this.props.auth.region.toUpperCase();
      let uri = `${globals.api}/api/keywords/suggestion?rows=4&keyword=${this.state.keyword}&region=${region}`;
      // get the api response
      this.getRequest(uri)
        .then(async response => {
          setTimeout(() => {
            this.showResults(response);
            this.getFrencyWords();
            this.setState({
              inputDisabled: true,
              progressLine: { show: false },
              isLoading: true,
            });
          }, 2000);
        })
        .catch(error => {
          this.setState({ isLoading: false, progressLine: { show: false } });
        });
    }
  };

  onColUpdate = keyword => {
    return getColumnKeyword(keyword);
  };

  getFrencyWords = () => {
    if (!this.state.keywords || this.state.keywords.map(it => it.keyword).length <= 0) {
      return;
    }
    const arrayKeywords = this.state.keywords.map(it => it.keyword).join(' ');
    const wordsArray = arrayKeywords.split(/\s+/);
    let wordsMap = {};
    let finalText = '';
    wordsArray.forEach(key => {
      if (wordsMap.hasOwnProperty(key)) {
        wordsMap[key]++;
      } else {
        wordsMap[key] = 1;
      }
    });
    for (const prop in wordsMap) {
      if (wordsMap[prop] > 1 && /[a-z]/.test(prop.toLowerCase())) {
        finalText += `${prop}(${wordsMap[prop]}) `;
      }
    }
    this.setState({ frency_words: finalText });
  };

  sortTable(o) {
    let newkeywords = _.orderBy(this.state.keywords, [o.field], [o.order]);
    this.setState({ orderBy: o, keywords: newkeywords });
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  addKeyword = () => {};

  render() {
    const { classMain, onDrawerToggle, open } = this.props;
    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Keyword Details"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain} main`}>
          <div className={'page-scroll'}>
            <h3 style={{ color: '#000', fontSize: '25px', paddingLeft: '8px' }}>
              Related Keyword: {this.state.keyword}
            </h3>

            <Grid container spacing={0}>
              <Grid item xs={4}>
                <Card style={styles.card}>
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                      Aggregated Search Volume
                    </Typography>
                    <Typography className="text-primary-color" component="h2">
                      {this.state.keywords.length > 0
                        ? `${this.state.aggregatedVolumes.sumLowVolume}  -  ${this.state.aggregatedVolumes.sumHighVolume}`
                        : 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={styles.card}>
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                      Related Keywords
                    </Typography>
                    <Typography className="text-primary-color" component="h2">
                      {this.thusandsFormat(this.state.keywords.length)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={styles.card}>
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                      Word Frequency
                    </Typography>
                    <Typography className="text-primary-color" component="h2">
                      {this.state.frency_words}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card style={styles.card}>
                  <CardContent>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      style={{
                        fontSize: '1.09rem',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Box component="span" m={1}>
                        <Icon
                          style={{ verticalAlign: 'middle' }}
                          className={classNames('fas fa-key')}
                        />
                      </Box>
                      Top Keywords
                      <Tooltip
                        title="A list of the most significant keywords for this product"
                        placement="right"
                        style={styles.questionMark}
                      >
                        <Icon
                          style={{ verticalAlign: 'middle', marginLeft: '7px' }}
                          className={classNames('fas fa-question-circle')}
                        />
                      </Tooltip>
                    </Typography>

                    <ProgressLine
                      show={this.state.progressLine.show}
                      style={{ width: '100%', marginTop: '-4px', backgroundColor: '#fff' }}
                    />
                    <TableKeywords
                      addKeyword={this.addKeyword}
                      orderBy={this.state.orderBy}
                      sortTable={this.sortTable.bind(this)}
                      isSearching={this.state.isSearching}
                      keywords={this.state.keywords}
                    />

                    <Typography
                      style={{ marginTop: '10px', textAlign: 'center' }}
                      variant="caption"
                      display="block"
                    >
                      Need more keywords? Click here to go to{' '}
                      <Link style={{ textDecoration: 'none' }} to="/keywords/research">
                        keyword finder tool
                      </Link>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(DetailsKeywordsPage));
