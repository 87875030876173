import { CardHeader as CardHeaderMUI, withStyles } from '@material-ui/core';

const CarHeaderCustomized = withStyles(() => ({
  root: {
    borderBottom: '1px solid #dadada',
    paddingLeft: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingTop: 10,
    marginLeft: 16,
    marginRight: 16,
  },
  avatar: {
    marginRight: 10,
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
}))(CardHeaderMUI);

export default CarHeaderCustomized;
