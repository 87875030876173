import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/Star';
import Alert from '@material-ui/lab/Alert';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import SearchIcon from '@material-ui/icons/Search';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import moment from 'moment';
import React from 'react';
import { withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  root: {
    flexGrow: 1,
  },
  label: {
    width: '100%',
    color: '#333',
    fontSize: '13px',
    marginBottom: '-20px',
    fontWeight: '500',
  },
  formControl: {
    width: '48%',
    marginBottom: '30px',
    marginRight: '2%',
  },
  paperContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paper: {
    height: 'auto',
    width: '100%',
    padding: '40px',
  },
  rowCols: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  col50: {
    width: '50%',
  },
  col100: {
    // width: '100%'
  },
  segment: {
    color: '#333',
    marginBottom: '15px',
  },
  segmentCol: {
    marginRight: '10px',
    fontWeight: '600',
    width: '140px',
    display: 'inline-block',
  },
  star: {
    color: '#fff',
  },
  starFilled: {
    color: '#FDD835',
  },
  heartFilled: {
    color: '#FF7043',
  },
  heart: {
    ':hover': {
      color: '#FF7043',
    },
  },
  card: {
    minHeight: '8vw',
    margin: '12px',
    flex: 1,
  },
  card_full: {
    minHeight: '8vw',
    marginTop: '24px',
    marginLeft: '12px',
    marginRigth: '12px',
    flex: 1,
  },
  questionMark: {
    marginLeft: 5,
  },
};

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: 'white',
  },
  bar: {
    borderRadius: 15,
    backgroundColor: '#F4821E',
  },
})(LinearProgress);

function ReviewComponent(isLoading, product, product_rating) {
  function checkIfLoading(value) {
    if (isLoading) {
      return <CircularProgress style={{ marginRight: '10px', width: '18px', height: '18px' }} />;
    }
    return value;
  }
  function setAlibabaLink(productTitle) {
    if (productTitle.length > 0) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            'https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=' +
            productTitle
          }
        >
          <img
            src="/assets/imgs/logo_alibaba.png"
            alt="Amazon Logo"
            style={{
              verticalAlign: 'middle',
              width: '4rem',
              marginLeft: '10px',
              margin: '10px',
            }}
          />
        </a>
      );
    } else {
      return null;
    }
  }
  return (
    <>
      {product ? (
        <>
          <Avatar
            alt={product.asin}
            src={product.small_image_url || 'http://via.placeholder.com/300'}
            style={{
              margin: '0px 12px',
              float: 'left',
              width: '100px',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
          <h3 style={{ color: '#000', fontSize: '25px' }}> {product.title} </h3>
          <div
            style={{
              marginLeft: '12px',
              marginBottom: '20px',
              color: 'gray',
              marginTop: '-20px',
              display: 'flex',
            }}
          >
            <span style={{ verticalAlign: 'middle', marginTop: '5px' }}>Show on</span>
            <a
              href={product.url}
              style={{ color: '#fff' }}
              target="blank"
              rel="noopener noreferrer"
            >
              <img
                border="0"
                src="/assets/imgs/amazon_logo.png"
                alt="Amazon Logo"
                style={{
                  margin: '10px',
                  width: '3rem',
                  verticalAlign: 'middle',
                }}
              />
            </a>
            <span style={{ verticalAlign: 'middle', marginTop: '5px' }}>
              {' '}
              or find a supplier on
            </span>
            {setAlibabaLink(product.title)}
          </div>
        </>
      ) : (
        <div></div>
      )}

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Card style={styles.card}>
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                Overall Rating
              </Typography>
              <Typography className="text-primary-color" component="h2">
                {checkIfLoading(this.state.reviews.summary ? this.state.reviews.summary.rating : 0)}
              </Typography>
              <Typography className="text-primary-color" component="h2">
                {checkIfLoading(product_rating)}
              </Typography>

              <Typography variant="subtitle2" gutterBottom>
                Total Reviews
              </Typography>
              <Typography className="text-primary-color" component="h2">
                {checkIfLoading(
                  this.state.reviews.summary
                    ? this.format(
                        this.state.reviews.summary.reviews_positive +
                          this.state.reviews.summary.reviews_critical,
                        0,
                      )
                    : 0,
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={5}>
          <Card style={styles.card}>
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                Rating Distribution
              </Typography>
              {this.state.starts.map((row, index) => {
                return (
                  <Box display="flex">
                    <Box flexGrow={0}>
                      {row.start}{' '}
                      <span style={{ verticalAlign: 'middle' }}>
                        <StarIcon color="disabled" />
                      </span>
                    </Box>
                    <Box flexGrow={1} p={1}>
                      <BorderLinearProgress
                        variant="determinate"
                        color="secondary"
                        value={
                          this.state.reviews.summary
                            ? this.state.reviews.summary.rating_breakdown[row.name].percentage
                            : 0
                        }
                      />
                    </Box>
                    <Box style={{ paddingTop: '5px' }}>
                      {checkIfLoading(
                        this.state.reviews.summary
                          ? this.state.reviews.summary.rating_breakdown[row.name].percentage
                          : 0,
                      )}
                      % (
                      {this.state.reviews.summary
                        ? this.state.reviews.summary.rating_breakdown[row.name].count
                        : 0}
                      )
                    </Box>
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card style={styles.card}>
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                Review Summary
              </Typography>
              <Alert style={{ marginTop: '1rem' }} severity="success">
                <Box display="flex">
                  <Box flexGrow={1}>Positives</Box>
                  <Box pl={1}>
                    <span style={{ color: 'green' }}>
                      {checkIfLoading(
                        this.state.reviews.summary
                          ? this.format(this.state.reviews.summary.reviews_positive, 0)
                          : 0,
                      )}
                    </span>
                  </Box>
                </Box>
              </Alert>
              <Alert style={{ marginTop: '1rem' }} severity="error">
                <Box display="flex">
                  <Box flexGrow={1}>Criticals</Box>
                  <Box pl={1}>
                    <span style={{ color: 'red' }}>
                      {checkIfLoading(
                        this.state.reviews.summary
                          ? this.format(this.state.reviews.summary.reviews_critical, 0)
                          : 0,
                      )}
                    </span>
                  </Box>
                </Box>
              </Alert>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Card style={styles.card}>
        <CardContent>
          <Typography variant="subtitle2" gutterBottom style={{ fontSize: '1.09rem' }}>
            <Box component="span" m={1} style={{ verticalAlign: 'middle' }}>
              <StarIcon fontSize="small" color="disabled" />
            </Box>
            Review Keywords Intelligence
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} style={{ display: 'inline' }}>
              <Typography variant="h6" component="h2">
                Positive
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                5 and 4 stars
              </Typography>

              <TableContainer>
                {checkIfLoading(
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell align="left">Keyword</TableCell>
                        <TableCell align="left">Weight</TableCell>
                        <TableCell align="left">Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.reviews.positives_rank ? (
                        this.state.reviews.positives_rank.map((row, _index) => {
                          return (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {_index + 1}
                              </TableCell>
                              <TableCell align="left">{row.text}</TableCell>
                              <TableCell align="left">{row.weight}</TableCell>
                              <TableCell align="left">{this.format(row.score, 2)}</TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell colSpan={2} align="left">
                            No data
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>,
                )}
              </TableContainer>
            </Grid>
            <Grid item xs={6} style={{ display: 'inline', borderLeft: '2px solid gray' }}>
              <Typography variant="h6" component="h2">
                Negative
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                3,2 and 1 stars
              </Typography>

              <TableContainer>
                {checkIfLoading(
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell align="left">Keyword</TableCell>
                        <TableCell align="left">Weight</TableCell>
                        <TableCell align="left">Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.reviews.negatives_rank ? (
                        this.state.reviews.negatives_rank.map((row, _index) => {
                          return (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {_index + 1}
                              </TableCell>
                              <TableCell align="left">{row.text}</TableCell>
                              <TableCell align="left">{row.weight}</TableCell>
                              <TableCell align="left">{this.format(row.score, 2)}</TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell colSpan={2} align="left">
                            No data
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>,
                )}
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card style={styles.card_full}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom style={{ fontSize: '1.09rem' }}>
            <Box component="span" m={1} style={{ verticalAlign: 'middle' }}>
              <SearchIcon fontSize="small" color="disabled" />
            </Box>
            Filters
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'inline' }}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '20%' }}>Star Rating</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Review</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.reviews.reviews ? (
                      this.state.reviews.reviews.map((row, _index) => {
                        let row_rating = 'N/A';
                        row_rating = [];
                        const mod_rating = row.rating % 2;
                        const length = parseInt(row.rating);
                        for (let index = 0; index < length; index++) {
                          row_rating.push(<StarIcon key={index} style={{ color: '#F4821E' }} />);
                          if (length !== 5 && mod_rating > 0 && index === length - 1) {
                            row_rating.push(
                              <StarHalfIcon key={index} style={{ color: '#F4821E' }} />,
                            );
                          }
                        }

                        return (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row_rating}
                            </TableCell>
                            <TableCell align="left">
                              {moment(row.date.utc).format('MM/DD/YYYY')}
                            </TableCell>
                            <TableCell
                              style={{
                                color: row.rating >= 4 ? 'green' : 'red',
                              }}
                              align="left"
                            >
                              {row.rating >= 4 ? 'Positive' : 'Negative'}
                            </TableCell>
                            <TableCell align="left">
                              {row.body.length > 100 ? row.body.slice(0, 100) + '...' : row.body}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell colSpan={2} align="left">
                          No data
                        </TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
export default ReviewComponent;
