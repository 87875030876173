import React from 'react';
import { makeStyles, TextField as TextFieldMUI, withStyles } from '@material-ui/core';
import AutocompleteMUI from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const AutocompleteCustomized = withStyles(theme => {
  return {
    root: {
      width: '100%',
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input ': {
        padding: '7px 4px 18px 35px',
      },
      backgroundColor: '#fff',
      border: '0.1px solid #f5eeee',
      color: '#bfbbbb',
      '&:hover .MuiOutlinedInput-notchedOutline': {},
    },
    inputRoot: {
      height: 34,
      borderRadius: 3,
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        outerWidth: 1,
      },
    },
    input: {
      fontSize: '.9rem',
      color: '#bfbbbb',
      fontWeight: 600,
      '&::placeholder': {
        color: '#bfbbbb',
        fontWeight: 600,
        opacity: 1,
      },
      padding: 0,
    },
  };
})(AutocompleteMUI);

const AutocompleteSelect = withStyles(() => {
  return {
    root: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input ': {
        padding: '7px 4px 18px 5px',
      },
    },
    input: {
      fontSize: '.9rem',
      color: '#000',
      fontWeight: 400,
      padding: 0,
      '&::placeholder': {
        color: '#000',
        fontWeight: 400,
        opacity: 1,
      },
    },
  };
})(AutocompleteMUI);

const AutocompleteSelectMultiple = withStyles(() => {
  return {
    root: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input ': {
        padding: '7px 4px 7px 5px',
      },
    },
  };
})(AutocompleteSelect);

const TextFieldCustomized = withStyles(() => ({
  root: {
    minHeight: 34,
    width: '100%',
    borderRadius: 4,
    fontSize: '1rem',

    backgroundColor: '#fff',
    color: '#bfbbbb',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      minHeight: 34,
    },
    fontWeight: 420,
    '&::placeholder': {
      color: '#bfbbbb',
      fontWeight: 420,
      opacity: 1,
    },
    padding: 0,
  },
}))(TextFieldMUI);

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      paddingLeft: 10,
    },
  },
  wrapperInput: { padding: 2, backgroundColor: '#F5F7F8', width: '100%' },
});

const Autocomplete = props => {
  const _props = { ...props };
  delete _props.propertyLabel;
  delete _props.InputProps;
  delete _props.select;
  const { options, propertyLabel, placeholder = '', onChange, value } = props;
  const classes = useStyles();

  const AutocompleteRender = !props.select
    ? AutocompleteCustomized
    : props.multiple
    ? AutocompleteSelectMultiple
    : AutocompleteSelect;

  if (!_props.multiple) {
    _props.getOptionSelected = (option, value) => {
      if (!value[propertyLabel]) return true;

      return value[propertyLabel] === option[propertyLabel];
    };
  }
  return (
    <div className={classes.wrapperInput}>
      <AutocompleteRender
        {..._props}
        value={value}
        openOnFocus={true}
        options={options}
        getOptionLabel={option => option[propertyLabel]}
        onChange={(_, value) => {
          onChange(value);
        }}
        renderInput={params => {
          return (
            <TextFieldCustomized
              placeholder={placeholder}
              className={classes.textField}
              {...params}
              variant="outlined"
            />
          );
        }}
      />
    </div>
  );
};

Autocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  propertyLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default Autocomplete;
