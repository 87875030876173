import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

const ProgressLine = React.memo(function (props) {
  if (props.show) {
    return <LinearProgress style={props.style} />;
  } else {
    return null;
  }
});

ProgressLine.propTypes = {
  show: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
};

ProgressLine.defaultProps = {
  style: {},
};

export default ProgressLine;
