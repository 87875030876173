import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  img: {
    objectFit: 'contain',
  },
});
const SearchDataIcon = ({ width = 200 }) => {
  const classes = useStyles();
  return (
    <img
      className={classes.img}
      width={width}
      src="/assets/icons/search_data.png"
      alt="search data icon"
    />
  );
};

SearchDataIcon.propTypes = {
  width: PropTypes.number,
};
export default SearchDataIcon;
