export const GET_KEYWORD_TAGS = 'GET_KEYWORD_TAGS';
export const SET_KEYWORD_TAGS = 'SET_KEYWORD_TAGS';
export const ADD_KEYWORD_TAG = 'ADD_KEYWORD_TAG';
export const DELETE_KEYWORD_TAG = 'DELETE_KEYWORD_TAG';

export const addTag = tag => {
  return {
    type: ADD_KEYWORD_TAG,
    tag: tag,
  };
};

export const deleteTag = tag => {
  return {
    type: DELETE_KEYWORD_TAG,
    tag: tag,
  };
};

export const setTags = tags => {
  return {
    type: SET_KEYWORD_TAGS,
    tags: tags,
  };
};
