import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { responsiveStateReducer } from 'redux-responsive';
import firekitReducers from 'firekit';

import auth from './auth';
import subscription from './subscription';
import users from './users';
import keyword_tags from './keword_tags';

const reducers = Object.assign({}, firekitReducers, {
  auth,
  users,
  keyword_tags,
  subscription,
  i18n: i18nReducer,
  browser: responsiveStateReducer,
});

export default combineReducers(reducers);
