const dev = {
  api: 'http://localhost:5000',
  domain: 'http://localhost:5000',
  apiSecondary: 'http://localhost:5000',
  apiThird: 'http://localhost:5000',
  fastAPI: 'http://localhost:5001',
  firebase: {
    apiKey: 'AIzaSyDME7OJb3sifbL5hGRuLLmEPPFiCeBBiT8',
    authDomain: 'cypherly-io.firebaseapp.com',
    databaseURL: 'https://cypherly-io.firebaseio.com',
    projectId: 'cypherly-io',
    storageBucket: 'cypherly-io.appspot.com',
    messagingSenderId: '584698697673',
    appId: '1:584698697673:web:b385cbf06683ec82',
  },
};

const prod = {
  api: 'https://cypherly-io.appspot.com',
  apiSecondary: 'https://secondary-dot-cypherly-io.appspot.com',
  apiThird: 'https://secondary-dot-cypherly-io.appspot.com',
  fastAPI: 'https://main-api-2op255ltla-ue.a.run.app',
  domain: 'https://cypherly-io.appspot.com',
  firebase: {
    apiKey: 'AIzaSyDME7OJb3sifbL5hGRuLLmEPPFiCeBBiT8',
    authDomain: 'cypherly-io.firebaseapp.com',
    databaseURL: 'https://cypherly-io.firebaseio.com',
    projectId: 'cypherly-io',
    storageBucket: 'cypherly-io.appspot.com',
    messagingSenderId: '584698697673',
    appId: '1:584698697673:web:b385cbf06683ec82',
  },
};

const config = process.env.REACT_APP_ENV === 'prod' ? prod : dev;

export default {
  ...config,
};
