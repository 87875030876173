/* eslint-disable indent */
/* eslint-disable react/prop-types */

import React, { useReducer, useRef, useEffect } from 'react';

import globals from '../../../config/globals';
import { makeStyles, Typography } from '@material-ui/core';
import Autocomplete from '../../molecules/Autocomplete';
import { SearchIcon } from '../../atoms/Icons';
const initialState = { isLoading: false, data: [], message: '', error: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'fetching':
      return { ...state, isLoading: true };
    case 'success':
      return { ...state, isLoading: false, data: action.payload };
    case 'failure':
      return { ...state, isLoading: false, data: [], error: action.payload };
    case 'restart':
      return initialState;
    default:
      return state;
  }
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      color: 'green',
      borderRadius: 4,
      height: 34,
      padding: 0,
    },
  },
  boxIcon: { width: 20, height: 20, position: 'absolute', bottom: 11, left: 17, zIndex: 2 },
});

const SearchAutocompleteKeywords = ({ region = 'US', onChange }) => {
  const classes = useStyles();
  const [search, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = React.useState(false);

  const cacheRef = useRef(null);
  let cancelRequest = false;

  const fetchKeywordsByText = async keyword => {
    dispatch({ type: 'fetching' });
    let uri = `${globals.apiSecondary}/api/keywords/auto_complete/${keyword}/region/${region}`;

    if (cacheRef.current) {
      const data = cacheRef.current[uri];
      if (data && data.length > 0) {
        dispatch({ type: 'success', payload: data.map(name => ({ name })) });
        return;
      }
    }

    try {
      // get the api response
      const response = await fetch(uri, {
        mode: 'cors',
        headers: { Accept: 'application/json' },
      });

      const responseJson = await response.json();
      const keywordsList = responseJson.response;
      if (keywordsList.length === 0) {
        throw new Error('Keywords not found');
      }
      const keywordFoundInList = keywordsList.find(item => item === keyword);
      if (!keywordFoundInList) keywordsList.unshift(keyword);
      if (cancelRequest) return;

      cacheRef.current = {
        ...cacheRef.current,
        [uri]: keywordsList,
      };

      dispatch({ type: 'success', payload: keywordsList.map(name => ({ name })) });
    } catch (error) {
      console.error('error', error);
      if (cancelRequest) return;
      dispatch({ type: 'failure', payload: error.message });
    }
  };

  useEffect(() => {
    return () => {
      cancelRequest = true;
    };
  }, []);

  useEffect(() => {
    if (!open) {
      dispatch({ type: 'restart' });
    }
  }, [open]);

  return (
    <div>
      <div className={classes.container}>
        <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
          <div className={classes.boxIcon}>
            <SearchIcon color={'disabled'} />
          </div>

          <Autocomplete
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            propertyLabel={'name'}
            onInputChange={e => {
              const value = e.target.value;
              if (value && value.length > 2) {
                setOpen(true);
                fetchKeywordsByText(value);
                if (onChange) onChange({ name: value });
              }
            }}
            onChange={value => {
              if (onChange) onChange(value);

              console.log('value: ', value);
            }}
            options={search.data}
            loading={search.isLoading}
            freeSolo
            selectOnFocus
            placeholder="Enter a Keyword"
          />
        </div>
      </div>
      {search.error ? <Typography color="error">{search.error}</Typography> : ''}
    </div>
  );
};

export default SearchAutocompleteKeywords;
