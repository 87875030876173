/*eslint-disable*/
import React from 'react';

//material ui components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';

import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Fade from 'react-reveal/Zoom';

// main extended component to acces methods for all react components
import Kashing from '../../../components/_kashing';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewSearchData from '../../../components/molecules/ViewSearchData';
import Shadow from '../../../components/atoms/Shadow';
import Divider from '../../../components/atoms/Divider';
import AddIcon from '../../../components/atoms/Icons/AddIcon/AddIcon';
import Tooltip from '../../../components/atoms/Tooltip';
import { Zoom } from '@material-ui/core';

const styles = {
  title_ellipsis: {
    whiteSpace: 'nowrap',
    width: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 10px',
  },
  title_out_ellipsis: {
    width: '150px',
    overflow: 'hidden',
    padding: '0 10px',
  },
};

class TableData extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.auth = this.props.firebaseApp.auth();
    this.pending_asins = [];
    this.state = {
      pending_asin: [],
      data: [],
      rowActionSelected: null,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  addProduct = async row => {
    this.setState(
      {
        rowActionSelected: row,
      },
      async () => {
        await this.props.addProduct(row);

        this.setState({
          rowActionSelected: null,
        });
      },
    );
  };

  handleTrackHistory = async row => {
    this.setState(
      {
        rowActionSelected: row,
      },
      async () => {
        await this.props.trackHistory(row);

        this.setState({
          rowActionSelected: null,
        });
      },
    );
  };

  handleUnTrack = async row => {
    this.setState(
      {
        rowActionSelected: row,
      },
      async () => {
        await this.props.openConfirmDelete(row);
        setTimeout(() => {
          this.setState({
            rowActionSelected: null,
          });
        }, 7000);
      },
    );
  };

  tableActions(row, cellLeftClass) {
    if (row.status === 'db') {
      return (
        <TableCell className={cellLeftClass} style={{ textAlign: 'center' }}>
          <Fade>
            <div
              style={{
                display: 'flex',
                paddingRight: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {this.state.rowActionSelected && this.state.rowActionSelected.asin === row.asin && (
                <CircularProgress style={{ color: 'black' }} size={18} />
              )}
              {(!this.state.rowActionSelected ||
                this.state.rowActionSelected.asin !== row.asin) && (
                <>
                  <Shadow style={{ borderRadius: '50%', padding: 0 }}>
                    <div
                      role="button"
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onClick={async () => await this.handleTrackHistory(row)}
                    >
                      <img
                        style={{ width: 22, objectFit: 'contain' }}
                        src={
                          row.trackMonitor ? '/assets/icons/unhide.png' : '/assets/icons/hide.png'
                        }
                      />
                    </div>
                  </Shadow>
                  <Divider margin={'0px 2px'} />
                  <Shadow style={{ borderRadius: '50%', padding: 0 }}>
                    <img
                      onClick={async () => await this.handleUnTrack(row)}
                      style={{ width: 20, objectFit: 'contain' }}
                      src="/assets/icons/trash.png"
                    />
                  </Shadow>
                </>
              )}
            </div>
          </Fade>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          className={cellLeftClass}
          style={{
            textAlign: 'center',
          }}
        >
          <Fade>
            <div className="d-flex-center">
              {this.state.rowActionSelected && this.state.rowActionSelected.asin === row.asin && (
                <CircularProgress style={{ color: 'black' }} size={20} />
              )}
              {(!this.state.rowActionSelected ||
                this.state.rowActionSelected.asin !== row.asin) && (
                <Tooltip
                  TransitionComponent={Zoom}
                  key={`tooltip-${row.asin}`}
                  title={'Add to your Product Tracker'}
                  placement="top"
                >
                  <div
                    role="button"
                    onClick={async () => await this.addProduct(row)}
                    className="icon-plus"
                  >
                    <AddIcon width={20} />
                  </div>
                </Tooltip>
              )}
            </div>
          </Fade>
        </TableCell>
      );
    }
  }

  _renderSearchStatus = (status = 'Please wait...') => {
    return (
      <TableBody className="tableBody">
        <TableRow className="tableRow">
          <TableCell colSpan={this.props.headers.length} style={{ textAlign: 'center' }}>
            {status}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  _renderNoDataStatus = () => {
    return (
      <TableBody className="tableBody">
        <TableRow className="tableRow">
          <TableCell colSpan={this.props.headers.length} style={{ textAlign: 'center' }}>
            No data
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  _renderInitView = () => {
    return (
      <TableBody className="tableBody">
        <TableRow className="tableRow">
          <TableCell colSpan={this.props.headers.length} style={{ textAlign: 'center' }}>
            <ViewSearchData message={this.props.message || 'Loading...'} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  _getEstimationResult = row => {
    let est_sales_result;
    if (
      row.sales_estimate &&
      row.sales_estimate.estimate &&
      // row.sales_estimate.estimate !== null &&
      // row.sales_estimate.estimate !== undefined &&
      row.sales_estimate.estimate !== '' &&
      row.sales_estimate.estimate !== '0'
    ) {
      if (row.sales_estimate.estimate >= 1000) {
        est_sales_result =
          this.thusandsFormat(row.sales_estimate.estimate, 1) +
          this.thusandsFormatLetter(row.sales_estimate.estimate, false) +
          '/mo';
      } else {
        if (row.sales_estimate.estimate === 'N/A') {
          est_sales_result = 'N/A';
        } else if (row.sales_estimate.estimate <= 0) {
          est_sales_result = 'Less than 10';
        } else {
          est_sales_result =
            this.thusandsFormat(row.sales_estimate.estimate, 0) +
            this.thusandsFormatLetter(row.sales_estimate.estimate, false) +
            '/mo';
        }
      }
    } else {
      est_sales_result = 'N/A';
    }

    let estimate_result;
    if (
      (row.sales_estimate &&
        row.sales_estimate.estimate &&
        // row.sales_estimate.estimate !== null &&
        // row.sales_estimate.estimate !== undefined &&
        row.sales_estimate.estimate !== '' &&
        row.sales_estimate.estimate !== '0') ||
      row.sales_estimate.estimate > 0
    ) {
      //here region
      let symbol = '\u0024';
      if (this.props.auth.region.toUpperCase() === 'UK') {
        symbol = '\u00A3';
      }
      let estimate = row.sales_estimate.estimate;
      let offer_priceMoney = this.moneyFormat(row.offer_price.amount, this.props.auth.region);
      if (offer_priceMoney === 'N/A' && !offer_priceMoney) {
        estimate_result = 'N/A';
      } else if (estimate <= 0) {
        estimate_result = 'N/A';
      } else {
        let offer_price = 'N/A';
        try {
          offer_price = offer_priceMoney.toString();
          offer_price = offer_price.substring(1, offer_price.length);
          let total = this.thusandsFormat(parseFloat(offer_price) * parseFloat(estimate), 1);
          if (!total || !offer_price || !estimate) {
            estimate_result = 'N/A';
          } else
            estimate_result =
              symbol +
              total +
              this.thusandsFormatLetter(parseFloat(offer_price) * parseFloat(estimate), false) +
              '/mo';
        } catch (e) {
          estimate_result = 'N/A';
        }
      }
    } else {
      estimate_result = 'N/A';
    }

    return [est_sales_result, estimate_result];
  };

  render() {
    const data = this.props.data;
    if (data.length === 0 && !this.props.isSearched) return this._renderInitView();
    if (data.length === 0 && this.props.isSearched) return this._renderNoDataStatus();
    if (this.props.isSearching) {
      return this._renderSearchStatus(this.props.searchingText || 'Please wait...');
    }

    if (data.length > 0) {
      return (
        <TableBody className="tableBody">
          {data.map((row, index) => {
            const cellRightTopClass = index === 0 ? ' td-top-first ' : '';
            const cellLeftTopClass = index === 0 ? ' td-top-last ' : '';

            const cellRightBottomClass = data.length - 1 === index ? ' td-bottom-first ' : '';
            const cellLeftBottomClass = data.length - 1 === index ? ' td-bottom-last ' : '';

            const cellRightClass = 'tableCell ' + cellRightTopClass + cellRightBottomClass;
            const cellLeftClass = 'tableCell ' + cellLeftTopClass + cellLeftBottomClass;

            const [est_sales_result, estimate_result] = this._getEstimationResult(row);

            if (row.show) {
              return (
                <TableRow key={index} className="tableRow">
                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className={cellRightClass}
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>
                        <img
                          src={`/assets/imgs/flags-normal/${row.region.toLowerCase()}.png`}
                          alt={row.region}
                          width="20px"
                        />
                      </Fade>
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>{row.asin}</Fade>
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>
                        <Avatar
                          alt={row.asin}
                          src={row.small_image_url || 'http://via.placeholder.com/300'}
                          style={{ margin: 'auto' }}
                        />
                      </Fade>
                    </TableCell>
                  </Hidden>
                  <TableCell onClick={() => this.props.selectRow(row)} className="tableCell">
                    <Fade>
                      <div style={styles.title_ellipsis}>{row.title || ''}</div>
                    </Fade>
                  </TableCell>
                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>
                        <div style={{ width: '130px' }}>
                          {row.product_group || row.main_category ? row.main_category : 'NA'}
                        </div>
                      </Fade>
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>{row.bsr ? this.format(row.bsr || null, 0) : 'N/A'}</Fade>
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>
                        {row && row.list_price && row.list_price.amount
                          ? this.moneyFormat(row.list_price.amount, this.props.auth.region)
                          : ''}
                      </Fade>
                    </TableCell>
                  </Hidden>

                  <Hidden smDown>
                    <Hidden smDown>
                      <TableCell
                        onClick={() => this.props.selectRow(row)}
                        className="tableCell"
                        style={{ textAlign: 'center' }}
                      >
                        <Fade>
                          {row.pending === true ? (
                            <CircularProgress style={{ marginLeft: '20px' }} />
                          ) : (
                            est_sales_result
                          )}
                        </Fade>
                      </TableCell>
                    </Hidden>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>
                        {row.pending === true ? (
                          <CircularProgress style={{ marginLeft: '20px' }} />
                        ) : (
                          estimate_result
                        )}
                      </Fade>
                    </TableCell>
                  </Hidden>

                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>{row.rating ? this.format(row.rating, 1) : 'N/A'}</Fade>
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell
                      onClick={() => this.props.selectRow(row)}
                      className="tableCell"
                      style={{ textAlign: 'center' }}
                    >
                      <Fade>{row.reviews_count ? this.format(row.reviews_count, 0) : 'N/A'}</Fade>
                    </TableCell>
                  </Hidden>
                  <Hidden smDown>
                    {!this.props.noActions ? this.tableActions(row, cellLeftClass) : <></>}
                  </Hidden>
                </TableRow>
              );
            }
          })}
        </TableBody>
      );
    }
  }
}

const mapStateToProps = state => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(TableData));
