import { firebaseApp } from '../config/firebase';

class FirestoreService {
  constructor(firestore) {
    this.firestore = firestore;
  }

  async getDocs(colPath, docPath) {
    const docRef = await this.firestore.collection(colPath).doc(docPath).get();
    if (!docRef.exists) return null;
    return docRef.data();
  }

  async getCollection(colPath) {
    const colRef = await this.firestore.collection(colPath).get();
    return colRef.docs;
  }

  async searchDocs(colPath, where = [], orderBy = []) {
    let collection = this.firestore.collection(colPath);
    where.forEach(([key, opStr, value]) => {
      collection = collection.where(key, opStr, value);
    });
    orderBy.forEach(([fieldPath, dirStr]) => {
      collection = collection.orderBy(fieldPath, dirStr);
    });

    const result = await collection.get();
    return result.docs;
  }

  subscribeSearchDocs(colPath, where = [], orderBy = [], cb = null) {
    let collection = this.firestore.collection(colPath);
    where.forEach(([key, opStr, value]) => {
      collection = collection.where(key, opStr, value);
    });
    orderBy.forEach(([fieldPath, dirStr]) => {
      collection = collection.orderBy(fieldPath, dirStr);
    });

    return collection.onSnapshot(cb);
  }

  async updateDocs(colPath, docPath, data) {
    return await this.firestore.collection(colPath).doc(docPath).update(data);
  }

  async bulkUpdateDocs(colPath, docPathList, data) {
    const batch = this.firestore.batch();

    for (const docPath of docPathList) {
      const ref = this.firestore.collection(colPath).doc(docPath);
      batch.update(ref, data);
    }
    return await batch.commit();
  }

  async setDocs(colPath, docPath, data, setOptions = {}) {
    return await this.firestore.collection(colPath).doc(docPath).set(data, setOptions);
  }

  async deleteDocs(colPath, docPath) {
    return this.firestore.collection(colPath).doc(docPath).delete();
  }
}

let serviceInstance = null;

const getFirestoreService = function () {
  // console.log('FirestoreServiceInstance: ', serviceInstance);
  if (!serviceInstance) {
    console.log('FirestoreServiceInstance created');
    serviceInstance = new FirestoreService(firebaseApp.firestore());
  }
  return serviceInstance;
};

export default getFirestoreService;
