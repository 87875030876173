/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';

import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import { setRegion } from '../../../redux/actions/auth';

import Box from '@material-ui/core/Box';

import Header from '../../../components/Header';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { GetRequest } from '../../../utils';
import globals from '../../../config/globals';
import { algoliaSearch } from '../../../config/firebase';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const ProductsAdminReport = ({ classMain, onDrawerToggle, open, auth }) => {
  const classes = useStyles();

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleClickOpen = () => {
    this.setState({ open: true });
  };

  const fetchProducts = async () => {
    try {
      let products = localStorage.getItem('products');
      if (products) {
        products = JSON.parse(products);
      } else {
        let uri = `${globals.api}/api/products/report/${auth.region}`;
        console.log('URI', uri);
        const response = await fetch(uri);
        products = await response.json();
        localStorage.setItem('products', JSON.stringify(products));
      }
      const productsFiltered = [];
      for (let product of products) {
        const productFound = productsFiltered.find(item => item.category === product.category);
        if (!productFound) {
          productsFiltered.push(product);
        }
      }

      productsFiltered.sort((a, b) => (a.count > b.count ? -1 : 1));

      setProducts(productsFiltered);
    } catch (error) {
      console.log('error:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  return (
    <>
      <Header
        goTo={this.props.goTo}
        open={open}
        onChangeRegion={onChangeRegion}
        region={auth.region}
        onDrawerToggle={onDrawerToggle}
      >
        <Box display="flex" p={1}>
          <Box p={1} width="100%">
            <Translate value="Product Admin Report" />
          </Box>

          <Box p={1} flexShrink={0}>
            <span
              style={{ color: '#fff', cursor: 'pointer', fontSize: 'small' }}
              onClick={handleClickOpen}
            >
              How does this screen work?
            </span>
          </Box>
        </Box>
      </Header>
      <main className={classMain}>
        <div className="page container">
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>BSR 1-2k</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map(row => (
                  <TableRow key={row['category:']}>
                    <TableCell component="th" scope="row">
                      {row['category']}
                    </TableCell>
                    <TableCell align="right">{row['count']}</TableCell>
                    <TableCell align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = state => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(ProductsAdminReport));
