import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import { setRegion } from '../../../redux/actions/auth';
import TableData from '../components/TableData';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';

import Header from '../../../components/Header';
import Kashing from '../../../components/_kashing';
import MainTable from '../../../components/MainTable';
import SnackBar from '../../../components/Snackbar';

import categories from '../../../utils/categories';
import products_headers from '../../../utils/products_headers';
import getFirestoreService from '../../../utils/firestoreService';
import { manageSalesEstimateByProduct } from '../../../utils/productsServices';
import { cleanProducts, getEstimateTotal, isIncludeTitle, manageProductImages } from '../utils';
import RequestDialog from '../../../components/RequestDialog';
import ProductFinderFilters from '../../../components/organisms/ProductFinderFilters/ProductFinderFilters';
import ProductFiltersProvider from '../../../providers/ProductFilters/ProductFiltersProvider';
import DialogVideo from '../../../components/molecules/DialogVideo';

import {
  findProductsWithFilters,
  getFavoritesProducts,
  nameFilterParsed,
  saveFavoritesProducts,
  setDbUser,
  setFavoriteProduct,
} from '../../../helpers';

const firestoreService = getFirestoreService();

const initialPagination = {
  products: [],
  offset: 0,
  limit: 20,
};

class ProductsFinderPage extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;

    this.state = {
      show: true,
      keywords: '',
      asins: '',
      products: [],
      showFilters: false,
      table: [],
      item: null,
      advancedWindowOpen: false,
      searchResults: [],
      orderBy: { field: 'bsr', order: 'asc' },
      asisnsInputDisabled: false,
      selectedRowId: null,
      filter: {
        category: '',
      },
      filters: [],
      pre_filter: {
        bsr: { max: 6000, min: 0 },
        list_price: { max: 5000, min: 1500 },
        reviews: { max: 1000, min: 0 },
      },
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      advancedWindow: false,
      tableProductHeaders: products_headers,
      search_index: '',
      categories: categories,
      progressLine: {
        show: false,
      },
      progresslineAsin: {
        show: false,
      },
      filter_bar: {
        expanded: false,
      },
      trackerProducts: [],
      productCategories: [],
      selectItem: null,
      video: '',
      open: false,
      loadingProgress: false,
      isSearching: false,
      isLoaded: false,
      isOpenRequestDialog: false,
      isFiltered: false,
      pagination: { ...initialPagination },
      isLoadingMore: false,
    };

    this.auth = this.props.firebaseApp.auth();
    this.keyword = '';
    this.min_price = '';
    this.max_price = '';
  }

  async componentDidUpdate(props, state) {
    if (props.auth.region !== this.props.auth.region) {
      await this.searchByStateFilters()
    }
  }

  async searchByStateFilters() {
    const filters = this.state.filters;
    if (filters && filters.length > 0) {
      await this.onHandleFilter(filters);
    }
  }

  async componentDidMount() {
    await this.validateSuscription(this.props.auth.user);
    await this.fetchProductCategories();
    await this.getTrackerProducts();

    this.state.filter_bar.expanded = true;
  }

  async getTrackerProducts() {
    let state = this.store.getState();
    let trackerProducts = await getFavoritesProducts(state.auth.user.gid)

    this.setState({
      trackerProducts,
    });
  }

  async fetchProductCategories() {
    const region = this.props.auth.region;
    const row = await firestoreService.getDocs('categories', region.toUpperCase());
    const categories = row.data;
    let _categories = [];

    categories.forEach(item => {
      _categories[item] = item;
    });

    this.setState({ productCategories: _categories });
  }

  setFilter(dataset, filters = null) {
    let results = dataset;

    if (this.state.filters || filters) {
      const fields = {
        bsr: 'bsr',
        rating: 'rating',
        reviews: 'reviews_count',
        list_price: 'list_price.amount',
        offer_price: 'offer_price.amount',
        sales_estimate: 'sales_estimate.estimate',
        sales_estimate_total: 'sales_estimate.total',
      };

      if (!filters) filters = this.state.filters;

      for (let key in filters) {
        let field = fields[key];
        results = _.filter(results, product => {
          let value = this.getValueFromData(field, product);
          let min = filters[key].min;
          if (filters[key].max > 0) {
            let max = filters[key].max;
            if (value >= min && value <= max) {
              return product;
            }
          } else {
            if (value >= min) {
              return product;
            }
          }
        });
      }
    }
    return results;
  }

  sortTable(o) {
    this.setState({ orderBy: o }, () => {
      let sort = _.orderBy(
        this.state.table,
        [this.state.orderBy.field],
        [this.state.orderBy.order],
      );
      this.setState({ table: sort });
    });
  }

  searchByAsin(asin) {
    const region = this.props.auth.region.toUpperCase();
    if (asin) {
      this.setState({ isSearching: true, isFiltered: false });
      //const uri = `${globals.api}/api/products/${asin}/region/${region}`;
      this.searchProductByAsin({filters: [{ asin }, { region }]});
    }
  }

  showNotFoundResults() {
    this.setState({
      isSearching: false,
      isFiltered: false,
      snackbar: {
        open: true,
        title: ' ',
        type: 'error',
        message: 'No results found',
        position: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
    });
  }

  async searchProductByAsin(payload) {
    findProductsWithFilters(payload)
      .then(async result => {
        let item = result.length > 0 ? result[0] : {};

        if (item.asin) {
          let dataset = [];
          item.status = 'temporal';
          item.show = true;
          item.region = this.props.auth.region;
          dataset.push(item);
          let merge = _.union(dataset, this.state.products);
          merge = _.orderBy(
            merge,
            ['status', this.state.orderBy.field],
            ['asc', this.state.orderBy.order],
          );
          this.setState({
            table: merge,
            searchResults: dataset,
            progresslineAsin: { show: false },
            isSearching: false,
            isFiltered: false,
          });
        } else {
          this.showNotFoundResults();
        }
      })
      .catch(error => {
        this.setState({
          progresslineAsin: { show: false },
          snackbar: {
            open: true,
            type: 'error',
            message: 'Something was wrong, try again, [Server communication error]',
            position: {
              vertical: 'top',
              horizontal: 'right',
            },
          },
        });
      });
  }

  addProductFromAdvancedSearch = async row => {
    return new Promise((resolve, reject) => {
      this.setState({ item: row }, async () => {
        this.handleSnackbarClose();
        await this.addProduct();
        resolve('ok');
      });
    });
  };

  async addProduct() {
    let state = this.store.getState();
    this.setState({ show: false });

    if (this.state.trackerProducts.length >= 25) {
      this.setState({
        snackbar: {
          open: true,
          type: 'error',
          message: 'Remove some products and try again.',
          position: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
      });

      return;
    }

    let user = setDbUser(state.auth.user);

    let payload = {
      ...user,
      favorite: setFavoriteProduct(this.state.item),
      lastUpdatedAt: moment().toDate()
    }

    await saveFavoritesProducts(payload)

    this.setState((state) => {
      state.trackerProducts.push(setFavoriteProduct(state.item))

      return {
        trackerProducts: state.trackerProducts,
        table: this.filterOmitInTracker(state.table),
        snackbar: {
          open: true,
          type: 'success',
          message: 'This product has successfully been added to your Product Tracker',
          position: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
      }
    });
  }

  getCatalog(field) {
    let items = this.state.table.map((item) => Object.byString(item, field));
    return _.uniq(items);
  }

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  selectRow(row) {
    this.state.selectItem = row;
  }

  openConfirmDelete(row) {}

  getValueFromData(str, product) {
    let parts = str.split('.');
    let o = null;
    if (product[parts[0]]) {
      o = product[parts[0]];
      for (let i in parts) {
        let key = parts[i];
        if (o[key]) {
          o = o[key];
        }
      }
    }
    return o;
  }

  setLoaderComplete = () => {
    setTimeout(() => {
      this.setState({
        isSearching: false,
        isLoaded: false,
      });
    }, 2000);
  };

  existInTracker(row) {
    const trackerProducts = this.state.trackerProducts;
    return trackerProducts.find((item) => item.productId === row.productId);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  onChangeData(row) {
    let table = this.state.table;
    const fr = table.find(item => item.asin === row.asin);
    const i = table.indexOf(fr);
    table[i] = row;
    this.setState({ table: table });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
    setTimeout(() => {
      this.onHandleFilter(this.state.filters);
    }, 500);
  };

  onHandleFilter = async (filters) => {
    await this.setState({
      table: [],
      productsFound: [],
      pagination: { ...initialPagination },
      filters,
    });

    if (filters.asin) {
      this.searchByAsin(filters.asin);
      return;
    }

    const currencies = ['price', 'monthly_sales'];

    for (let key in filters.range) {
      if (currencies.includes(key)) {
        filters.range[key].min = Math.floor(parseFloat(filters.range[key].min)) * 100;
        filters.range[key].max = parseFloat(filters.range[key].max) * 100;
      }
    }

    await this.searchProducts(
      filters.range,
      filters.included_keywords,
      filters.excluded_keywords,
      filters.categories,
    );
  };

  manageEmptyReponse = () => {
    this.setState(
      {
        table: [],
        products: [],
        isLoaded: true,
        isFiltered: true,
      },
      this.setLoaderComplete,
    );
  };

  filterOmitInTracker = (products) => products.filter(product => !this.existInTracker(product));

  manageSalesEstimateByProducts = async (products, region) => {
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      product.status = 'tmp';
      product.show = true;
      product.product_group = product.product_group ? product.product_group.split('>')[0] : 'N/A';
      const estimate = await manageSalesEstimateByProduct(product, region);

      product.sales_estimate = {
        ...product.sales_estimate,
        estimate: estimate,
      };
      products[i] = product;
    }
    return products;
  };

  manageFilterByKeywords = (products, includedKeywords, excludedKeywords) => {
    if (includedKeywords || excludedKeywords) {
      if (includedKeywords) {
        products = products.filter(product => {
          return isIncludeTitle(product.title, includedKeywords);
        });
      }

      if (excludedKeywords) {
        products = products.filter(product => {
          return !isIncludeTitle(product.title, excludedKeywords);
        });
      }
      return products;
    }
    return products;
  };

  loadProductsByChunks = async () => {
    const region = this.props.auth.region.toUpperCase();
    const products = await this.manageSalesEstimateByProducts(
      this.state.pagination.products,
      region,
    );

    // order table by field
    const table = _.orderBy(products, [this.state.orderBy.field], [this.state.orderBy.order]);
    //set to view
    const productsMerged = [...this.state.table, ...table];
    this.setState(
      {
        table: productsMerged,
        products: productsMerged,
        isLoaded: true,
        isFiltered: true,
        isLoadingMore: false,
      },
      this.setLoaderComplete,
    );
  };

  setFilterRanges = (range) => {
    if (!range) return {}

    return Object.entries(range).map(item => {
      let result = {}
      let innerQuery = {}
      if (item[1].min) innerQuery.from = item[1].min
      if (item[1].max) innerQuery.to = item[1].max
      let nameFilter = nameFilterParsed(item[0])

      result[nameFilter] = innerQuery
      return result
    })
  }

  setInitialQuery = (range, categories, region) => {
    if (!range && categories.length === 0) return {}
    let ranges = this.setFilterRanges(range)
    let _categories = categories.map((item) => ({ categories: item.name }))

    return {
      filters: [
        ...ranges,
        ..._categories,
        {region}
      ]
    }
  }

  searchProducts = async (range, includedKeywords, excludedKeywords, categories = []) => {
    try {
      this.setState({ isSearching: true, isFiltered: false });
      const region = this.props.auth.region.toUpperCase();

      let initialQuery = this.setInitialQuery(range, categories, region)
      let results = await findProductsWithFilters(initialQuery)

      if (results.length === 0) {
        this.manageEmptyReponse();
        return;
      }

      let products = results;
      products = cleanProducts(products);
      products = getEstimateTotal(products);
      products = manageProductImages(products);
      products = this.manageFilterByKeywords(products, includedKeywords, excludedKeywords);

      products = this.filterOmitInTracker(products);

      const productsSplitted = products.slice(
        this.state.pagination.offset,
        this.state.pagination.limit,
      );

      this.setState(
        {
          productsFound: products,
          pagination: { ...this.state.pagination, products: productsSplitted },
        },
        this.loadProductsByChunks,
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  loadMore = event => {
    if (this.state.isLoadingMore) {
      return;
    }
    const element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      const { productsFound } = this.state;

      let { offset, limit, products } = this.state.pagination;

      let nextOffset = offset + 10;
      if (offset === 0) {
        nextOffset = 20;
      }
      const nextLimit = nextOffset + 10;

      const productsSliced = productsFound.slice(nextOffset, nextLimit);

      if (!productsSliced || productsSliced.length === 0) {
        return;
      }

      const productsMerge = [...products, ...productsSliced];

      this.setState(
        {
          isLoadingMore: true,
          pagination: {
            offset: nextOffset,
            limit,
            products: productsSliced,
          },
        },
        this.loadProductsByChunks,
      );

      // Do load more content here!
    }
  };

  render() {
    const { classMain, onDrawerToggle, open } = this.props;
    let categories_options = [];
    this.state.categories.forEach((item, index) => {
      categories_options.push(
        <option key={'category-option' + index} value={item}>
          {item}
        </option>,
      );
    });
    let main_categories = this.getCatalog('product_group');
    main_categories.forEach((item, index) => {
      main_categories.push(
        <option key={'main-category-option' + index} value={item}>
          {item}
        </option>,
      );
    });

    return (
      <ProductFiltersProvider>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Product Finder"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain} main`}>
          <div className="page-scroll">
            <DialogVideo
              open={this.state.open}
              onClose={this.handleClose.bind(this)}
              videoHashedId={this.state.video}
            />

            <ProductFinderFilters
              isFiltered={this.state.isFiltered}
              onFilter={this.onHandleFilter}
              productCategories={this.state.productCategories}
              onReset={() => {
                this.setState({
                  isFiltered: false,
                });
              }}
            />

            <Fade
              in={this.state.loadingProgress}
              style={{
                transitionDelay: this.state.loadingProgress ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <div>
                <Paper style={{ padding: '30px', marginTop: '20px' }}>
                  <Typography variant="h5" component="h3">
                    Please wait for a bit... We are digging deeper to get the data on this product
                    <CircularProgress style={{ marginLeft: '20px' }} />
                  </Typography>
                </Paper>
              </div>
            </Fade>

            <div ref={this.myRef} onScroll={this.loadMore} className="table-scroll">
              <MainTable
                headers={this.state.tableProductHeaders}
                orderBy={this.state.orderBy}
                sortTable={this.sortTable.bind(this)}
              >
                <TableData
                  isSearched={this.state.isFiltered}
                  message={'To find your ideal product, select a category and set your filters'}
                  data={this.state.table}
                  onChangeData={this.onChangeData.bind(this)}
                  isSearching={false}
                  headers={this.state.tableProductHeaders}
                  selectRow={this.selectRow.bind(this)}
                  addProduct={this.addProductFromAdvancedSearch}
                  openConfirmDelete={this.openConfirmDelete.bind(this)}
                />
              </MainTable>
            </div>
            {this.state.isLoadingMore && (
              <Box display={'flex'} marginY={1} justifyContent="center">
                <CircularProgress color="primary" size={20} />
              </Box>
            )}

            <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />
            {this.state.isSearching && (
              <RequestDialog
                open={this.state.isSearching}
                isLoaded={this.state.isLoaded}
                handleClose={() => {}}
              />
            )}
          </div>
        </main>
      </ProductFiltersProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};

export default connect(mapStateToProps, {})(withFirebase(ProductsFinderPage));
