/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';

import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import { setRegion } from '../../../redux/actions/auth';

import Box from '@material-ui/core/Box';

import Header from '../../../components/Header';
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent, CardHeader, LinearProgress, Typography } from '@material-ui/core';
import TextFieldSearch from '../../../components/TextFieldSearch';
import { useFetchGet, useFetchPost } from '../../../hooks/useFetch';
import globals from '../../../config/globals';

const useStyles = makeStyles(theme => ({
  cardPatent: {
    marginBottom: 20,
  },
}));

const GooglePatents = ({ classMain, onDrawerToggle, open, auth }) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [reqFetchCheckDomain, fetchCheckDomain] = useFetchPost();

  const handleClickOpen = () => {
    this.setState({ open: true });
  };

  const onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  return (
    <>
      <Header
        goTo={this.props.goTo}
        open={open}
        onChangeRegion={onChangeRegion}
        region={auth.region}
        onDrawerToggle={onDrawerToggle}
      >
        <Box display="flex" p={1}>
          <Box p={1} width="100%">
            <Translate value="Goole patents" />
          </Box>

          <Box p={1} flexShrink={0}>
            <span
              style={{ color: '#fff', cursor: 'pointer', fontSize: 'small' }}
              onClick={handleClickOpen}
            >
              How does this screen work?
            </span>
          </Box>
        </Box>
      </Header>
      <main className={classMain}>
        <Box
          flexDirection={'row'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mb={4}
        >
          <Box>
            <TextFieldSearch label="Search a patent" onHandleButtonSearch={setText} />
          </Box>
        </Box>
        <PatentsContainer text={text} />
      </main>
    </>
  );
};

const PatentsContainer = ({ text }) => {
  const [reqFetchGooglePatents, fetchGooglePatentsGet] = useFetchGet();
  const [patents, setPatents] = useState([]);
  const fetchGooglePatents = text => {
    try {
      const url = `${globals.api}/api/google/patents/search/${text}`;
      fetchGooglePatentsGet(url);
    } catch (error) {
      console.log('Error:', error);
    }
  };
  useEffect(() => {
    if (reqFetchGooglePatents.data) {
      const responseData = reqFetchGooglePatents.data;
      let patentsList = responseData.data;
      patentsList = patentsList.filter(
        item => item.abstract.length > 0 && item.title_localized.length > 0,
      );
      console.log(' patentsList', patentsList);
      setPatents(patentsList);
    }
  }, [reqFetchGooglePatents.data]);
  useEffect(() => {
    fetchGooglePatents(text);
  }, [text]);
  return (
    <>
      {reqFetchGooglePatents.isFetching && <LinearProgress />}
      {patents.map(item => {
        let abstractObject = item.abstract.find(abst => abst.language == 'en');
        if (item.abstract.length == 1) {
          abstractObject = item.abstract[0];
        }

        let titleObject = item.title_localized.find(tl => tl.language == 'en');
        if (item.title_localized.length == 1) {
          titleObject = item.title_localized[0];
        }
        return (
          <PatentItem
            key={item.publication_number}
            publicationNumber={item.publication_number}
            country={item.country_code}
            title={titleObject.text || ''}
            abstract={abstractObject.text}
            year={Math.floor(item.filing_date / 10000)}
          />
        );
      })}
    </>
  );
};

const PatentItem = ({
  title = 'Header',
  abstract = 'Abstract',
  country = 'US',
  publicationNumber = '2021',
  year = '2021',
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardPatent} variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h3">
          {title}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          {country} - {year} - {publicationNumber}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {abstract}
        </Typography>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(GooglePatents));
