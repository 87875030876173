import React from 'react';

import Button from '../../atoms/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Text from '../../atoms/Text';
import { Box, makeStyles } from '@material-ui/core';
import Divider from '../../atoms/Divider';

const useStyles = makeStyles({
  text: {
    fontSize: '.8rem',
  },
  button: {
    display: 'flex',
    padding: 0,
    backgroundColor: '#FCF1E4',
    justifyContent: 'space-between',
  },
});
const HelpButton = ({ onClick, label = 'Need Help?', disabled = false }) => {
  const classes = useStyles();
  return (
    <Button type="white" onClick={!disabled ? onClick : () => {}} className={classes.button}>
      <Box display="flex" flex={1} paddingLeft={1} alignItems="center">
        <HelpOutlineIcon fontSize="small" color="primary" />
        <Divider margin="0px 5px" />
        <Text variant="subtitle2" weight="semibold" className={classes.text}>
          {label}
        </Text>
      </Box>
    </Button>
  );
};

export default HelpButton;
