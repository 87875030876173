/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
// globals
import globals from '../../../config/globals';

// main extended component to acces methods for all react components
import Kashing from '../../../components/_kashing';
import Header from '../../../components/Header';
import TableKeywords from '../../../components/TableKeywords';

import { setRegion } from '../../../redux/actions/auth';
import getFirestoreService from '../../../utils/firestoreService';
import { getColumnKeyword, getKeywordsOrderBy } from '../utils';
import SearchAutocompleteKeywords from '../../../components/organisms/SearchAutocompleteKeywords';
import SnackBar from '../../../components/Snackbar';
import RequestDialog from '../../../components/RequestDialog';
import SearchButton from '../../../components/molecules/SearchButton';
import { Box, CircularProgress } from '@material-ui/core';
import ViewSearchData from '../../../components/molecules/ViewSearchData/ViewSearchData';
import DialogVideo from '../../../components/molecules/DialogVideo';

const firestoreService = getFirestoreService();

const initialPagination = {
  keywords: [],
  offset: 0,
  limit: 20,
};

class KeywordFinderPage extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.auth = this.props.firebaseApp.auth();

    this.context = context;
    this.store = this.context.store;
    this.typingTimeout = null;
    this.state = {
      keywords: [],
      table: [],
      savedTags: [],
      suggestion: [],
      orderBy: { field: 'volume', order: 'desc' },
      inputDisabled: false,
      selectedRowId: null,
      removeDialog: {
        open: false,
      },
      progressLine: {
        show: false,
      },
      open: false,
      video: '',
      isLoading: false,
      isLoaded: false,
      autoComplete: [],
      typing: false,
      typingTimeout: 0,
      tableKeywordHeaders: [
        { label: 'Region', field: 'region', width: '5%', noicon: 'true' },
        {
          label: 'Keyword',
          field: 'keyword',
          width: '15%',
          align: 'center',
          icon: '',
        },
        {
          label: 'Volume',
          field: 'volume',
          width: '10%',
          icon: 'fas  fa-angle-down',
        },
        { label: 'CMP', field: 'cmp', width: '10%', icon: '' },
        { label: 'CPC', field: 'cpc', width: '10%', icon: '' },
        { label: 'Actions', field: '', width: '10%', noicon: true },
      ],
      snackbar: {
        open: false,
        type: 'error',
        message: 'Remove some products and try again.',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      isActiveSearch: false,
      pagination: { ...initialPagination },
      keywordsFound: [],
      isLoadingMore: false,
    };
  }

  async componentDidMount() {
    await this.validateSuscription(this.props.auth.user);
    this.gid = this.props.auth.user.gid;
  }

  componentDidUpdate(props, state) {
    if (props.auth.region !== this.props.auth.region) {
      // search data
      this.setState({
        table: [],
      });
      this.searchKeyword(this.state.keyword);
    }
  }

  componentWillUnmount() {}

  onColUpdate = keywords => {
    return keywords.map(keyword => {
      return getColumnKeyword(keyword);
    });
  };

  selectRow() {
    // this.context.router.history.push('/keywords/suggestion/'+this.slugify(row.keyword))
  }

  autoCompleteKeywords = keyword => {
    let region = this.props.auth.region.toUpperCase();
    let uri = `${globals.apiSecondary}/api/keywords/auto_complete/${keyword}/region/${region}`;
    // get the api response
    this.getRequest(uri)
      .then(async response => {
        this.setState({
          isLoadingFilter: false,
          autoComplete: response.response,
        });
      })
      .catch(() => {
        console.log('error on');
      });
  };

  loadTags() {
    this.unsubscribe = firestoreService.subscribeSearchDocs(
      'groups_keywords',
      [
        ['gid', '==', this.gid],
        ['region', '==', this.props.auth.region],
      ],
      [[this.state.orderBy.field, this.state.orderBy.order]],
      results => {
        const savedTags = [];
        results.docs.forEach(doc => {
          savedTags.push(doc.data());
        });
        this.setState({ savedTags });
      },
    );
  }

  getKeywordsFirestore = async keywords => {
    const keywordsGrouped = [];
    const chunk = 10;
    while (keywords.length > 0) {
      const keywordIds = keywords.splice(0, chunk).map(k => k.uid);

      const keywordsFirestore = await firestoreService.searchDocs(
        'keywords',
        [['uid', 'in', keywordIds]],
        [],
      );
      keywordsFirestore.forEach(doc => keywordsGrouped.push({ ...doc.data(), show: true }));
    }

    return keywordsGrouped;
  };

  // fetch by post
  fetchPost = async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  };

  loadKeywordsByChunks = async () => {
    const region = this.props.auth.region.toUpperCase();
    const urlSuggestionsData = `${globals.apiThird}/api/keywords/suggestions/data?region=${region}`;
    const responseKeywordsData = await this.fetchPost(urlSuggestionsData, {
      keywords_list: this.state.pagination.keywords,
      region,
    });

    let keywords = await this.getKeywordsFirestore(responseKeywordsData.data);
    keywords = this.onColUpdate(keywords);

    keywords.sort((a, b) => (a.cmp.value > b.cmp.value ? -1 : 1));
    const findIndex = keywords.findIndex(keyword => keyword.keyword == this.state.keyword);
    if (findIndex > -1) {
      const keywordSearched = keywords[findIndex];
      keywords.splice(findIndex, 1);
      keywords.unshift(keywordSearched);
    }
    this.setState(
      {
        keywords: [...this.state.keywords, ...keywords],
        isLoaded: true,
        autoComplete: [],
        isLoadingMore: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isLoading: false,
            isLoaded: false,
          });
        }, 2000);
      },
    );
  };

  searchKeyword = async () => {
    const keyword = this.state.keyword;
    if (!keyword) return;

    this.setState({
      keyword,
      isActiveSearch: true,
      isLoading: true,
      table: [],
      products: [],
      pagination: { ...initialPagination },
    });

    const region = this.props.auth.region.toUpperCase();
    const uri = `${globals.apiThird}/api/keywords/suggestion?keyword=${keyword}&region=${region}`;

    try {
      const resStringKeywords = await this.getRequest(uri);
      const stringKeywords = resStringKeywords.data;
      const keywordsSliced = stringKeywords.slice(
        this.state.pagination.offset,
        this.state.pagination.limit,
      );

      this.setState(
        {
          keywordsFound: stringKeywords,
          pagination: { ...this.state.pagination, keywords: keywordsSliced },
        },
        this.loadKeywordsByChunks,
      );
    } catch (error) {
      console.error('error on searchKeyword', error);
    }
  };

  resetForm() {
    this.setState({ keyword: '', inputDisabled: false });
  }

  async showResults(results) {
    if (results) {
      let suggestion = [];
      for (let x in results) {
        let node = results[x];
        for (let i in node) {
          let row = {};
          row = node[i];
          row.region = this.props.auth.region;
          row.keyword = row.string;
          suggestion.push(row);
        }
      }
      let newsuggestions = _.orderBy(
        suggestion,
        [this.state.orderBy.field],
        [this.state.orderBy.order],
      );
      newsuggestions = newsuggestions.filter(it => it.string && it.string.length > 0);
      this.setState({
        table: newsuggestions,
        inputDisabled: false,
        progressLine: { show: false },
        isLoading: false,
      });
    }
  }

  async onRemoveDialog(response) {
    if (response === true) {
      await firestoreService.deleteDocs('groups_keywords', this.state.selectedRowId);
      this.setState({
        selectedRowId: null,
        removeDialog: {
          open: false,
        },
      });
    } else {
      this.setState({
        removeDialog: {
          open: false,
        },
      });
    }
  }

  openConfirmDelete(row) {
    let id = this.slugify(row.keyword);
    this.setState({
      selectedRowId: id,
      removeDialog: {
        open: true,
      },
    });
  }

  handleChange(a, e) {
    let o = this.state.advanced;
    o[a] = e.target.value;
    this.setState(o);
  }

  onFilter = value => {
    if (value && value.length >= 4) {
      const self = this;

      if (self.typingTimeout) {
        clearTimeout(self.typingTimeout);
      }

      self.typingTimeout = setTimeout(() => {
        if (this.state.autoComplete.length == 0) {
          this.setState({ isLoadingFilter: true });
        }

        self.autoCompleteKeywords(value);
      }, 1000);
    }
  };

  onChangeKeyword = value => {
    this.setState({ keyword: value });
  };

  onKeyUp(e) {
    if (e.keyCode === 13) {
      this.searchKeyword();
    }
  }

  advancedWindowOpen() {
    let status = this.state.advancedWindow === false ? true : false;
    this.setState({ advancedWindow: status });
  }

  keywordsSuggestionClose() {
    this.setState({ keywordsSuggestion: { open: false } });
  }

  async createTag(tag) {
    const tagData = {
      gid: this.props.auth.user.gid,
      string: tag,
    };
    await firestoreService.setDocs('keyword_tags', tag, tagData);
  }

  async addKeyword(row, tag) {
    await firestoreService.setDocs(`groups/${this.props.auth.user.gid}/keywords`, row.id, {
      active: true,
    });

    const keywords = _.remove(this.state.keywords, obj => {
      return obj.id === row.id;
    });
    this.setState({ keywords: keywords });
  }

  sortTable(o) {
    const keywordsOrdered = getKeywordsOrderBy(this.state.keywords, o);
    this.setState({
      orderBy: o,
      keywords: keywordsOrdered,
    });
  }

  clearInput() {
    this.setState({ keyword: '' });
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  onHandleAddedTag = () => {
    this.setState({
      // table: merge,
      // searchResults: newresults,
      snackbar: {
        open: true,
        type: 'success',
        message: 'Keyword was tagged',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    });
  };

  loadMore = event => {
    if (this.state.isLoadingMore) {
      console.info('loading more...');
      return;
    }

    const element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      const { keywordsFound } = this.state;

      let { offset, limit } = this.state.pagination;

      let nextOffset = offset + 5;
      if (offset === 0) {
        nextOffset = 20;
      }
      const nextLimit = nextOffset + 5;
      const keywordsSliced = keywordsFound.slice(nextOffset, nextLimit);
      if (!keywordsSliced || keywordsSliced.length === 0) {
        return;
      }
      this.setState(
        {
          isLoadingMore: true,
          pagination: {
            offset: nextOffset,
            limit,
            keywords: keywordsSliced,
          },
        },
        this.loadKeywordsByChunks,
      );
    }
  };

  render() {
    const { classMain, onDrawerToggle, open } = this.props;
    const { goTo } = this.props;

    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Keyword Research"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />
        <main className={`${classMain} main`}>
          <div className="page-scroll">
            <Dialog
              open={this.state.removeDialog.open}
              onClose={this.onRemoveDialog.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Remove keyword'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" color="secondary">
                  Are you sure to remove this keyword from the database?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onRemoveDialog.bind(this, false)}
                  variant="outlined"
                  color="default"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.onRemoveDialog.bind(this, true)}
                  variant="outlined"
                  color="default"
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <DialogVideo
              open={this.state.open}
              onClose={this.handleClose.bind(this)}
              videoHashedId={this.state.video}
            />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flex={1.4}>
                <SearchAutocompleteKeywords
                  onChange={({ name }) => {
                    if (!name) return;
                    this.setState({
                      keyword: name,
                    });
                  }}
                  region={this.props.auth.region.toUpperCase()}
                />
              </Box>
              <Box flex={0.6} paddingLeft={4}>
                <SearchButton onClick={this.searchKeyword} />
              </Box>
            </Box>
            {!this.state.isActiveSearch && (
              <Box paddingY={2} marginTop={5} style={{ backgroundColor: 'white' }}>
                <ViewSearchData message="Enter a product keyword to get your research started!" />
              </Box>
            )}
            <div
              style={{ width: '40%', margin: '', display: 'flex', marginBottom: '3.2rem' }}
            ></div>
            {this.state.isActiveSearch && (
              <div className="table-scroll" onScroll={this.loadMore}>
                <TableKeywords
                  goTo={goTo}
                  isHideLoading={true}
                  orderBy={this.state.orderBy}
                  addKeyword={this.addKeyword.bind(this)}
                  sortTable={this.sortTable.bind(this)}
                  isLoading={this.state.isLoading}
                  keywords={this.state.keywords}
                  useAction={'finderAction'}
                  gid={this.props.auth.user.gid}
                  onHandleAddedTag={this.onHandleAddedTag}
                  region={this.props.auth.region}
                />
                <SnackBar
                  options={this.state.snackbar}
                  onClose={() =>
                    this.setState({
                      snackbar: {
                        ...this.state.snackbar,
                        open: false,
                      },
                    })
                  }
                />
              </div>
            )}
            {this.state.isLoadingMore && (
              <Box display={'flex'} marginY={1} justifyContent="center">
                <CircularProgress color="primary" size={20} />
              </Box>
            )}
          </div>
          {this.state.isLoading && (
            <RequestDialog
              open={this.state.isLoading}
              isLoaded={this.state.isLoaded}
              handleClose={() => {}}
            />
          )}
        </main>
      </>
    );
  }
}

const mapStateToProps = state => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(KeywordFinderPage));
