/* eslint-disable */

import { formatNumber } from '../../../utils';

const getCMP = keyword => {
  const ropoMonthly = getRopo(keyword);
  const cmp = {
    value: 0,
    label: '--',
    style: {
      color: 'rgb(170, 172, 176)',
      backgroundColor: 'black',
      borderRadius: 4,
      height: 30,
    },
  };

  if (ropoMonthly > 0 && ropoMonthly <= 10) {
    cmp.label = 'EASY';
    cmp.value = 1;
    cmp.style = { ...cmp.style, color: '#fff', backgroundColor: '#65BB5D' };
  } else if (ropoMonthly >= 11 && ropoMonthly <= 25) {
    cmp.label = 'EASY';
    cmp.value = 1;
    cmp.style = { ...cmp.style, color: '#fff', backgroundColor: '#65BB5D' };
  } else if (ropoMonthly >= 26 && ropoMonthly <= 50) {
    cmp.label = 'MODERATE';
    cmp.value = 2;
    cmp.style = { ...cmp.style, color: '#fff', backgroundColor: '#F5C141' };
  } else if (ropoMonthly >= 51 && ropoMonthly <= 75) {
    cmp.label = 'HARD';
    cmp.value = 3;
    cmp.style = { ...cmp.style, color: '#fff', backgroundColor: '#E64952' };
  } else if (ropoMonthly >= 75) {
    cmp.label = 'HARD';
    cmp.value = 3;
    cmp.style = { ...cmp.style, color: '#fff', backgroundColor: '#E64952' };
  }

  return cmp;
};

const getVolume = keyword => {
  let lowVolume = keyword.low_volume;
  let highVolume = keyword.high_volume;
  let isLessOneThousand = false;
  switch (true) {
    case !lowVolume || !highVolume:
      lowVolume = 'Less than 500';
      highVolume = '--';
      isLessOneThousand = true;
      break;
    case highVolume < 1000 || lowVolume < 1000:
      lowVolume = 'Less than 1,000';
      highVolume = '--';
      isLessOneThousand = true;
      break;
    default:
      lowVolume = formatNumber(lowVolume, 0);
      highVolume = formatNumber(highVolume, 0);
  }

  return {
    highVolume,
    lowVolume,
    isLessOneThousand,
  };
};

const getRopo = keyword => {
  return keyword && keyword.ropo && keyword.ropo.monthly && keyword.ropo.monthly > 0
    ? keyword.ropo.monthly
    : 0;
};

const getFormattedRopoByKeyword = keyword => {
  const ropo = getRopo(keyword);
  return ropo > 0 ? formatNumber(ropo, 0) : '--';
};

const getFormattedCPC = keyword => {
  return keyword.cpc !== null && keyword.cpc ? '$' + formatNumber(keyword.cpc, 2) : '--';
};

const getColumnKeyword = keyword => {
  const formattedRopoDaily = getFormattedRopoByKeyword(keyword);
  const formattedCPC = getFormattedCPC(keyword);
  const volumeObject = getVolume(keyword);
  let cmp = getCMP(keyword);
  cmp.style['width'] = '100%';

  return {
    ...keyword,
    id: keyword.uid,
    keyword: keyword.keyword,
    volume: keyword.volume,
    cmp: cmp,
    formattedCPC: formattedCPC,
    formattedRopoDaily: formattedRopoDaily,
    ...volumeObject,
  };
};

const getAggregatedVolumes = keywords => {
  let initialValue = 0;
  let sumLowVolume = keywords.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue.low_volume;
  }, initialValue);

  let sumHighVolume = keywords.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue.high_volume;
  }, initialValue);

  return {
    sumLowVolume: formatNumber(sumLowVolume),
    sumHighVolume: formatNumber(sumHighVolume),
  };
};

const chunkArray = (array = []) => {
  return function (offset, limit) {
    const slice = array.slice(offset, limit);
    return slice;
  };
};

const getKeywordsOrderBy = (keywords, o) => {
  let field = o.field;
  const fieldsMatch = {
    ropo: 'ropo.monthly',
    cmp: 'cmp.value',
  };

  const fieldMatch = fieldsMatch[field];
  if (fieldMatch) {
    field = fieldMatch;
  }

  return _.orderBy(keywords, [field], [o.order]);
};

export { getColumnKeyword, getAggregatedVolumes, chunkArray, getKeywordsOrderBy };
