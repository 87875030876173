import React from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import { Translate } from 'react-redux-i18n';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Kashing from '../../components/_kashing';
import Header from '../../components/Header';
import { setRegion } from '../../redux/actions/auth';
import getFirestoreService from '../../utils/firestoreService';

const firestoreService = getFirestoreService();

const TableHeadCell = withStyles(theme => ({
  head: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    border: 0,
    fontSize: '12px',
    fontWeight: 700,
  },
}))(TableCell);

class DataTable extends Kashing {
  render() {
    return (
      <Table className="table">
        <TableHead>
          <TableRow>
            <TableHeadCell width="15%">Keyword</TableHeadCell>
            <TableHeadCell width="10%">Volume</TableHeadCell>
            <TableHeadCell width="10%">CMP</TableHeadCell>
            <TableHeadCell width="10%">CPC</TableHeadCell>
            <TableHeadCell width="10%">Score</TableHeadCell>
            <TableHeadCell width="20%">Actions</TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody className="tableBody">
          {this.props.data.map((row, index) => {
            let cellRightTopClass = index === 0 ? ' td-top-first ' : '';
            let cellLeftTopClass = index === 0 ? ' td-top-last ' : '';

            let cellRightBottomClass =
              this.props.data.length - 1 === index ? ' td-bottom-first ' : '';
            let cellLeftBottomClass =
              this.props.data.length - 1 === index ? ' td-bottom-last ' : '';

            let cellRightClass = 'tableCell ' + cellRightTopClass + cellRightBottomClass;
            let cellLeftClass = 'tableCell ' + cellLeftTopClass + cellLeftBottomClass;

            return (
              <TableRow key={index} className="tableRow">
                <TableCell className={cellRightClass}>
                  {row.string && row.string !== '' ? row.string : 'NA'}
                </TableCell>
                <TableCell className="tableCell">
                  {row.volume && row.volume !== '' ? this.format(row.volume) : 'NA'}
                </TableCell>
                <TableCell className="tableCell">
                  {row.cmp && row.cmp !== '' ? this.money(row.cmp) : 'NA'}
                </TableCell>
                <TableCell className="tableCell">
                  {row.cpc && row.cpc !== '' ? this.money(row.cpc) : 'NA'}
                </TableCell>

                <TableCell className="tableCell">
                  {row.score && row.score !== '' ? row.score : 'NA'}
                </TableCell>
                <TableCell className={cellLeftClass}>
                  <Icon
                    className={classNames('icon-table', 'fas fa-times')}
                    style={{ fontSize: 15 }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

class Details extends Kashing {
  constructor(props) {
    super(props);
    this.state = {
      keywordName: '',
      keywords: [],
    };
  }

  async componentDidMount() {
    this.setState({ keywordName: this.props.computedMatch.params.id });

    const keywordDocs = await firestoreService.searchDocs(
      'keywords',
      [['keyid', '==', this.props.computedMatch.params.id]],
      [['volume', 'desc']],
    );

    const keywords = [];
    for (let x in keywordDocs) {
      keywords.push(keywordDocs[x].data());
    }
    this.setState({ keywords });
  }

  openConfirmDelete(row) {
    let pid = `${row.id}-${row.region}`;
    this.setState({
      selectedRowId: pid,
      removeDialog: {
        open: true,
      },
    });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  selectRow(row) {
    this.context.router.history.push('/keywords/suggestion/' + row.id);
  }

  render() {
    const { classMain, onDrawerToggle } = this.props;
    return (
      <>
        <Header
          goTo={this.props.goTo}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
        >
          Suggestion: <Translate value={this.state.keywordName} />
        </Header>
        <main className={classMain}>
          <div className="page container">
            <DataTable
              data={this.state.keywords}
              selectRow={this.selectRow.bind(this)}
              openConfirmDelete={this.openConfirmDelete.bind(this)}
            />
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => {
  const suggestion = state.collections['suggestion'] ? state.collections['suggestion'] : [];
  return { suggestion };
};

export default connect(mapStateToProps, {})(withFirebase(Details));
