import { firebaseApp } from '../config/firebase';

class FireDBService {
  constructor(firebaseDB) {
    this.firebaseDB = firebaseDB;
  }

  async getGroup(gid) {
    const refGroup = await this.firebaseDB.ref('/groups/' + gid).once('value');
    return refGroup.val();
  }

  async createEmptyGroup() {
    return this.firebaseDB.ref().child('groups').push().key;
  }

  async setGroup(gid, data, cb = null) {
    return this.firebaseDB.ref('groups/' + gid).set(data, cb);
  }

  async updateGroup(gid, data, cb = null) {
    return this.firebaseDB.ref('groups/' + gid).update(data, cb);
  }

  async removeGroup(gid) {
    return this.firebaseDB.ref('groups/' + gid).remove();
  }

  async getUser(uid) {
    const refUser = await this.firebaseDB.ref('/users/' + uid).once('value');
    return refUser.val();
  }

  async createEmptyUser() {
    return this.firebaseDB.ref().child('users').push().key;
  }

  async setUser(uid, data, cb = null) {
    return this.firebaseDB.ref('users/' + uid).set(data, cb);
  }

  async updateUser(uid, data, cb = null) {
    return this.firebaseDB.ref('users/' + uid).update(data, cb);
  }

  async removeUser(uid) {
    return this.firebaseDB.ref('users/' + uid).remove();
  }

  // async getVideo(vid) {
  //   const refGroup = await this.firebaseDB.ref('/groups/' + vid).once('value');
  //   return refGroup.val();
  // }

  // async updateUser(vid, data, cb = null) {
  //   return this.firebaseDB.ref('users/' + uid).update(data, cb);
  // }

  subscribeVideos(cb = null) {
    return this.firebaseDB.ref('videos').on('value', cb);
  }
}

let rdbServiceInstance = null;

const getFireDBService = function () {
  // console.log('FireDBServiceInstance: ', rdbServiceInstance);
  if (!rdbServiceInstance) {
    console.log('FireDBServiceInstance created');
    rdbServiceInstance = new FireDBService(firebaseApp.database());
  }
  return rdbServiceInstance;
};

export default getFireDBService;
