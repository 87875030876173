import React from 'react';
import TooltipMUI from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const TooltipCustomized = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: 'black',
    fontSize: 13,
  },
}))(TooltipMUI);

const Tooltip = props => {
  const { children } = props;
  return <TooltipCustomized {...props}>{children}</TooltipCustomized>;
};

Tooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
