import React from 'react';
import PropTypes from 'prop-types';

const Divider = ({ margin = '0px 20px' }) => {
  return <div style={{ margin }} />;
};

Divider.propTypes = {
  margin: PropTypes.string,
};

export default Divider;
