import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {
  injectStripe,
  Elements,
  StripeProvider,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';

// settings
import globals from '../../config/globals';
import Kashing from '../_kashing';
import StripeElementWrapper from '../../routes/settings/components/StripeElementWrapper';

import { setTempData } from '../../redux/actions/auth';

class _CheckoutForm extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.state = {
      loader: false,
    };
  }
  async onSubmit(e) {
    e.preventDefault();
    this.setState({ loader: true });
    this.props.onProcessing();
    // owner info for stripe
    let ownerInfo = {
      owner: {
        name: this.props.auth.user.displayName,
        email: this.props.auth.user.email,
      },
      mandate: {
        notification_method: 'email',
      },
    };
    if (this.props.stripe) {
      let result = await this.props.stripe.createToken();
      if (result.error) {
        this.props.onProcessFinished();
        console.error('[error]', result.error);
      } else {
        this.props.stripe
          .createSource({
            type: 'card',
            ...ownerInfo,
          })
          .then(result => {
            this.store.dispatch(setTempData(result));
            this.props.next();
          });
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  render() {
    return (
      <form
        ref="stripePaymentForm"
        onSubmit={this.onSubmit.bind(this)}
        style={{ padding: '20px 60px' }}
      >
        <Grid container>
          <Grid item xs={12}>
            <StripeElementWrapper
              label="Card Number"
              component={CardNumberElement}
              disabled={this.state.loader}
            />
          </Grid>
          <Grid item xs={6}>
            <StripeElementWrapper
              label="Expiry (MM / YY)"
              component={CardExpiryElement}
              disabled={this.state.loader}
            />
          </Grid>
          <Grid item xs={6}>
            <StripeElementWrapper
              label="CVC"
              component={CardCVCElement}
              disabled={this.state.loader}
            />
          </Grid>
        </Grid>
        <FormControl fullWidth margin="normal">
          <Button type="submit" color="primary" fullWidth={true} variant="contained">
            Next
          </Button>
        </FormControl>
      </form>
    );
  }
}

const CheckoutForm = injectStripe(_CheckoutForm);

class PaymentForm extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }
  static getDerivedStateFromProps(props, state) {
    state.error = props.error;
    state.loader = props.loader;
    return state;
  }
  constructor(props, context) {
    super(props, context);
    this.initBtnLabel = 'Create account';
    this.state = {
      name: '',
      email: '',
      password: '',
      loader: false,
      error: null,
      btnLabel: this.initBtnLabel,
    };
  }

  render() {
    return (
      <StripeProvider apiKey={globals.stripe.apikey}>
        <Elements>
          <CheckoutForm {...this.props} />
        </Elements>
      </StripeProvider>
    );
  }
}

PaymentForm.propTypes = {
  firebaseApp: PropTypes.object.isRequired,
  loader: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  let auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(PaymentForm));
