import axios from 'axios';
import globals from '../config/globals';

export const URL_PRODUCTS_API = `${globals.fastAPI}/api/v1/products/`
export const URL_USERS_API = `${globals.fastAPI}/api/v1/users/`

const usersWhoCan = [
  "michael@vantageio.com",
  "jamie@cloud247.io"
]

export const findProductsWithFilters = async (query) => {
  let results = []

  try {
    let { data } = await axios.post(`${URL_PRODUCTS_API}filter`, query)
    results = data.data
  }catch (error) {
    console.error(error);
  }

  return results
}

export const saveProductsByFile = async (file) => {
  try {
    let { data } = await axios.post(`${URL_PRODUCTS_API}upload-file`, {file}, {headers: { 'Content-Type': 'multipart/form-data', } })
    return data.data ? data.data : []
  }catch (error) {
    console.error(error);
    return `Error: ${error.message}`
  }
}

export const getProduct = async (product_id) => {
  let results = null

  try {
    let { data } = await axios.get(`${URL_PRODUCTS_API}details/${product_id}`)
    results = data.data
  }catch (error) {
    console.error(error);
  }

  return results
}

export const nameFilterParsed = (name) => {
  if (name === 'price') return 'list_price'
  if (name === 'reviews') return 'reviews_count'
  return name
}

export const setDbUser = (user) => {
  const {email, gid, phoneNumber} = user
  return {email, gid, phoneNumber}
}

export const setFavoriteProduct = (product) => {
  const {_id,region,asin,small_image_url,title,main_category,bsr,list_price,rating,reviews_count,sales_estimate,offer_price,productId} = product
  return {_id,region,asin,small_image_url,title,main_category,bsr,list_price,rating,reviews_count,sales_estimate,offer_price,productId}
}

export const saveFavoritesProducts = async (payload) => {
  let results = []
  try {
    let { data } = await axios.post(`${URL_USERS_API}favorites-products`, payload)
    results = data.data
  } catch (error) {
    console.error(error);
  }

  return results
}

export const getFavoritesProducts = async (gid) => {
  let results = []
  try {
    let { data } = await axios.get(`${URL_USERS_API}by-gid?gid=${gid}`)
    results = data.data.favorites
  } catch (error) {
    console.error(error);
  }

  return results
}

export const deleteFavoriteProduct = async (gid, payload) => {
  let results = []
  try {
    let { data } = await axios.post(`${URL_USERS_API}delete-favorite/${gid}`, payload)
    results = data.data
  } catch (error) {
    console.error(error);
  }

  return results
}

export const checkIfCan = (email) => {
  return usersWhoCan.includes(email)
}
