const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#233039',
  },
  boxHeaderForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAliign: 'center',
  },
  imgLogo: {
    height: 'auto',
    width: 150,
    objectFit: 'contain',
  },
  title: {
    textAlign: 'center',
    margin: '0px 40px',
  },
  wrapBoxForm: {
    width: '26%',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
  },
  boxForm: {
    backgroundColor: 'white',
    padding: '40px 0px',
    borderRadius: 8,
  },
  form: {
    padding: '0px 60px',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 30px',
    },
  },
});

export default styles;
