import React from 'react';
import PropTypes from 'prop-types';
import { set, setSubscription } from '../../../redux/actions/auth';
import { setLocale } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import getFireDBService from '../../../utils/fireDBService';
import { getSubscription } from '../../../utils/fastpring';
const fireDBService = getFireDBService();

class Checkout extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  async componentDidMount() {
    const { id } = this.props.computedMatch.params;

    this.uid = id;
    this.user = await fireDBService.getUser(id);
    window.dataPopupWebhookReceived = async data => {
      try {
        const { id } = this.props.computedMatch.params;

        this.gid = await fireDBService.createEmptyGroup();
        const user = this.user;

        await fireDBService.updateUser(id, { gid: this.gid });
        const group = {
          owner: id,
          payment: {
            id: data.id,
            reference: data.reference,
            account: data.account,
            product: data.items[0].product,
            subscription: data.items[0].subscription,
            totalInPayoutCurrency: data.totalInPayoutCurrency,
            cardEnding: data.payment.cardEnding || 'N/A',
          },
        };

        const subscription = await getSubscription(data.items[0].subscription);
        this.context.store.dispatch(setSubscription(subscription.data));
        this.setState({ subscription: subscription.data });

        await fireDBService.updateGroup(this.gid, group, async error => {
          user['payment'] = group.payment;
          this.store.dispatch(set(user));
          this.setState({ loader: false });
          this.context.store.dispatch(setLocale(user.lang || 'en'));
          this.context.store.dispatch(set(user));
          this.context.router.history.push('/');
        });
      } catch (error) {
        console.error('dataPopupWebhookReceived-', error);
      }
    };
  }

  constructor(props, context) {
    super(props, context);
    this.user = {};
    this.context = context;
    this.store = this.context.store;
    let _session = window.localStorage.getItem('session');
    if (_session) {
      _session = JSON.parse(_session);
      window.fastspring.builder.push(_session, data => {
        window.localStorage.removeItem('session');
        window.fastspring.builder.checkout();
      });
    }
  }

  render() {
    return <div>Hello here</div>;
  }
}

const mapStateToProps = (state, props) => {
  let auth = state.auth;
  return { auth };
};

export default connect(mapStateToProps, {})(withFirebase(Checkout));
