import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { auth } from '../../config/firebase';
import globals from '../../config/globals';
import { Request, Form } from '../../utils/';
import { PaperAlert } from '../../routes/login/components';

import { set } from '../../redux/actions/auth';

import getFireDBService from '../../utils/fireDBService';
const fireDBService = getFireDBService();

class RegisterForm extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;

    this.initBtnLabel = 'Create account';
    this.state = {
      name: '',
      email: '',
      password: '',
      loader: false,
      error: null,
      btnLabel: this.initBtnLabel,
    };
  }

  onSubmitUser(e) {
    e.preventDefault();
    this.setState({ loader: true });
    this.props.onProcessing();
    const user = this.props.firebaseApp.auth().currentUser;
    this.createAccount(user);
  }
  onSubmit(e) {
    e.preventDefault();
    this.setState({ loader: true });
    this.props.onProcessing();

    if (this.state.name.length > 2) {
      this.setState({ loader: true, btnLabel: 'Procesing, wait a moment' });
      this.register();
    } else {
      this.setState({
        loader: false,
        btnLabel: this.initBtnLabel,
        error: { code: 'Name', message: 'Name is required' },
      });
    }
  }
  register() {
    auth
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(_ => {
        const user = this.props.firebaseApp.auth().currentUser;

        user
          .updateProfile({
            displayName: this.state.name,
          })
          .then(
            __ => {
              const user = this.props.firebaseApp.auth().currentUser;

              this.createAccount(user);

              // this.setState({loader:false})
              // this.store.dispatch(setTempData(user))
              // this.props.next();
            },
            error => {
              console.error(error);
              this.setState({
                loader: false,
                btnLabel: this.initBtnLabel,
                error: error,
              });
            },
          );
      })
      .catch(error => {
        setTimeout(() => {
          this.props.onProcessFinished();
          console.error('on error create user', error);
          this.setState({
            loader: false,
            btnLabel: this.initBtnLabel,
            error: error,
          });
        }, 600);
      });
  }
  onChangeName(e) {
    this.setState({ name: e.target.value });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  showErrors() {
    if (this.state.error) {
      return <PaperAlert error={this.state.error} />;
    }
  }
  async createAccount(user) {
    this.user = user;
    const user_exists = await fireDBService.getUser(this.user.uid);
    if (user_exists) {
      this.setState({ loader: false, errorMessage: 'User already registered!' });
      return null;
    }
    await this.setUserGroup();
  }

  async setUserGroup() {
    this.gid = await fireDBService.createEmptyGroup();
    await fireDBService.setGroup(this.gid, { owner: this.user.uid });

    await fireDBService.setUser(
      this.user.uid,
      {
        gid: this.gid,
        email: this.user.email,
        displayName: this.user.displayName,
        phoneNumber: this.user.phoneNumber,
        photoURL: this.user.photoURL,
        emailVerified: this.user.emailVerified,
        providerData: this.user.providerData,
        metadata: this.user.metadata,
      },
      async error => {
        this.createCustomer()
          .then(result => {
            // customer
            this.customer = result;
            //set suscription
            this.setSuscription()
              .then(result => {
                //suscription
                this.subscription = result;
                //setCustomerAndSubscriptionToGroup
                this.setCustomerAndSubscriptionToGroup()
                  .then(async group => {
                    const user = await fireDBService.getUser(this.user.uid);
                    user.group = group;
                    this.store.dispatch(set(user));
                    this.props.next();
                  })
                  .catch(error => {
                    this.removeUser();
                    this.setState({
                      loader: false,
                      error: {
                        message: 'An error has ocurr trying to create account ',
                      },
                    });
                  });
              })
              .catch(error => {
                console.error('setSuscription', error);
                this.removeUser();
                this.setState({
                  loader: false,
                  error: {
                    message:
                      'An error has ocurr trying to create account Suscription [suscription]',
                  },
                });
              });
          })
          .catch(error => {
            this.removeUser();
            console.log('An error has ocurr trying to create account Suscription [customer]');
            this.setState({
              loader: false,
              error: { message: 'Server error, pls try again ' },
            });
          });
      },
    );
  }

  removeUser() {
    this.user
      .delete()
      .then(async result => {
        console.log('user auth successful delete', result);
        await fireDBService.removeGroup(this.gid);
        await fireDBService.removeUser(this.user.uid);
      })
      .catch(error => {
        console.error('delete user auth', error);
      });
  }

  async createCustomer() {
    let service = `${globals.api}/api/groups/${this.gid}/payments/profile`;
    let form = Form({ email: this.user.email, gid: this.gid });
    return new Promise(async (resolve, reject) => {
      Request(service, form)
        .then(async response => {
          let customer = response.response;
          return resolve(customer);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async setSuscription() {
    return new Promise(async (resolve, reject) => {
      let trial_end = moment().add('3', 'days').unix();
      let suscription_service = `${globals.api}/api/groups/${this.gid}/payments/profile/${this.customer.id}/subscriptions`;
      let form = Form({
        customer: this.customer.id,
        plan: globals.stripe.plan,
        trial_end: trial_end,
      });
      Request(suscription_service, form)
        .then(async suscription => {
          resolve(suscription.response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async setCustomerAndSubscriptionToGroup() {
    return new Promise(async (resolve, reject) => {
      const group = {
        payment: {
          customer: this.customer,
          subscription: this.subscription,
        },
      };
      await fireDBService.updateGroup(this.gid, group, async error => {
        if (error) {
          return reject(error);
        }
        return resolve(group);
      });
    });
  }

  render() {
    if (this.props.auth.user) {
      return (
        <form
          style={{ padding: '20px 70px' }}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmitUser.bind(this)}
        >
          {this.showErrors()}

          <input
            tabIndex="-1"
            type="password"
            name="fake-password"
            autoComplete="new-password"
            style={{
              opacity: 0,
              float: 'left',
              border: 'none',
              height: '0',
              width: '0',
            }}
          />

          <Grid container direction="row" justify="center" style={{ padding: '5px' }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                style={{ paddingRight: '20px' }}
                id="standard-name"
                label="Full Name"
                required={true}
                value={this.props.auth.user.displayName}
                readOnly={true}
                margin="normal"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                style={{ paddingRight: '20px' }}
                id="standard-email"
                label="Email"
                required={true}
                type="email"
                value={this.props.auth.user.email}
                readOnly={true}
                margin="normal"
              />
            </Grid>
          </Grid>

          <FormControl fullWidth margin="normal">
            <Button type="submit" color="primary" fullWidth={true} variant="contained">
              Confirm and next
            </Button>
          </FormControl>
        </form>
      );
    } else {
      return (
        <React.Fragment>
          <form
            style={{ padding: '20px 70px' }}
            noValidate
            autoComplete="off"
            onSubmit={this.onSubmit.bind(this)}
          >
            {this.showErrors()}

            <input
              tabIndex="-1"
              type="password"
              name="fake-password"
              autoComplete="new-password"
              style={{
                opacity: 0,
                float: 'left',
                border: 'none',
                height: '0',
                width: '0',
              }}
            />

            <Grid container direction="row" justify="center" style={{ padding: '5px' }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  style={{ paddingRight: '20px' }}
                  id="standard-name"
                  label="Full Name"
                  required={true}
                  value={this.state.name}
                  disabled={this.state.loader}
                  onChange={this.onChangeName.bind(this)}
                  margin="normal"
                />
              </Grid>
            </Grid>

            <Grid container direction="row" justify="center" style={{ padding: '5px' }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  style={{ paddingRight: '20px' }}
                  id="standard-email"
                  label="Email"
                  required={true}
                  type="email"
                  value={this.state.email}
                  disabled={this.state.loader}
                  onChange={this.onChangeEmail.bind(this)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="standard-password"
                  label="Password"
                  disabled={this.state.loader}
                  required={true}
                  type="password"
                  value={this.state.password}
                  onChange={this.onChangePassword.bind(this)}
                  margin="normal"
                />
              </Grid>
            </Grid>

            <FormControl fullWidth margin="normal">
              <Button type="submit" color="primary" fullWidth={true} variant="contained">
                Next
              </Button>
            </FormControl>
          </form>
        </React.Fragment>
      );
    }
  }
}

RegisterForm.propTypes = {
  firebaseApp: PropTypes.object.isRequired,
  onProcessing: PropTypes.func.isRequired,
  onProcessFinished: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  let auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(RegisterForm));
