import React from 'react';
import CustomIcon from '../CustomIcon/CustomIcon';
import PropTypes from 'prop-types';

const CalculatorIcon = ({ width = 50 }) => {
  return <CustomIcon path="/assets/icons/calculator.png" alt="cart icon" width={width} />;
};

CalculatorIcon.propTypes = {
  width: PropTypes.number,
};

export default CalculatorIcon;
