import globals from '../config/globals';

/* eslint-disable no-console */
export const getSubscription = async suscriptionId => {
  const url = `${globals.api}/api/subscriptions/${suscriptionId}`;
  const response = await fetch(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return await response.json();
};
