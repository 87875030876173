import firebase from 'firebase/app';
import '@firebase/firestore';
import globals from './globals';
import algoliasearch from 'algoliasearch/lite';

require('firebase/auth');
require('firebase/database');
require('firebase/firestore');

// init firebase
export const firebaseApp = firebase.initializeApp(globals.firebase, {
  timestampsInSnapshots: true,
});

export const firestoreApp = firebaseApp.firestore();
export const firestore = firebase.firestore;
export const auth = firebase.auth();
export const google = new firebase.auth.GoogleAuthProvider();
export const facebook = new firebase.auth.FacebookAuthProvider();
export const algoliaSearch = algoliasearch('F9IFPQ2LPZ', 'c3b6ea4618909cb2e9f78292cc85390d');
