/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Router, Switch } from 'react-router-dom';

//history
import { createBrowserHistory } from 'history';
//layouts
import MainLayout from '../layouts/main';
import LoginLayout from '../layouts/login';
import EmptyLayout from '../layouts/empty';
import SignupLayout from '../layouts/signup';

//pages
import Dashboard from './dashboard';
import Login from './login';
import FastLogin from './fastlogin';
import Reset from './reset';
import Register from './register';
import Checkout from './signup/components/Checkout';
import Signup from './signup';
import ListingPage from './listing';
import Settings from './settings';
import Products from './products/actions/index';
import PreFilter from './products/actions/prefilter';
import ProductUpload from './products/actions/productUpload';
import ProductFinder from './products/actions/finder';
import ProductsAdminReport from './products/actions/report';
import ProductTracker from './products/actions/tracker';
import ProductsDetails from './products/actions/details';
import ProductCheckName from './products/actions/check_name';
import GooglePatents from './products/actions/google_patents';
import DedicatedCalculator from './products/actions/dedicated_calculator';
import ProductsHistoryPage from './products/actions/history';
import KeywordsFinder from './keywords/actions/finder';
import KeywordsTracker from './keywords/actions/tracker';
import KeywordsDetails from './keywords/details';
import DetailsKeywordsPage from './keywords/actions/details';
import Notfound from './errors';
import Support from './support/actions/index';
import ReviewIntelligence from './review_intelligence/actions/index';
import ReviewIntelligenceFinder from './review_intelligence/actions/finder';
import Extension from './extension/actions/index';
import Login2 from './login/Login2';
import InWorking from '../components/InWorking';

//get browser history
const history = createBrowserHistory();

//routes
class Routes extends React.Component {
  static get contextTypes() {
    return {
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    console.log({props});
  }

  render() {
    const subscription = this.store.getState().subscription.subscription;
    return (
      <Router history={history}>
        <Switch>
          <EmptyLayout component={InWorking} />
          {/*<MainLayout exact path="/" component={PreFilter} />
          <MainLayout exact path="/calculator" component={DedicatedCalculator} />
          <MainLayout exact path="/extension" component={Extension} />
          <MainLayout exact path="/products/upload" component={ProductUpload} />
          <MainLayout exact path="/products/finder" component={ProductFinder} />
          <MainLayout exact path="/products/report" component={ProductsAdminReport} />
          <MainLayout exact path="/brand/check" component={ProductCheckName} />
          <MainLayout exact path="/google/patents" component={GooglePatents} />
          <MainLayout exact path="/products/tracker" component={Products} />
          <MainLayout exact path="/products/prefilter" component={PreFilter} />
          <MainLayout exact path="/products/listing" component={ListingPage} />
          <MainLayout exact path="/settings" component={Settings} />
          <MainLayout exact path="/support" component={Support} />
          <MainLayout exact path="/keywords/research" component={KeywordsFinder} />
          <MainLayout exact path="/keywords/details/:id" component={DetailsKeywordsPage} />
          <MainLayout exact path="/keywords/tracker" component={KeywordsTracker} />
          <MainLayout exact path="/keywords/tracker/:id" component={KeywordsTracker} />
          <MainLayout exact path="/keywords/suggestion/:id" component={KeywordsDetails} />
          <MainLayout exact path="/products/details/:id" component={ProductsDetails} />
          <MainLayout exact path="/tracker/:id" component={ProductTracker} />
          <MainLayout exact path="/review/intelligence/:id" component={ReviewIntelligence} />
          <MainLayout exact path="/review/intelligence" component={ReviewIntelligenceFinder} />
          <MainLayout exact path="/product/history" component={ProductsHistoryPage} />
          <LoginLayout exact path="/login" component={Login} />
          <LoginLayout exact path="/login2" component={Login2} />
          <LoginLayout path="/fast_login" component={FastLogin} />
          <LoginLayout exact path="/register" component={Register} />
          <SignupLayout exact path="/register/steps/:email/:password" component={Signup} />
          <SignupLayout exact path="/payoffer/steps" component={Signup} />
          <SignupLayout exact path="/checkout/:id" component={Checkout} />
          <LoginLayout exact path="/reset" component={Reset} />
          <EmptyLayout component={Notfound} />
          {subscription && subscription.state !== 'active' && subscription.state !== 'trial' && (
            <Redirect push to="/login" />
          )}*/}
        </Switch>
      </Router>
    );
  }
}
export default Routes;
