import React from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import _ from 'lodash';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Kashing from '../../../components/_kashing';
import globals from '../../../config/globals';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Header from '../../../components/Header';
import { setRegion } from '../../../redux/actions/auth';
import TableContainer from '@material-ui/core/TableContainer';
import SnackBar from '../../../components/Snackbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import getFireDBService from '../../../utils/fireDBService';
import getFirestoreService from '../../../utils/firestoreService';
import HistoryIcon from '../../../components/atoms/Icons/HistoryIcon';
import Divider from '../../../components/atoms/Divider';
import Text from '../../../components/atoms/Text';
import ProductHeader from '../../../components/organisms/ProductHeader';
import DialogVideo from '../../../components/molecules/DialogVideo';

const fireDBService = getFireDBService();
const firestoreService = getFirestoreService();

function DataGraftLineProcessing(DataTestBsr) {
  if (DataTestBsr.length > 0) {
    let datePrincipal = DataTestBsr[DataTestBsr.length - 1].date;
    let valueCurrentData = DataTestBsr[DataTestBsr.length - 1].value;
    let result = [];
    let dateProcessing;

    for (let daysFor = 0; daysFor <= 29; daysFor++) {
      if (daysFor > 0) {
        dateProcessing = moment(datePrincipal, 'YYYY-MM-DD', true).subtract(1, 'days');
      } else {
        dateProcessing = moment(datePrincipal, 'YYYY-MM-DD', true).subtract(0, 'days');
      }

      let day = dateProcessing.get('D');
      if (day < 10) {
        day = '0' + day;
      }

      let month = dateProcessing.get('M') + 1;
      if (month < 10) {
        month = '0' + month;
      }

      let year = dateProcessing.get('Y');

      let valueSearch = year + '-' + month + '-' + day;
      datePrincipal = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD');

      for (let ForAPrincipal = 0; ForAPrincipal <= DataTestBsr.length - 1; ForAPrincipal++) {
        if (DataTestBsr[ForAPrincipal].date === valueSearch) {
          valueCurrentData = DataTestBsr[ForAPrincipal].value;
          result[daysFor] = { date: valueSearch, value: valueCurrentData };
        } else {
          result[daysFor] = { date: valueSearch, value: valueCurrentData };
        }
      }
    }

    return result;
  }
}

/**Data Hardcode to testing**/
let Bsr = [
  { date: '2019-06-20', value: 25 },
  { date: '2019-06-26', value: 15 },
  { date: '2019-06-13', value: 75 },
  { date: '2019-06-22', value: 30 },
];
/**Data Hardcode to testing**/

/**Order Data from backend**/
let DataTestBsr = Bsr.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

/**Order Data from backend**/

let DataGeneral = DataGraftLineProcessing(DataTestBsr);
let Labels = [];
let Datas = [];
let i = 0;

DataGeneral.forEach(function (element) {
  Labels[i] = element.date;
  Datas[i] = element.value;
  i++;
});

// eslint-disable-next-line
const dataLineSales = {
  labels: Labels,
  datasets: [
    {
      label: 'Sales',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: Datas,
    },
  ],
};

const styles = {
  root: {
    flexGrow: 1,
  },
  label: {
    width: '100%',
    color: '#333',
    fontSize: '13px',
    marginBottom: '-20px',
    fontWeight: '500',
  },
  formControl: {
    width: '48%',
    marginBottom: '30px',
    marginRight: '2%',
  },
  paperContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paper: {
    height: 'auto',
    width: '100%',
    padding: '40px',
  },
  rowCols: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  col50: {
    width: '50%',
  },
  col100: {
    // width: '100%'
  },
  segment: {
    color: '#333',
    marginBottom: '15px',
  },
  segmentCol: {
    marginRight: '10px',
    fontWeight: '600',
    width: '140px',
    display: 'inline-block',
  },
  star: {
    color: '#fff',
  },
  starFilled: {
    color: '#FDD835',
  },
  heartFilled: {
    color: '#FF7043',
  },
  heart: {
    ':hover': {
      color: '#FF7043',
    },
  },
  card: {
    minHeight: '8vw',
    flex: 1,
  },
  questionMark: {
    marginLeft: 5,
  },
};

// Line chart
const data = {
  labels: [],
  datasets: [
    {
      label: 'Sales',
      fill: false,
      lineTension: 0.1,
      backgroundColor: '#848484',
      borderColor: '#848484',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#848484',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#848484',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    },
  ],
};

class Tracker extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.state = {
      pending_loading: false,
      stories: data,
      orderBy: { field: 'volume', order: 'desc' },
      keywords: [],
      video: '',
      open: false,
      isSearching: false,
      revisions: [],
      region: '',
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      openDialog: false,
    };

    this.auth = this.props.firebaseApp.auth();
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  componentDidMount() {
    this.validateSuscription(this.props.auth.user);
    const { watchDoc } = this.props;
    watchDoc('products/' + this.props.computedMatch.params.id);

    this.asin = this.props.computedMatch.params.id.split('-')[0];
    this.region = this.props.computedMatch.params.id.split('-')[1];
    // this.loadKeywords(this.asin, this.region);
    this.getStories();

    fireDBService.subscribeVideos(snapshot => {
      const data = snapshot.val();
      this.setState({ video: data.details });
    });
    this.getProductAmz();
  }

  getProductAmz() {
    let uri = `${globals.api}/api/products/${this.asin}/region/${this.region}`;
    const res = this.getRequest(uri);
    res.then(data => {
      if (data.error) {
        // alert(data.error.error)
        this.setState({ openDialog: true });
      }
    });
  }

  async handleAccept() {
    const id = `${this.asin}-${this.region}`;
    await firestoreService.deleteDocs('products', id);

    let products = await firestoreService.getDocs('gids_products', this.props.auth.user.gid);
    products = products.products.filter(it => it !== id);

    await firestoreService.updateDocs('gids_products', this.props.auth.user.gid, {
      products: products,
    });

    this.closeDialog();
    this.props.goTo('/');
  }

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  showSnackBar(message, type = 'success') {
    this.setState({
      snackbar: {
        open: true,
        type: type,
        message: message,
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    });
  }

  initEstimate() {
    const uri = `${globals.api}/api/products/${this.asin}/region/${this.region}/sales_estimate`;
    this.getRequest(uri).then(res => {
      // console.log('data est', res);
      this.setState({ pending_loading: false });
    });
  }

  async loadKeywords(asin, region) {
    this.setState({ isSearching: true });
    const keywords = await this.getKeywordsByAsin(asin, region);
    this.setState({
      keywords: keywords.filter(it => it.volume >= 0),
      isSearching: false,
    });
  }

  async getStories() {
    let dateFormat = 'YYYY-MM-DD';
    let startOfWeek = moment().subtract(1, 'months');
    let endOfMonth = moment();
    let month = [];
    let day = startOfWeek;
    while (day <= endOfMonth) {
      month.push(day.format(dateFormat));
      day = day.clone().add(1, 'd');
    }

    const results = await firestoreService.searchDocs(
      'products_history',
      [
        ['asin', '==', this.asin],
        ['region', '==', this.region],
      ],
      [['date', 'asc']],
    );

    let stories = [];
    results.map(doc => {
      let item = doc.data();
      let date = moment.unix(item.date.seconds).format(dateFormat);

      stories[date] = { bsr: item.bsr, sales: item.sales };
      item.date = date;
      return item;
    });
    let bsr_values = [];
    let sales_values = [];
    let max_sales_value = 0;
    let max_bsr_value = 0;
    for (let x in month) {
      let day = month[x];

      if (stories[day] && stories[day].bsr) {
        if (stories[day].bsr > max_bsr_value) {
          max_bsr_value = stories[day].bsr;
        }
        bsr_values.push(stories[day].bsr);
      } else {
        bsr_values.push(0);
      }

      if (stories[day] && stories[day].sales) {
        if (stories[day].sales > max_sales_value) {
          max_sales_value = stories[day].sales;
        }
        sales_values.push(stories[day].sales);
      } else {
        sales_values.push(0);
      }
    }
    sales_values.forEach((item, i) => {
      if (item <= 0 || !item) {
        sales_values[i] = max_sales_value;
      }
    });
    bsr_values.forEach((item, i) => {
      if (item <= 0 || !item) {
        bsr_values[i] = max_bsr_value;
      }
    });
    let data = {
      labels: month,
      datasets: [
        {
          label: 'Sales',
          fill: false,
          lineTension: 0.3,
          backgroundColor: '#388e3c',
          borderColor: '#388e3c',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#388e3c',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#388e3c',
          pointHoverBorderColor: '#388e3c',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: sales_values,
        },
        {
          label: 'BSR',
          fill: false,
          backgroundColor: '#F4821E',
          borderColor: '#F4821E',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#F4821E',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: '#F4821E',
          pointHoverBorderColor: '#F4821E',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: bsr_values,
        },
      ],
    };

    this.setState({ stories: data });
  }

  sortTable(o) {
    let newkeywords = _.orderBy(this.state.keywords, [o.field], [o.order]);
    this.setState({ orderBy: o, keywords: newkeywords });
  }

  setAlibabaLink(styles, productTitle) {
    if (productTitle.length > 0) {
      return (
        <a
          style={{ marginTop: '15px' }}
          target="_blank"
          rel="noopener noreferrer"
          href={
            'https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=' +
            productTitle
          }
        >
          <img
            src="/assets/imgs/logo_alibaba.png"
            alt="Alibaba Logo"
            style={{
              verticalAlign: 'middle',
              width: '4rem',
              marginLeft: '10px',
              margin: '10px',
            }}
          />
        </a>
      );
    } else {
      return null;
    }
  }

  goBack() {
    this.context.router.history.push('/products/tracker');
  }

  pendingLoading(prod) {
    if (this.state.pending_loading) {
      return true;
    }
    if (
      !prod.sales_estimate ||
      !prod.sales_estimate.last_update ||
      !prod.sales_estimate.last_update.seconds
    ) {
      this.setState({ pending_loading: true });
      this.initEstimate();
      return true;
    }

    const diff = moment().diff(moment.unix(prod.sales_estimate.last_update.seconds), 'days');
    if (diff >= 1) {
      this.setState({ pending_loading: true });
      this.initEstimate();
      return true;
    }
    return false;
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
    this.setState({ region: region });
  };

  viewTracker(row) {}

  async trackHistory(row) {
    let trackMonitor = row.trackMonitor || false;
    trackMonitor = !trackMonitor;
    await firestoreService.updateDocs('products', row.asin + '-' + row.region.toUpperCase(), {
      trackMonitor: trackMonitor,
    });

    if (!trackMonitor) {
      const { goTo } = this.props;
      goTo('/');
    }
  }

  async getMonitor(row) {
    const trackMonitorDocs = await firestoreService.getCollection(
      `products/${row.asin}-${row.region.toUpperCase()}/history`,
    );

    const revisions = [];
    trackMonitorDocs.forEach(row => {
      row.data().revision.forEach(it => {
        const d = it;
        d.date = moment.unix(row.id).format('MM/DD/YYYY hh:mm');
        if (d.value === 'bsr') {
          d.new_value = this.format(d.new_value, 0);
          d.old_value = this.format(d.old_value, 0);
          d.displayName = 'Bsr';
        }
        if (d.value === 'opportunity') {
          d.displayName = 'Opportunity';
        }
        if (d.field === 'offer_price') {
          d.new_value = this.moneyFormat(d.new_value, this.props.auth.region);
          d.old_value = this.moneyFormat(d.old_value, this.props.auth.region);
        }
        if (d.field === 'list_price') {
          d.displayName = 'List Price';
          d.new_value = this.moneyFormat(d.new_value, this.props.auth.region);
          d.old_value = this.moneyFormat(d.old_value, this.props.auth.region);
        }
        if (d.value !== 'date') revisions.push(d);
      });
    });
    this.setState({ revisions });
  }

  closeDialog() {
    this.setState({ openDialog: false });
  }

  render() {
    const { doc, classMain, onDrawerToggle } = this.props;
    let product = doc['products/' + this.props.computedMatch.params.id];
    if (product && !product.trackMonitor) {
      this.trackHistory(product);
    }
    if (product) {
      this.getMonitor(product);
    }
    if (product) {
      let est_sale_sold_result = 'N/A';
      let est_sale_result = 'N/A';
      if (!this.pendingLoading(product)) {
        if (
          product.sales_estimate.estimate &&
          product.sales_estimate.estimate !== '' &&
          product.sales_estimate.estimate !== '0'
        ) {
          const estimate = product.sales_estimate.estimate;
          if (estimate >= 1000) {
            est_sale_sold_result =
              this.thusandsFormat(product.sales_estimate.estimate, 1) +
              this.thusandsFormatLetter(product.sales_estimate.estimate, false) +
              '/mo';
          } else if (estimate < 10) {
            est_sale_sold_result = 'Less than 10';
          } else if (estimate > 0) {
            est_sale_sold_result =
              this.thusandsFormat(product.sales_estimate.estimate, 0) +
              this.thusandsFormatLetter(product.sales_estimate.estimate, false) +
              '/mo';
          } else {
            est_sale_sold_result = 'N/A';
          }
        } else {
          est_sale_sold_result = 'No sales estimate';
        }

        if (product.sales_estimate.estimate && product.offer_price) {
          let estimate = product.sales_estimate.estimate;
          let offer_priceMoney = this.money(product.offer_price.amount, '2');
          if (offer_priceMoney === 'N/A') {
            est_sale_result = 'N/A';
          } else if (estimate > 0) {
            let offer_price = offer_priceMoney.toString();
            offer_price = offer_price.substring(1, offer_price.length);
            let total = this.thusandsFormat(parseFloat(offer_price) * parseFloat(estimate), 1);
            est_sale_result =
              this.symbol(product.region) +
              total +
              this.thusandsFormatLetter(parseFloat(offer_price) * parseFloat(estimate), false) +
              '/mo';
          } else {
            est_sale_result = 'N/A';
          }
        } else {
          est_sale_result = 'No sales estimate';
        }
      }

      let indicator = '';
      if (
        product.bsr < 6000 &&
        product.reviews_count < 1000 &&
        product.list_price.amount >= 1500 &&
        product.list_price.amount <= 5000
      ) {
        indicator = 'Great Opportunity';
      } else if (
        product.bsr >= 6000 &&
        product.bsr >= 10000 &&
        product.reviews_count < 1500 &&
        product.list_price.amount >= 1500 &&
        product.list_price.amount <= 5000
      ) {
        indicator = 'Average';
      } else {
        indicator = 'Not Great';
      }

      let product_rating = 'N/A';
      if (product.rating) {
        product_rating = [];

        const mod_rating = product.rating % 2;
        const length = parseInt(product.rating);

        for (let index = 0; index < length; index++) {
          product_rating.push(
            <StarIcon
              key={'starticon-' + index}
              style={{ color: '#FFD203', fontSize: 14, marginTop: 2 }}
            />,
          );
          if (mod_rating > 0 && index === length - 1) {
            product_rating.push(
              <StarHalfIcon
                key={'starthalf-' + index}
                style={{ color: '#FFD203', fontSize: 14, marginTop: 2 }}
              />,
            );
          }
        }
      }

      return (
        <>
          <Header
            goTo={this.props.goTo}
            nameRegion={this.state.region}
            open={this.props.open}
            onChangeRegion={this.onChangeRegion}
            region={this.props.auth.region}
            onDrawerToggle={onDrawerToggle}
            title={'Listing Log'}
            handleClickOpen={this.handleClickOpen.bind(this)}
          />
          <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />
          <Dialog
            open={this.state.openDialog}
            onClose={this.closeDialog.bind(this)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Product not found in amazon:</DialogTitle>
            <DialogContent>
              <DialogContentText>Do you want to delete this product?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAccept.bind(this)} color="primary">
                Delete product
              </Button>
            </DialogActions>
          </Dialog>
          <main className={`${classMain} main`}>
            <div className="page-scroll">
              <DialogVideo
                open={this.state.open}
                onClose={this.handleClose.bind(this)}
                videoHashedId={this.state.video}
              />

              <ProductHeader product={product} />
              <Grid container spacing={0}>
                {product.trackMonitor ? (
                  <>
                    <Grid item xs={12}>
                      <Card style={styles.card}>
                        <CardContent>
                          <Box display="flex" alignItems="center" marginBottom={2}>
                            <HistoryIcon width={35} />
                            <Divider margin="0px 5px" />
                            <Typography
                              variant="subtitle2"
                              style={{ fontSize: '1rem', marginTop: 2 }}
                            >
                              Listing Log
                            </Typography>
                          </Box>
                          <TableContainer style={{ overflow: 'hidden' }}>
                            <Table style={{ overflow: 'hidden' }}>
                              <TableHead>
                                <TableRow
                                  style={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                                  }}
                                >
                                  <TableCell
                                    style={{ padding: '2px 5px' }}
                                    component="th"
                                    align="left"
                                    scope="row"
                                  >
                                    <Typography variant="subtitle2">Date & Time</Typography>
                                  </TableCell>
                                  <TableCell component="th" align="left" style={{ padding: 0 }}>
                                    <Grid container spacing={2}>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{ display: 'inline', paddingLeft: 24 }}
                                      >
                                        <Typography variant="subtitle2">Before</Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        style={{
                                          display: 'inline',
                                          borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                          paddingLeft: 18,
                                        }}
                                      >
                                        <Typography variant="subtitle2">After</Typography>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.revisions.map((it, ix) => (
                                  <TableRow key={'dtk' + ix}>
                                    <TableCell component="th" scope="row" align="center">
                                      <Text className="text-primary-color" weight="semibold">
                                        {it.date}
                                      </Text>
                                    </TableCell>
                                    <TableCell component="th" align="left" style={{ padding: 0 }}>
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={6}
                                          style={{ display: 'inline', padding: 24 }}
                                        >
                                          <Text
                                            className="text-primary-color underline"
                                            weight="semibold"
                                            gutterBottom
                                          >
                                            {it.displayName} Before:
                                          </Text>
                                          <Divider margin="5px 0px" />
                                          {it.displayName === 'Image' && (
                                            <div
                                              style={{
                                                width: 120,
                                                height: 120,
                                                display: 'flex',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <a href={it.old_value} target="_blank">
                                                <img
                                                  style={{ width: 80, objectFit: 'contain' }}
                                                  src={it.old_value}
                                                />
                                              </a>
                                            </div>
                                          )}
                                          {it.displayName !== 'Image' && (
                                            <Text variant="body2" weight="regular">
                                              {it.old_value}
                                            </Text>
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={6}
                                          style={{
                                            display: 'inline',
                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                            padding: '24px  18px',
                                          }}
                                        >
                                          <Text
                                            graphy
                                            className="text-primary-color underline"
                                            weight="semibold"
                                            gutterBottom
                                          >
                                            {it.displayName} After:
                                          </Text>
                                          <Divider margin="5px 0px" />

                                          {it.displayName === 'Image' && (
                                            <div
                                              style={{
                                                width: 120,
                                                height: 120,
                                                display: 'flex',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <a href={it.new_value} target="_blank">
                                                <img
                                                  style={{ width: 80, objectFit: 'contain' }}
                                                  src={it.new_value}
                                                />
                                              </a>
                                            </div>
                                          )}
                                          {it.displayName !== 'Image' && (
                                            <Text variant="body2" weight="regular">
                                              {it.new_value}
                                            </Text>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </div>
          </main>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  let doc = state.docs;
  let auth = state.auth;
  return { doc, auth };
};

export default connect(mapStateToProps, {})(withFirebase(Tracker));
