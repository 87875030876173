import React from 'react';
import Text from '../../atoms/Text';
import Avatar from '@material-ui/core/Avatar';

const ProductHeader = ({ product }) => {
  const setAlibabaLink = () => {
    const productTitle = product.title;

    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          'https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=' +
          productTitle
        }
      >
        <img
          src="/assets/imgs/logo_alibaba.png"
          alt="Alibaba Logo"
          style={{
            verticalAlign: 'middle',
            width: '3rem',
            marginTop: 3,
            marginLeft: '5px',
          }}
        />
      </a>
    );
  };

  return (
    <div className="product-header">
      <Avatar
        alt={product.asin}
        src={product.small_image_url || 'http://via.placeholder.com/300'}
        style={{
          width: 120,
          height: 120,
          disply: 'flex',
          justifyContent: 'center',
          borderRadius: 8,
          objectFit: 'contain',
          backgroundColor: '#fff',
        }}
      />

      <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
        <Text variant="h6" weight="semibold">
          {product.title}
        </Text>

        <div
          style={{
            marginTop: 10,
            color: 'rgba(0,0,0,.4)',
            fontWeight: 400,
            fontFamily: 'Open Sans',
          }}
        >
          <span style={{ verticalAlign: 'middle' }}>Show on</span>
          <a href={product.url} style={{ color: '#fff' }} target="blank" rel="noopener noreferrer">
            <img
              border="0"
              src="/assets/imgs/amazon_logo.png"
              alt="Amazon Logo"
              style={{
                marginTop: 10,
                marginLeft: 5,
                marginRight: 5,
                width: '3rem',
                verticalAlign: 'middle',
              }}
            />
          </a>
          <span
            style={{
              verticalAlign: 'middle',
            }}
          >
            or source on
          </span>

          {setAlibabaLink()}
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;
