import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Text from '../../atoms/Text';
import Tooltip from '../../atoms/Tooltip';
import { HelpIcon } from '../../atoms/Icons';

const useStyles = makeStyles({
  text: {
    color: '#18202C',
    fontSize: 15,
  },
  iconHelp: {
    fontSize: 14,
    color: '#E4E4E4',
    marginTop: 1,
  },
});
const Label = ({ text, help = '', variant = 'body2', weight = 'semibold' }) => {
  const classes = useStyles();
  return (
    <Box display={'inline-flex'} alignItems={'center'}>
      <Text weight={weight} className={classes.text} variant={variant} component="span">
        {text}
      </Text>
      {help && (
        <Box marginLeft={1} display={'flex'} alignItems="center">
          <Tooltip title={help}>
            <HelpIcon color="disabled" fontSize="small" className={classes.iconHelp} />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Label;
