import { SET_SUBSCRIPTION } from '../actions/auth';

let init = { subscription: null };
export default function (state = init, action) {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return Object.assign({}, state, {
        subscription: action.subscription,
      });
    default:
      return state;
  }
}
