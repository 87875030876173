import React, { useState, useEffect, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
//atoms
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import FilterIcon from '../../atoms/Icons/FilterIcon/FilterIcon';

//molecules
import { InputField } from '../../molecules/InputField/InputField';
import InputRange from '../../molecules/InputRange';
import SearchButton from '../../molecules/SearchButton';
import {
  ExpandMore as ExpandMoreIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import ResetButton from '../../molecules/ResetButton';
import ProductFiltersContext from '../../../providers/ProductFilters/ProductFiltersContext';

const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 20 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
};
const AccordionFilter = ({ children, isExpanded, onToggleAccordion }) => {
  return (
    <Accordion
      expanded={isExpanded}
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: '0px',
      }}
      onChange={onToggleAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          display="flex"
          alignItems="center"
          borderBottom="1px solid rgba(0, 0, 0, 0.1)"
          width="100%"
        >
          <FilterIcon width={16} height={16} />
          <Divider margin={'0px 4px'} />
          <Text variant={'subtitle1'} component="span">
            Filters
          </Text>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const ProductFinderFilters = React.memo(({ productCategories, onFilter, isFiltered, onReset }) => {
  const [categories, setCategories] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);

  const productFiltersContext = useContext(ProductFiltersContext);
  useEffect(() => {
    if (productFiltersContext.filters.isReset) {
      onReset();
    }
  }, [productFiltersContext.filters.isReset]);

  useEffect(() => {
    if (productCategories) setCategories(Object.keys(productCategories).map(name => ({ name })));
  }, [productCategories]);

  useEffect(() => {
    if (isFiltered) {
      productFiltersContext.onToggleFilter();
    }
  }, [isFiltered]);

  const categoriesSelected = productFiltersContext.filters.categories;

  const handleCategoriesSelect = (e, child) => {
    const value = e.target.value || [];
    const selectValue = child.props.value;

    if (selectValue === 'all') {
      if (!isSelectAll) {
        setSelectAll(true);
        const categoriesName = categories.map(category => category.name);
        categoriesName.unshift('all');
        productFiltersContext.onChangeCategory(categoriesName);
        return;
      }
      setSelectAll(false);
      productFiltersContext.onChangeCategory([]);
      return;
    }

    if (isSelectAll) {
      setSelectAll(false);
      value.splice(0, 1);
    }
    if (value.length === categories.length) {
      setSelectAll(true);
      value.unshift('all');
    }

    productFiltersContext.onChangeCategory(value);
  };

  return (
    <AccordionFilter
      onToggleAccordion={productFiltersContext.onToggleFilter}
      isExpanded={productFiltersContext.filters.isExpandedFilter}
    >
      <Box padding={'10px'}>
        <Box display="flex" flex={1} justifycontent="space-between">
          <Box flex={1}>
            <Box
              style={{
                flexWrap: 'wrap',
              }}
              width={
                categoriesSelected.length == 0
                  ? '30%'
                  : 30 + categoriesSelected.length * 5 < 60
                  ? `${parseInt(30 + categoriesSelected.length * 5)}%`
                  : '60%'
              }
              marginBottom={7}
            >
              <Select
                style={{ width: 400 }}
                labelId="select-categories-mutiple-checkbox-label"
                id="select-categories-mutiple-checkbox-id"
                multiple
                displayEmpty
                value={productFiltersContext.filters.categories}
                onChange={handleCategoriesSelect}
                input={<Input />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return 'Select product categories (optional)';
                  }
                  return selected.join(', ');
                }}
                MenuProps={MenuProps}
              >
                <MenuItem disabled selected value="">
                  <em>Select product categories (optional)</em>
                </MenuItem>
                <MenuItem value="all">
                  <Checkbox color="primary" checked={categoriesSelected.indexOf('all') > -1} />
                  <ListItemText primary={'Select All'} />
                </MenuItem>
                {categories.map(category => (
                  <MenuItem key={category.name} value={category.name}>
                    <Checkbox
                      color="primary"
                      checked={categoriesSelected.indexOf(category.name) > -1}
                    />
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box>
            <ResetButton onClick={productFiltersContext.onResetFilters} width={'100%'} />
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Box paddingRight={3}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <InputRange
                    minValue={productFiltersContext.filters.range.bsr.min}
                    maxValue={productFiltersContext.filters.range.bsr.max}
                    onChangeInputMin={productFiltersContext.onChangeInputMin}
                    onChangeInputMax={productFiltersContext.onChangeInputMax}
                    type="number"
                    help="Select a minimum and/or maximum for Bestseller Rank"
                    label={'BSR'}
                    name="bsr"
                  />
                  <Divider margin={'0px 40px'} />
                  <InputRange
                    minValue={productFiltersContext.filters.range.price.min}
                    maxValue={productFiltersContext.filters.range.price.max}
                    onChangeInputMin={productFiltersContext.onChangeInputMin}
                    onChangeInputMax={productFiltersContext.onChangeInputMax}
                    type="text"
                    help="Select a minimum and/or maximum price"
                    isCurrency
                    label={'Price'}
                    name="price"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputRange
                    minValue={productFiltersContext.filters.range.monthly_units.min}
                    maxValue={productFiltersContext.filters.range.monthly_units.max}
                    onChangeInputMin={productFiltersContext.onChangeInputMin}
                    onChangeInputMax={productFiltersContext.onChangeInputMax}
                    type="number"
                    help={`Select a minimum and/or maximum
                          number of estimated monthly units sold`}
                    label={'Monthly Units'}
                    name="monthly_units"
                  />
                  <Divider margin={'0px 40px'} />
                  <InputRange
                    minValue={productFiltersContext.filters.range.monthly_sales.min}
                    maxValue={productFiltersContext.filters.range.monthly_sales.max}
                    onChangeInputMin={productFiltersContext.onChangeInputMin}
                    onChangeInputMax={productFiltersContext.onChangeInputMax}
                    type="text"
                    isCurrency
                    help="Select a minimum and/or maximum
                    estimated monthly revenue"
                    label={'Monthly Sales'}
                    name="monthly_sales"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputRange
                    minValue={productFiltersContext.filters.range.reviews.min}
                    maxValue={productFiltersContext.filters.range.reviews.max}
                    onChangeInputMin={productFiltersContext.onChangeInputMin}
                    onChangeInputMax={productFiltersContext.onChangeInputMax}
                    type="number"
                    help="Select a minimum and/or
                    maximum number of reviews"
                    label={'Reviews'}
                    name="reviews"
                  />
                  <Divider margin={'0px 40px'} />
                  <InputRange
                    minValue={productFiltersContext.filters.range.rating.min}
                    maxValue={productFiltersContext.filters.range.rating.max}
                    onChangeInputMin={productFiltersContext.onChangeInputMin}
                    onChangeInputMax={productFiltersContext.onChangeInputMax}
                    type="number"
                    help="Select a minimum and/or maximum
                    Star Rating (1 Star - 5 Stars)"
                    label={'Rating'}
                    name="rating"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              paddingLeft={{ xs: 0, md: 10 }}
              marginTop={{ xs: 5, md: 0 }}
              display={'flex'}
              flex={1}
              flexDirection="column"
            >
              <Box flex={1}>
                <InputField
                  value={productFiltersContext.filters.included_keywords}
                  label={'Include Keywords'}
                  help="Only include products that
                  contain the entered keyword in
                  the title"
                  placeholder={'Search Term to Include (Optional)'}
                  icon={<ZoomInIcon color={'disabled'} />}
                  onChange={productFiltersContext.onChangeIncludedKeywords}
                />
                <Divider margin={'20px 0px'} />
                <InputField
                  value={productFiltersContext.filters.excluded_keywords}
                  label={'Exclude Keywords'}
                  help="Exclude products that contain
                  the entered keywords in the
                  title (separated by commas)"
                  placeholder={'Search Term to Exclude (Optional)'}
                  icon={<ZoomOutIcon color={'disabled'} />}
                  onChange={productFiltersContext.onChangeExcludedKeywords}
                />
                <Divider margin={'30px 0px'} />
                <InputField
                  value={productFiltersContext.filters.asin}
                  label={'Search by ASIN'}
                  help="Product ASIN search only"
                  placeholder={'Enter ASIN (Optional)'}
                  icon={<SearchIcon color={'disabled'} />}
                  onChange={productFiltersContext.onChangeAsin}
                />
              </Box>
              <Box>
                <SearchButton
                  onClick={() => {
                    const filters = JSON.parse(JSON.stringify(productFiltersContext.filters));
                    filters.categories = filters.categories.map(name => ({ name }));
                    onFilter(filters);
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AccordionFilter>
  );
});

export default ProductFinderFilters;
