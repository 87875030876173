import React from 'react';
import PropTypes from 'prop-types';

//material ui components
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

// kashing Main component
import Kashing from './_kashing';
import { Zoom } from '@material-ui/core';
import Tooltip from './atoms/Tooltip';

const TableHeadCell = withStyles(theme => ({
  head: {
    position: 'sticky',
    backgroundColor: '#EAEFF1',
    color: theme.palette.common.black,
    border: 0,
    fontSize: '12px',
    fontWeight: 700,
    padding: 0,
    margin: 0,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    lineHeight: '4rem',
  },
}))(TableCell);

class THeader extends React.Component {
  static getDerivedStateFromProps(props, state) {
    state.headers = props.headers;
    return state;
  }
  constructor(props) {
    super(props);
    this.state = {};
  }
  orderHeaders(field, order) {
    this.state.headers.map(row => {
      if (field === row.field) {
        row.icon = order === 'desc' ? 'fas  fa-angle-down' : 'fas  fa-angle-up';
      } else {
        if (!row.noicon) {
          row.icon = '';
        }
      }
      return row;
    });
  }
  onClickHeader(row) {
    if (!row.noicon) {
      let field = this.props.orderBy.field;
      let order = this.props.orderBy.order;
      if (field === row.field) {
        order = order === 'desc' ? 'asc' : 'desc';
        this.props.sortTable({ field: field, order: order });
      } else {
        order = 'desc';
        field = row.field;
        this.props.sortTable({ field: field, order: order });
      }
      this.orderHeaders(field, order);
    }
  }
  render() {
    return (
      <TableHead style={{ marginLeft: 10, marginRight: 10 }}>
        <TableRow>
          {this.state.headers.map((row, index) => {
            return (
              <Hidden key={'cellheader-' + index} smDown>
                <TableHeadCell
                  onClick={this.onClickHeader.bind(this, row)}
                  width={row.width}
                  style={{
                    textAlign: row.align || 'center',
                    lineHeight: 3,
                  }}
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    key={index}
                    title={row.help ? row.help : ''}
                    placement="top"
                  >
                    <div className="box-content-table-header">
                      <span
                        style={{
                          textAlign: row.align || 'center',
                          lineHeight: 3,
                        }}
                      >
                        {row.label}
                      </span>
                      {row.isActiveOrder && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Icon
                            className={`fas  fa-angle-up`}
                            style={{
                              position: 'absolute',
                              fontSize: 8,
                              marginTop: 10,
                              marginLeft: 4,
                              color: row.icon == 'fas  fa-angle-down' ? '#F4821E' : '#F4821E',
                            }}
                          />
                          <Icon
                            className={`fas  fa-angle-down`}
                            style={{
                              position: 'absolute',
                              fontSize: 8,
                              marginLeft: 4,
                              marginTop: 16,
                              color: row.icon == 'fas  fa-angle-up' ? '#F4821E' : '#F4821E',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </TableHeadCell>
              </Hidden>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

THeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderBy: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(['asc', 'desc']),
  }),
  sortTable: PropTypes.func,
};

export default class MainTable extends Kashing {
  render() {
    return (
      <Table className="table">
        <THeader {...this.props} />
        {this.props.children}
      </Table>
    );
  }
}
