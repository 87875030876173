import React from 'react';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

const SearchButton = ({ onClick, label = 'Search' }) => {
  return (
    <Button type="primary" onClick={onClick}>
      <Text variant="subtitle1" component="span" weight="regular">
        {label}
      </Text>
    </Button>
  );
};

export default SearchButton;
