import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import Kashing from '../../../components/_kashing';
import ProgressLine from '../../../components/ProgressLine';

import {
  // injectStripe,
  Elements,
  // StripeProvider,
  // CardNumberElement,
  // CardExpiryElement,
  // CardCVCElement,
} from 'react-stripe-elements';

const styles = theme => ({
  Dialog: {
    transition: 'all 4s ease-out',
    root: {
      transition: 'all 4s ease-out',
    },
  },
  DialogTitle: {
    background: '#213139',
    padding: '15px',
    '& h2': {
      color: 'white',
    },
  },
  DialogTitleIcon: {
    fontSize: 15,
    marginRight: '10px',
  },
  DialogContent: {
    padding: '40px',
  },
  DialogContentPurchase: {
    padding: '40px',
    backgroundColor: '#f8f8f8',
    borderBottom: '1px solid #ccc',
  },
});

class _CheckoutForm extends Kashing {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }
  async onSubmit(e) {
    e.preventDefault();
    this.setState({ loader: true });
    // owner info for stripe
    let ownerInfo = {
      owner: {
        name: this.props.auth.user.displayName,
        email: this.props.auth.user.email,
      },
      mandate: {
        notification_method: 'email',
      },
    };
    if (this.props.stripe) {
      let result = await this.props.stripe.createToken();
      if (result.error) {
        // console.error('[error]', result.error)
      } else {
        this.props.stripe
          .createSource({
            type: 'card',
            ...ownerInfo,
          })
          .then(result => {
            this.props.onUpdate(result);
          });
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  render() {
    return (
      <React.Fragment>
        <form ref="stripePaymentForm" onSubmit={this.onSubmit.bind(this)}>
          <Grid container>
            <Grid item xs={12}>
              <ProgressLine
                show={this.state.loader}
                style={{ width: '100%', marginTop: '-10px', backgroundColor: '#fff' }}
              />
            </Grid>
            <Grid item xs={4}>
              <ProgressLine
                show={this.state.loader}
                style={{ width: '100%', marginTop: '-10px', backgroundColor: '#fff' }}
              />
            </Grid>
            <Grid item xs={4}>
              <ProgressLine
                show={this.state.loader}
                style={{ width: '100%', marginTop: '-10px', backgroundColor: '#fff' }}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth margin="normal">
            <Button type="submit" size="small" color="primary" fullWidth={true} variant="contained">
              Update
            </Button>
          </FormControl>
        </form>
      </React.Fragment>
    );
  }
}

const CheckoutForm = _CheckoutForm;

class DialogCardPayment extends Kashing {
  showBtn() {
    if (this.props.showCancelBtn) {
      return (
        <DialogActions>
          <Button onClick={this.props.onClose.bind(this)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      );
    } else {
      return null;
    }
  }
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={this.props.show}
        onClose={this.props.onClose.bind(this)}
        className={this.props.classes.Dialog}
      >
        <DialogTitle className={this.props.classes.DialogTitle}>
          <Icon
            className={classNames('', 'far fa-credit-card')}
            style={{ fontSize: 15, marginRight: '10px' }}
          />{' '}
          Update payment method
        </DialogTitle>
        <DialogContent>
          <Elements>
            <CheckoutForm {...this.props} />
          </Elements>
        </DialogContent>
        {this.showBtn()}
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogCardPayment);
