import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import ProgressLine from '../components/ProgressLine';

//settings
import Kashing from './_kashing';

import Form from './signup/Form';
import Payment from './signup/Payment';
import Review from './signup/Review';

const styles = theme => ({
  Dialog: {
    transition: 'all 4s ease-out',
    root: {
      transition: 'all 4s ease-out',
    },
  },
  DialogTitle: {
    background: '#213139',
    padding: '15px',
    '& h2': {
      color: 'white',
    },
  },
  DialogTitleIcon: {
    fontSize: 15,
    marginRight: '10px',
  },
  DialogContent: {
    padding: '40px',
  },
  DialogContentPurchase: {
    padding: '40px',
    backgroundColor: '#f8f8f8',
    borderBottom: '1px solid #ccc',
  },
});

class ShowForm extends React.Component {
  render() {
    switch (this.props.step) {
      case 0:
        return <Form {...this.props} />;
      case 1:
        return <Payment {...this.props} />;
      case 2:
        return <Review {...this.props} />;
      default:
        return null;
    }
  }
}

class MembershipRequest extends Kashing {
  constructor(props, context) {
    super(props, context);

    this.state = {
      error: null,
      step: 0,
      errorMessage: null,
      stepsLabels: ['General Info', 'Payment details'],
    };
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.props.show}
          onClose={this.props.onClose.bind(this)}
          className={this.props.classes.Dialog}
        >
          <ProgressLine
            show={this.props.loader}
            style={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
          />
          <DialogContent>
            <h1 style={{ color: '#000', fontSize: '25px', textAlign: 'center' }}>
              {' '}
              {this.props.title}{' '}
            </h1>

            <Stepper
              activeStep={this.props.step}
              alternativeLabel
              style={{ padding: '0 !important' }}
            >
              {this.state.stepsLabels.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <ShowForm {...this.props} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MembershipRequest);
