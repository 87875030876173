import React, { Component, PureComponent } from 'react';

import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import { InputField } from '../../../components/molecules/InputField/InputField';
import Label from '../../../components/molecules/Label';
import Divider from '../../../components/atoms/Divider';
import { round } from 'lodash';

const useStyles = {
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textInfo: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBlockStart: '0.3rem',
    marginBlockEnd: '0.3rem',
  },
  textInfoValue: {
    fontFamily: 'Open Sans',
    fontSize: '.9rem',
    fontWeight: 600,
    marginBlockStart: '0.3rem',
    marginBlockEnd: '0.3rem',
  },
  borderBottom: {
    borderBottom: '2px solid',
    marginBottom: '0.1rem',
  },
};

const CalculatorTitleValue = ({
  title = 'Amazon Selling Fees',
  value = 2.7,
  currency = '$',
  borderBottom = false,
  sign = '',
  help = '',
}) => {
  const _borderBottom = borderBottom ? useStyles.borderBottom : {};
  value = value && value.toString().search('-') > -1 ? value.split('-')[1] : value;
  sign = value === 0 ? '+' : sign;
  const stylesPrices = {
    borderRadius: 4,
    padding: '2px 0px',
    color: 'white',
    width: '5.7rem',
    marginTop: 5,
    paddingRight: 5,
    textAlign: 'end',
  };

  return (
    <div style={{ ...useStyles.containerRow, ..._borderBottom }}>
      <Label variant="subtitle1" text={title} help={help} />

      {!sign && (
        <div style={{ borderRadius: 8 }}>
          <span
            style={{ ...useStyles.textInfoValue, color: '#F4821E' }}
          >{`${currency}${value}`}</span>
        </div>
      )}

      {sign == '+' && (
        <div style={{ ...stylesPrices, background: '#65BB5D' }}>
          <span style={{ ...useStyles.textInfoValue }}>{`${currency}${value}`}</span>
        </div>
      )}

      {sign == '-' && (
        <div style={{ ...stylesPrices, background: '#E64952' }}>
          <span style={{ ...useStyles.textInfoValue }}>{`${currency}-${value}`}</span>
        </div>
      )}
    </div>
  );
};

const CalculatorTitleInput = ({
  name = '',
  title = 'Amazon Price',
  value = 2.7,
  currency = '$',
  iTitle = 'Price',
  onChange,
  type = 'text',
  help = '',
}) => {
  return (
    <div style={useStyles.containerRow}>
      <div style={{ flex: 1 }}>
        <Label variant="subtitle1" text={title} help={help} />
      </div>
      <div className="calc-input">
        <InputField
          onChange={value => onChange({ name, value })}
          value={value}
          type={type}
          isShadow={false}
          style={{ width: '5rem', textAlign: 'center' }}
          inputProps={{ min: 0, style: { textAlign: 'end', paddingRight: 5, color: '#F4821E' } }}
        />
      </div>
    </div>
  );
};

class Calculator extends PureComponent {
  constructor(props) {
    super(props);
    this.auth = this.props.firebaseApp.auth();
    this.state = {};
  }

  componentDidMount() {
    this.setData(this.props);
  }

  setData = props => {
    const { products = [] } = { ...props };
    const [product = {}] = [...products];
    let {
      bsr = 0,
      rating = 0.0,
      reviews_count = 0,
      sales_estimate,
      offer_price,
      opportunity = '',
      fba_fulfillment_fees = 0.0,
      closing_fee = 0,
      referral_fee = 0.0,
      est_storage_fee = 0.0,
      region = '',
      fulfillment_costs: fullfillmentCosts,
    } = { ...product };

    if (!fullfillmentCosts) {
      fullfillmentCosts = {
        fulfillment_by_amazon_fees: 2.5,
        selling_on_amazon_fees: {
          closing_fee: 0,
          referral_fee: (offer_price.amount / 100) * 0.15,
        },
        storage_cost_per_unit: 0.25,
      };
    }

    const fbaFullfillmentFee = fullfillmentCosts.fulfillment_by_amazon_fees || fba_fulfillment_fees;

    const amazonSellingFee = +(
      fullfillmentCosts.selling_on_amazon_fees.closing_fee ||
      closing_fee + fullfillmentCosts.selling_on_amazon_fees.referral_fee ||
      referral_fee
    ).toFixed(2);

    const estStorageFee = fullfillmentCosts.storage_cost_per_unit || est_storage_fee;
    const { estimate = 0 } = { ...sales_estimate };
    const { amount = 0 } = { ...offer_price };
    const entityCountry = region === 'US' ? '\u0024' : '\u00A3';

    if (typeof rating === 'string') rating = parseFloat(rating)

    const ratingValue = `${rating.toFixed(1)}/${reviews_count}`;
    const unitsSoldValue = `${estimate} /mo`;
    const salesValue = `${(estimate * (amount / 100)).toFixed(2)} /mo`;
    const amzPrice = amount / 100;
    const salesFulfillment = (amazonSellingFee + fbaFullfillmentFee + estStorageFee).toFixed(2);
    const COGSValue = (amzPrice * 0.15).toFixed(2);
    const netPayout = `${(amzPrice - salesFulfillment).toFixed(2)}`;
    const cogsValue = COGSValue;
    const cogsPercent = `${((100 * COGSValue) / amzPrice).toFixed(2)}`;

    const profit = (amzPrice - salesFulfillment - COGSValue).toFixed(2);
    // const profitPercent = ((100 * profit) / amzPrice).toFixed(2);
    const grossProfit = `${profit}`;
    const margin = `${((grossProfit / amzPrice) * 100).toFixed(2)}%`;
    const roi = `${((netPayout / cogsValue - 1) * 100).toFixed(2)}%`;
    const state = {
      bsr,
      opportunity,
      ratingValue,
      unitsSoldValue,
      salesValue,
      fbaFullfillmentFee,
      amazonSellingFee,
      estStorageFee,
      amzPrice,
      salesFulfillment,
      entityCountry,
      netPayout,
      cogsValue,
      cogsPercent,
      grossProfit,
      margin,
      roi,
    };
    if (this.props.onCalculateValues) {
      this.props.onCalculateValues(state);
    }

    this.setState(state);
  };

  removeDots = str => {
    return str.replace(/\./g, '');
  };

  changeCalculator = async ({ name, value }) => {
    const { salesFulfillment = 0.0, amzPrice = 0.0 } = this.state;
    let COGSValue = 0.0;
    let _amzPrice = 0.0;

    if (name === 'COGS-percent-value') {
      COGSValue = value;
      _amzPrice = amzPrice;
    } else if (name === 'amz-price') {
      _amzPrice = value;
      COGSValue = (_amzPrice * 0.15).toFixed(2);
    }

    const netPayout = `${(_amzPrice - salesFulfillment).toFixed(2)}`;
    const cogsValue = COGSValue;
    const cogsPercent = `${((100 * COGSValue) / _amzPrice).toFixed(2)}`;

    const grossProfit = (_amzPrice - salesFulfillment - COGSValue).toFixed(2);
    const margin = `${((grossProfit / _amzPrice) * 100).toFixed(2)}%`;
    const roi = `${((netPayout / cogsValue - 1) * 100).toFixed(2)}%`;
    const _amzPrice2 = parseFloat(amzPrice).toFixed(2);

    this.setState({
      netPayout,
      cogsValue,
      cogsPercent,
      grossProfit,
      amzPrice: _amzPrice,
      margin,
      roi,
    });
  };

  render() {
    const {
      fbaFullfillmentFee,
      amazonSellingFee,
      estStorageFee,
      amzPrice,
      salesFulfillment,
      netPayout,
      cogsValue,
      cogsPercent,
      grossProfit,
      entityCountry,
      margin,
      roi,
    } = { ...this.state };

    return (
      <div style={{ minHeight: '8vw' }}>
        <BorderWrapper>
          <CalculatorTitleInput
            name={'amz-price'}
            onChange={this.changeCalculator}
            title={'Price'}
            help="The selling price."
            value={parseFloat(amzPrice).toFixed(2)}
            currency={entityCountry}
            iTitle={'Price'}
            type={'number'}
          />
        </BorderWrapper>
        <Divider margin="20px 0px" />
        <BorderWrapper>
          <CalculatorTitleValue
            title={'Selling on Amazon Fees'}
            value={parseFloat(amazonSellingFee).toFixed(2)}
            currency={entityCountry}
            help="Amazon referral fees and closing fees."
          />
          <CalculatorTitleValue
            title={'Fulfillment by Amazon Fees'}
            value={parseFloat(fbaFullfillmentFee).toFixed(2)}
            currency={entityCountry}
            help="Estimated Amazon fees for packing and shipping an individual product."
          />
          <CalculatorTitleValue
            title={'Storage Cost'}
            value={parseFloat(estStorageFee).toFixed(2)}
            currency={entityCountry}
            help="Estimated amount you will pay in storage for the product per unit sold."
          />
        </BorderWrapper>

        <Divider margin="20px 0px" />
        <BorderWrapper>
          <CalculatorTitleValue
            title={'Net Payout'}
            value={parseFloat(netPayout).toFixed(2)}
            currency={entityCountry}
            help="Estimated payout after all Amazon’s fees."
          />
        </BorderWrapper>
        <Divider margin="20px 0px" />
        <BorderWrapper>
          <CalculatorTitleInput
            name={'COGS-percent-value'}
            onChange={this.changeCalculator}
            title={`Landed Cost`}
            value={parseFloat(cogsValue).toFixed(2)}
            currency={entityCountry}
            iTitle={'COGS'}
            type={'number'}
            help="The costs of acquiring the product from your supplier, or
            the cost required to manufacture or produce a single unit"
          />
        </BorderWrapper>
        <Divider margin="20px 0px" />
        <BorderWrapper>
          <CalculatorTitleValue
            title={'Sales & Fulfillment'}
            sign="-"
            value={`${parseFloat(salesFulfillment).toFixed(2)}`}
            currency={entityCountry}
            help="Total estimated sales and fulfillment fees."
          />

          <CalculatorTitleValue
            title={'Gross Profit'}
            sign={this.isNegative(grossProfit) ? '-' : '+'}
            value={parseFloat(grossProfit).toFixed(2)}
            currency={entityCountry}
            help="Estimated gross profit per unit sold."
          />
          <CalculatorTitleValue
            title={'Profit Margin'}
            sign={this.isNegative(margin) ? '-' : '+'}
            value={this.manageIninity(margin)}
            currency=""
            help="Estimated percentage of financial
return on total investment"
          />
          <CalculatorTitleValue
            title={'ROI'}
            sign={this.isNegative(roi) ? '-' : '+'}
            value={this.manageIninity(roi)}
            currency=""
            help="Estimated percentage of financial
            return on total investment"
          />
        </BorderWrapper>
      </div>
    );
  }

  manageIninity = value => {
    if (value && value.search(/Infinity/) > -1) {
      return 0;
    }
    return value;
  };

  isNegative = value => value && value.search('-') >= 0;
}

const BorderWrapper = ({ children }) => {
  return (
    <div
      style={{
        borderTop: '.5px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '.5px solid rgba(0, 0, 0, 0.1)',
        padding: '3px 0px 5px 0px',
      }}
    >
      {children}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};

export default connect(mapStateToProps, {})(withFirebase(Calculator));
