/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonMUI, withStyles, makeStyles } from '@material-ui/core';

const ButtonCustomized = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: '4px',
    margin: 0,
    paddingLeft: 12,
    paddingRight: 12,
  },
}))(ButtonMUI);

const useStyles = makeStyles({
  white: {
    height: 34,
    color: '#F4821E',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      outline: '0',
    },
  },
  transparent: {
    height: 34,
    color: 'grey',
    backgroundColor: 'white',
    boxShadow: 'inset 0px 0px 0px 1px gainsboro',
    boxSizing: 'border-box',
    '&:focus': {
      outline: '0',
    },
  },
  primary: {
    height: 34,
    color: '#fff',
    backgroundColor: '#F4821E',
    '&:hover': {
      backgroundColor: '#F4821E',
    },
    '&:focus': {
      outline: '0',
    },
  },
});

const Button = props => {
  const { onClick, children, type, className = '', disabled = false } = props;
  const classes = useStyles();

  let buttonClass = `${className} `;
  switch (type) {
    case 'primary':
      buttonClass += classes.primary;
      break;
    case 'white':
      buttonClass += classes.white;
      break;
    case 'transparent':
      buttonClass = classes.transparent;
      break;
  }

  return (
    <ButtonCustomized disabled={disabled} className={buttonClass} onClick={onClick}>
      {children}
    </ButtonCustomized>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
