import React from 'react';
import { Fragment } from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import { withStyles, lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import { auth } from '../../../config/firebase';
import { set } from '../../../redux/actions/auth';
import { setLocale } from 'react-redux-i18n';
import { FormButton } from '../../../components/SocialButton';
import SnackBar from '../../../components/Snackbar';
import getFireDBService from '../../../utils/fireDBService';

const fireDBService = getFireDBService();

const styles = theme => ({
  paper: {
    height: '100%',
    backgroundColor: '#fff',
  },

  paper_: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  maxWith: {
    maxWidth: '490px',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  card: {
    marginTop: '3rem',
    padding: '.8rem',
    boxShadow: '0px 0px 13px -4px #000000, 0px 0px 13px -4px #000000',
  },

  button: {
    marginTop: '15px',
  },

  font_login: {
    fontSize: '10px',
  },

  font_login_sm: {
    fontSize: '.52rem',
  },

  font_login_xm: {
    fontSize: '.4rem',
  },

  indicador: {
    float: 'left',
    color: '#fff',
    position: 'absolute',
    padding: '5px 5em',
    zIndex: '200',
    fontSize: '12px',
    fontWeight: '400',
  },

  subtitle: {
    fontSize: '1.51rem',
    letterSpacing: '0px',
    fontWeight: '600',
  },

  subtitlexm: {
    fontSize: '.93rem',
    fontWeight: '1000',
    letterSpacing: '0px',
  },

  subtitlesm: {
    fontSize: '1.1rem',
    fontWeight: '1000',
    letterSpacing: '0px',
  },

  subtitlemd: {
    fontSize: '1.3rem',
    fontWeight: '1000',
    letterSpacing: '0px',
  },

  title: {
    fontSize: '2.38rem',
    fontWeight: '1000',
    letterSpacing: '0px',
  },

  titlexm: {
    fontSize: '1.55rem',
    fontWeight: '1000',
    letterSpacing: '0px',
  },

  titlesm: {
    fontSize: '1.8rem',
    fontWeight: '1000',
    letterSpacing: '0px',
  },

  titlemd: {
    fontSize: '2rem',
    fontWeight: '1000',
    letterSpacing: '0px',
  },

  check_term: {
    marginTop: '15px',
    marginBottom: '15px',
  },

  check_label: {
    marginRigth: '5px',
  },

  font_term: {
    fontSize: '.53rem',
    marginRight: '-15px',
  },
});

const BorderLinearProgress = withStyles({
  root: {
    height: 30,
    backgroundColor: lighten('#c2c2c2', 0.5),
  },
  bar: {
    borderRadius: '5px',
    backgroundColor: '#4573B6',
  },
})(LinearProgress);

const YellowCheckbox = withStyles({
  root: {
    color: '#c2c2c2',
    '&$checked': {
      color: '#F4821E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

class Signup extends React.Component {
  plan;
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  handleChange = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;

    this._route = this.props.location;
    let displayName = 'Cypherly Monthly - Standard Plan';
    let displayPrice = '$97/mo';
    let isDiscounted = false;
    let title = 'Start Your FREE 14 Day Trial Now!';
    let subtitle =
      'Completely FREE for 14 days, then only $97 per month. No contracts. Cancel your account anytime with a single click from your dashboard…';
    let daysFree = '14';
    this.plan = 'cypherly-monthly-plan';

    if (this._route.pathname === '/payoffer/steps') {
      displayName = 'Cypherly Monthly - Discount Plan';
      displayPrice = '$48.5/mo';
      isDiscounted = true;
      title = 'Get Your FREE 60 Day Access Now!';
      subtitle =
        'Completely FREE for 60 days, then your special FWA members only rate of $48.50 per month. No contracts. Cancel your account anytime with a single click from your dashboard…';
      daysFree = '60';
      this.plan = 'cypherly-discounted-monthly-plan';
    }

    this.state = {
      error: null,
      step: 0,
      errorMessage: null,
      stepsLabels: ['General Info', 'Payment details'],
      displayName: displayName,
      displayPrice: displayPrice,
      isDiscounted: isDiscounted,
      location: this._route.pathname,
      password: this.props.computedMatch.params.password || '',
      email: this.props.computedMatch.params.email || '',
      checkedB: false,
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      title: title,
      subtitle: subtitle,
      daysFree: daysFree,
    };
  }

  showSnackBar(message, type = 'success') {
    this.setState({
      snackbar: {
        open: true,
        type: type,
        message: message,
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    });
  }

  componentDidMount() {
    this.setState({ loader: true });
    if (this.state.email.length > 1) {
      this.setState({ loader: true, btnLabel: 'Procesing, wait a moment' });
      this.register();
    } else {
      this.setState({
        loader: false,
        btnLabel: this.initBtnLabel,
        error: { code: 'Email', message: 'Email required' },
      });
    }
  }

  getSteps() {
    return;
  }

  setStep() {
    let step = this.state.step + 1;
    this.setState({ step: step, loader: false });
  }

  onProcessing() {
    this.setState({ loader: true });
  }

  onProcessFinished() {
    this.setState({ loader: false });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.email.length <= 0) {
    }
    this.setState({ loader: true });
    if (this.state.email.length > 1) {
      this.setState({ loader: true, btnLabel: 'Procesing, wait a moment' });
      this.register();
    } else {
      this.setState({
        loader: false,
        btnLabel: this.initBtnLabel,
        error: { code: 'Email', message: 'Email required' },
      });
    }
  }

  register() {
    auth
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(result => {
        // user
        let user = auth.currentUser;
        //user update profile
        user
          .updateProfile({
            displayName: this.state.first_name + ' ' + this.state.last_name,
          })
          .then(
            result => {
              auth
                .signInWithEmailAndPassword(this.state.email, this.state.password)
                .then(_res => {
                  this.createAccount(user);
                })
                .catch(error => {
                  console.log('error on process to create user', error);
                  this.showSnackBar('Error on process to create user', 'error');
                });
            },
            error => {
              console.error(error);
              this.setState({
                loader: false,
                btnLabel: this.initBtnLabel,
                error: error,
              });
            },
          );
      })
      .catch(error => {
        this.showSnackBar(error.message, 'error');
        setTimeout(() => {
          this.onProcessFinished();
          this.setState({
            loader: false,
            btnLabel: this.initBtnLabel,
            errorMessage: error,
          });
        }, 600);
      });
  }

  async createAccount(user) {
    this.user = user;
    const user_exists = await fireDBService.getUser(this.user.uid);
    if (user_exists) {
      this.setState({ loader: false, errorMessage: 'User already registerd!' });
      return null;
    }
    this.setUserGroup();
  }

  async setUserGroup() {
    // Prepare session object
    let mySession = {
      reset: true,
      products: [
        {
          path: this.plan,
          quantity: 1,
        },
      ],
      paymentContact: {
        email: this.state.email,
        firstName: this.state.first_name,
        lastName: this.state.last_name,
      },
      language: 'en',
    };

    this.gid = await fireDBService.createEmptyGroup();
    await fireDBService.setGroup(this.gid, { owner: this.user.uid });

    const providerData = this.user.providerData;
    providerData.uid = this.user.uid;
    const user = {
      gid: this.gid,
      email: this.user.email,
      displayName: '',
      phoneNumber: '',
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      providerData: providerData,
      metadata: this.user.metadata,
    };

    await fireDBService.setUser(this.user.uid, user, async error => {
      this.store.dispatch(set(user));
      this.setState({ loader: false });
      this.context.store.dispatch(setLocale(user.lang || 'en'));
      this.context.store.dispatch(set(user));
      window.localStorage.setItem('session', JSON.stringify(mySession));
      this.context.router.history.push('/checkout/' + this.user.uid);
    });
  }

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  render() {
    const { classes } = this.props;
    return this.state.email === '' && this.state.password === '' ? (
      <div className={classes.paper}>
        <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />
        <Container component="main" className={classes.maxWith}>
          <CssBaseline />
          <div className={classes.paper_}>
            <Card className={classes.card}>
              <CardContent>
                <Typography component="div">
                  <div>
                    <Media
                      queries={{
                        xsmall: '(max-width: 344px)',
                        small: '(min-width: 345px) and (max-width: 599px)',
                        medium: '(min-width: 600px) and (max-width: 1199px)',
                        large: '(min-width: 1200px)',
                      }}
                    >
                      {matches => (
                        <Fragment>
                          {matches.xsmall && (
                            <Box textAlign="center" className={classes.titlexm}>
                              Create Your Account
                            </Box>
                          )}
                          {matches.small && (
                            <Box textAlign="center" className={classes.titlesm}>
                              Create Your Account
                            </Box>
                          )}
                          {matches.medium && (
                            <Box textAlign="center" className={classes.titlemd}>
                              Create Your Account
                            </Box>
                          )}
                          {matches.large && (
                            <Box textAlign="center" className={classes.title}>
                              Create Your Account
                            </Box>
                          )}
                        </Fragment>
                      )}
                    </Media>
                  </div>

                  <div>
                    <Media
                      queries={{
                        xsmall: '(max-width: 344px)',
                        small: '(min-width: 345px) and (max-width: 599px)',
                        medium: '(min-width: 600px) and (max-width: 1199px)',
                        large: '(min-width: 1200px)',
                      }}
                    >
                      {matches => (
                        <Fragment>
                          {matches.xsmall && (
                            <Box
                              textAlign="center"
                              fontWeight="fontWeightBold"
                              className={classes.subtitlexm}
                            >
                              {this.state.title}
                            </Box>
                          )}
                          {matches.small && (
                            <Box
                              textAlign="center"
                              fontWeight="fontWeightBold"
                              className={classes.subtitlesm}
                            >
                              {this.state.title}
                            </Box>
                          )}
                          {matches.medium && (
                            <Box
                              textAlign="center"
                              fontWeight="fontWeightBold"
                              className={classes.subtitlemd}
                            >
                              {this.state.title}
                            </Box>
                          )}
                          {matches.large && (
                            <Box
                              textAlign="center"
                              fontWeight="fontWeightBold"
                              className={classes.subtitle}
                            >
                              {this.state.title}
                            </Box>
                          )}
                        </Fragment>
                      )}
                    </Media>
                  </div>

                  <label className={classes.indicador}>Step 1 of 2 …</label>

                  <BorderLinearProgress
                    className={classes.margin}
                    variant="determinate"
                    color="secondary"
                    value={75}
                  ></BorderLinearProgress>
                </Typography>

                <hr />

                <Typography component="div">
                  <div>
                    <Media
                      queries={{
                        xsmall: '(max-width: 344px)',
                        small: '(min-width: 345px) and (max-width: 599px)',
                        medium: '(min-width: 600px) and (max-width: 1199px)',
                        large: '(min-width: 1200px)',
                      }}
                    >
                      {matches => (
                        <Fragment>
                          {matches.xsmall && (
                            <Box textAlign="center" m={1} className={classes.font_login_xm}>
                              {this.state.subtitle}
                            </Box>
                          )}
                          {matches.small && (
                            <Box textAlign="center" m={1} className={classes.font_login_sm}>
                              {this.state.subtitle}
                            </Box>
                          )}
                          {matches.medium && (
                            <Box textAlign="center" m={1} className={classes.font_login}>
                              {this.state.subtitle}
                            </Box>
                          )}
                          {matches.large && (
                            <Box textAlign="center" m={1} className={classes.font_login}>
                              {this.state.subtitle}
                            </Box>
                          )}
                        </Fragment>
                      )}
                    </Media>
                  </div>
                </Typography>

                <form onSubmit={this.onSubmit.bind(this)} className={classes.form}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Enter Your Email Address ..."
                    name="email"
                    type={'email'}
                    autoComplete="email"
                    value={this.state.email}
                    disabled={this.state.loader}
                    onChange={this.onChangeEmail.bind(this)}
                    autoFocus
                    InputLabelProps={{
                      style: {
                        fontSize: '12px',
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.font_login,
                      },
                    }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    className={classes.font_login}
                    name="password"
                    label="Enter A Password With More Than 8 Characters"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    disabled={this.state.loader}
                    onChange={this.onChangePassword.bind(this)}
                    InputLabelProps={{
                      style: {
                        fontSize: '12px',
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.font_login,
                      },
                    }}
                  />

                  <FormButton
                    fullWidth
                    type="submit"
                    className={classes.button}
                    color="primary"
                    variant="contained"
                  >
                    Start My Free Trial
                  </FormButton>

                  <FormControlLabel
                    control={
                      <YellowCheckbox
                        required
                        checked={this.state.checkedB}
                        onChange={this.handleChange('checkedB')}
                        value="checkedB"
                        color="primary"
                        className={classes.check_term}
                      />
                    }
                    label={
                      <div className={classes.font_term}>
                        <span>
                          {this.state.daysFree} days free from today, then just{' '}
                          {this.state.displayPrice}. Cancel through your dashboard or by e-mail (
                        </span>
                        <Link to={'/terms'}>support@cypherly.io</Link>
                        <span>). I also agree to the </span>
                        <Link to={'/privacy'}>Terms of Service and Privacy Policy.</Link>
                      </div>
                    }
                  />

                  <Typography component="div" className={classes.font_login}>
                    <Box textAlign="center" m={1}>
                      Already a member? <Link href="/login"> Login Here </Link>
                    </Box>
                  </Typography>
                </form>
              </CardContent>
            </Card>
          </div>
        </Container>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, props) => {
  let auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withStyles(styles)(withFirebase(Signup)));
