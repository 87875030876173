import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import { withFirebase } from 'firekit-provider';
import { setRegion } from '../redux/actions/auth';
import { setTags, deleteTag } from '../redux/actions/keyword_tags';
import WistiaEmbed from '../utils/wistiaEmbed';
import Navigator from './Navbar';
import getFireDBService from '../utils/fireDBService';
import getFirestoreService from '../utils/firestoreService';
import theme from '../theme/theme';

const fireDBService = getFireDBService();
const firestoreService = getFirestoreService();

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',

    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: '30px 32px',
    background: '#eaeff1',
    marginTop: 125,
  },
  main_close: {
    flex: 1,
    padding: '30px 32px 30px 80px',
    background: '#eaeff1',
    marginTop: 125,
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

class MainContainer extends React.Component {
  static get contextTypes() {
    return {
      classes: PropTypes.object,
      theme: PropTypes.object,
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.firebase = props.firebaseApp;

    this.auth = this.firebase.auth();

    this.state = {
      mobileOpen: true,
      display: 'hidden',
      open_tracker: false,
      TrackerAnchorEl: null,
      sel_keyword_tag: '',
      openVideo: false,
      video: '',
      removeDialog: {
        open: false,
      },
    };
    this.fetchKeywordTags();
  }

  componentDidMount() {
    const state = this.store.getState();
    fireDBService.subscribeVideos(snapshot => {
      const data = snapshot.val();
      this.setState({ video: data.tutorial });
      if (!state.auth || !state.auth.user || !state.auth.user.tutorialVideo) {
        this.openVideo();
      }
    });
  }

  openVideo = () => {
    this.setState({ openVideo: true });
  };

  async closeVideo() {
    this.setState({ openVideo: false });
    // save db flag tutorialView
    const state = this.store.getState();
    await fireDBService.updateUser(state.auth.user.group.owner, { tutorialVideo: true });
  }

  handleDrawerToggle = () => {
    this.setState({ display: true });
    this.setState({ mobileOpen: !this.state.mobileOpen });
    if (this.state.mobileOpen) {
      this.setState({ drawerWidth: 0 });
    } else {
      this.setState({ drawerWidth: drawerWidth });
    }
  };

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false });
    if (this.context.router.history.location.pathname === '/products/tracker') {
      // this.setState({ display: false });
    } else {
      // this.setState({ display: true });
    }
  };

  fetchKeywordTags() {
    firestoreService.subscribeSearchDocs(
      'keyword_tags',
      [
        ['gid', '==', this.props.auth.user.gid],
        // ['region', '==', this.props.auth.region],
      ],
      [
        // [this.state.orderBy.field, this.state.orderBy.order],
      ],
      results => {
        let tags_ary = [];
        results.docs.forEach(doc => {
          let tag_data = doc.data();
          tags_ary.push(tag_data.string);
        });
        this.store.dispatch(setTags(tags_ary));
      },
    );
  }

  goTo = route => {
    this.context.router.history.push(route);
    if (route === '/products/tracker') {
      // this.setState({ display: false });
      // this.setState({ open: false });
    } else {
      // this.setState({ display: true });
    }
  };
  onChangeRegion(e) {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  }
  displayAvatar(auth) {
    if (auth.user.photoURL) {
      // this.setState({ display: 'yes' });
      return (
        <img
          className="rounded-circle pull-left"
          src={auth.user.photoURL}
          alt={auth.user.displayName}
        />
      );
    }
    return (
      <div className="pull-left initials">
        {auth.user.displayName ? auth.user.displayName.substr(0, 1) : ''}
      </div>
    );
  }

  openKeywordTracker = event => {
    let open_status = this.state.open_tracker;
    this.setState(
      {
        open_tracker: !open_status,
        TrackerAnchorEl: open_status ? null : event.target,
        display: true,
        mobileOpen: true,
      },
      () => {
        if (this.state.mobileOpen) {
          this.setState({ drawerWidth: 256 });
        }
      },
    );

    this.goTo('/keywords/tracker');
  };

  onRemoveDialog = async confirmed => {
    this.setState({
      removeDialog: {
        open: false,
      },
    });

    if (confirmed) {
      await firestoreService.deleteDocs('keyword_tags', this.state.sel_keyword_tag);

      firestoreService.subscribeSearchDocs(
        'groups_keywords',
        [
          ['gid', '==', this.props.auth.user.gid],
          ['tag', '==', this.state.sel_keyword_tag],
        ],
        [],
        async results => {
          await Promise.all(
            results.docs.map(doc => firestoreService.deleteDocs('groups_keywords', doc.id)),
          );
        },
      );

      this.store.dispatch(deleteTag(this.state.sel_keyword_tag));
      this.goTo('/keywords/tracker');
    }
  };

  openConfirmDelete = tag => {
    this.setState({
      sel_keyword_tag: tag,
      removeDialog: {
        open: true,
      },
    });
  };

  render() {
    const { classes, keyword_tags } = this.props;

    // const keyword_tags = this.store.getState().keyword_tags;

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Dialog
            open={this.state.openVideo}
            onClose={this.closeVideo.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'md'}
          >
            <DialogContent>
              <div className="wistia-embed-wrapper">
                {/* You can include any Wistia embed options as props. See the full list at https://wistia.com/doc/embed-options#options_list */}
                <WistiaEmbed hashedId={this.state.video} playerColor="#54bbff" autoPlay={true} />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeVideo.bind(this)} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <CssBaseline />
          <nav
            style={{ width: this.state.drawerWidth, flexShrink: 0, backgroundColor: '#eaeff1' }}
            className={classes.drawer}
          >
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                goTo={this.goTo}
                openKeywordTracker={this.openKeywordTracker}
                removeDialog={this.state.removeDialog}
                onRemoveDialog={this.onRemoveDialog}
                open_tracker={this.state.open_tracker}
                keyword_tags={keyword_tags}
                openConfirmDelete={this.openConfirmDelete}
                openVideo={this.openVideo}
                currentUser={this.auth.currentUser}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                goTo={this.goTo}
                variant="persistent"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                openKeywordTracker={this.openKeywordTracker}
                removeDialog={this.state.removeDialog}
                onRemoveDialog={this.onRemoveDialog}
                open_tracker={this.state.open_tracker}
                keyword_tags={keyword_tags}
                openConfirmDelete={this.openConfirmDelete}
                openVideo={this.openVideo}
                currentUser={this.auth.currentUser}
              />
            </Hidden>
          </nav>
          <div className={classes.app}>
            {React.Children.map(this.props.children, child =>
              React.cloneElement(child, {
                classMain: this.state.mobileOpen ? classes.main : classes.main_close,
                open: this.state.mobileOpen,
                goTo: this.goTo,
                keyword_tags: keyword_tags,
                openConfirmDelete: this.openConfirmDelete,
                onDrawerToggle: this.handleDrawerToggle,
              }),
            )}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  const keyword_tags = state.keyword_tags;
  return { keyword_tags };
};

export default connect(mapStateToProps, {})(withFirebase(withStyles(styles)(MainContainer)));
