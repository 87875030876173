import React from 'react';
import MainContainer from '../components/MainContainer';

class Login extends React.Component {
  render() {
    return (
      <div className="h-100 ">
        <this.props.component {...this.props} />
      </div>
    );
  }
}
export default Login;
