import {
  GET_KEYWORD_TAGS,
  SET_KEYWORD_TAGS,
  ADD_KEYWORD_TAG,
  DELETE_KEYWORD_TAG,
} from '../actions/keyword_tags';

let init = [];
export default function (state = init, action) {
  switch (action.type) {
    case GET_KEYWORD_TAGS:
      return Object.assign({}, state, {
        // keyword_tags    : [...state.keyword_tags, action.keyword_tag]
      });
    case SET_KEYWORD_TAGS: {
      return Object.assign([], state, action.tags);
    }
    case ADD_KEYWORD_TAG: {
      return Object.assign([], state, [...state, action.tag]);
    }
    case DELETE_KEYWORD_TAG: {
      let index = state.indexOf(action.tag);
      if (index > -1) state.splice(index, 1);
      return Object.assign([], state, state);
    }
    default:
      return state;
  }
}
