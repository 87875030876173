import React from 'react';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// components
import Kashing from '../../../components/_kashing';
// import globals from "../../../config/globals";

// pk_1mNAZAUrxrunLV9KiT1Qz1xe0ipYT
const styless = theme => ({
  Dialog: {
    transition: 'all 4s ease-out',
    root: {
      transition: 'all 4s ease-out',
    },
  },
  DialogTitle: {
    background: '#213139',
    '& h2': {
      color: 'white',
    },
  },
  DialogTitleIcon: {
    fontSize: 15,
    marginRight: '10px',
  },
  DialogContent: {
    padding: '40px',
  },
  DialogContentPurchase: {
    padding: '40px',
    backgroundColor: '#f8f8f8',
    borderBottom: '1px solid #ccc',
  },
  planCard: {
    width: '250px',
    boxShadow: 'none',
    border: '1px solid #ddd',
  },
  planCardActive: {
    borderColor: blue[500],
  },
  planCardHeader: {
    borderBottom: '1px solid #ddd',
  },
  planCardContent: {
    borderBottom: '1px solid #ddd',
    minHeight: '150px',
  },
  selectedPlanCardContent: {
    borderBottom: '1px solid #ddd',
    minHeight: '100px',
  },
  planName: {
    textTransform: 'capitalize',
  },
  planPrice: {
    fontSize: '18px',
    color: blue[500],
  },
  planCardActions: {
    position: 'flex',
    justifyContent: 'center',
  },
  listPlan: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  planNameToPurchase: {
    fontSize: '20px',
  },
  btnPurchase: {
    background: blue[500],
    color: '#fff',
    borderRadius: '10px',
    '&:hover': {
      background: blue[600],
    },
  },
});

class ItemPlan extends Kashing {
  render() {
    let currentClass = this.props.currentPlan ? this.props.classes.planCardActive : '';
    return (
      <Grid item xs={4}>
        <Card className={this.props.classes.planCard + ' ' + currentClass}>
          <CardContent className={this.props.classes.planCardHeader}>
            <Typography color="textSecondary" gutterBottom className={this.props.classes.planName}>
              {this.props.item.label}
            </Typography>
            <Typography color="textPrimary" className={this.props.classes.planPrice}>
              {this.props.item.priceDesc} {this.money(this.props.item.price, 2, null)}/month
            </Typography>
          </CardContent>
          <CardContent className={this.props.classes.planCardContent}>
            <ul className={this.props.classes.listPlan}>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
              <li>Item 5</li>
            </ul>
          </CardContent>
          <CardActions className={this.props.classes.planCardActions}>
            <Button
              onClick={this.props.onClick.bind(this)}
              variant="contained"
              color="primary"
              fullWidth={true}
              value={this.props.item.name}
            >
              {' '}
              Select Plan{' '}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

class PlanToPurchaseDetails extends Kashing {
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={this.props.show}
        onClose={this.props.onClose.bind(this)}
        className={this.props.classes.Dialog}
      >
        <DialogTitle className={this.props.classes.DialogTitle}>
          <Icon className={classNames('fas fa-paper-plane', this.props.classes.DialogTitleIcon)} />
          Confirm purchase
        </DialogTitle>
        <DialogContent className={this.props.classes.DialogContentPurchase}>
          <Grid item xs={4}>
            <Card className={this.props.classes.planCard}>
              <CardContent className={this.props.classes.selectedPlanCardContent}>
                <Typography color="textSecondary" gutterBottom>
                  Selected plan
                </Typography>
                <Typography className={this.props.classes.planNameToPurchase}>
                  Plan {this.props.selected.label}
                </Typography>
                <Typography color="textPrimary" className={this.props.classes.planPrice}>
                  {this.money(this.props.selected.price, 2, null)}/month
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose.bind(this)} color="primary">
            Cancel
          </Button>
          <Button onClick={this.props.onPurchase.bind(this)} variant="contained" color="primary">
            Purchase
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class PlanState extends Kashing {
  render() {
    if (this.props.showDetails) {
      return <PlanToPurchaseDetails {...this.props} />;
    } else {
      return this.props.itemList;
    }
  }
}

class DialogPlan extends Kashing {
  constructor(props, context) {
    super(props);
    this.state = {
      showDetails: false,
      selectedPlan: null,
    };

    this.items = [
      { name: 'trial', label: 'Trial', price: 0, priceDesc: 'Free' },
      { name: 'basic', label: 'Basic', price: 10, priceDesc: 'Fixed' },
      { name: 'full', label: 'Full', price: 20, priceDesc: '' },
    ];
  }

  showBillingWindow(o) {
    o = typeof o === 'boolean' ? o : false;
    this.setState({ billingWindow: o });
  }
  choosePlan(e) {
    let item = _.find(this.items, { name: e.currentTarget.value });
    this.setState({ showDetails: true, selectedPlan: item });
  }
  onCloseDetails() {
    this.setState({ showDetails: false });
  }
  onPurchase() {}
  render() {
    let itemList = this.items.map((item, index) => {
      return (
        <ItemPlan
          {...this.props}
          item={item}
          key={'item-plan-' + index}
          onClick={this.choosePlan.bind(this)}
        />
      );
    });

    return (
      <Dialog
        maxWidth={'md'}
        open={this.props.show}
        onClose={this.props.onClose.bind(this)}
        className={this.props.classes.Dialog}
      >
        <DialogTitle className={this.props.classes.DialogTitle}>
          <Icon className={classNames('fas fa-paper-plane', this.props.classes.DialogTitleIcon)} />
          Cypherly Keywords Plans
        </DialogTitle>
        <DialogContent className={this.props.classes.DialogContent}>
          <Grid container spacing={0}>
            <PlanState
              {...this.props}
              items={this.items}
              itemList={itemList}
              selected={this.state.selectedPlan}
              showDetails={this.state.showDetails}
              onPurchase={this.onPurchase.bind(this)}
              onClose={this.onCloseDetails.bind(this)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose.bind(this)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withStyles(styless)(DialogPlan);
