import Kashing from '../../../components/_kashing';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import Button from '@material-ui/core/Button';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SnackBar from '../../../components/Snackbar';
import rules from './rules.json';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Header from '../../../components/Header';
import { withStyles } from '@material-ui/core';
import { setRegion } from '../../../redux/actions/auth';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

import getFireDBService from '../../../utils/fireDBService';
import DialogVideo from '../../../components/molecules/DialogVideo';

const fireDBService = getFireDBService();
const keyword_extractor = require('keyword-extractor');
const difflib = require('difflib');

const PrettoSlider = withStyles({
  root: {
    color: '#F4821E',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const styles = {
  card: {
    minHeight: '8vw',
    borderRadius: '0px',
    flex: 1,
  },
  planCard: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    minHeight: 'auto',
  },
  planCardContent: {
    borderBottom: '1px solid #ddd',
  },
  planCardActions: {
    position: 'flex',
    justifyContent: 'center',
  },
  listPlan: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },

  container: {
    width: '100%',
  },

  loginSection: {
    width: '100%',
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  editor: {
    minHeight: '6em',
    width: '100%',
  },

  title: {
    fontSize: '0.9rem',
    fontWeight: '700',
  },

  subtitle: {
    fontSize: '0.7rem',
  },

  editor_text: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderWidth: '1px',
    borderRadius: '4px',
    pointerEevents: 'none',
    padding: '3px',
    height: '200px',
  },

  chip: {
    margin: '0.3rem',
  },

  div_section: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
};

const ColorButton = withStyles(theme => ({
  root: {
    marginBottom: '10px',
    marginTop: '10px',
    color: '#fff',
    width: '100%;',
    backgroundColor: '#F4821E',
    '&:hover': {
      backgroundColor: '#F4821E',
    },
  },
}))(Button);

class ListingPage extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  state = {
    editorState: EditorState.createEmpty(),
    editorSearchTermState: EditorState.createEmpty(),
    editorDescriptionTermState: EditorState.createEmpty(),
    editorBullet1State: EditorState.createEmpty(),
    editorBullet2State: EditorState.createEmpty(),
    editorBullet3State: EditorState.createEmpty(),
  };

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.state = {
      keyword: '',
      bsrs: '',
      keywords: [],
      savedTags: [],
      suggestion: {
        separator: '',
        trigger: ' ',
        suggestions: [],
      },
      orderBy: { field: 'volume', order: 'desc' },
      inputDisabled: false,
      selectedRowId: null,
      isSearching: false,
      removeDialog: {
        open: false,
      },
      progressLine: {
        show: false,
      },
      open: false,
      video: '',
      openVideo: false,
      isLoading: false,
      title: '',
      searchTerm: '',
      bullet1: '',
      bullet2: '',
      bullet3: '',
      bullet4: '',
      bullet5: '',
      description: '',
      searchTerms: '',
      benefits: '',
      selectedKeywords: [],
      bsrs_array: [],
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      readyData: false,
      asins: '',
      step: 1,
      words: [],
      text_process: '',
      phrases: [],
      related: [],
      suggestions: [],
      use_suggestion: {},
      use_suggestion_title: {},
      use_suggestion_bullet: {},
      use_suggestion_description: {},
      use_suggestion_search: {},
      loading_related: false,
      list_bullets: [
        { id: 1, name: 'bullet1', counter_c: 0, counter_w: 0, counter_b: 0 },
        { id: 2, name: 'bullet2', counter_c: 0, counter_w: 0, counter_b: 0 },
        { id: 3, name: 'bullet3', counter_c: 0, counter_w: 0, counter_b: 0 },
        { id: 4, name: 'bullet4', counter_c: 0, counter_w: 0, counter_b: 0 },
        { id: 5, name: 'bullet5', counter_c: 0, counter_w: 0, counter_b: 0 },
      ],
      counter_title: 0,
      counter_title_words: 0,

      counter_search_bytes: 0,
      counter_title_search: 0,
      counter_search: 0,
      counter_search_words: 0,

      counter_description: 0,
      counter_description_words: 0,
      counter_description_bytes: 0,
    };

    this.setEditor = editor => {
      this.editor = editor;
    };

    this.focusEditor = e => {
      console.log('editor', e);
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    fireDBService.subscribeVideos(snapshot => {
      const data = snapshot.val();
      this.setState({ video: data.listing_creator });
    });
  }

  countUsesByText = (text, name) => {
    let use_suggestion = {};
    const phrases = this.state.suggestions;
    if (!phrases) {
      return;
    }
    phrases.forEach(it => {
      use_suggestion[it] = 0;
    });
    if (text.indexOf('<a') >= 0) {
      const t = text.split('<a');
      t.forEach(it => {
        if (it.indexOf('href=') >= 0) {
          const index_eq = it.indexOf('=');
          const find_text = this.findValueHref(it, index_eq);
          const find_sug = this.state.suggestions.find(el => el === find_text);
          if (find_sug) {
            let counter = parseInt(use_suggestion[find_sug], 10);
            counter++;
            use_suggestion[find_sug] = counter;
            this.setState({ [name]: use_suggestion });
          }
        }
      });
      this.setState({ [name]: use_suggestion });
      return use_suggestion;
    }
  };

  findValueHref = (text, index) => {
    let tc = '';
    let text_read = '';
    for (let i = index; i < text.length; i++) {
      const c = text[i];
      if (c === '"') {
        if (tc === 'start_read') {
          tc = 'on_read';
          continue;
        }

        if (tc === 'on_read') {
          tc = 'end_read';
          break;
        }
      }
      if (c === '=') {
        tc = 'start_read';
      }
      if (tc === 'on_read') {
        text_read += c;
      }
    }
    return text_read;
  };

  countAllInputs = () => {
    const all_values = {};
    const use_suggestion_title = this.state.use_suggestion_title;
    const use_suggestion_search = this.state.use_suggestion_title;
    const use_suggestion_bullet = this.state.use_suggestion_bullet;
    const use_suggestion_description = this.state.use_suggestion_description;

    const phrases = this.state.suggestions;

    for (const it of phrases) {
      let sum = 0;
      if (use_suggestion_title) {
        sum += use_suggestion_title[it];
      }
      if (use_suggestion_bullet) {
        sum += use_suggestion_bullet[it];
      }
      if (use_suggestion_search) {
        sum += use_suggestion_search[it];
      }

      if (use_suggestion_description) {
        sum += use_suggestion_description[it];
      }

      all_values[it] = sum;
    }
    this.setState({ use_suggestion: all_values });
  };

  strip_html_tags = str => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  };

  replaceNbsps(str) {
    if (!str) {
      return '';
    }
    let re = new RegExp(String.fromCharCode(160), 'g');
    return str.replace(re, ' ').replace(/&nbsp;/g, ' ');
  }

  s(x) {
    return x.charCodeAt(0);
  }

  onEditorStateDescriptionChange = editorState => {
    const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const use_suggestion_description = this.countUsesByText(
      description,
      'use_suggestion_description',
    );

    let raw_text = this.replaceNbsps(this.strip_html_tags(description));
    if (raw_text.startsWith('\n')) {
      raw_text = '';
    }
    if (raw_text.endsWith('\n')) {
      raw_text = raw_text.substr(0, raw_text.length - 1);
    }
    const words = raw_text.split(' ').filter(it => it.length > 0);
    const bytes = raw_text.split('').map(this.s);
    // count mentions
    this.setState(
      {
        editorDescriptionTermState: editorState,
        description: description,
        use_suggestion_description: use_suggestion_description,
        counter_description: raw_text.length,
        counter_description_words: words.length,
        counter_description_bytes: bytes ? bytes.length : 0,
      },
      () => {
        this.countAllInputs();
      },
    );
  };

  cleanText = description => {
    let raw_text = this.replaceNbsps(this.strip_html_tags(description));
    if (raw_text.startsWith('\n')) {
      raw_text = '';
    }
    if (raw_text.endsWith('\n')) {
      raw_text = raw_text.substr(0, raw_text.length - 1);
    }
    return raw_text;
  };

  onEditorStateSearchChange = editorState => {
    const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const use_suggestion_search = this.countUsesByText(description, 'use_suggestion_search');

    let raw_text = this.cleanText(description);
    const words = raw_text.split(' ').filter(it => it.length > 0);
    const bytes = raw_text.split('').map(this.s);
    // count mentions

    this.setState(
      {
        editorSearchTermState: editorState,
        searchTerm: description,
        use_suggestion_search: use_suggestion_search,
        counter_search: raw_text.length,
        counter_search_words: words.length,
        counter_search_bytes: bytes ? bytes.length : 0,
      },
      () => {
        this.countAllInputs();
      },
    );
  };

  onEditorStateChange = editorState => {
    const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const use_suggestion_title = this.countUsesByText(description, 'use_suggestion_title');

    let raw_text = this.replaceNbsps(this.strip_html_tags(description));
    if (raw_text.startsWith('\n')) {
      raw_text = '';
    }
    if (raw_text.endsWith('\n')) {
      raw_text = raw_text.substr(0, raw_text.length - 1);
    }
    const words = raw_text.split(' ').filter(it => it.length > 0);
    const bytes = raw_text.split('').map(this.s);
    // count mentions
    this.setState(
      {
        editorState: editorState,
        title: description,
        use_suggestion_title: use_suggestion_title,
        counter_title: raw_text.length,
        counter_title_words: words.length,
        counter_title_bytes: bytes ? bytes.length : 0,
      },
      () => {
        this.countAllInputs();
      },
    );
  };

  onEditorBulletStateChange = (editorState, n) => {
    const name = 'editorBullet' + n + 'State';
    const nameState = 'bullet' + n;
    const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const use_suggestion_bullet = this.countUsesByText(description, 'use_suggestion_bullet');
    const list_bullets = this.state.list_bullets;
    const f = list_bullets.find(it => it.id === n);
    const i = list_bullets.indexOf(f);

    let raw_text = this.replaceNbsps(this.strip_html_tags(description));
    if (raw_text.startsWith('\n')) {
      raw_text = '';
    }
    if (raw_text.endsWith('\n')) {
      raw_text = raw_text.substr(0, raw_text.length - 1);
    }
    const words = raw_text.split(' ').filter(it => it.length > 0);
    const bytes = raw_text.split('').map(this.s);
    f.counter_c = raw_text.length;
    f.counter_w = words.length;
    f.counter_b = bytes ? bytes.length : 0;
    list_bullets[i] = f;
    // count mentions
    this.setState(
      {
        [name]: editorState,
        [nameState]: description,
        use_suggestion_bullet: use_suggestion_bullet,
        list_bullets: list_bullets,
      },
      () => {
        this.countAllInputs();
      },
    );
  };

  getQuality(value, type) {
    const text = value;
    if (!text) {
      return 0;
    }
    if (text.length <= 0) {
      return 0;
    }
    const selectedKeywords = this.state.selectedKeywords;
    const benefitsArray = this.state.benefits.split('\n');
    if (selectedKeywords.length <= 0 || benefitsArray.length <= 0) {
      return 0;
    }
    let quality = 0;
    switch (type) {
      case 'title':
        {
          const rule = rules.titleQuality;
          quality = this.queryQuality(rule, text, selectedKeywords, benefitsArray);
        }
        break;
      case 'bullet':
        {
          const rule = rules.bulletPointQuality;
          quality = this.queryQuality(rule, text, selectedKeywords, benefitsArray);
        }
        break;
      case 'description':
        {
          const rule = rules.descriptionQuality;
          quality = this.queryQuality(rule, text, selectedKeywords, benefitsArray);
        }
        break;

      default: {
        return 0;
      }
    }
    return quality;
  }

  queryQuality(rule, text, selectedKeywords, benefitsArray) {
    let quality = 0;
    for (const propertyName in rule) {
      const keywords = parseInt(rule[propertyName].keywords);
      const benefits = parseInt(rule[propertyName].benefits);
      const average_frequency = parseFloat(rule[propertyName].average_frequency);
      const length = parseInt(rule[propertyName]['length']);
      if (text.length <= length) {
        let count_k = 0;
        let count_b = 0;
        selectedKeywords.forEach(k => {
          if ((text.match(new RegExp(k.keyword, 'g')) || []).length > 0) {
            count_k++;
          }
        });
        benefitsArray.forEach(b => {
          if ((text.match(new RegExp(b, 'g')) || []).length > 0) {
            count_b++;
          }
        });
        if (count_b > benefits && count_k > keywords) {
          continue;
        }
        const av_f = selectedKeywords.length / count_k;
        if (average_frequency < av_f) {
          continue;
        }
        quality = parseInt(propertyName);
        break;
      } else {
        continue;
      }
    }
    return quality;
  }

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  addSelectedKeyword(item, check) {
    let selectedKeywords = this.state.selectedKeywords;
    if (!check) {
      const s = selectedKeywords.find(element => element.keyword === item.keyword);
      const index = selectedKeywords.indexOf(s);
      selectedKeywords.splice(index, 1);
    } else {
      selectedKeywords.push(item);
    }
    this.setState({ selectedKeywords: selectedKeywords });
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  sortTable(o) {
    this.setState({ orderBy: o });
    let newsuggestions = _.orderBy(
      this.state.table,
      [this.state.orderBy.field],
      [this.state.orderBy.order],
    );
    this.setState({
      table: newsuggestions,
    });
  }

  selectRow(row) {}

  onChangeField(field, e) {
    const value = e.target.value;
    this.setState({ [field]: value });
  }

  async regenerate(name, type, e) {
    e.preventDefault();
    const selectedKeywords = this.state.selectedKeywords;
    const benefits = this.state.benefits.split('\n');
    if (selectedKeywords.length <= 1) {
      // show dialog
      this.setState({
        snackbar: {
          open: true,
          type: 'warning',
          message: 'You need select 2 keywords at least',
          position: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      });
      return;
    }

    if (benefits.length <= 3) {
      this.setState({
        snackbar: {
          open: true,
          type: 'warning',
          message: 'You need select 4 benefits at least',
          position: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      });
      return;
    }

    const keywords = selectedKeywords.map(item => item.keyword);
    const value = await this.getKeywordsListing(keywords, benefits, type);
    this.setState({ [name]: value });
  }

  startOver() {
    this.setState({
      keywords: [],
      benefits: '',
      title: '',
      bullet1: '',
      bullet2: '',
      bullet3: '',
      bullet4: '',
      bullet5: '',
      description: '',
      searchTerms: '',
      selectedKeywords: [],
      bsrs: '',
      readyData: false,
      words: [],
      text_process: '',
    });
  }

  async generateListing() {
    const selectedKeywords = this.state.selectedKeywords;
    const benefits = this.state.benefits.split('\n');
    if (this.state.benefits.length <= 0) {
      this.setState({
        snackbar: {
          open: true,
          type: 'warning',
          message: 'You need select 4 benefits at least',
          position: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      });
      return;
    }
    if (selectedKeywords.length <= 1) {
      // show dialog
      this.setState({
        snackbar: {
          open: true,
          type: 'warning',
          message: 'You need select 2 keywords at least',
          position: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      });
      return;
    }

    if (benefits.length <= 3) {
      this.setState({
        snackbar: {
          open: true,
          type: 'warning',
          message: 'You need select 4 benefits at least',
          position: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      });
      return;
    }
    this.setState({ isLoading: true });

    const keywords = selectedKeywords.map(item => item.keyword);
    const keywordsListingTitle = await this.getKeywordsListing(keywords, benefits, 'title');
    this.setState({ title: keywordsListingTitle });
    for (let i = 1; i <= 5; i++) {
      const keywordsListingBullet = await this.getKeywordsListing(keywords, benefits, 'bullet');
      const name = 'bullet' + i;
      this.setState({ [name]: keywordsListingBullet });
    }
    const keywordsListingDescription = await this.getKeywordsListing(
      keywords,
      benefits,
      'description',
    );

    const arr_keywords = this.state.keywords;
    let searchTerms = '';
    arr_keywords.forEach(item => {
      searchTerms = searchTerms + ' ' + item.keyword;
    });
    const content = {
      entityMap: {},
      blocks: [
        {
          key: '637gr',
          text: keywordsListingDescription,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    };
    const contentState = convertFromRaw(content);
    const state1 = EditorState.createWithContent(contentState);
    this.setState({
      editorState: state1,
      description: keywordsListingDescription,
      searchTerms: searchTerms,
      isLoading: false,
      readyData: true,
    });
  }

  async search() {
    if (!this.state.asins || this.state.asins.length <= 0) {
      this.setState({
        snackbar: {
          open: true,
          type: 'warning',
          message: 'You need a type a ASIN at least',
          position: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      });
      return;
    }
    const bsrs = this.state.asins.split(',');
    if (bsrs.length <= 0) {
      // show dialog error format from bsr list
      this.setState({
        snackbar: {
          open: true,
          type: 'warning',
          message: 'You need a type a ASIN at least',
          position: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      });
      return;
    }
    this.setState({ isSearching: true, isLoading: true });
    const region = this.props.auth.region.toUpperCase();
    let bsrs_array = [];
    const asins = this.state.asins.split(',');
    for (let i = 0; i < asins.length; i++) {
      const asin = asins[i].trim();
      let product = await this.getProductBy(asin, region, false);
      if (!product.asin) {
        product.asin = asin;
      }
      bsrs_array.push(product);
    }
    this.setState({ bsrs_array: bsrs_array });

    let listKeyWords = [];

    for (let i = 0; i < bsrs.length; i++) {
      const keywords = await this.getKeywordsByAsin(bsrs[i].trim(), region);
      listKeyWords = listKeyWords.concat(keywords);
    }
    listKeyWords.sort((a, b) => (a.volume > b.volume ? -1 : b.volume > a.volume ? 1 : 0));
    listKeyWords = listKeyWords.slice(0, 5);
    this.setState({
      keywords: listKeyWords,
      isSearching: false,
      isLoading: false,
    });
  }

  downloadTxtFile = () => {
    const element = document.createElement('a');
    let str = 'Title: \n' + this.cleanText(this.state.title) + '\n\n';
    for (let i = 1; i <= 5; i++) {
      const name = 'bullet' + i;
      const v = 'Bullet point ' + i + ':\n' + this.cleanText(this.state[name]);
      str += v + '\n\n';
    }
    str += 'Description:\n' + this.cleanText(this.state.description) + '\n\n';
    str += 'Search terms:\n' + this.cleanText(this.state.searchTerm) + '\n\n';
    const file = new Blob([str], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'export.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  getSimilarities(t1, t2) {
    if (!t1 || !t2) {
      return 0;
    }
    if (t1.trim() === 'No data' || t2.trim() === 'No data') {
      return 0;
    }
    const s = new difflib.SequenceMatcher(null, t1, t2);
    return Math.round(s.ratio() * 100);
  }

  getSimilaritiesPercent(t1, t2) {
    return this.getSimilarities(t1, t2) + '%';
  }

  getUsedAndUnsedKeywords(t1, t2) {
    const k1 = keyword_extractor.extract(t1, {
      language: 'english',
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: false,
    });
    const k2 = keyword_extractor.extract(t2, {
      language: 'english',
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: false,
    });
    let useds = 0;
    let unuseds = 0;
    k2.forEach(item => {
      const f = k1.find(el => el === item);
      if (f) {
        useds++;
      } else {
        unuseds++;
      }
    });
    return 'Used Words ' + useds + ' / Unused Words ' + unuseds;
  }

  getBulletPoint(row, index) {
    if (row.bullet_points) {
      if (index > row.bullet_points.length - 1) {
        return 'No data';
      }
      return row.bullet_points[index].text;
    }
    return '';
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  openVideo() {
    this.setState({ openVideo: true });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  getWordCounter = () => {
    const use_suggestion = this.state.use_suggestion;
    const words = this.state.words;
    let counter = 0;
    if (use_suggestion) {
      words.forEach(key => {
        const value = use_suggestion[key];
        if (value > 0) {
          counter++;
        }
      });
    }
    return counter;
  };

  getCounter = () => {
    const use_suggestion = this.state.use_suggestion;
    const phrases = this.state.phrases;
    let counter = 0;
    if (use_suggestion) {
      phrases.forEach(key => {
        const value = use_suggestion[key];
        if (value > 0) {
          counter++;
        }
      });
    }
    return counter;
  };

  getCounterRelated = () => {
    const use_suggestion = this.state.use_suggestion;
    const related = this.state.related;
    let counter = 0;
    if (use_suggestion) {
      related.forEach(key => {
        const value = use_suggestion[key];
        if (value > 0) {
          counter++;
        }
      });
    }
    return counter;
  };

  splitData = () => {
    const text = this.state.text_process;
    // const words = text.replace('\n', ' ').split(' ');
    const words = text.replace(/\n/g, ' ').split(' ').unique();
    const phrases = text.split('\n');
    const suggestions = phrases.concat(words);
    const array_sug = [];
    phrases.forEach(it => {
      array_sug.push({ text: it, value: it, url: it });
    });
    words.forEach(it => {
      array_sug.push({ text: it, value: it, url: it });
    });
    const use_suggestion = {};
    const promises = [];
    phrases.forEach(it => {
      use_suggestion[it] = 0;
      promises.push(this.getRelatedKeywords(it, this.props.auth.region.toUpperCase()));
    });
    words.forEach(it => {
      use_suggestion[it] = 0;
    });
    this.setState({ loading_related: true });
    Promise.all(promises).then(async res => {
      const related = [];
      res.forEach(a => {
        a = a.response.slice(0, 10);
        a.forEach(it => {
          const key = it.string.replace(/"/g, '');
          const findP = phrases.find(el => el.toLowerCase() === key.toLowerCase());
          if (!findP) {
            related.push(key);
          }
        });
      });
      const suggestion = this.state.suggestion;
      const list_suggestion = this.state.suggestions;
      const _suggestions = this.state.use_suggestion;
      const suggestions = suggestion.suggestions;
      related.forEach(it => {
        suggestions.push({ text: it, value: it, url: it });
        _suggestions[it] = 0;
        list_suggestion.push(it);
      });
      suggestion.suggestions = suggestions;
      await this.setState({
        loading_related: false,
        related: related,
        suggestion: suggestion,
        use_suggestion: _suggestions,
        suggestions: list_suggestion,
      });
    });

    const sug = {
      separator: '',
      trigger: ' ',
      suggestions: array_sug,
    };
    this.setState({
      words: words,
      phrases: phrases,
      suggestions: suggestions,
      suggestion: sug,
      use_suggestion_title: use_suggestion,
      use_suggestion_search: use_suggestion,
      use_suggestion_description: use_suggestion,
      use_suggestion_bullet: use_suggestion,
      use_suggestion: use_suggestion,
    });
  };

  exportData = () => {
    this.downloadTxtFile();
  };

  applyData = async () => {
    this.splitData();
    this.setState({ step: 2 });
  };

  closeVideo() {
    this.setState({ openVideo: false });
  }

  render() {
    const { classMain, onDrawerToggle, open } = this.props;

    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Listing Creator"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain}`}>
          <div className="page-scroll">
            <DialogVideo
              open={this.state.open}
              onClose={this.handleClose.bind(this)}
              videoHashedId={this.state.video}
            />

            <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />

            <Grid container spacing={2}>
              <Grid item xs={3} sm={3}>
                <Card style={styles.card}>
                  <CardContent>
                    {this.state.step === 1 && (
                      <div>
                        <FormControl style={styles.container}>
                          <TextField
                            label="Add phrases (1 per line)"
                            multiline
                            rows="15"
                            margin="normal"
                            variant="outlined"
                            value={this.state.text_process}
                            onChange={this.onChangeField.bind(this, 'text_process')}
                          />
                        </FormControl>

                        <ColorButton onClick={this.applyData}>Apply</ColorButton>
                      </div>
                    )}
                    {this.state.step === 2 && (
                      <div>
                        <div>
                          <Typography style={styles.title}>
                            Words({this.getWordCounter()}/{this.state.words.length})
                          </Typography>
                          <PrettoSlider
                            valueLabelDisplay="auto"
                            aria-label="pretto slider"
                            defaultValue={20}
                          />
                        </div>

                        <div>
                          {this.state.words.map((row, index) => {
                            return (
                              <Chip
                                key={'phrases' + row + index}
                                avatar={<Avatar>{this.state.use_suggestion[row]}</Avatar>}
                                style={styles.chip}
                                label={this.state.use_suggestion[row] > 0 ? <del>{row}</del> : row}
                              />
                            );
                          })}
                        </div>

                        <Divider style={styles.div_section} />

                        <div>
                          <Typography style={styles.title}>
                            Phrases({this.getCounter()}/{this.state.phrases.length})
                          </Typography>
                          <PrettoSlider
                            valueLabelDisplay="auto"
                            aria-label="pretto slider"
                            defaultValue={20}
                            readonly
                          />
                        </div>

                        <div>
                          {this.state.phrases.map((row, index) => {
                            return (
                              <Chip
                                key={'phrases' + row + index}
                                avatar={<Avatar>{this.state.use_suggestion[row]}</Avatar>}
                                style={styles.chip}
                                label={this.state.use_suggestion[row] > 0 ? <del>{row}</del> : row}
                              />
                            );
                          })}
                        </div>

                        <Divider style={styles.div_section} />
                        {this.state.loading_related && (
                          <div>
                            <CircularProgress
                              style={{
                                marginRight: '10px',
                                width: '18px',
                                height: '18px',
                              }}
                            />
                          </div>
                        )}
                        {!this.state.loading_related && (
                          <div>
                            <div>
                              <Typography style={styles.title}>
                                Related({this.getCounterRelated()}/{this.state.related.length})
                              </Typography>
                              <PrettoSlider
                                valueLabelDisplay="auto"
                                aria-label="pretto slider"
                                defaultValue={20}
                              />
                            </div>
                            <div>
                              {this.state.related.map((row, index) => {
                                return (
                                  <Chip
                                    key={'related_' + index}
                                    avatar={
                                      <Avatar>
                                        {this.state.use_suggestion[row]
                                          ? this.state.use_suggestion[row]
                                          : 0}
                                      </Avatar>
                                    }
                                    style={styles.chip}
                                    label={
                                      this.state.use_suggestion[row] > 0 ? <del>{row}</del> : row
                                    }
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={9} sm={9}>
                <Card style={styles.card}>
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection="row-reverse"
                      m={1}
                      bgcolor="background.paper"
                    >
                      <Box>
                        <ColorButton onClick={this.exportData}>Export</ColorButton>
                      </Box>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Grid item xs container direction="column" spacing={1}>
                          <Grid item xs style={{ paddingBottom: '0px' }}>
                            <div style={{ width: '100%' }}>
                              <Box display="flex" style={{ alignItems: 'baseline' }}>
                                <Box flexGrow={1}>
                                  <Typography style={styles.title} gutterBottom>
                                    Title
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    style={styles.subtitle}
                                    color="textSecondary"
                                    gutterBottom
                                  >
                                    {this.state.counter_title_bytes}/200 bytes,{' '}
                                    {this.state.counter_title} characters,{' '}
                                    {this.state.counter_title_words} words
                                  </Typography>
                                </Box>
                              </Box>
                            </div>
                          </Grid>
                          <Grid item style={{ paddingTop: '0px' }}>
                            <div style={styles.editor} onClick={this.focusEditor}>
                              <Editor
                                editorState={this.state.editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorStyle={styles.editor_text}
                                mention={this.state.suggestion}
                                toolbar={{
                                  options: ['inline'],
                                  inline: {
                                    options: ['bold', 'italic', 'underline'], // this can be specified as well, toolbar wont have
                                  },
                                }}
                                onEditorStateChange={this.onEditorStateChange}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {this.state.list_bullets.map((row, index) => {
                        return (
                          <React.Fragment key={'bullet' + index}>
                            <Grid item xs={6} sm={6}>
                              <Grid item xs container direction="column" spacing={1}>
                                <Grid item xs style={{ paddingBottom: '0px' }}>
                                  <div style={{ width: '100%' }}>
                                    <Box display="flex" style={{ alignItems: 'baseline' }}>
                                      <Box flexGrow={1}>
                                        <Typography style={styles.title} gutterBottom>
                                          Bullet Point #{row.id}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography
                                          style={styles.subtitle}
                                          color="textSecondary"
                                          gutterBottom
                                        >
                                          {row.counter_b} /200 bytes, {row.counter_c} characters,{' '}
                                          {row.counter_w} words
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item style={{ paddingTop: '0px' }}>
                                  <div style={styles.editor} onClick={this.focusEditor}>
                                    <Editor
                                      editorState={this.state['editorBullet' + row.id + 'State']}
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      editorStyle={styles.editor_text}
                                      mention={this.state.suggestion}
                                      toolbar={{
                                        options: ['inline'],
                                        inline: {
                                          options: ['bold', 'italic', 'underline'], // this can be specified as well, toolbar wont have
                                        },
                                      }}
                                      onEditorStateChange={e =>
                                        this.onEditorBulletStateChange(e, row.id)
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Grid item xs container direction="column" spacing={1}>
                                <Grid item xs style={{ paddingBottom: '0px' }}>
                                  <div style={{ width: '100%' }}>
                                    <Box display="flex" style={{ alignItems: 'baseline' }}>
                                      <Box flexGrow={1}>
                                        <Typography style={styles.title} gutterBottom>
                                          Subject Matter #{row.id}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography
                                          style={styles.subtitle}
                                          color="textSecondary"
                                          gutterBottom
                                        >
                                          0/200 bytes, 0 characters, 0 words
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </div>
                                </Grid>
                                <Grid item style={{ paddingTop: '0px' }}>
                                  <div style={styles.editor} onClick={this.focusEditor}></div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      })}

                      <Grid item xs={12} sm={12}>
                        <Grid item xs container direction="column" spacing={1}>
                          <Grid item xs style={{ paddingBottom: '0px' }}>
                            <div style={{ width: '100%' }}>
                              <Box display="flex" style={{ alignItems: 'baseline' }}>
                                <Box flexGrow={1}>
                                  <Typography style={styles.title} gutterBottom>
                                    Description
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    style={styles.subtitle}
                                    color="textSecondary"
                                    gutterBottom
                                  >
                                    {this.state.counter_description_bytes}/200 bytes,{' '}
                                    {this.state.counter_description} characters,{' '}
                                    {this.state.counter_description_words} words
                                  </Typography>
                                </Box>
                              </Box>
                            </div>
                          </Grid>
                          <Grid item style={{ paddingTop: '0px' }}>
                            <div style={styles.editor} onClick={this.focusEditor}>
                              <Editor
                                editorState={this.state.editorDescriptionTermState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorStyle={styles.editor_text}
                                mention={this.state.suggestion}
                                toolbar={{
                                  options: ['inline'],
                                  inline: {
                                    options: ['bold', 'italic', 'underline'], // this can be specified as well, toolbar wont have
                                  },
                                }}
                                onEditorStateChange={this.onEditorStateDescriptionChange}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid item xs container direction="column" spacing={1}>
                          <Grid item xs style={{ paddingBottom: '0px' }}>
                            <div style={{ width: '100%' }}>
                              <Box display="flex" style={{ alignItems: 'baseline' }}>
                                <Box flexGrow={1}>
                                  <Typography style={styles.title} gutterBottom>
                                    Search Terms
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    style={styles.subtitle}
                                    color="textSecondary"
                                    gutterBottom
                                  >
                                    {this.state.counter_search_bytes}/200 bytes,{' '}
                                    {this.state.counter_search} characters,{' '}
                                    {this.state.counter_search_words} words
                                  </Typography>
                                </Box>
                              </Box>
                            </div>
                          </Grid>
                          <Grid item style={{ paddingTop: '0px' }}>
                            <div style={styles.editor} onClick={this.focusEditor}>
                              <Editor
                                editorState={this.state.editorSearchTermState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorStyle={styles.editor_text}
                                mention={this.state.suggestion}
                                toolbar={{
                                  options: ['inline'],
                                  inline: {
                                    options: ['bold', 'italic', 'underline'], // this can be specified as well, toolbar wont have
                                  },
                                }}
                                onEditorStateChange={this.onEditorStateSearchChange}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};

export default connect(mapStateToProps, {})(withFirebase(ListingPage));
