import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import HelpButton from './molecules/HelpButton';
import Text from './atoms/Text';
import Divider from './atoms/Divider';
import CustomIcon from './atoms/Icons/CustomIcon/CustomIcon';

const lightColor = 'rgba(255, 255, 255, 0.7)';
const drawerWidth = 256;
const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const useStyles = makeStyles({
  toolbar: { paddingLeft: 32, paddingRight: 32, minHeight: 126 },
  select: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    cursor: 'pointer',
  },
});

const handleContactUs = () => {
  const mailtoLink = 'mailto:support@cypherly.io';
  window.location.href = mailtoLink;
};

function Header(props) {
  const {
    classes,
    onDrawerToggle,
    region,
    onChangeRegion,
    open,
    isVisibleRegion = true,
    isVisibleHelp = true,
  } = props;

  const classesMui = useStyles();
  return (
    <React.Fragment>
      <AppBar
        color="primary"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar disableGutters className={classesMui.toolbar}>
          <Grid container spacing={1} style={{ marginTop: '0.65rem' }}>
            <Grid style={{ visibility: isVisibleRegion ? 'visible' : 'hidden' }} item xs={12}>
              <div className="region">
                <span className="icon-flag">
                  <img src={`/assets/imgs/flags-normal/${region}.png`} alt="uk" />
                </span>
                <span className="country-code">
                  <select
                    defaultValue={region}
                    onChange={e => onChangeRegion(e)}
                    className={`form-control ${classesMui.select}`}
                  >
                    <option value="us">US</option>
                    <option value="uk">UK</option>
                  </select>
                </span>
              </div>
            </Grid>

            <Grid item xs={12}>
              {!open && (
                <IconButton onClick={onDrawerToggle} size="small" style={{ padding: '0 1.1rem' }}>
                  <MenuIcon />
                </IconButton>
              )}
              <Box display="flex" justifyContent="space-between" flex={1} marginTop={2}>
                <Box flex={1}>
                  <Text variant="h5" weight="semibold" component="h1" style={{ width: '97%' }}>
                    {props.title}
                  </Text>
                </Box>
                <Box visibility={isVisibleHelp ? 'visible' : 'hidden'} className="help">
                  <div className="help-button">
                    <HelpButton
                      disabled
                      onClick={() => {
                        if (props.handleClickOpen) props.handleClickOpen();
                      }}
                    />
                  </div>
                  <div className="dropdown-menu">
                    <div style={{ marginTop: 10 }}>
                      <div className="menu-divider-color" />
                      <ul>
                        {props.handleClickOpen && (
                          <li
                            role="button"
                            onClick={() => {
                              if (props.handleClickOpen) props.handleClickOpen();
                            }}
                          >
                            <CustomIcon width={18} path="/assets/imgs/video.png" />
                            <Divider margin="0px 0px 0px 10px" />
                            <Text
                              variant="subtitle2"
                              weight="semibold"
                              className={'text-popover-li'}
                            >
                              Video Tutorial
                            </Text>
                          </li>
                        )}
                        <li role="button" onClick={handleContactUs}>
                          <CustomIcon width={18} path="/assets/imgs/message.png" />
                          <Divider margin="0px 0px 0px 10px" />
                          <Text variant="subtitle2" weight="semibold" className={'text-popover-li'}>
                            Support
                          </Text>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  onDrawerToggle: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  onChangeRegion: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Header);
