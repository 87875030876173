import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import { auth } from '../../config/firebase';
import { Link } from 'react-router-dom';
import { CircularProgressSpinner } from '../login/components';
import Button from '../../components/atoms/Button';
import InputField from '../../components/molecules/InputField';
import styles from '../../styles/public';
import { Box, withStyles, ThemeProvider } from '@material-ui/core';
import Divider from '../../components/atoms/Divider';
import Text from '../../components/atoms/Text';
import theme from '../../theme/theme';

const INITIAL_STATE = {
  email: '',
  error: null,
  sendEmail: 0,
};

const resetStyles = theme => ({
  ...styles(theme),
});

// Login
class Reset extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;

    this.state = {
      errorMessage: null,
    };
    this.state = { ...INITIAL_STATE };
    this.sendResetPassword = this.sendResetPassword.bind(this);
  }
  componentDidMount() {
    auth
      .signOut()
      .then(() => {
        this.removeAuth();
      })
      .catch(error => {
        console.log(error);
      });
  }

  removeAuth() {
    window.sessionStorage.removeItem('isAuthenticated');
    window.localStorage.removeItem('appToken');
  }

  sendResetPassword() {
    const { email } = this.state;
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log('Sent');
      })
      .catch(error => {
        console.log('Error');
      });

    this.setState({ sendEmail: 1 });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeEmail = email => {
    this.setState({ email });
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <main className={this.props.classes.container}>
          <div className={this.props.classes.wrapBoxForm}>
            <img
              src="/assets/imgs/cypherly-logo-white.svg"
              className={this.props.classes.imgLogo}
              alt="kashing"
            />
            <Divider margin={'15px 0px'} />
            <div className={this.props.classes.boxForm}>
              {this.state.sendEmail === 0 && (
                <div>
                  <Box className={this.props.classes.boxHeaderForm}>
                    <Text component="h2" variant="h5" weight="bold">
                      Reset Password
                    </Text>
                    <Divider margin={'4px 0px'} />
                    <Text component="span" variant="subtitle2" weight="regular">
                      Do you have an account?
                      <span className="text-primary-color">
                        <Link to="/login">{' Login'}</Link>
                      </span>
                    </Text>
                  </Box>
                  <Divider margin={'15px 0px'} />

                  <form noValidate autoComplete="off" className={this.props.classes.form}>
                    <InputField
                      style={{ paddingLeft: 10, fontWeight: '400 !important' }}
                      placeholder={'Email'}
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChangeEmail}
                      type="email"
                    />
                    <Divider margin={'15px 0px'} />
                    <Button type="primary" onClick={this.sendResetPassword.bind(this)}>
                      Reset Password
                      <CircularProgressSpinner show={this.state.loader} />
                    </Button>
                  </form>
                </div>
              )}
              {this.state.sendEmail === 1 && (
                <Box className={this.props.classes.boxHeaderForm}>
                  <Text
                    className={this.props.classes.title}
                    component="h2"
                    variant="h5"
                    weight="bold"
                  >
                    Reset Password sent you Email
                  </Text>
                  <Divider margin={'4px 0px'} />
                  <Text component="h1" variant="subtitle2" weight="regular">
                    <span className="text-primary-color">
                      <Link to="/login">{' Login'}</Link>
                    </span>
                  </Text>
                </Box>
              )}
            </div>
          </div>
        </main>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {})(withFirebase(withStyles(resetStyles)(Reset)));
