import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

export const SocialButtonGoogle = withStyles(theme => ({
  root: {
    padding: '10px 40px',
    backgroundColor: '#fff',
    margin: theme.spacing(1),
    '&:hover,&:focus': {
      backgroundColor: '#f8f8f8',
    },
  },

  label: {
    textTransform: 'none',
    color: '#666',
  },
}))(Button);

export const SocialButtonFacebook = withStyles(theme => ({
  root: {
    padding: '10px 40px',
    backgroundColor: '#3f50b5',
    color: '#fff',
    margin: theme.spacing(1),
    '&:hover,&:focus': {
      backgroundColor: blue[500],
    },
  },

  label: {
    textTransform: 'none',
    color: '#fff',
  },
}))(Button);

// SocialButton
export const FormButton = withStyles(theme => ({
  root: {
    backgroundColor: '#F4821E',
    '&:hover,&:focus': {
      backgroundColor: '#e9a250',
    },
    '&:disabled': {
      backgroundColor: '#e9a250',
    },
  },

  label: {
    textTransform: 'none',
    color: '#fff',
  },
}))(Button);
