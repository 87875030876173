import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import Button from '@material-ui/core/Button/index';
import StarIcon from '@material-ui/icons/Star';
import ReviewComponent from '../component';
import Kashing from '../../../components/_kashing';

import { setRegion } from '../../../redux/actions/auth';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import SnackBar from '../../../components/Snackbar';
import WistiaEmbed from '../../../utils/wistiaEmbed';
import Header from '../../../components/Header';
import globals from '../../../config/globals';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import getFireDBService from '../../../utils/fireDBService';

const fireDBService = getFireDBService();

class ReviewIntelligence extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.firebase = props.firebaseApp;
    const asin = this.props.computedMatch.params.id;
    this.state = {
      openVideo: false,
      video: '',
      region: '',
      reviews: {},
      isLoading: true,
      starts: [
        {
          start: 5,
          name: 'five_star',
        },
        {
          start: 4,
          name: 'four_star',
        },
        {
          start: 3,
          name: 'three_star',
        },
        {
          start: 2,
          name: 'two_star',
        },
        {
          start: 1,
          name: 'one_star',
        },
      ],
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      asin: asin,
    };
    this.fetchReviews();
  }

  componentDidMount() {
    const { watchDoc } = this.props;
    const region = this.props.auth.region;
    const asin = this.props.computedMatch.params.id;
    watchDoc('products/' + asin + '-' + region.toUpperCase());

    fireDBService.subscribeVideos(snapshot => {
      const data = snapshot.val();
      this.setState({ video: data.details });
    });
  }

  setAlibabaLink(styles, productTitle) {
    if (this.state.reviews.length > 0) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            'https://www.alibaba.com/trade/search?fsb=y&IndexArea=product_en&CatId=&SearchText=' +
            productTitle
          }
        >
          <img
            src="/assets/imgs/logo_alibaba.png"
            alt="Alibaba Logo"
            style={{
              verticalAlign: 'middle',
              width: '4rem',
              marginLeft: '10px',
              margin: '10px',
            }}
          />
        </a>
      );
    } else {
      return null;
    }
  }

  fetchReviews() {
    const region = this.props.auth.region;
    const asin = this.state.asin;
    const uri = `${globals.api}/api/keywords/reviews/${asin}/region/${region}`;
    // get the api response
    this.getRequest(uri)
      .then(async response => {
        this.setState({
          reviews: response,
          progressLine: { show: false },
          isLoading: false,
        });
      })
      .catch(error => {
        // show alert
        // Review data is not available code: PY001
        this.showSnackBar('Review data is not available code: PY001', 'warning');
        this.setState({ isLoading: false, progressLine: { show: false } });
      });
  }

  makeDialogSnack(message) {
    this.setState({
      snackbar: {
        open: true,
        type: 'warning',
        message: message,
        position: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
    });
  }

  handleClickOpen() {
    this.setState({ openVideo: true });
  }

  handleClose() {
    this.setState({ openVideo: false });
  }

  handleCancelClose() {
    this.setState({ openVideo: false });
  }

  showSnackBar(message, type = 'success') {
    this.setState({
      snackbar: {
        open: true,
        type: type,
        message: message,
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    });
  }

  openVideo() {
    this.setState({ openVideo: true });
  }

  closeVideo() {
    this.setState({ openVideo: false });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
    this.setState({ region: region });
  };

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  render() {
    const { doc, classMain, onDrawerToggle, open } = this.props;
    const product_id = this.state.asin + '-' + this.props.auth.region.toUpperCase();
    let product = doc['products/' + product_id];
    let product_rating = 'N/A';
    if (this.state.reviews.summary && this.state.reviews.summary.rating) {
      product_rating = [];
      const mod_rating = this.state.reviews.summary.rating % 2;
      const length = parseInt(this.state.reviews.summary.rating);
      for (let index = 0; index < length; index++) {
        product_rating.push(<StarIcon key={index} style={{ color: '#F4821E' }} />);
        if (mod_rating > 0 && index === length - 1) {
          product_rating.push(<StarHalfIcon key={index} style={{ color: '#F4821E' }} />);
        }
      }
    }
    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          nameRegion={this.state.region}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Review Intelligence"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={classMain}>
          <div className="page container">
            <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />
            <Dialog
              open={this.state.openVideo}
              onClose={this.closeVideo.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'md'}
            >
              <DialogContent>
                <div className="wistia-embed-wrapper">
                  {/* You can include any Wistia embed options as props. See the full list at https://wistia.com/doc/embed-options#options_list */}
                  <WistiaEmbed hashedId={this.state.video} playerColor="#54bbff" autoPlay={true} />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeVideo.bind(this)} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {ReviewComponent.call(this, this.state.isLoading, product, product_rating)}
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => {
  let doc = state.docs;
  const auth = state.auth;
  return { doc, auth };
};
export default connect(mapStateToProps, {})(withFirebase(ReviewIntelligence));
