import { makeStyles, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { InputField } from './molecules/InputField/InputField';
import SearchButton from './molecules/SearchButton/SearchButton';
import { SearchIcon } from './atoms/Icons';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});
const TextFieldSearch = ({
  label = 'Search',
  placeholder = 'Enter a product ASIN to get started',
  onHandleButtonSearch,
}) => {
  const classes = useStyles();
  const [text, setText] = useState('');

  const onHandleClickButton = () => {
    onHandleButtonSearch(text);
  };

  const onHandleInputChange = value => {
    value = value.replace(/\./g, '_');
    setText(value);
  };

  return (
    <div className={classes.container}>
      <Box flex={1.4}>
        <InputField
          icon={<SearchIcon color={'disabled'} />}
          placeholder={placeholder}
          name="asin"
          value={text}
          onChange={onHandleInputChange}
        />
      </Box>
      <Box flex={0.6} paddingLeft={4}>
        <SearchButton label={label} onClick={onHandleClickButton} />
      </Box>
    </div>
  );
};

export default TextFieldSearch;
