import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import Kashing from '../../../components/_kashing';
import MainTable from '../../../components/MainTable';
import TableData from '../components/TableData';
import Header from '../../../components/Header';
import { setRegion } from '../../../redux/actions/auth';

import products_headers from '../../../utils/products_headers';
import getFirestoreService from '../../../utils/firestoreService';
import { manageSalesEstimateByProduct, updateProductByAsin } from '../../../utils/productsServices';
import { getEstimateTotal } from '../utils';
import Text from '../../../components/atoms/Text';
import DialogVideo from '../../../components/molecules/DialogVideo';
import ProgressLine from '../../../components/ProgressLine';
import { deleteFavoriteProduct, getFavoritesProducts } from '../../../helpers';
const firestoreService = getFirestoreService();

class ProductsPage extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.auth = this.props.firebaseApp.auth();

    this.state = {
      asins: '',
      asin: '',
      products: [],
      table: [],
      item: null,
      showFilters: false,
      searchResults: [],
      orderBy: { field: 'bsr', order: 'desc', startAt: 0 },
      asisnsInputDisabled: false,
      selectedRowId: null,
      removeDialog: {
        open: false,
      },
      filter: {
        category: '',
      },
      advancedWindow: false,
      alertDialog: {
        open: false,
      },
      tableProductHeaders: products_headers,
      search_index: null,
      endAt: null,
      open: false,
      video: '',
      isSearching: true,
      isOpenDialogDeleteAll: false,
    };
  }

  selectRow(row) {
    if (row.status === 'db') {
      let id = row.asin + '-' + row.region;
      this.context.router.history.push('/products/details/' + id);
    }
  }

  async componentDidMount() {
    await this.validateSuscription(this.props.auth.user);
    await this.watchProducts();
  }

  componentWillUnmount() {
    // this.unsubscribe()
  }

  manageFullfillmentCosts = async rows => {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (!row.fulfillment_costs) {
        const product = await updateProductByAsin(row.asin, this.props.auth.region);
        rows[i] = {
          ...row,
          ...product,
        };
      }
    }
    return rows;
  };

  manageSalesEstimateProducts = async rows => {
    return rows.map(async row => {
      const categories = row.categories;
      let mainCategory = row.main_category;
      if (categories && categories.length > 0 && !mainCategory) {
        mainCategory = categories[0];
      }

      if (!mainCategory) {
        mainCategory = row.binding.split(' > ')[0];
      }

      row.main_category = mainCategory;
      const estimate = await manageSalesEstimateByProduct(row, this.props.auth.region);
      row = {
        ...row,
        sales_estimate: {
          ...row.sales_estimate,
          estimate: estimate === 0 ? row.sales_estimate.estimate : estimate,
        },
      };
      this.setState(prevState => {
        return {
          ...prevState,
          isSearching: false,
          table: [...prevState.table, row],
        };
      });
      return row;
    });
  };

  watchProducts = async () => {
    let state = this.store.getState();
    let region = state.auth.region.toUpperCase();
    await this.setState({ table: [], products: [] });

    const favorites_products = await getFavoritesProducts(state.auth.user.gid);

    if (favorites_products.length > 0) {
      let products = favorites_products.map(product => {
        if (product.asin && product.region === region) {
          product.status = 'db';

          if (product.product_group && product.product_group.split('>').length > 0) {
            product.product_group = product.product_group.split('>')[0];
          } else {
            product.product_group = product.main_category;
          }

          product.show = true;
        }

        return product
      });

      products = getEstimateTotal(products);

      let table = _.orderBy(products, [this.state.orderBy.field], [this.state.orderBy.order]);

      table = table.map((item) => ({
        ...item,
        pending: false,
      }));

      const salesEstimatePromises = await this.manageSalesEstimateProducts(table);
      table = await Promise.all(salesEstimatePromises);
      table = await this.manageFullfillmentCosts(table);

      this.setState({ table, products, isSearching: false });
    } else {
      this.setState({
        table: [],
        products: [],
        isSearching: false,
      });
    }
  };

  async onRemoveDialog(response) {
    if (response === true) {
      const state = this.store.getState();
      let table = [...this.state.table];
      const product_id = this.state.selectedRowId;
      console.log({product_id});
      let gid = state.auth.user.gid;

      await deleteFavoriteProduct(gid, {product_id})

      table = table.filter((item) => item.product_id !== product_id);

      await this.setState({
        table,
        selectedRowId: null,
        removeDialog: {
          open: false,
        },
      });

      await this.watchProducts()
    } else {
      this.setState({
        removeDialog: {
          open: false,
        },
      });
    }
  }

  openConfirmDelete = row => {
    let pid = `${row.asin}-${row.region}`;
    this.setState({
      selectedRowId: pid,
      removeDialog: {
        open: true,
      },
    });
  };

  async sortTable(o) {
    let table = this.state.table;
    table.forEach(row => {
      row['est_sales'] = row.sales_estimate.estimate * row.offer_price.amount;
    });
    this.setState({ orderBy: o }, () => {
      let sort = _.orderBy(table, [this.state.orderBy.field], [this.state.orderBy.order]);
      this.setState({ table: sort });
    });
  }

  addProductFromAdvancedSearch(row) {
    //
  }

  trackHistory = async row => {
    /*let trackMonitor = row.trackMonitor || false;
    trackMonitor = !trackMonitor;

    let table = this.state.table;
    table = table.map(item => {
      if (item.asin === row.asin) {
        item.trackMonitor = trackMonitor;
      }
      return item;
    });
    this.setState({
      table,
      products: table,
    });

    await firestoreService.updateDocs('products', row.asin + '-' + row.region.toUpperCase(), {
      trackMonitor,
    });

    return true;*/
  };

  async componentDidUpdate(props, state) {
    if (props.auth.region !== this.props.auth.region) {
      await this.watchProducts();
    }
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  onChangeData(row) {
    /*let table = this.state.table;
    const fr = table.find(item => item.asin === row.asin);
    const i = table.indexOf(fr);
    table[i] = row;
    this.setState({ table: table });*/
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  render() {
    const { classMain, onDrawerToggle, open } = this.props;

    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Product Tracker"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain} main`}>
          <div className="table-scroll">
            <DialogVideo
              open={this.state.open}
              onClose={this.handleClose.bind(this)}
              videoHashedId={this.state.video}
            />

            <Dialog
              open={this.state.removeDialog.open}
              onClose={this.onRemoveDialog.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle style={{ paddingBottom: 0 }} id="alert-dialog-title">
                {'Are you sure?'}
              </DialogTitle>
              <DialogContent>This will delete the product from your tracker</DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onRemoveDialog.bind(this, false)}
                  variant="outlined"
                  color="default"
                >
                  No
                </Button>
                <Button
                  onClick={this.onRemoveDialog.bind(this, true)}
                  variant="outlined"
                  color="default"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            <MainTable
              headers={this.state.tableProductHeaders}
              orderBy={this.state.orderBy}
              sortTable={this.sortTable.bind(this)}
            >
              <TableData
                searchingText="Please wait..."
                isSearching={this.state.isSearching}
                data={this.state.table}
                onChangeData={this.onChangeData.bind(this)}
                headers={this.state.tableProductHeaders}
                selectRow={this.selectRow.bind(this)}
                addProduct={this.addProductFromAdvancedSearch.bind(this)}
                trackHistory={this.trackHistory}
                openConfirmDelete={this.openConfirmDelete}
              />
            </MainTable>
            {this.state.isSearching && (
              <ProgressLine
                show={this.state.isSearching}
                style={{ width: '100%', marginTop: '-4px', backgroundColor: '#fff' }}
              />
            )}
          </div>

          <div className="info-footer">
            <Text weight="semibold" variant="caption">
              {this.state.table.length}/25 Products Tracked
            </Text>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(ProductsPage);
