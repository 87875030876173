import React from 'react';
import PropTypes from 'prop-types';
// import moment    from 'moment';
import 'whatwg-fetch';

//settings
import globals from '../config/globals';
import { GetRequest } from '../utils/';
import _ from 'lodash';
import moment from 'moment';

// eslint-disable-next-line no-extend-native
Array.prototype.contains = function (v) {
  for (let i = 0; i < this.length; i++) {
    if (this[i] === v) return true;
  }
  return false;
};

// eslint-disable-next-line no-extend-native
Array.prototype.unique = function () {
  let arr = [];
  for (let i = 0; i < this.length; i++) {
    if (!arr.contains(this[i])) {
      arr.push(this[i]);
    }
  }
  return arr;
};

export default class Kashing extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  // format(x,decimals=2) {
  //   if(x){
  //     return Number(x.toString()).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  //   return '0'
  // }

  thusandsFormatLetter(number, processing = true) {
    if (processing) {
      let s = number.toString();
      number = s.substring(1, s.length);
    }

    if (parseFloat(number) >= 1000 && parseFloat(number) < 1000000) {
      return 'K';
    } else if (parseFloat(number) >= 1000000) {
      return 'M';
    } else {
      return '';
    }
  }

  percentage(list_price, offer_price) {
    let percentages;

    if (parseFloat(list_price) > 0 && parseFloat(offer_price) > 0) {
      if (parseFloat(list_price) < parseFloat(offer_price)) {
        percentages =
          this.format((parseFloat(offer_price) / parseFloat(list_price)) * 100 - 100, '1') + '%';
      } else {
        percentages =
          this.format((parseFloat(list_price) / parseFloat(offer_price)) * 100 - 100, '1') + '%';
      }
    }
    if (percentages === '0%' || percentages === '' || percentages === undefined) {
      return '';
    }
    return '(' + percentages + ')';
  }

  thusandsFormat(number, decimal = 0) {
    let r;
    if (number === undefined || number === '' || number === 0) {
      r = 0;
    } else if (parseFloat(number) > 0 && parseFloat(number) < 1000) {
      r = this.format(number, decimal);
    } else if (parseFloat(number) >= 1000 && parseFloat(number) < 1000000) {
      r = this.format(parseFloat(number) / 1000, decimal);
    } else if (parseFloat(number) >= 1000000) {
      r = this.format(parseFloat(number) / 1000000, decimal);
    }

    return r;
  }

  moneyFormat(s, region = 'US', decimals = 2, hundreds = true) {
    region = region.toUpperCase();
    if (!s) {
      return 'N/A';
    }
    let symbol = '\u0024';
    switch (region) {
      case 'US':
        if (hundreds) s = s / 100;
        break;
      case 'UK':
        symbol = '\u00A3';
        s = s / 100;
        break;
      default:
        break;
    }
    return symbol + this.format(s, decimals);
  }

  symbol(currency = 'USD') {
    switch (currency) {
      case 'US': {
        return '\u0024';
      }
      case 'USD': {
        return '\u0024';
      }
      case 'UK': {
        return '\u00A3';
      }
      default:
    }
  }

  money(s, decimals = 2, currency = 'USD', month = '') {
    let symbol = '\u00A3';
    if (s) {
      switch (currency) {
        case 'USD': {
          s = s / 100;
          break;
        }

        case 'UK': {
          s = s / 100;
          break;
        }

        default: {
          break;
        }
      }
      if (month !== '') {
        return symbol + this.format(s, decimals) + month;
      } else {
        return symbol + this.format(s, decimals);
      }
      // return '$'+(s).toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else {
      return 'N/A';
    }

    // if (month !== '') {
    //   return symbol + '0.00' + month;
    // } else {
    //   return symbol + '0.00';
    // }
  }

  format(number, decimals) {
    number = parseFloat(number);
    number = number.toFixed(decimals) + '';

    let x = number.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1,$2');
    }
    return x1 + x2;
  }

  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
    let from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    let to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    return str;
  }

  getRequest(source) {
    return fetch(source, {
      mode: 'cors',
      headers: { Accept: 'application/json' },
    }).then(response => {
      try {
        if (response && response.ok) {
          return response.json();
        } else {
          return response;
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  async validateSuscription(user) {
    return true;
  }

  async getSuscription(gid, customer, subscription) {
    return new Promise(async (resolve, reject) => {
      let subscription_service = `${globals.api}/api/groups/${gid}/payments/profile/${customer}https://cors-anywhere.herokuapp.com/https://api.fastspring.com/subscriptions/${subscription}`;
      GetRequest(subscription_service)
        .then(async result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  toSettings() {
    if (this.context.router.history.location.pathname !== '/settings') {
      this.context.router.history.push('/settings');
    }
  }

  async getRelatedKeywords(keyword, region) {
    let uri = `${globals.api}/api/keywords/suggestion?rows=100&keyword=${keyword}&region=${region}&no_scrapper=true`;
    // get the api response
    return this.getRequest(uri);
  }

  async getKeywordsListing(keywords, benefits, type) {
    try {
      const keyworduri = `${globals.api}/api/keywords/${keywords}/${benefits}/${type}/listing`;
      const response = await GetRequest(keyworduri);
      if (response && response.response) {
        return response.response;
      } else return '';
    } catch (e) {
      return '';
    }
  }

  async getProductBy(asin, region, force = false) {
    try {
      let uri = `${globals.api}/api/products/${asin}/region/${region}`;
      if (force) {
        uri += '?force=force';
      }
      const response = await GetRequest(uri);
      return response;
    } catch (e) {
      return {};
    }
  }

  async getKeywordsByAsin(asin, region) {
    const keyworduri = `${globals.apiThird}/api/keywords/${asin}/region/${region}`;
    try {
      const request = await GetRequest(keyworduri);
      const response = request.response;
      return response;
    } catch (e) {
      console.log('ex', e);
      return [];
    }
  }

  async loadEstimateds(uri) {
    const response = await GetRequest(uri);
    let keywords = [];
    // console.log('reponse estimated', response);
    keywords = response.response;
    let newkeywords = _.orderBy(keywords, [this.state.orderBy.field], [this.state.orderBy.order]);
    return newkeywords;
  }

  getHeaderFastSpring() {
    return new Headers({
      Authorization: 'Basic U01RQjBYREhTTDJGSFBZWVVSMUJZUTptR0xDdTNhV1NMQ0xtU3lIYjBxZ2Vn',
    });
  }

  cancelSubscription() {
    // open fastspring window to checkout payment method
    const id = this.state.subscription;
    const options = {
      method: 'DELETE',
      headers: this.getHeaderFastSpring(),
    };
    return fetch(
      'https://cors-anywhere.herokuapp.com/https://api.fastspring.com/subscriptions/' +
        id +
        '?billingPeriod=0',
      options,
    )
      .then(response => {
        const _json = response.json();
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return _json;
      })
      .then(contents => {})
      .catch(error => {});
  }

  setSessionFastSpring(user) {
    const plan = 'cypherly-monthly-plan-without-trial';
    // Prepare session object
    let session = {
      reset: true,
      products: [
        {
          path: plan,
          quantity: 1,
        },
      ],
      paymentContact: {
        email: user.email,
        firstName: user.displayName,
        lastName: '',
      },
      language: 'en',
    };
    window.fastspring.builder.push(session, data => {
      window.fastspring.builder.checkout();
    });
  }

  checkEstimateSalesExpired(row) {
    if (row.title.indexOf('Amazon.com :') >= 0 || row.title.indexOf('Amazon.com: ') >= 0) {
      row.title = row.title.replace('Amazon.com :', '').replace('Amazon.com: ', '').trim();
    }
    if (
      !(
        !row.sales_estimate ||
        !row.sales_estimate.last_update ||
        !row.sales_estimate.last_update.seconds
      )
    ) {
      const last_update = moment.unix(row.sales_estimate.last_update.seconds);
      const diff = moment().diff(last_update, 'days');
      return diff >= 1 || isNaN(diff);
    } else {
      return true;
    }
  }

  updateProductSimple(row) {
    let uri = `${globals.api}/api/amazon/${row.asin}/region/${row.region}/simple`;
    const res = this.getRequest(uri);
    res.then(res => {
      console.log(res);
    });
  }

  async getEstimate(row, ctx, name) {
    let uri = `${globals.api}/api/products/${row.asin}/region/${row.region}/sales_estimate`;
    const res = this.getRequest(uri);
    res
      .then(res => {
        const data = ctx.state[name];
        if (res.response !== 'success') {
          row.pending = false;
          ctx.setState({
            [name]: data.map(x => (x.asin === row.asin ? { ...x, pending: false } : x)),
          });
          return;
        }
        const sales_estimate = res.result;
        ctx.setState({
          [name]: data.map(x =>
            x.asin === row.asin ? { ...x, pending: false, sales_estimate: sales_estimate } : x,
          ),
        });
        // ctx.setState({[name]: data.map(x => ({...x, pending: false, sales_estimate: sales_estimate}) )})
      })
      .catch(res => {
        const data = ctx.state[name];
        ctx.setState({
          [name]: data.map(x => (x.asin === row.asin ? { ...x, pending: false } : x)),
        });
      });
  }
}
