import React from 'react';
import NavbarSignup from '../components/NavbarSignup';
import PropTypes from 'prop-types';

class Signup extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
  }

  render() {
    return (
      <div className="h-100 " ref={this.ref}>
        <NavbarSignup auth={this.props.auth} />
        <this.props.component {...this.props} {...this.states} />
      </div>
    );
  }
}

Signup.propTypes = {
  firebaseApp: PropTypes.object,
  auth: PropTypes.object,
};

export default Signup;
