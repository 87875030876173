import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import { Translate } from 'react-redux-i18n';
import Grid from '@material-ui/core/Grid/index';
import Button from '@material-ui/core/Button/index';

// components
import Kashing from '../../../components/_kashing';

import { setRegion } from '../../../redux/actions/auth';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import WistiaEmbed from '../../../utils/wistiaEmbed';
import Box from '@material-ui/core/Box/index';
import Header from '../../../components/Header';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

class Support extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.firebase = props.firebaseApp;
    this.state = {
      openVideo: false,
      video: '',
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    };
  }

  componentDidMount() {}

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  handleCancelClose() {
    this.setState({ openDialogCancel: false });
  }

  showSnackBar(message, type = 'success') {
    this.setState({
      snackbar: {
        open: true,
        type: type,
        message: message,
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    });
  }

  openVideo() {
    this.setState({ openVideo: true });
  }

  closeVideo() {
    this.setState({ openVideo: false });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  render() {
    const { classMain, onDrawerToggle, open } = this.props;

    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
        >
          <Box display="flex" p={1}>
            <Box p={1} width="100%">
              <Translate value="Support" />
            </Box>
          </Box>
        </Header>
        <main className={classMain}>
          <div className="page container">
            <Dialog
              open={this.state.openVideo}
              onClose={this.closeVideo.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'md'}
            >
              <DialogContent>
                <div className="wistia-embed-wrapper">
                  {/* You can include any Wistia embed options as props. See the full list at https://wistia.com/doc/embed-options#options_list */}
                  <WistiaEmbed hashedId={this.state.video} playerColor="#54bbff" autoPlay={true} />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeVideo.bind(this)} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography color="textSecondary">
                      <Box textAlign="center" fontWeight={500}>
                        Please contact us at support@cypherly.io and make sure to include the same
                        name and email you used to sign up with.
                      </Box>
                      <Box textAlign="left" style={{ marginTop: '1rem' }} fontWeight={500}>
                        Thank you
                      </Box>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(Support));
