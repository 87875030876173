import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(2),
    border: '1px solid #ddd',
  },
});
//

export class CircularProgressSpinner extends React.Component {
  render() {
    if (this.props.show) {
      return <CircularProgress style={{ marginLeft: '8px', color: '#fff' }} size={20} />;
    } else {
      return null;
    }
  }
}

class PaperComponentAlert extends React.Component {
  render() {
    return (
      <Paper className={this.props.classes.root} elevation={0}>
        <Typography component="p" style={{ color: 'red' }}>
          <i className="fas fa-exclamation-circle"></i> {this.props.error.message}
        </Typography>
      </Paper>
    );
  }
}

export const PaperAlert = withStyles(styles)(PaperComponentAlert);

const index = {};
export default index;
