import React from 'react';

import CustomIcon from '../CustomIcon';
import PropTypes from 'prop-types';
const HistoryIcon = ({ width = 50 }) => {
  return <CustomIcon path="/assets/icons/history.svg" alt="cart icon" width={width} />;
};

HistoryIcon.propTypes = {
  width: PropTypes.number,
};

export default HistoryIcon;
