import { Request, Form } from '../../utils';
import Globals from '../../config/globals';

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_SESSION = 'SET_SESSION';
export const SET_REGION = 'SET_REGION';
export const SET_TEMP_DATA = 'SET_TEMP_DATA';
export const SET_TRIAL_PAYMENT = 'SET_TRIAL_PAYMENT';

export const start = data => {
  let form = Form(data);
  let service = Globals.api + '/auth';
  return dispatch => {
    return Request(service, form);
  };
};

export const setSubscription = subs => {
  return {
    type: SET_SUBSCRIPTION,
    subscription: subs,
  };
};

export const set = user => {
  return {
    type: SET_SESSION,
    user: user,
  };
};

export const setRegion = region => {
  return {
    type: SET_REGION,
    region: region,
  };
};

export const getToken = token => {
  let form = Form(token);
  let service = Globals.api + '/auth/token';
  return dispatch => {
    return Request(service, form);
  };
};

export const setTrialStatus = status => {
  return {
    type: SET_TRIAL_PAYMENT,
    trial: status,
  };
};

export const setTempData = data => {
  return {
    type: SET_TEMP_DATA,
    data: data,
  };
};
