import { withStyles, Zoom } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Kashing from './_kashing';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import TableBody from '@material-ui/core/TableBody';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import Fade from 'react-reveal/Zoom';
import Popover from '@material-ui/core/Popover';
import getFirestoreService from '../utils/firestoreService';
import AutocompleteTrackerKeywords from './AutocompleteTrackerKeywords';
import Shadow from './atoms/Shadow';
import Box from '@material-ui/core/Box';
import AddIcon from './atoms/Icons/AddIcon/AddIcon';
import Tooltip from './atoms/Tooltip';
const firestoreService = getFirestoreService();

const TableHeadCell = withStyles(theme => ({
  head: {
    position: 'sticky',
    backgroundColor: '#eaeff1',
    color: theme.palette.common.black,
    border: 0,
    fontSize: '12px',
    fontWeight: 700,
    padding: 0,
    margin: 0,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    lineHeight: '2rem',
  },
}))(TableCell);

class TableKeywords extends Kashing {
  constructor(props) {
    super(props);

    let headers = [
      {
        label: 'Keyword',
        field: 'keyword',
        icon: '',
        align: 'left',
        icon: 'fas fa-angle-down',
        isActiveOrder: true,
        help: 'Keyword or phrase used by customers searching for a product',
      },
      {
        label: 'Low Volume',
        field: 'low_volume',
        icon: 'fas fa-angle-down',
        isActiveOrder: true,
        align: 'left',
        help: 'The lowest number of times this keyword phrase is searched for monthly (estimated)',
      },
      {
        label: 'High Volume',
        field: 'high_volume',
        icon: 'fas fa-angle-down',
        isActiveOrder: true,
        align: 'left',
        help: 'The highest number of times this keyword phrase is searched for monthly (estimated)',
      },
      {
        label: 'Search on Amazon',
        field: 'amazon_link',
        icon: '',
        align: 'left',
        isActiveOrder: false,
        help: 'Show Amazon search results for this keyword',
      },
      {
        label: 'Ranking Difficulty',
        field: 'cmp',
        icon: '',
        align: 'center',
        help: 'How easy it is for a new product to rank on page one for this keyword.',
        isActiveOrder: true,
      },
      /* { label: 'CPC', field: 'cpc', icon: '', align: 'left' }, */
      {
        label: 'ROPO',
        field: 'ropo',
        icon: '',
        align: 'center',
        isActiveOrder: true,
        help: `Estimated number of units that need to be sold over an 8
               day period (resulting from a search for this keyword), in
               order for the product to rank on page one for this keyword.`,
      },
    ];

    if (props.useAction) {
      headers.push({ label: 'Actions', field: 'action', icon: '', align: 'center' });
    }

    if (props.useExclude) {
      headers.push({
        label: 'Use/Exclude',
        field: 'user_exclude',
        icon: '',
        align: 'left',
      });
      const search = headers.find(item => item.field === 'chart');
      const index = headers.indexOf(search);
      headers.splice(index, 1);
    }

    this.state = {
      headers: headers,
      product: '',
      isAddTagOpen: false,
    };
  }

  orderHeaders(field, order) {
    this.state.headers.map(row => {
      if (field === row.field) {
        row.icon = order === 'desc' ? 'fas  fa-angle-down' : 'fas  fa-angle-up';
      } else {
        if (!row.noicon) {
          row.icon = '';
        }
      }
      return row;
    });
  }

  onClickHeader(row) {
    let field = this.props.orderBy.field;
    let order = this.props.orderBy.order;
    if (field === row.field) {
      order = order === 'desc' ? 'asc' : 'desc';
      this.props.sortTable({ field, order });
    } else {
      order = 'asc';
      field = row.field;
      this.props.sortTable({ field, order, row });
    }

    this.orderHeaders(field, order);
  }

  onChangeData(item) {}

  renderIconsOrder = row => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Icon
          className={`fas  fa-angle-up`}
          style={{
            position: 'absolute',
            fontSize: 8,
            marginTop: 8,
            marginLeft: 4,
            color: row.icon == 'fas  fa-angle-up' ? '#F4821E' : '#F4821E',
          }}
        />
        <Icon
          className={`fas  fa-angle-down`}
          style={{
            position: 'absolute',
            fontSize: 8,
            marginTop: 14,
            marginLeft: 4,
            color: row.icon == 'fas  fa-angle-down' ? '#F4821E' : '#F4821E',
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <Table className="table MuiTableCell">
        <TableHead>
          <TableRow>
            {this.state.headers.map((row, index) => {
              if (row.label === 'CMP2') {
                return (
                  <Tooltip
                    TransitionComponent={Zoom}
                    key={index}
                    title="Ranking difficulty"
                    placement="top"
                  >
                    <TableHeadCell
                      onClick={row.isActiveOrder ? this.onClickHeader.bind(this, row) : () => {}}
                      key={'cellheader-' + index}
                      style={{
                        textAlign: row.align || 'center',
                        width: row.width,
                        display: 'inline-flex',
                      }}
                    >
                      <div className="box-content-table-header">
                        <span> {row.label}</span>

                        {row.isActiveOrder && this.renderIconsOrder(row)}
                      </div>
                    </TableHeadCell>
                  </Tooltip>
                );
              } else if (row.label === 'ROPO') {
                return (
                  <Tooltip
                    TransitionComponent={Zoom}
                    key={index}
                    title={row.help ? row.help : ''}
                    placement="top"
                  >
                    <TableHeadCell
                      onClick={row.isActiveOrder ? this.onClickHeader.bind(this, row) : () => {}}
                      key={'cellheader-' + index}
                      style={{
                        textAlign: row.align || 'center',
                        width: row.width,
                      }}
                    >
                      <div className="box-content-table-header">
                        <span> {row.label}</span>

                        {row.isActiveOrder && this.renderIconsOrder(row)}
                      </div>
                    </TableHeadCell>
                  </Tooltip>
                );
              } else {
                return (
                  <TableHeadCell
                    onClick={row.isActiveOrder ? this.onClickHeader.bind(this, row) : () => {}}
                    key={'cellheader-' + index}
                    style={{
                      textAlign: row.align || 'center',
                    }}
                  >
                    <Tooltip key={index} title={row.help ? row.help : ''} placement="top">
                      <div
                        className="box-content-table-header"
                        style={{
                          padding: row.label == 'Keyword' ? '0px 14px' : '0px 0px',
                          justifyContent: row.label == 'Keyword' ? 'flex-start' : 'center',
                        }}
                      >
                        <span> {row.label}</span>

                        {row.isActiveOrder && this.renderIconsOrder(row)}
                      </div>
                    </Tooltip>
                  </TableHeadCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableData
          initMessage={this.props.initMessage}
          isHideLoading={this.props.isHideLoading}
          gid={this.props.gid}
          onHandleAddedTag={this.props.onHandleAddedTag}
          useAction={this.props.useAction}
          useExclude={this.props.useExclude}
          headers={this.state.headers}
          onChangeData={this.onChangeData.bind(this)}
          data={this.props.keywords}
          isLoading={this.props.isLoading}
          addSelectedKeyword={this.props.addSelectedKeyword}
          addKeyword={this.props.addKeyword}
          onRemoveRow={this.props.onRemoveRow}
        />
      </Table>
    );
  }
}

class TableData extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.state = {
      addTagId: 1,
      anchorAddTagEl: null,
      existingTags: [],
    };

    this.handleAddTagClick = this.handleAddTagClick.bind(this);
    this.handleAddTagClose = this.handleAddTagClose.bind(this);
  }

  handleChange(value, e) {
    this.props.addSelectedKeyword(value, e.target.checked);
  }

  handleClickA(eve, row) {
    window.open('https://www.amazon.com/s?k=' + eve.keyword, '_blank');
  }

  goTo = route => {
    this.context.router.history.push(route);
    if (route === '/products/tracker') {
    } else {
      // ?
    }
  };

  handleAddTagClick(event, id) {
    this.setState({ anchorAddTagEl: event.currentTarget, addTagId: id });
  }

  handleAddTagClose() {
    this.setState({ addTagId: null });
  }

  getCleanedTag = tag => {
    return tag
      .trim()
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(' ', '-')
      .toUpperCase();
  };

  async setTag(row, tag, tagSelected) {
    const gid = this.props.gid;
    let tagId = this.getCleanedTag(tag);
    if (!tagSelected) {
      await firestoreService.setDocs(`groups/${gid}/tags`, tagId, { name: tag });
    } else {
      tagId = tagSelected.id;
    }

    const keyword = await firestoreService.getDocs(`groups/${gid}/keywords`, row.id);
    const newTags = [];
    if (keyword) {
      const tags = keyword.tags;
      if (tags && tags.length > 0) {
        for (const tagItem of tags) {
          if (tagId !== tagItem) newTags.push(tagItem);
        }
      }
    }
    newTags.push(tagId);

    await firestoreService.setDocs(`groups/${gid}/keywords`, row.id, { tags: newTags });
    this.handleAddTagClose();
    this.props.onHandleAddedTag();
  }

  getDashColor = value => {
    return value === '--'
      ? {
          color: '#AAACB0',
        }
      : {};
  };

  render() {
    const goTo = this.goTo;

    if (this.props.isHideLoading && this.props.isLoading) return null;
    if (this.props.isLoading) {
      return (
        <TableBody className="tableBody">
          <TableRow className="tableRow">
            <TableCell colSpan={this.props.headers.length} style={{ textAlign: 'center' }}>
              Please wait... This may take up to 2 minutes.
            </TableCell>
          </TableRow>
        </TableBody>
      );
    } else if (this.props.data.length === 0 && !this.props.isLoading) {
      return (
        <TableBody className="tableBody">
          <TableRow className="tableRow">
            <TableCell colSpan={9} style={{ textAlign: 'center' }}>
              {this.props.initMessage || 'Start your search by typing a word in the search above'}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody className="tableBody">
        {this.props.data.map((row, index) => (
          <TableRow key={index}>
            <TableCell style={{ paddingRight: '0px' }}>
              <span
                //onClick={() => goTo('/keywords/details/' + row.keyword)}
                style={{
                  color: '#000',
                  //cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                {row.keyword}
              </span>
            </TableCell>
            <TableCell style={{ paddingRight: 20 }}>
              <span className="d-flex-center" style={this.getDashColor(row.lowVolume)}>
                {row.lowVolume}
              </span>
            </TableCell>
            <TableCell style={{ paddingRight: 20 }}>
              <span className="d-flex-center" style={this.getDashColor(row.highVolume)}>
                {row.highVolume}
              </span>
            </TableCell>
            <TableCell style={{ paddingRight: 20 }}>
              <div className="d-flex-center">
                <Shadow style={{ width: 22, height: 22, borderRadius: '50%' }}>
                  <i
                    onClick={this.handleClickA.bind(this, row)}
                    style={{
                      fontSize: 14,
                      verticalAlign: 'middle',
                      color: '#FFA726',

                      cursor: 'pointer',
                    }}
                    className="fab fa-amazon"
                  />
                </Shadow>
              </div>
            </TableCell>
            <TableCell style={{ paddingRight: '20px' }}>
              {row.cmp && <Chip label={row.cmp.label} style={row.cmp.style} />}
            </TableCell>

            <TableCell style={{ textAlign: 'center' }}>
              <span style={this.getDashColor(row.formattedRopoDaily)}>
                {row.formattedRopoDaily}
              </span>
            </TableCell>

            {this.props.useAction === 'finderAction' && (
              <TableCell style={{ textAlign: 'center' }}>
                <Fade>
                  <div className="d-flex-center">
                    <Tooltip
                      TransitionComponent={Zoom}
                      key={`tooltip-${index}`}
                      title={'Add to your Keyword Tracker'}
                      placement="top"
                    >
                      <div
                        role="button"
                        onClick={e => this.handleAddTagClick(e, row.id)}
                        className="icon-plus"
                        style={{ cursor: 'pointer' }}
                      >
                        <AddIcon width={20} />
                      </div>
                    </Tooltip>

                    <Popover
                      id={this.state.addTagId}
                      open={this.state.addTagId === row.id}
                      anchorEl={this.state.anchorAddTagEl}
                      onClose={this.handleAddTagClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <AutocompleteTrackerKeywords
                        gid={this.props.gid}
                        region={this.props.region}
                        onHandleAddTag={(tag, tagSelected) => {
                          if (tag) this.setTag(row, tag, tagSelected);
                        }}
                      />
                    </Popover>
                  </div>
                </Fade>
              </TableCell>
            )}
            {this.props.useAction === 'trackerAction' && (
              <TableCell style={{ textAlign: 'center' }}>
                <Fade>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Shadow style={{ width: 22, height: 22, borderRadius: '50%' }}>
                      <Box
                        style={{ cursor: 'pointer' }}
                        display={'flex'}
                        alignItems="center"
                        padding="0"
                        onClick={() => this.props.onRemoveRow(row.id)}
                      >
                        <img
                          style={{ width: 20, objectFit: 'contain' }}
                          src="/assets/icons/trash.png"
                        />
                      </Box>
                    </Shadow>
                  </div>
                </Fade>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  }
}

export default TableKeywords;
