/* eslint-disable indent */
import { useReducer } from 'react';
const REQUEST_FETCHING = 'REQUEST_FETCHING';
const REQUEST_SUCCES = 'REQUEST_SUCCES';
const REQUEST_FAILURE = 'REQUEST_FAILURE';
const initialState = {
  isFetching: false,
  status: '',
  data: null,
  message: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCHING:
      return { ...state, isFetching: true };
    case REQUEST_SUCCES:
      return { ...state, isFetching: false, data: action.payload, status: 'success' };
    case REQUEST_FAILURE:
      return { ...state, isFetching: false, data: null, status: 'error', message: action.payload };
    default:
      return state;
  }
};

const useFetchPost = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchPost = async (url, data) => {
    try {
      dispatch({ type: REQUEST_FETCHING });
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const requestOptions = {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(url, requestOptions);
      const responseJson = await response.json();
      dispatch({ type: REQUEST_SUCCES, payload: responseJson });
    } catch (error) {
      console.log('error', error);
      dispatch({ type: REQUEST_FAILURE, payload: error });
    }
  };

  return [state, fetchPost];
};

const useFetchBulkPost = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchBulkPost = async (bulk = [{ url: '', data: '' }]) => {
    try {
      dispatch({ type: REQUEST_FETCHING });

      const promises = [];
      bulk.forEach(async element => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const requestOptions = {
          method: 'POST',
          headers,
          body: JSON.stringify(element.data),
        };

        const request = fetch(element.url, requestOptions);
        promises.push(request);
      });

      const responses = await Promise.all(promises);
      const responseJson = await Promise.all(
        responses.map(response => {
          return response.json();
        }),
      );

      dispatch({ type: REQUEST_SUCCES, payload: responseJson.flat() });
    } catch (error) {
      dispatch({ type: REQUEST_FAILURE, payload: error });
    }
  };

  return [state, fetchBulkPost];
};

const useFetchGet = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetchGet = async url => {
    try {
      dispatch({ type: REQUEST_FETCHING });

      const response = await fetch(url);
      const responseJson = await response.json();
      dispatch({ type: REQUEST_SUCCES, payload: responseJson });
    } catch (error) {
      dispatch({ type: REQUEST_FAILURE, payload: error });
    }
  };

  return [state, fetchGet];
};

export { useFetchPost, useFetchBulkPost, useFetchGet };
