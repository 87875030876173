import { SET_SESSION, SET_REGION, SET_TEMP_DATA, SET_TRIAL_PAYMENT } from '../actions/auth';

let region = window.localStorage.getItem('appRegion')
  ? window.localStorage.getItem('appRegion')
  : 'us';
let init = {
  user: null,
  region: region,
  trial: true,
  status: null,
  tempData: null,
  error: null,
  loader: false,
};
export default function (state = init, action) {
  switch (action.type) {
    case SET_SESSION:
      return Object.assign({}, state, {
        user: action.user,
      });
    case SET_TEMP_DATA:
      return Object.assign({}, state, {
        tempData: action.data,
      });
    case SET_REGION:
      return Object.assign({}, state, {
        region: action.region,
      });
    case SET_TRIAL_PAYMENT:
      return Object.assign({}, state, {
        trial: action.trial,
      });
    default:
      return state;
  }
}
