import { makeStyles } from '@material-ui/core';
import React from 'react';
import Divider from '../../atoms/Divider';
import SearchDataIcon from '../../atoms/Icons/SearchDataIcon';
import Text from '../../atoms/Text';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    marginBottom: 50,
  },
  message: {
    color: '#AAACB0',
    fontSize: 18,
  },
});

const ViewSearchData = ({ message = 'Loading...' }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SearchDataIcon width={130} />
      <Divider margin="0px 10px" />
      <Text className={classes.message} weight="semibold">
        {message}
      </Text>
    </div>
  );
};

export default ViewSearchData;
