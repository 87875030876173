import React from 'react';
import PropTypes from 'prop-types';
import { setLocale } from 'react-redux-i18n';
import moment from 'moment';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';

import { auth, google } from '../../../config/firebase';
import globals from '../../../config/globals';
import { Request, Form } from '../../../utils/';

import { FormButton, SocialButtonGoogle } from '../../../components/SocialButton';
import { StylesInput } from '../../../components/StylesInput';
import { PaperAlert } from '../../login/components';

import { set } from '../../../redux/actions/auth';
import getFireDBService from '../../../utils/fireDBService';

const fireDBService = getFireDBService();

class Login extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;

    this.state = {
      error: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    auth
      .signOut()
      .then(() => {
        this.removeAuth();
      })
      .catch(error => {
        console.log(error);
      });
  }

  removeAuth() {
    window.sessionStorage.removeItem('isAuthenticated');
    window.localStorage.removeItem('appToken');
  }

  getNetworkAuth(network) {
    this.setState({ loader: true });
    auth
      .signInWithPopup(network)
      .then(async result => {
        // user doc ref
        this.createAccount(result.user);
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  async createAccount(user) {
    this.user = user;
    const user_exists = await fireDBService.getUser(this.user.uid);
    if (user_exists) {
      this.setState({ loader: false, errorMessage: 'User already registerd!' });
      return null;
    } else {
      this.context.store.dispatch(set(this.user));
      this.context.router.history.push('/register/steps');
    }
    // this.setUserGroup()
  }

  async setUserGroup() {
    this.gid = await fireDBService.createEmptyGroup();
    await fireDBService.setGroup(this.gid, { owner: this.user.uid });

    await fireDBService.setUser(
      this.user.uid,
      {
        gid: this.gid,
        email: this.user.email,
        displayName: this.user.displayName,
        phoneNumber: this.user.phoneNumber,
        photoURL: this.user.photoURL,
        emailVerified: this.user.emailVerified,
        providerData: this.user.providerData,
        metadata: this.user.metadata,
      },
      async error => {
        this.createCustomer()
          .then(result => {
            console.log('customer created');
            // customer
            this.customer = result;
            //set suscription
            this.setSuscription()
              .then(result => {
                //suscription
                this.subscription = result;
                //setCustomerAndSubscriptionToGroup
                this.setCustomerAndSubscriptionToGroup()
                  .then(async group => {
                    const user = await fireDBService.getUser(this.user.uid);
                    user.group = group;
                    this.goToStart(user);
                  })
                  .catch(error => {
                    this.removeUser();
                    this.setState({
                      loader: false,
                      error: {
                        message: 'An error has ocurr trying to create account ',
                      },
                    });
                  });
              })
              .catch(error => {
                console.error('setSuscription', error);
                this.removeUser();
                this.setState({
                  loader: false,
                  error: {
                    message:
                      'An error has ocurr trying to create account Suscription [suscription]',
                  },
                });
              });
          })
          .catch(error => {
            this.removeUser();
            console.log('An error has ocurr trying to create account Suscription [customer]');
            this.setState({
              loader: false,
              error: { message: 'Server error, pls try again ' },
            });
          });
      },
    );
  }

  removeUser() {
    this.user
      .delete()
      .then(async result => {
        console.log('user auth successful delete', result);
        // remove groups
        await fireDBService.removeGroup(this.gid);
        await fireDBService.removeUser(this.user.uid);
      })
      .catch(error => {
        console.error('delete user auth', error);
      });
  }

  async createCustomer() {
    let service = `${globals.api}/api/groups/${this.gid}/payments/profile`;
    let form = Form({ email: this.user.email, gid: this.gid });
    return new Promise(async (resolve, reject) => {
      Request(service, form)
        .then(async response => {
          let customer = response.response;
          return resolve(customer);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async setSuscription() {
    return new Promise(async (resolve, reject) => {
      let trial_end = moment().add('3', 'days').unix();
      let suscription_service = `${globals.api}/api/groups/${this.gid}/payments/profile/${this.customer.id}/subscriptions`;
      let form = Form({
        customer: this.customer.id,
        plan: globals.stripe.plan,
        trial_end: trial_end,
      });
      Request(suscription_service, form)
        .then(async suscription => {
          resolve(suscription.response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async setCustomerAndSubscriptionToGroup() {
    return new Promise(async (resolve, reject) => {
      let group = {
        payment: {
          customer: this.customer,
          subscription: this.subscription,
        },
      };
      await fireDBService.updateGroup(this.gid, group, async error => {
        if (error) {
          return reject(error);
        }
        return resolve(group);
      });
    });
  }

  goToStart(user) {
    this.context.store.dispatch(setLocale(user.lang || 'en'));
    this.context.store.dispatch(set(user));
    this.context.router.history.push('/');
  }

  setSocialError() {
    if (this.state.errorMessage) {
      return (
        <div
          style={{
            margin: '-10px',
            marginLeft: '8px',
            width: '100%',
            paddingTop: '30px',
          }}
        >
          <PaperAlert error={{ message: this.state.errorMessage }} />
        </div>
      );
    }
  }

  onFormProcessing() {
    this.setState({ loader: true, error: null });
  }

  async onFormError(error) {
    this.setState({ loader: false, error: error });
  }

  showFormFull() {
    this.context.router.history.push('/register/steps');
  }

  render() {
    return (
      <div className="login-container h-100">
        <div className="login-form h-100">
          <div className="login-padding">
            <img src="/assets/imgs/logo-login.png" className="logo-login" alt="kashing" />
            <h3
              style={{
                border: 0,
                borderBottom: '1px solid #ddd',
                paddingBottom: '15px',
              }}
            >
              Create Account{' '}
            </h3>
            <strong>Register using social network</strong>

            <br />
            <br />
            <SocialButtonGoogle
              variant="contained"
              color="default"
              fullWidth
              disabled={this.state.loader}
              onClick={this.getNetworkAuth.bind(this, google)}
            >
              <img
                width="15px"
                style={{ marginRight: '3px' }}
                alt="Kaching Signup"
                src="/assets/imgs/icon-google.png"
              />{' '}
              Google
            </SocialButtonGoogle>

            {this.setSocialError()}

            <br />
            <br />

            <hr
              style={{
                border: 0,
                borderBottom: '1px solid #ddd',
                paddingBottom: '15px',
              }}
            />
            <br />

            <strong>Register using your email and password</strong>
            <br />
            <br />
            <br />

            <FormControl style={StylesInput.formControl}>
              <FormButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                onClick={this.showFormFull.bind(this)}
              >
                <i className="far fa-plus-square" style={{ marginRight: '4px' }} /> Create account
              </FormButton>
            </FormControl>

            <br />
            <br />
            <Link to="/login" className="helper-link">
              Already registered? Login here{' '}
            </Link>
          </div>
        </div>
        <div className="screen-container h-100">
          <div className="login-padding">
            <h2>Why Join</h2>
            <ul className="list-join">
              <li>
                <i className="fas fa-check-circle"></i> Find new product opportunities
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Track your competition
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Unique marketplace insights
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Discover keywords from worldwide
                marketplaces
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Get the latest and most extensive keyword
                shopper data
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Grow your your Amazon business and sales
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Amazon focused buyer keyword database
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Track product rankings
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Find keyword opportunities
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Track product rankings
              </li>
              <li>
                <i className="fas fa-check-circle"></i> Reduce your spend, increase your sales with
                Amazon Ads
              </li>
            </ul>
            <div className="hide">
              <h2>Trusted & Used by Top Sellers</h2>
              <Paper className="paper">
                <img
                  alt="Fernando Soto"
                  src="https://lh6.googleusercontent.com/-2OsUBwhZylI/AAAAAAAAAAI/AAAAAAAACAM/fCrMe-kCWy0/photo.jpg"
                  className="thumbnail-rounded"
                />
                <div>
                  <h3>Fernando Soto</h3>
                  <p>
                    sdasdasd asdasdasd sdas3423423 4gsfsadfsdf sdasdasd asdasdasd sdas3423423
                    4gsfsadfsdf sdasdasd asdasdasd sdas3423423 4gsfsadfsdf sdasdasd asdasdasd
                    sdas3423423 4gsfsadfsdf
                  </p>
                </div>
              </Paper>
              <br />
              <Paper className="paper">
                <img
                  alt="Samuel Morhaim"
                  src="https://lh6.googleusercontent.com/-2OsUBwhZylI/AAAAAAAAAAI/AAAAAAAACAM/fCrMe-kCWy0/photo.jpg"
                  className="thumbnail-rounded"
                />
                <div>
                  <h3>Samuel Morhaim</h3>
                  <p>
                    sdasdasd asdasdasd sdas3423423 4gsfsadfsdf sdasdasd asdasdasd sdas3423423
                    4gsfsadfsdf sdasdasd asdasdasd sdas3423423 4gsfsadfsdf sdasdasd asdasdasd
                    sdas3423423 4gsfsadfsdf
                  </p>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(Login));
