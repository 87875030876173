import React from 'react';
import PropTypes from 'prop-types';
const FilterIcon = ({ width, height }) => {
  return <img width={width} height={height} src="/assets/icons/filter.png" alt="filter icon" />;
};

FilterIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
export default FilterIcon;
