/* eslint-disable indent */
/* eslint-disable react/prop-types */

import React, { useReducer, useRef, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, makeStyles, Typography, withStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import getFirestoreService from '../utils/firestoreService';
const firestoreService = getFirestoreService();
const initialState = { isLoading: false, data: [], message: '', error: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'fetching':
      return { ...state, isLoading: true };
    case 'success':
      return { ...state, isLoading: false, data: action.payload };
    case 'failure':
      return { ...state, isLoading: false, data: [], error: action.payload };
    case 'restart':
      return initialState;
    default:
      return state;
  }
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      height: 34,
      padding: 0,
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      paddingLeft: 10,
    },
    '& .MuiOutlinedInput-root input': {
      paddingLeft: 5,
      opacity: 1,
      fontSize: 14,
      fontweight: 600,
      color: '#bfbbbb',
      paddingLeft: 10,
    },
  },
});

const ColorButton = withStyles(() => ({
  root: {
    height: 34,

    color: '#fff',
    backgroundColor: '#F4821E',
    '&:hover': {
      backgroundColor: '#F4821E',
    },
    '&:focus': {
      outline: '0',
    },
    borderRadius: '0px 8px 8px 0px',
    margin: 0,
  },
}))(Button);

export default function AutocompleteTrackerKeywords({ gid = '', onHandleAddTag }) {
  const classes = useStyles();
  const [search, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = React.useState(false);
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const inputRef = useRef(null);

  const fetchTrackedKeywords = async tagTyped => {
    dispatch({ type: 'fetching' });
    try {
      const docs = await firestoreService.searchDocs(`groups/${gid}/tags`);
      let tags = [];
      docs.forEach(function (doc) {
        tags.push({
          id: doc.id,
          name: doc.data().name,
        });
      });

      var tagsMatched = tags.filter(tag => tag.name.toLowerCase().match(tagTyped.toLowerCase()));

      dispatch({ type: 'success', payload: tagsMatched });
    } catch (error) {
      console.error('Error:', error);
      dispatch({ type: 'failure', payload: error.message });
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (!open) {
      //dispatch({ type: 'restart' });
    }
  }, [open]);

  return (
    <div>
      <div className={classes.container}>
        <Autocomplete
          style={{ width: 220, borderRadius: '50%', height: 34 }}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={e => {
            const value = e.target.value;
            if (value && value.length > 2) {
              setOpen(true);
              fetchTrackedKeywords(value);
            }
          }}
          getOptionSelected={(option, value) => option.name === value}
          getOptionLabel={option => option.name}
          options={search.data}
          loading={search.isLoading}
          freeSolo
          selectOnFocus
          renderInput={params => {
            return (
              <TextField
                inputRef={inputRef}
                {...params}
                variant="outlined"
                placeholder="Search for or add a tag"
                className={classes.textField}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {search.isLoading ? (
                        <CircularProgress style={{ color: 'black' }} size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
        />

        <ColorButton
          disabled={isButtonPressed}
          onClick={() => {
            const tag = inputRef.current.value;
            if (!tag) return;
            setIsButtonPressed(true);
            const tagSelected = search.data.find(item => item.name === tag);
            onHandleAddTag(tag, tagSelected);
          }}
        >
          {isButtonPressed ? (
            <CircularProgress size={20} style={{ color: 'white' }} />
          ) : (
            <AddCircleIcon size={20} />
          )}
        </ColorButton>
      </div>
      {search.error ? <Typography color="error">{search.error}</Typography> : ''}
    </div>
  );
}
