import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const starList = Array.from(Array(6).keys()).splice(1);

const useStyles = makeStyles({
  star: {
    color: '#FFD203',
    fontSize: 14,
    marginTop: 2,
  },
});

const Rating = ({ rating = 4 }) => {
  const classes = useStyles();
  return (
    <div>
      {starList.map((item, index) => {
        const mod = rating % item;
        const ratingCeil = Math.ceil(rating);
        if (item <= rating) {
          return <StarIcon className={classes.star} key={'si' + index.toString()} />;
        }

        if (mod === rating && ratingCeil === item) {
          return <StarHalfIcon className={classes.star} key={'shi' + index.toString()} />;
        }
        return <StarBorderIcon className={classes.star} key={'sbi' + index.toString()} />;
      })}
    </div>
  );
};

Rating.propTypes = {
  rating: PropTypes.number,
  fontSize: PropTypes.string,
};

export default Rating;
