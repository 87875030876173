/*eslint-disable*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { I18n, Translate, Localize } from 'react-redux-i18n';

const styles = {
  card: {
    minHeight: '8vw',
  },
  planCard: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    minHeight: 'auto',
  },
  planCardContent: {
    borderBottom: '1px solid #ddd',
  },
  planCardActions: {
    position: 'flex',
    justifyContent: 'center',
  },
  listPlan: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
};

/*eslint-enable*/
class Dashboard extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
      socket: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.firebase = props.firebaseApp;
  }
  componentWillUnmount() {
    this.unsuscribe();
  }
  componentDidMount() {
    this.unsuscribe = this.store.subscribe(() => {
      this.setState(this.store.getState());
    });
  }

  render() {
    // const user = this.firebase.auth().currentUser;
    // const auth = {
    //   ...this.props.auth.user,
    //   metadata: user.metadata,
    // };

    return (
      <div style={{ visibility: 'hidden' }} className="page container">
        <h1>
          <i className="ion-clock" /> <Translate value="Dashboard" />
        </h1>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Card style={styles.card}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Products Tracked
                </Typography>
                <Typography variant="h3" component="h3">
                  N/A
                </Typography>
                <Typography color="textSecondary">Items</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={styles.card}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Keywords Tracked
                </Typography>
                <Typography variant="h3" component="h3">
                  N/A
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(Dashboard));
