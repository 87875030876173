import React from 'react';
import PropTypes from 'prop-types';
import { setLocale } from 'react-redux-i18n';

import { StylesInput } from '../../components/StylesInput';
import { CircularProgressSpinner, PaperAlert } from './components';
import { withStyles, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../theme/theme';
import { connect } from 'react-redux';
import { auth } from '../../config/firebase';
import { Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../components/atoms/Button';

import { set, setSubscription } from '../../redux/actions/auth';
import getFireDBService from '../../utils/fireDBService';
import { getSubscription } from '../../utils/fastpring';
import Text from '../../components/atoms/Text';

import Divider from '../../components/atoms/Divider';
import InputField from '../../components/molecules/InputField';
import Label from '../../components/molecules/Label';
import styles from '../../styles/public';

const fireDBService = getFireDBService();

const loginStyles = theme => ({
  ...styles(theme),
  buttonReminder: {
    width: 12,
    height: 12,
    border: '1px solid #bfbbbb',
    borderRadius: 4,
    marginRight: 10,
    marginBottom: 1,
    cursor: 'pointer',
  },
});

class SignForm extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  getChildContext() {
    return { subscription: this.state.subscription };
  }

  static getDerivedStateFromProps(props, state) {
    state.error = props.error;
    state.loader = props.loader;
    return state;
  }

  constructor(props, context) {
    super(props, context);
    this.initBtnLabel = 'Sign in';
    this.state = {
      name: '',
      email: '',
      password: '',
      loader: false,
      error: null,
      btnLabel: this.initBtnLabel,
      reminder: false,
    };
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onProcessing();
    this.setState({ btnLabel: 'Starting session...' });
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(result => {
        this.props.onSuccess(result);
      })
      .catch(error => {
        this.setState({ btnLabel: this.initBtnLabel });
        this.props.onError(error);
      });
  }

  onChangeEmail(email) {
    this.setState({ email });
  }

  onChangePassword(password) {
    this.setState({ password });
  }

  showErrors() {
    if (this.state.error) {
      return (
        <div>
          <PaperAlert error={this.state.error} />
          <Divider margin={'10px 0px'} />
        </div>
      );
    }
  }

  handleReminderToggle = () => {
    this.setState({ reminder: !this.state.reminder });
  };

  render() {
    return (
      <form noValidate autoComplete="off">
        {this.showErrors()}

        <input
          tabIndex="-1"
          type="password"
          name="fake-password"
          autoComplete="new-password"
          style={{
            opacity: 0,
            float: 'left',
            border: 'none',
            height: '0',
            width: '0',
          }}
        />

        <FormControl style={StylesInput.formControl}>
          <InputField
            style={{ paddingLeft: 10, fontWeight: '400 !important' }}
            placeholder={'Email'}
            name="email"
            value={this.state.email}
            onChange={this.onChangeEmail.bind(this)}
            type="email"
          />
        </FormControl>

        <FormControl style={StylesInput.formControl}>
          <InputField
            style={{ paddingLeft: 10 }}
            placeholder={'Password'}
            name="password"
            value={this.state.password}
            onChange={this.onChangePassword.bind(this)}
            type="password"
          />
        </FormControl>
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          alignSelf="start"
        >
          <div
            onClick={this.handleReminderToggle}
            className={this.props.classes.buttonReminder}
            style={{
              backgroundColor: this.state.reminder ? '#F4821E' : 'white',
            }}
          />

          <Label text="Remember Me" weight="regular" variant="subtitle1" />
        </Box>
        <FormControl style={StylesInput.formControl}>
          <Divider margin={'15px 0px'} />
          <Button type="primary" onClick={this.onSubmit.bind(this)}>
            Sign In
            <CircularProgressSpinner show={this.state.loader} />
          </Button>
        </FormControl>
      </form>
    );
  }
}

SignForm.childContextTypes = {
  subscription: PropTypes.any,
};

class Login extends React.Component {
  static get contextTypes() {
    return {
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.user = {};
    this.state = {
      errorMessage: null,
    };
  }

  componentDidMount() {
    auth
      .signOut()
      .then(() => {
        this.removeAuth();
      })
      .catch(error => {
        console.log(error);
      });
  }

  removeAuth() {
    window.sessionStorage.removeItem('isAuthenticated');
    window.localStorage.removeItem('appToken');
  }

  getNetworkAuth(network) {
    auth
      .signInWithPopup(network)
      .then(async result => {
        this.startSession(result);
      })
      .catch(error => {
        console.log(error);
      });
  }

  async manageSubscription(user) {
    const subscription = await getSubscription(user.group.payment.subscription);
    const subscriptionData = subscription.data;
    this.context.store.dispatch(setSubscription(subscriptionData));
    this.setState({ subscription: subscriptionData });
    this.goToStart(user);
  }

  async startSession(result) {
    try {
      const user = await fireDBService.getUser(result.user.uid);
      if (user) {
        user.group = await fireDBService.getGroup(user.gid);
        if (user.group && user.group.payment) {
          this.manageSubscription(user);
        }
        return;
      }
      // handle incomplete register
      this.user = result.user;
      await this.setUserGroup();
    } catch (error) {
      console.error('startSession: Error', error);
    }
  }

  async setUserGroup() {
    const mySession = {
      reset: true,
      products: [
        {
          path: 'cypherly-monthly-plan',
          quantity: 1,
        },
      ],
      paymentContact: {
        email: this.user.email,
        firstName: this.user.displayName,
        lastName: '',
      },
      language: 'en',
    };

    this.gid = await fireDBService.createEmptyGroup();
    await fireDBService.setGroup(this.gid, { owner: this.user.uid });

    const providerData = {
      ...this.user.providerData,
      uid: this.user.uid,
    };

    const user = {
      gid: this.gid,
      email: this.user.email,
      displayName: this.user.displayName,
      phoneNumber: this.user.phoneNumber,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      providerData: providerData,
      metadata: this.user.metadata,
    };

    await fireDBService.setUser(this.user.uid, user, err => {
      this.store.dispatch(set(user));
      this.setState({ loader: false });
      this.context.store.dispatch(setLocale(user.lang || 'en'));
      this.context.store.dispatch(set(user));
      window.localStorage.setItem('session', JSON.stringify(mySession));
      this.context.router.history.push('/checkout/' + this.user.uid);
    });
  }

  goToStart(user) {
    this.context.store.dispatch(setLocale(user.lang || 'en'));
    this.context.store.dispatch(set(user));
    this.context.router.history.push('/');
  }

  setMessage() {
    if (this.state.errorMessage) {
      return <span>{this.state.errorMessage}</span>;
    } else {
      return null;
    }
  }

  onFormProcessing() {
    this.setState({ loader: true, error: null });
  }

  async onFormError(error) {
    this.setState({ loader: false, error: error });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <main className={this.props.classes.container}>
          <div className={this.props.classes.wrapBoxForm}>
            <img
              src="/assets/imgs/cypherly-logo-white.svg"
              className={this.props.classes.imgLogo}
              alt="kashing"
            />
            <Divider margin={'15px 0px'} />
            <div className={this.props.classes.boxForm}>
              <Box className={this.props.classes.boxHeaderForm}>
                <Text component="h2" variant="h5" weight="bold">
                  Sign In
                </Text>
                <Divider margin={'4px 0px'} />
                <Text component="span" variant="subtitle2" weight="regular">
                  New to Cypherly?
                  <span className="text-primary-color">
                    <Link
                      onClick={() => {
                        window.location.href = 'https://cypherly.io/sign-up/';
                      }}
                      to={'/login'}
                    >
                      {` Sign Up Now`}
                    </Link>
                  </span>
                </Text>
              </Box>

              <div className={this.props.classes.form}>
                <Divider margin={'15px 0px'} />

                <SignForm
                  loader={this.state.loader}
                  error={this.state.error}
                  onSuccess={this.startSession.bind(this)}
                  onError={this.onFormError.bind(this)}
                  onProcessing={this.onFormProcessing.bind(this)}
                  {...this.props}
                />

                <Divider margin={'15px 0px'} />

                <Text className="caption-link" component="span" variant="caption" weight="regular">
                  <Link to="/reset">Forgot password?</Link>
                </Text>
                <Text className="caption-link" component="span" variant="caption" weight="regular">
                  <a href="https://cypherly.io/contact-us/">
                    Didn't receive confirmation instructions?
                  </a>
                </Text>
              </div>
            </div>
          </div>
        </main>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(withStyles(loginStyles)(Login));
