import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import FirebaseProvider from 'firekit-provider';
import { firebaseApp } from './config/firebase';

// settings
import Router from './routes/index';
import store from './redux/store';

import { translations } from './translations';
import * as serviceWorker from './serviceWorker';

//set langauage
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

// import {hotjar} from 'react-hotjar';//
// hotjar.initialize(1194438, 6);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(window.localStorage.getItem('lang') || 'en'));

ReactDOM.render(
  <Provider store={store}>
    <FirebaseProvider firebaseApp={firebaseApp}>
      <Router />
    </FirebaseProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
