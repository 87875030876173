import React from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import moment from 'moment';
import './details.css';
import { Line } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Grid from '@material-ui/core/Grid';
import Calculator from '../components/Calculator';

import Kashing from '../../../components/_kashing';
import globals from '../../../config/globals';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from '../../../components/Header';
import GaugeChart from 'react-gauge-chart';
import { setRegion } from '../../../redux/actions/auth';
import SnackBar from '../../../components/Snackbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import getFirestoreService from '../../../utils/firestoreService';
import { getColumnKeyword, getKeywordsOrderBy } from '../../keywords/utils';
import Divider from '../../../components/atoms/Divider';
import CalculatorIcon from '../../../components/atoms/Icons/CalculatorIcon';
import ChartIcon from '../../../components/atoms/Icons/ChartIcon';
import ProductHeader from '../../../components/organisms/ProductHeader/ProductHeader';
import DialogVideo from '../../../components/molecules/DialogVideo';
import Rating from '../../../components/atoms/Rating';
import { getProduct } from '../../../helpers';

const firestoreService = getFirestoreService();

const DataGraftLineProcessing = (DataTestBsr) => {
  if (DataTestBsr.length > 0) {
    let datePrincipal = DataTestBsr[DataTestBsr.length - 1].date;
    let valueCurrentData = DataTestBsr[DataTestBsr.length - 1].value;
    let result = [];
    let dateProcessing;

    for (let daysFor = 0; daysFor <= 29; daysFor++) {
      if (daysFor > 0) {
        dateProcessing = moment(datePrincipal, 'YYYY-MM-DD', true).subtract(1, 'days');
      } else {
        dateProcessing = moment(datePrincipal, 'YYYY-MM-DD', true).subtract(0, 'days');
      }

      let day = dateProcessing.get('D');
      if (day < 10) {
        day = '0' + day;
      }

      let month = dateProcessing.get('M') + 1;
      if (month < 10) {
        month = '0' + month;
      }

      let year = dateProcessing.get('Y');

      let valueSearch = year + '-' + month + '-' + day;
      datePrincipal = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD');

      for (let ForAPrincipal = 0; ForAPrincipal <= DataTestBsr.length - 1; ForAPrincipal++) {
        if (DataTestBsr[ForAPrincipal].date === valueSearch) {
          valueCurrentData = DataTestBsr[ForAPrincipal].value;
          result[daysFor] = { date: valueSearch, value: valueCurrentData };
        } else {
          result[daysFor] = { date: valueSearch, value: valueCurrentData };
        }
      }
    }

    return result;
  }
}

/**Data Hardcode to testing**/
let Bsr = [
  { date: '2019-06-20', value: 25 },
  { date: '2019-06-26', value: 15 },
  { date: '2019-06-13', value: 75 },
  { date: '2019-06-22', value: 30 },
];
/**Data Hardcode to testing**/

/**Order Data from backend**/
let DataTestBsr = Bsr.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

/**Order Data from backend**/

let DataGeneral = DataGraftLineProcessing(DataTestBsr);
let Labels = [];
let Datas = [];
let i = 0;

DataGeneral.forEach(function (element) {
  Labels[i] = element.date;
  Datas[i] = element.value;
  i++;
});

const styles = {
  root: {
    flexGrow: 1,
  },
  label: {
    width: '100%',
    color: '#333',
    fontSize: '13px',
    marginBottom: '-20px',
    fontWeight: '500',
  },
  formControl: {
    width: '48%',
    marginBottom: '30px',
    marginRight: '2%',
  },
  paperContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paper: {
    height: 'auto',
    width: '100%',
    padding: '40px',
  },
  rowCols: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  col50: {
    width: '50%',
  },
  col100: {
    // width: '100%'
  },
  segment: {
    color: '#333',
    marginBottom: '15px',
  },
  segmentCol: {
    marginRight: '10px',
    fontWeight: '600',
    width: '140px',
    display: 'inline-block',
  },
  star: {
    color: '#fff',
  },
  starFilled: {
    color: '#FDD835',
  },
  heartFilled: {
    color: '#FF7043',
  },
  heart: {
    ':hover': {
      color: '#FF7043',
    },
  },
  card: {
    minHeight: '8vw',
    flex: 1,
  },
  questionMark: {
    marginLeft: 5,
  },
};

// Line chart
const data = {
  labels: [],
  datasets: [
    {
      label: 'Sales',
      fill: false,
      lineTension: 0.1,
      backgroundColor: '#848484',
      borderColor: '#848484',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#848484',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#848484',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    },
  ],
};

const EstSaleSold = (props) => {
  if (props && !props.pending_loading) {
    const est_sale_sold_result = props.est_sale_sold_result;
    return (
      <Typography className="text-primary-color" component="h2">
        {est_sale_sold_result}
      </Typography>
    );
  }
  return <CircularProgress style={{ marginLeft: '20px' }} />;
}

const EstSaleResult = (props) => {
  if (props && !props.pending_loading) {
    const est_sale_result = props.est_sale_result;
    return (
      <Typography className="text-primary-color" component="h2">
        {est_sale_result}
      </Typography>
    );
  }
  return <CircularProgress style={{ marginLeft: '20px' }} />;
}

class Details extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.state = {
      pending_loading: false,
      stories: data,
      orderBy: { field: 'volume', order: 'desc' },
      keywords: [],
      video: '',
      open: false,
      isSearching: false,
      revisions: [],
      region: '',
      product: null,
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
      openDialog: false,
    };

    this.auth = this.props.firebaseApp.auth();
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  async componentDidMount() {
    await this.validateSuscription(this.props.auth.user);
    await this.getProductById()

    //this.loadKeywords(this.asin, this.region);

    this.getStories();

    this.getProductAmz();
  }

  getProductAmz() {
    let uri = `${globals.api}/api/products/${this.asin}/region/${this.region}`;
    const res = this.getRequest(uri);
    res.then(data => {
      console.log({data});
      if (data.error) {
        // alert(data.error.error)
        this.setState({ openDialog: true });
      }
    });
  }

  async handleAccept() {
    const id = `${this.asin}-${this.region}`;
    await firestoreService.deleteDocs('products', id);

    let products = await firestoreService.getDocs('gids_products', this.props.auth.user.gid);
    products = products.products.filter(it => it !== id);

    await firestoreService.updateDocs('gids_products', this.props.auth.user.gid, {
      products: products,
    });

    this.closeDialog();
    this.props.goTo('/');
  }

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  async loadKeywords(asin, region) {
    this.setState({ isSearching: true });
    let keywords = await this.getKeywordsByAsin(asin, region);
    const keywordIds = keywords
      .filter(k => k.keyword)
      .map(k => k.uid)
      .slice(0, 9);
    const keywordRefs = await firestoreService.searchDocs(
      'keywords',
      [['uid', 'in', keywordIds]],
      [],
    );

    keywords = this.onColUpdate(keywordRefs);
    keywords = getKeywordsOrderBy(keywords, { field: 'cmp', order: 'desc' });
    this.setState({
      keywords,
      isSearching: false,
    });
  }

  onColUpdate = keywordRefs => {
    return keywordRefs.map(keywordData => {
      const keyword = keywordData.data();
      return getColumnKeyword(keyword);
    });
  };

  async getStories() {
    let dateFormat = 'YYYY-MM-DD';
    let startOfWeek = moment().subtract(1, 'months');
    let endOfMonth = moment();
    let month = [];
    let day = startOfWeek;
    while (day <= endOfMonth) {
      month.push(day.format(dateFormat));
      day = day.clone().add(1, 'd');
    }

    const results = await firestoreService.searchDocs(
      'products_history',
      [
        ['asin', '==', this.asin],
        ['region', '==', this.region],
      ],
      [['date', 'asc']],
    );

    const stories = {};
    results.map(doc => {
      let item = doc.data();
      let date = moment.unix(item.date.seconds).format(dateFormat);

      stories[date] = { bsr: item.bsr, sales: item.sales };
      item.date = date;
      return item;
    });
    let bsr_values = [];
    let sales_values = [];
    let max_sales_value = 0;
    let max_bsr_value = 0;
    for (let x in month) {
      let day = month[x];

      if (stories[day] && stories[day].bsr) {
        if (stories[day].bsr > max_bsr_value) {
          max_bsr_value = stories[day].bsr;
        }
        bsr_values.push(stories[day].bsr);
      } else {
        bsr_values.push(0);
      }

      if (stories[day] && stories[day].sales) {
        if (stories[day].sales > max_sales_value) {
          max_sales_value = stories[day].sales;
        }
        sales_values.push(stories[day].sales);
      } else {
        sales_values.push(0);
      }
    }
    sales_values.forEach((item, i) => {
      if (item <= 0 || !item) {
        sales_values[i] = max_sales_value;
      }
    });
    bsr_values.forEach((item, i) => {
      if (item <= 0 || !item) {
        bsr_values[i] = max_bsr_value;
      }
    });

    let data = {
      labels: month,
      datasets: [
        {
          label: 'Sales',
          fill: false,
          lineTension: 0.3,
          backgroundColor: '#388e3c',
          borderColor: '#388e3c',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#388e3c',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#388e3c',
          pointHoverBorderColor: '#388e3c',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: sales_values,
        },
        {
          label: 'BSR',
          fill: false,
          backgroundColor: '#F4821E',
          borderColor: '#F4821E',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#F4821E',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: '#F4821E',
          pointHoverBorderColor: '#F4821E',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: bsr_values,
        },
      ],
    };

    this.setState({ stories: data });
  }

  addKeyword = () => {};

  sortTable(o) {
    const keywordsOrdered = getKeywordsOrderBy(this.state.keywords, o);
    this.setState({ orderBy: o, keywords: keywordsOrdered });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
    this.setState({ region: region });
  };

  async getMonitor(row) {
    const trackMonitorDocs = await firestoreService.getCollection(
      `products/${row.asin}-${row.region.toUpperCase()}/history`,
    );

    const revisions = [];
    trackMonitorDocs.forEach(row => {
      row.data().revision.forEach(it => {
        const d = it;
        d.date = moment.unix(row.id).format('MM/DD/YYYY hh:mm');
        if (d.value === 'bsr') {
          d.new_value = this.format(d.new_value, 0);
          d.old_value = this.format(d.old_value, 0);
          d.displayName = 'Bsr';
        }
        if (d.value === 'opportunity') {
          d.displayName = 'Opportunity';
        }
        if (d.field === 'offer_price') {
          d.new_value = this.moneyFormat(d.new_value, this.props.auth.region);
          d.old_value = this.moneyFormat(d.old_value, this.props.auth.region);
        }
        if (d.field === 'list_price') {
          d.displayName = 'List Price';
          d.new_value = this.moneyFormat(d.new_value, this.props.auth.region);
          d.old_value = this.moneyFormat(d.old_value, this.props.auth.region);
        }
        if (d.value !== 'date') revisions.push(d);
      });
    });
    this.setState({ revisions });
  }

  closeDialog() {
    this.setState({ openDialog: false });
  }

  getProductById = async () => {
    let product = await getProduct(this.props.computedMatch.params.id);

    if (!product) {
      return {}
    }

    this.asin = product.asin
    this.region = product.region

    await this.setState({product})
  }

  render() {
    const { doc, classMain, onDrawerToggle } = this.props;

    if (this.state.product && this.state.product.trackMonitor) {
      this.getMonitor(this.state.product);
    }
    if (this.state.product) {
      let est_sale_sold_result = 'N/A';
      let est_sale_result = 'N/A';

      if (
        this.state.product.sales_estimate.estimate &&
        this.state.product.sales_estimate.estimate !== '' &&
        this.state.product.sales_estimate.estimate !== '0'
      ) {
        const estimate = this.state.product.sales_estimate.estimate;
        if (estimate >= 1000) {
          est_sale_sold_result =
            this.thusandsFormat(this.state.product.sales_estimate.estimate, 1) +
            this.thusandsFormatLetter(this.state.product.sales_estimate.estimate, false) +
            '/mo';
        } else if (estimate > 0) {
          est_sale_sold_result =
            this.thusandsFormat(this.state.product.sales_estimate.estimate, 0) +
            this.thusandsFormatLetter(this.state.product.sales_estimate.estimate, false) +
            '/mo';
        } else {
          est_sale_sold_result = 'N/A';
        }
      } else {
        est_sale_sold_result = 'No sales estimate';
      }

      if (this.state.product.sales_estimate.estimate && this.state.product.offer_price) {
        let estimate = this.state.product.sales_estimate.estimate;
        let offer_priceMoney = this.money(this.state.product.offer_price.amount, '2');
        if (offer_priceMoney === 'N/A') {
          est_sale_result = 'N/A';
        } else if (estimate > 0) {
          let offer_price = offer_priceMoney.toString();
          offer_price = offer_price.substring(1, offer_price.length);
          let total = this.thusandsFormat(parseFloat(offer_price) * parseFloat(estimate), 1);
          est_sale_result =
            this.symbol(this.state.product.region) +
            total +
            this.thusandsFormatLetter(parseFloat(offer_price) * parseFloat(estimate), false) +
            '/mo';
        } else {
          est_sale_result = 'N/A';
        }
      } else {
        est_sale_result = 'No sales estimate';
      }

      let indicator = '';
      if (
        this.state.product.bsr < 6000 &&
        this.state.product.reviews_count < 1000 &&
        this.state.product.list_price.amount >= 1500 &&
        this.state.product.list_price.amount <= 5000
      ) {
        indicator = 'Great Opportunity';
      } else if (
        this.state.product.bsr >= 6000 &&
        this.state.product.bsr >= 10000 &&
        this.state.product.reviews_count < 1500 &&
        this.state.product.list_price.amount >= 1500 &&
        this.state.product.list_price.amount <= 5000
      ) {
        indicator = 'Average';
      } else {
        indicator = 'Not Great';
      }

      let product_rating = 'N/A';
      if (this.state.product.rating) {
        product_rating = [];

        const mod_rating = this.state.product.rating % 2;
        const length = parseInt(this.state.product.rating);

        for (let index = 0; index < length; index++) {
          product_rating.push(
            <StarIcon key={'starticon-' + index} style={{ color: '#FFC007', fontSize: 15 }} />,
          );
          if (mod_rating > 0 && index === length - 1) {
            product_rating.push(
              <StarHalfIcon
                key={'starthalf-' + index}
                style={{ color: '#FFC007', fontSize: 15 }}
              />,
            );
          }
        }
      }

      const { goTo } = this.props;

      if (!this.state.product) {
        return <></>
      }

      return (
        <>
          <Header
            goTo={this.props.goTo}
            nameRegion={this.state.region}
            open={this.props.open}
            onChangeRegion={this.onChangeRegion}
            region={this.props.auth.region}
            onDrawerToggle={onDrawerToggle}
            title="Product Insights"
            handleClickOpen={this.handleClickOpen.bind(this)}
          />

          <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />
          <Dialog
            open={this.state.openDialog}
            onClose={this.closeDialog.bind(this)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Product not found in amazon:</DialogTitle>
            <DialogContent>
              <DialogContentText>Do you want to delete this product?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAccept.bind(this)} color="primary">
                Delete product
              </Button>
            </DialogActions>
          </Dialog>

          <main className={`${classMain} main`}>
            <div className="page-scroll">
              <DialogVideo
                open={this.state.open}
                onClose={this.handleClose.bind(this)}
                videoHashedId={this.state.video}
              />

              <ProductHeader product={this.state.product} />

              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Typography variant="subtitle2" gutterBottom>
                        ASIN
                      </Typography>
                      <Typography className="text-primary-color">{this.state.product.asin || 'NA'}</Typography>
                      <Divider margin={'10px 0px '} />
                      <Typography variant="subtitle2" gutterBottom>
                        Price
                      </Typography>
                      <Typography className="text-primary-color" component="h2">
                        {this.moneyFormat(this.state.product.list_price.amount, this.state.product.region) || 'NA'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Typography variant="subtitle2" gutterBottom>
                        Main Category
                      </Typography>
                      <Typography className="text-primary-color">
                        {this.state.product.product_group || this.state.product.main_category
                          ? this.state.product.main_category
                          : 'N/A'}
                      </Typography>
                      <Divider margin={'10px 0px '} />
                      <Typography variant="subtitle2" gutterBottom>
                        Other Categories
                      </Typography>
                      <Typography className="text-primary-color">
                        {this.state.product.binding
                          ? this.state.product.binding
                          : this.state.product.categories && this.state.product.categories.length > 0
                          ? this.state.product.categories.join('\n')
                          : 'N/A'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Typography variant="subtitle2" gutterBottom>
                        BSR
                      </Typography>
                      <Typography className="text-primary-color" component="h2">
                        {this.format(this.state.product.bsr, 0) || 'NA'}
                      </Typography>
                      <Divider margin="10px 0px" />
                      <Typography variant="subtitle2" gutterBottom>
                        Reviews & Rating
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          className="text-primary-color"
                          component="h2"
                          style={{ marginRight: 10 }}
                        >
                          {this.state.product.reviews_count ? this.format(this.state.product.reviews_count, 0) : 'N/A'}{' '}
                        </Typography>

                        {this.state.product.rating ?
                          <Rating
                            rating={typeof this.state.product.rating === 'string' ? parseFloat(this.state.product.rating) : this.state.product.rating}
                          />
                          : 'N/A'
                        }
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card style={styles.card}>
                    <CardContent style={{ color: 'black' }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Opportunity Indicator
                      </Typography>
                      <GaugeChart
                        id="gauge-chart5"
                        nrOfLevels={420}
                        textColor={'transparent'}
                        arcsLength={[0.3, 0.5, 0.2]}
                        colors={['#EA4228', '#F5CD19', '#5AAC44']}
                        percent={0.6}
                        arcPadding={0.02}
                      />
                      <Typography className="text-primary-color" style={{ textAlign: 'center' }}>
                        {indicator}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={4}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Typography variant="subtitle2" gutterBottom>
                        Monthly Units sold:
                      </Typography>
                      <EstSaleSold
                        pending_loading={this.state.pending_loading}
                        est_sale_sold_result={est_sale_sold_result}
                      />

                      <br />
                      <Typography variant="subtitle2" gutterBottom>
                        Monthly revenue:
                      </Typography>

                      <EstSaleResult
                        pending_loading={this.state.pending_loading}
                        est_sale_result={est_sale_result}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Typography variant="subtitle2" gutterBottom>
                        Brand:
                      </Typography>
                      <Typography
                        className="text-primary-color"
                        component="h2"
                        style={{ marginRight: 10 }}
                      >
                        {this.state.product.brand ? this.state.product.brand : '--'}
                      </Typography>

                      <br />

                      <Typography variant="subtitle2" gutterBottom>
                        Manufacturer:
                      </Typography>
                      <Typography
                        className="text-primary-color"
                        component="h2"
                        style={{ marginRight: 10 }}
                      >
                        {this.state.product.brand ? this.state.product.brand : '--'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Typography variant="subtitle2" gutterBottom>
                        Weight:
                      </Typography>
                      <Typography
                        className="text-primary-color"
                        component="h2"
                        style={{ marginRight: 10 }}
                      >
                        {this.state.product.weight && this.state.product.weight.value
                          ? `${this.state.product.weight.value} ${this.state.product.weight.measure}`
                          : '--'}
                      </Typography>

                      <br />

                      <Typography variant="subtitle2" gutterBottom>
                        Dimensions:
                      </Typography>
                      <Typography
                        className="text-primary-color"
                        component="h2"
                        style={{ marginRight: 10 }}
                      >
                        {this.state.product.dimensions && this.state.product.dimensions.value
                          ? this.state.product.dimensions.value
                          : '--'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/*<Grid item xs={12}>
                  <Card style={styles.card}>
                    <CardContent>
                      <Box display="flex" component="span" m={1}>
                        <CartIcon width={40} />
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{
                            fontSize: '1.09rem',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          Related Search Terms
                        </Typography>
                      </Box>

                      <div className="detail">
                        <TableKeywords
                          initMessage="Loading..."
                          goTo={goTo}
                          orderBy={this.state.orderBy}
                          addKeyword={this.addKeyword}
                          sortTable={this.sortTable.bind(this)}
                          isSearching={this.state.isSearching}
                          keywords={this.state.keywords}
                        />
                      </div>

                      <Typography
                        style={{ marginTop: '10px', textAlign: 'center' }}
                        variant="caption"
                        display="block"
                      >
                        Need more keywords?{' '}
                        <Link style={{ textDecoration: 'none' }} to="/keywords/research">
                          Click here to go to the Keyword Finder Tool
                        </Link>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>*/}

                <Grid item xs={7}>
                  <Card style={styles.card}>
                    <CardContent>
                      <div style={{ display: 'flex' }}>
                        <ChartIcon width={25} />
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{
                            marginLeft: 10,
                            fontSize: '1.09rem',
                            marginTop: '18px',
                          }}
                        >
                          BSR & SALES HISTORY
                        </Typography>
                      </div>

                      <Line data={this.state.stories} />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={5}>
                  <Card style={styles.card}>
                    <CardContent>
                      <div style={{ display: 'flex' }}>
                        <CalculatorIcon width={25} />
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{
                            fontSize: '1.09rem',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          Profitability Calculator
                        </Typography>
                      </div>
                      <Calculator products={[this.state.product]}></Calculator>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </main>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  let doc = state.docs;
  let auth = state.auth;
  return { doc, auth };
};

export default connect(mapStateToProps, {})(withFirebase(Details));
