import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import { setRegion } from '../redux/actions/auth';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#213139',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoApp: {
    marginLeft: '36px',
    marginTop: '8px',
    height: '30px',
  },
});

class NavBar extends React.Component {
  static get contextTypes() {
    return {
      classes: PropTypes.object,
      theme: PropTypes.object,
      router: PropTypes.object,
      store: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.state = {
      open: false,
    };
  }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  goTo(route) {
    this.context.router.history.push(route);
  }
  onChangeRegion(e) {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  }
  displayAvatar(auth) {
    if (auth.user.photoURL) {
      return (
        <img
          className="rounded-circle pull-left"
          src={auth.user.photoURL}
          alt={auth.user.displayName}
        />
      );
    }
    return <div className="pull-left initials">{auth.user.displayName.substr(0, 1)}</div>;
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar style={{ alignSelf: 'center' }} disableGutters={!this.state.open}>
            <Typography variant="h6" color="inherit" noWrap>
              <img
                src="/assets/imgs/cypherly-logo-transparent.png"
                alt=" Kashing"
                className={classes.logoApp}
              />
            </Typography>
          </Toolbar>
        </AppBar>

        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NavBar);
