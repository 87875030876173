import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgressLabel from './CircularProgressLabel';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const words = ['Searching Amazon...', 'Analyzing results', 'Calculating metrics'];

export default function RequestDialog({ open, isLoaded, handleClose }) {
  const [text, setText] = useState(words[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setText(word => {
        const wordIndexFound = words.findIndex(item => item === word);
        let nextIndex = wordIndexFound + 1;
        if (nextIndex === 3) nextIndex = 0;
        return words[nextIndex];
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-waiting-response"
      >
        <DialogTitle id="alert-dialog-slide-title">{'Please wait...'}</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgressLabel
              initialPercent={Math.random() * (5 - 1) + 1}
              isLoaded={isLoaded}
            />
            <div style={{ margin: '10px 10px' }}>{text}</div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
