import { firestore } from '../config/firebase';
import globals from '../config/globals';

export const getProductByAsin = async (asin, region) => {
  const endpoint = `${globals.api}/api/products/${asin}/region/${region}`;
  const response = await fetch(endpoint);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const responseJson = await response.json();
  return responseJson;
};

export const fetchSalesEstimateByProduct = async product => {
  const endpoint = `${globals.api}/api/products/predict/sales-estimate`;
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');

  var body = JSON.stringify({
    product,
  });

  var options = {
    method: 'POST',
    headers,
    body,
    redirect: 'follow',
  };
  const response = await fetch(endpoint, options);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const responseJson = await response.json();
  return responseJson.data.sales_estimate;
};

export const updateProductByAsin = async (asin, region) => {
  const endpoint = `${globals.api}/api/products/${asin}/${region}`;

  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  var options = {
    method: 'PUT',
    headers,
  };
  const response = await fetch(endpoint, options);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const responseJson = await response.json();

  return responseJson.data;
};

export const manageSalesEstimateByProduct = async (product, region) => {
  const productRequest = {
    asin: product.asin,
    bsr: product.bsr,
    category: product.main_category,
    region: region.toUpperCase(),
  };
  const salesEstimate = product.sales_estimate;
  const getNumberDaysBetweenDates = (dateStart, dateEnd) => {
    const diff = dateEnd.getTime() - dateStart.getTime();
    return diff / (1000 * 3600 * 24);
  };

  const getLastUpdate = salesEstimate => {
    return typeof salesEstimate.last_update.toDate === 'function'
      ? salesEstimate.last_update.toDate()
      : new Date(salesEstimate.last_update);
  };

  try {
    const currentDate = firestore.Timestamp.now().toDate();
    let estimate = salesEstimate.estimate;

    if (salesEstimate && salesEstimate.last_update) {
      const lastUpdate = getLastUpdate(salesEstimate);
      const days = getNumberDaysBetweenDates(lastUpdate, currentDate);

      if (days >= 15) {
        estimate = await fetchSalesEstimateByProduct(productRequest);
      }
    } else {
      estimate = await fetchSalesEstimateByProduct(productRequest);
    }

    return estimate;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return 0;
  }
};
