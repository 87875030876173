import { REQUEST_USERS, SET_DATA_USERS, SET_DATA_ADMINS } from '../actions/users';

let init = {
  data: null,
  table: null,
  error: null,
  admintable: null,
  loader: false,
};
export default function (state = init, action) {
  switch (action.type) {
    case REQUEST_USERS:
      return Object.assign({}, state, {
        loader: action.loader,
      });
    case SET_DATA_USERS:
      return Object.assign({}, state, {
        loader: action.loader,
        table: action.data,
      });
    case SET_DATA_ADMINS:
      return Object.assign({}, state, {
        loader: action.loader,
        admintable: action.data,
      });
    default:
      return state;
  }
}
