/* eslint-disable no-console */
import { splitStringBySeparator } from '../../../utils';

const getEstimateTotal = rows => {
  return rows.map(item => {
    const sales_estimate_total = Math.round(
      item.sales_estimate.estimate * (item.list_price.amount / 100),
    );
    item.sales_estimate.total = sales_estimate_total;
    return item;
  });
};

const manageProductImages = rows => {
  return rows.map(row => {
    const images = row.images;
    if (images && images.length > 0) {
      const image = images[0];
      if (image.search('http') === -1) {
        if (images.length >= 2) {
          row.small_image_url = images[1];
        }
      }
    }

    return row;
  });
};

const filterProductsNotNA = products => {
  return products.filter(item => {
    return (
      item.list_price &&
      item.list_price.amount &&
      item.list_price.amount > 0 &&
      item.sales_estimate &&
      item.sales_estimate.estimate &&
      item.sales_estimate.estimate > 0
    );
  });
};

const cleanProducts = products => {
  products = products.map(item => {
    item.title = item.title.replace('Amazon.com:', '').trim();
    item.title = item.title.replace('Amazon.com : ', '').trim();
    return item;
  });
  return filterProductsNotNA(products);
};

const isIncludeTitle = (title, keywords = '') => {
  const keywordsSplit = splitStringBySeparator(keywords, ',').map(item => item.toLowerCase());
  const keywordsFilter = keywordsSplit.filter(keyword => title.toLowerCase().search(keyword) != -1);
  return keywordsFilter.length > 0;
};

export {
  manageProductImages,
  getEstimateTotal,
  isIncludeTitle,
  cleanProducts,
  filterProductsNotNA,
};
