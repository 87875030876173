import React, { useState } from 'react';
import { Box, makeStyles, ThemeProvider } from '@material-ui/core';
import InputField from '../../components/molecules/InputField';
import Divider from '../../components/atoms/Divider';
import Button from '../../components/atoms/Button';
import Text from '../../components/atoms/Text';
import { auth } from '../../config/firebase';
import Label from '../../components/molecules/Label';
import theme from '../../theme/theme';
import getFireDBService from '../../utils/fireDBService';
import { getSubscription } from '../../utils/fastpring';

const fireDBService = getFireDBService();

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#18202C',
  },
  boxHeaderForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxLoginForm: {
    width: '25%',
    backgroundColor: 'white',
    padding: '40px 0px',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
  },
  loginForm: {
    padding: '0px 60px',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 30px',
    },
  },
  buttonReminder: {
    width: 12,
    height: 12,
    border: '1px solid #bfbbbb',
    borderRadius: 4,
    marginRight: 10,
    cursor: 'pointer',
  },
});

const Login2 = props => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reminder, setReminder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const manageSubscription = async user => {
    const subscription = await getSubscription(user.group.payment.subscription);
    const subscriptionData = subscription.data;

    this.setState({ subscription: subscriptionData });
    this.goToStart(user);
  };

  const startSession = async result => {
    try {
      const user = await fireDBService.getUser(result.user.uid);
      if (user) {
        user.group = await fireDBService.getGroup(user.gid);
        if (user.group && user.group.payment) {
          this.manageSubscription(user);
        }
        return;
      }
      // handle incomplete register
      this.user = result.user;
      await this.setUserGroup();
    } catch (error) {
      console.error('startSession: Error', error);
    }
  };

  const handleLoginButton = async () => {
    setIsLoading(true);
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      startSession();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReminderToggle = () => {
    setReminder(!reminder);
  };

  return (
    <ThemeProvider theme={theme}>
      <main className={classes.container}>
        <Box className={classes.boxLoginForm}>
          <Box className={classes.boxHeaderForm}>
            <Text component="h2" variant="subtitle1" weight="bold">
              Sign In
            </Text>
            <Divider margin={'4px 0px'} />
            <Text component="span" variant="subtitle2" weight="regular">
              New to cypherly?<span className="text-primary-color">{` Sign up now`}</span>
            </Text>
          </Box>
          <form className={classes.loginForm}>
            <Divider margin={'10px 0px'} />
            <InputField
              style={{ paddingLeft: 10, fontWeight: '400 !important' }}
              placeholder={'Email'}
              name="email"
              value={email}
              onChange={setEmail}
              type="email"
            />
            <Divider margin={'5px 0px'} />
            <InputField
              style={{ paddingLeft: 10 }}
              placeholder={'Password'}
              name="password"
              value={password}
              onChange={setPassword}
              type="password"
            />
            <Divider margin={'15px 0px'} />
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              alignSelf="start"
            >
              <div
                onClick={handleReminderToggle}
                className={classes.buttonReminder}
                style={{
                  backgroundColor: reminder ? '#F4821E' : 'white',
                }}
              />

              <Label text="Remember Me" weight="semibold" variant="subtitle2" />
            </Box>
            <Divider margin={'15px 0px'} />
            <Button type="primary" onClick={handleLoginButton}>
              Sign In
            </Button>

            <Divider margin={'15px 0px'} />

            <Text className="label-input-range" component="span" variant="caption" weight="regular">
              Forgot password?
            </Text>
            <Text className="label-input-range" component="span" variant="caption" weight="regular">
              Didn't receive confirmation instructions?
            </Text>
          </form>
        </Box>
      </main>
    </ThemeProvider>
  );
};

export default Login2;
