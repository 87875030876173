import React from 'react';

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  main: {
    padding: 50,
    background: '#fff',
    borderRadius: 30
  },
  logo: {
    display: 'block',
    margin: '8px auto',
    height: '70px',
  },
  mainText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 25,
    marginBottom: 0
  },
  secondaryText: {
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 0
  }
};

const InWorking = () => {
  return (
    <div style={styles.container}>
      <div style={styles.main}>
        <img
          src="/assets/imgs/logo-login.png"
          style={styles.logo}
          alt="kashing"
        />

        <p style={styles.mainText}>
          Site under maintenance.
        </p>
        <p style={styles.secondaryText}>
          Please contact support.
        </p>
      </div>
    </div>
  )
}

export default InWorking
