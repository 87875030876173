/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// globals

// main extended component to acces methods for all react components
import Kashing from '../../../components/_kashing';
import TableKeywords from '../../../components/TableKeywords';
import ProgressLine from '../../../components/ProgressLine';
import Header from '../../../components/Header';

import { setRegion } from '../../../redux/actions/auth';
import getFireDBService from '../../../utils/fireDBService';
import getFirestoreService from '../../../utils/firestoreService';
import { getAggregatedVolumes, getColumnKeyword, chunkArray, getKeywordsOrderBy } from '../utils';
import Text from '../../../components/atoms/Text';
import DialogVideo from '../../../components/molecules/DialogVideo';

const fireDBService = getFireDBService();
const firestoreService = getFirestoreService();

const styles = {
  root: {
    flexGrow: 1,
  },
  label: {
    width: '100%',
    color: '#333',
    fontSize: '13px',
    marginBottom: '-20px',
    fontWeight: '500',
  },
  formControl: {
    width: '48%',
    marginBottom: '30px',
    marginRight: '2%',
  },
  paperContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paper: {
    height: 'auto',
    width: '100%',
    padding: '40px',
  },
  rowCols: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  col50: {
    width: '50%',
  },
  col100: {
    // width: '100%'
  },
  segment: {
    color: '#333',
    marginBottom: '15px',
  },
  segmentCol: {
    marginRight: '10px',
    fontWeight: '600',
    width: '140px',
    display: 'inline-block',
  },
  star: {
    color: '#fff',
  },
  starFilled: {
    color: '#FDD835',
  },
  heartFilled: {
    color: '#FF7043',
  },
  heart: {
    ':hover': {
      color: '#FF7043',
    },
  },
  card: {
    minHeight: '8vw',
    flex: 1,
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  questionMark: {
    marginLeft: 5,
  },
};

class KeywordTrackerPage extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.state = {
      keywords: [],
      filteredKeywords: [],
      filteredKeywordIds: [],
      existingTags: [],
      isLoading: false,
      region: '',
      keyword: '',
      table: [],
      frency_words: '',
      suggestion: [],
      orderBy: { field: 'volume', order: 'desc' },
      inputDisabled: false,
      selectedRowId: null,
      removeRowDialog: {
        open: false,
      },
      removeTagDialog: {
        open: false,
      },
      progressLine: {
        show: false,
      },
      tableKeywordHeaders: [
        { label: 'Region', field: 'region', width: '10%', noicon: 'true' },
        {
          label: 'Keyword',
          field: 'keyword',
          width: '45%',
          align: 'left',
          noicon: 'true',
        },
        {
          label: 'Search on Amazon',
          field: 'amazon_link',
          noicon: 'true',
          align: 'left',
        },
        {
          label: 'Volume',
          field: 'volume',
          width: '10%',
          icon: 'fas  fa-angle-down',
        },
        { label: 'CMP', field: 'cmp', width: '10%', icon: '' },
        { label: 'CPC', field: 'cpc', width: '10%', icon: '' },
        { label: 'Actions', field: '', width: '10%', noicon: true },
      ],
      open: false,
      video: '',
      tags: [],
      tagSelected: {},
    };

    this.auth = this.props.firebaseApp.auth();
  }

  getKeywordsFirebase = async (region, results) => {
    const chunkSize = 10;
    const chunk = chunkArray(results);
    let rows = [];
    for (let i = 0; i < results.length / chunkSize; i++) {
      const offset = i * chunkSize;
      const keywordsChunked = chunk(offset, chunkSize + offset);
      const keywordsIds = keywordsChunked.map(item => item.id);
      const keywordDocs = await firestoreService.searchDocs('keywords', [
        ['uid', 'in', keywordsIds],
      ]);
      keywordDocs.forEach(doc => {
        rows.push({ ...doc.data() });
      });
    }
    return rows;
  };

  async componentDidMount() {
    this.validateSuscription(this.props.auth.user);
    const user = await fireDBService.getUser(this.auth.currentUser.uid);
    this.gid = user.gid;

    firestoreService.subscribeSearchDocs(`groups/${this.gid}/keywords`, [], [], async qs => {
      const keywordsTracked = [];
      qs.forEach((doc, index) => {
        const tags = doc.data().tags;
        if (tags && tags.length > 0) keywordsTracked.push({ id: doc.id, tags: doc.data().tags });
      });

      this.setState({ tags: [] });

      firestoreService.subscribeSearchDocs(`groups/${this.gid}/tags`, [], [], async qs => {
        let tags = [];
        qs.forEach((doc, index) => {
          const tagsInKeywords = keywordsTracked.filter(item => {
            return item.tags && item.tags.find(tag => tag == doc.id);
          });
          tags.push({
            id: doc.id,
            name: doc.data().name,
            keywordIds: tagsInKeywords.map(tag => tag.id),
            count: tagsInKeywords ? tagsInKeywords.length : 0,
          });
        });

        tags = tags.filter(tag => tag.keywordIds.length > 0);

        this.setState({ tags });
      });

      if (keywordsTracked.length > 0) {
        const keywordDocs = await this.getKeywordsFirebase(this.props.auth.region, keywordsTracked);
        let keywords = this.onColUpdate(keywordDocs);

        const aggregatedVolumes = getAggregatedVolumes(keywords);

        keywords.sort((a, b) => (a.high_volume > b.high_volume ? -1 : 1));
        this.setState({
          keywords: keywords,
          filteredKeywords: keywords,
          aggregatedVolumes,
          isLoading: false,
        });

        this.getFrencyWords();
      } else {
        this.setState({
          keywords: [],
          filteredKeywords: [],
          isLoading: false,
          frency_words: '',
        });
      }
    });
  }

  watchKeywords() {
    this.unsubscribe = firestoreService.subscribeSearchDocs(
      'groups_keywords',
      [['gid', '==', this.gid]],
      [[this.state.orderBy.field, this.state.orderBy.order]],
      results => {
        const keywords = [];
        results.docs.forEach(doc => {
          keywords.push(doc.data());
        });
        this.setState({ keywords: keywords });
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth.region !== this.props.auth.region) {
      this.watchKeywords();
    }
  }

  onColUpdate = keywordDocs => {
    return keywordDocs.map(keywordData => {
      return getColumnKeyword(keywordData);
    });
  };

  openConfirmRowDelete(rowId) {
    this.setState({
      selectedRowId: rowId,
      removeRowDialog: {
        open: true,
      },
    });
  }

  async onRemoveRowDialog(response) {
    if (response === true) {
      const keywordId = this.state.selectedRowId;

      await firestoreService.deleteDocs(`groups/${this.gid}/keywords`, keywordId);
      this.setState({
        selectedTagId: null,
        selectedRowId: null,
        removeRowDialog: {
          open: false,
        },
      });
    } else {
      this.setState({
        removeRowDialog: {
          open: false,
        },
      });
    }
  }

  async onRemoveTagDialog(response) {
    if (response) {
      const tag = this.state.selectedTag;
      const keywordIds = tag.keywordIds;
      let filteredKeywords = [...this.state.filteredKeywords];
      filteredKeywords = filteredKeywords.filter(item => {
        return keywordIds.find(keywordId => item.id === keywordId);
      });

      for (const keywordId of keywordIds) {
        const newTags = [];

        const keyword = await firestoreService.getDocs(`groups/${this.gid}/keywords`, keywordId);
        const keywordTags = keyword.tags;
        for (const keywordTag of keywordTags) {
          if (keywordTag !== tag.id) newTags.push(keywordTag);
        }

        await firestoreService.setDocs(`groups/${this.gid}/keywords`, keywordId, { tags: newTags });
      }
      await firestoreService.deleteDocs(`groups/${this.gid}/tags`, tag.id);

      this.setState({
        selectedTag: null,
        removeTagDialog: {
          open: false,
        },
      });
    } else {
      this.setState({
        removeTagDialog: {
          open: false,
        },
      });
    }
  }

  async openConfirmTagDelete(tag) {
    this.setState({
      selectedTag: tag,
      removeTagDialog: {
        open: true,
      },
    });
  }

  handleChange(a, e) {
    let o = this.state.advanced;
    o[a] = e.target.value;
    this.setState(o);
  }

  onChangeKeyword(e) {
    this.setState({ keyword: e.target.value });
  }

  onKeyUp(e) {
    if (e.keyCode === 13) {
      this.searchKeyword();
    }
  }

  advancedWindowOpen() {
    let status = this.state.advancedWindow === false ? true : false;
    this.setState({ advancedWindow: status });
  }

  keywordsSuggestionClose() {
    this.setState({ keywordsSuggestion: { open: false } });
  }

  async addKeyword(row) {
    let id = this.slugify(row.string);
    row.keyword = row.string;

    await firestoreService.setDocs('keywords', id, row);
    const grow = {
      ...row,
      gid: this.props.auth.user.gid,
    };

    await firestoreService.setDocs('groups_keywords', id, grow);
    const newsuggestions = _.remove(this.state.table, obj => {
      return obj.string !== row.string;
    });
    this.setState({ table: newsuggestions });
  }

  sortTable(o) {
    const filteredKeywordsOrdered = getKeywordsOrderBy(this.state.filteredKeywords, o);
    this.setState({
      orderBy: o,
      filteredKeywords: filteredKeywordsOrdered,
    });
  }

  clearInput(a) {
    this.setState({ keyword: '' });
  }

  getFrencyWords = () => {
    const arrayKeywords = this.state.filteredKeywords.map(it => it.keyword).join(' ');
    const wordsArray = arrayKeywords.split(/\s+/);
    let wordsMap = {};
    let finalText = '';
    wordsArray.forEach(key => {
      if (wordsMap.hasOwnProperty(key)) {
        wordsMap[key]++;
      } else {
        wordsMap[key] = 1;
      }
    });
    for (const prop in wordsMap) {
      if (wordsMap[prop] > 0 && /[a-z]/.test(prop.toLowerCase())) {
        finalText += `${prop}(${wordsMap[prop]}) `;
      }
    }
    this.setState({ frency_words: finalText });
  };

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
    this.setState({ region: region });
  };

  _renderRemoveTagDialog = () => {
    return (
      <Dialog
        open={this.state.removeTagDialog.open}
        onClose={this.onRemoveTagDialog.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ paddingBottom: 0 }} id="alert-dialog-title">
          {'Are you sure?'}
        </DialogTitle>
        <DialogContent>This will delete the tag and remove all keywords with it.</DialogContent>
        <DialogActions>
          <Button
            onClick={this.onRemoveTagDialog.bind(this, false)}
            variant="outlined"
            color="default"
          >
            No
          </Button>
          <Button
            onClick={this.onRemoveTagDialog.bind(this, true)}
            variant="outlined"
            color="default"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  _renderRemoveRowDialog = () => {
    return (
      <Dialog
        open={this.state.removeRowDialog.open}
        onClose={this.onRemoveRowDialog.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ paddingBottom: 0 }} id="alert-dialog-title">
          {'Are you sure?'}
        </DialogTitle>
        <DialogContent>This will delete the keyword from your Keyword Tracker</DialogContent>
        <DialogActions>
          <Button
            onClick={this.onRemoveRowDialog.bind(this, false)}
            variant="outlined"
            color="default"
          >
            No
          </Button>
          <Button
            onClick={this.onRemoveRowDialog.bind(this, true)}
            variant="outlined"
            color="default"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  onHandleClickTag = tag => {
    const tagSelected = this.state.tagSelected;
    if (tagSelected && tagSelected == tag.id) {
      this.setState({
        filteredKeywords: this.state.keywords,
        tagSelected: '',
      });

      return;
    }

    const filteredKeywords = [...this.state.keywords].filter(keyword => {
      return tag.keywordIds.find(id => id == keyword.id);
    });

    this.setState({ filteredKeywords, tagSelected: tag.id });
  };

  render() {
    const { classMain, onDrawerToggle, keyword_tags, goTo, open } = this.props;

    return (
      <>
        <Header
          goTo={this.props.goTo}
          nameRegion={this.state.region}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          title="Keywords Tracker"
          handleClickOpen={this.handleClickOpen.bind(this)}
        />

        <main className={`${classMain} main`}>
          <div className="page-scroll">
            <DialogVideo
              open={this.state.open}
              onClose={this.handleClose.bind(this)}
              videoHashedId={this.state.video}
            />

            {this._renderRemoveTagDialog()}
            {this._renderRemoveRowDialog()}
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                {this.state.tags.map(tag => {
                  return (
                    <Chip
                      key={tag.id}
                      style={{
                        color: this.state.tagSelected === tag.id ? '#fff' : '#F19532',
                        border: this.state.tagSelected !== tag.id ? '1px solid #F19532' : '',
                        marginRight: 5,
                        backgroundColor: this.state.tagSelected === tag.id ? '#F19532' : 'white',
                        fontWeight: '500',
                        borderRadius: 4,
                        height: 30,
                      }}
                      label={`${tag.name} (${tag.count})`}
                      onDelete={this.openConfirmTagDelete.bind(this, tag)}
                      onClick={() => {
                        this.onHandleClickTag(tag);
                        goTo('/keywords/tracker/' + tag.name);
                      }}
                    />
                  );
                })}
              </div>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Card style={styles.card}>
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                      Aggregated Search Volume
                    </Typography>
                    <Typography className="text-primary-color" component="h2">
                      {this.state.filteredKeywords.length > 0
                        ? `${this.state.aggregatedVolumes.sumLowVolume}  -  ${this.state.aggregatedVolumes.sumHighVolume}`
                        : 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={styles.card}>
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                      Related Keywords
                    </Typography>
                    <Typography className="text-primary-color" component="h2">
                      {this.thusandsFormat(this.state.filteredKeywords.length)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card style={styles.card}>
                  <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                      Word Frequency
                    </Typography>
                    <Typography className="text-primary-color" component="h2">
                      {this.state.frency_words}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <div className="table-scroll">
              <TableKeywords
                goTo={goTo}
                orderBy={this.state.orderBy}
                addKeyword={this.addKeyword.bind(this)}
                sortTable={this.sortTable.bind(this)}
                isLoading={this.state.isLoading}
                keywords={this.state.filteredKeywords}
                useAction={'trackerAction'}
                onRemoveRow={this.openConfirmRowDelete.bind(this)}
                initMessage="Do not have tracked keywords"
              />
              <ProgressLine
                show={this.state.isLoading}
                style={{ width: '90%', marginTop: '-4px', backgroundColor: '#fff' }}
              />
            </div>
            <div className="info-footer">
              <Text weight="semibold" variant="caption">
                {this.state.filteredKeywords.length}/25 Keywords Tracked
              </Text>
            </div>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(KeywordTrackerPage));
