import { Request, Form } from '../../utils';
import Globals from '../../config/globals';

export const REQUEST_USERS = 'REQUEST_USERS';
export const SET_DATA_USERS = 'SET_DATA_USERS';
export const SET_DATA_ADMINS = 'SET_DATA_ADMINS';

const load = () => {
  return {
    type: REQUEST_USERS,
    loader: true,
  };
};

const setData = data => {
  return {
    type: SET_DATA_USERS,
    loader: false,
    data: data,
  };
};

const setDataAdmins = data => {
  return {
    type: SET_DATA_ADMINS,
    loader: false,
    data: data,
  };
};

//--------------------------------------------------------------------
export const loadUsers = data => {
  let service = Globals.api + '/users/table';
  let form = Form(data);
  return dispatch => {
    dispatch(load());
    return Request(service, form).then(response => {
      dispatch(setData(response));
    });
  };
};

export const loadAdmins = data => {
  let service = Globals.api + '/users/admins';
  let form = Form(data);
  return dispatch => {
    dispatch(load());
    return Request(service, form).then(response => {
      dispatch(setDataAdmins(response));
    });
  };
};

export const saveAdmins = data => {
  let service = Globals.api + '/users/save';
  let form = Form(data);
  return dispatch => {
    return Request(service, form).then(response => {
      dispatch(loadUsers());
      dispatch(loadAdmins());
      return response;
    });
  };
};

export const save = data => {
  let service = Globals.api + '/users/save';
  let form = Form(data);
  return dispatch => {
    return Request(service, form).then(response => {
      dispatch(loadUsers());
      dispatch(loadAdmins());
    });
  };
};

export const remove = data => {
  let service = Globals.api + '/users/remove';
  let form = Form(data);
  return dispatch => {
    return Request(service, form).then(response => {
      dispatch(loadUsers());
    });
  };
};

export const sendPayment = data => {
  let service = Globals.api + '/users/payment';
  let form = Form(data);
  return dispatch => {
    return Request(service, form);
  };
};

export const removeAdmin = data => {
  let service = Globals.api + '/users/remove';
  let form = Form(data);
  return dispatch => {
    return Request(service, form).then(response => {
      dispatch(loadAdmins());
    });
  };
};
