export const translations = {
  es: {
    users: 'Usuarios',
    more: 'Más',
    Members: 'Miembros',
    'Total members': 'Miembros registrados',
    'Fast register': 'Registro rápido',
    'Register member': 'Registrar miembro',
    'Registering data': 'Registrando datos',
    'Register was successfully added': 'El regitro fue correctamente agregado',
    Comments: 'Comentarios',
    'Invited by': 'Invitado por',
    'Interior number': 'Número interior',
    'Exterior number': 'Número exterior',
    Name: 'Nombre',
    zip: 'Código Postal',
    Address: 'Dirección',
    Country: 'País',
    'Choose one': 'Selecciona uno',
    Email: 'Correo Electrónico',
    'Mobile Phone': 'Teléfono móvil',
    Lastname: 'Apellido Paterno',
    'Second Lastname': 'Apellido Materno',
    'Child levels': 'Niveles',
    'Email already exists in our database':
      'Este email ya se encuentra registrado a nuestra base de datos',
    'Add member': 'Agregar miembro',
    'Last members registered': 'Ultimos miembros registrados',
    Search: 'Buscar',
    'Created at': 'Fecha de alta',
    'Cant resolver comunication with server, try again.':
      "Can't resolver comunication with server, try again.",
  },
  'es-mx': {},
  'es-pe': {},
  'es-cl': {},
  en: {},
  br: {},
};
