import React, { useEffect, useState } from 'react';
import Label from '../../../components/molecules/Label';
import { InputField } from '../../../components/molecules/InputField/InputField';
import Divider from '../../../components/atoms/Divider';
import { NumberFormatSeparatorNull } from '../../../components/molecules/InputRange/InputRange';

const useStyles = {
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textInfo: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBlockStart: '0.3rem',
    marginBlockEnd: '0.3rem',
  },
  textInfoValue: {
    fontFamily: 'Open Sans',
    fontSize: '.9rem',
    fontWeight: 600,
    marginBlockStart: '0.3rem',
    marginBlockEnd: '0.3rem',
  },
  borderBottom: {
    borderBottom: '2px solid',
    marginBottom: '0.1rem',
  },
};

const NewCalculator = ({ product, onChangeValues }) => {
  const { fulfillment_costs: fulfillmentCosts = {} } = product;

  const {
    fulfillment_by_amazon_fees: fulfillmentByAmazonFees = fulfillmentByAmazonFees || 0,
    selling_on_amazon_fees: { closing_fee: closingFee = 0, referral_fee: referralFee = 0 },
    storage_cost_per_unit: storageCostPerUnit = 0,
  } = fulfillmentCosts;

  const productPrice = product.offer_price.amount / 100;
  const [price, setPrice] = useState(productPrice);
  const [sellingFee, setSellingFee] = useState(referralFee + closingFee);
  const [fulFillmentFee, setFulFillmentFee] = useState(fulfillmentByAmazonFees);
  const [storageCost, setStorageCost] = useState(storageCostPerUnit);
  const [salesFulFillment, setSalesFulfillment] = useState(
    referralFee + fulfillmentByAmazonFees + storageCostPerUnit,
  );
  const [netPayout, setPayout] = useState(price - salesFulFillment);
  const [cogsValue, setCogsValue] = useState(productPrice * 0.15);
  const [grossProfit, setGrossProfit] = useState(price - salesFulFillment - cogsValue);
  const [margin, setMargin] = useState(`${(grossProfit / price) * 100}%`);
  const [roi, setRoi] = useState(`${(netPayout / cogsValue - 1) * 100}%`);

  const entityCountry = product.region === 'US' ? '\u0024' : '\u00A3';

  const manageIninity = value => {
    if (value && value.search(/Infinity/) > -1) {
      return 0;
    }
    return value;
  };

  const isNegative = value => {
    if (typeof value === 'number') {
      value = value.toFixed(2);
    }

    return value && value.search('-') >= 0;
  };

  const formatDecimal = (number, decimalNumber = 2) => {
    return parseFloat(number).toFixed(decimalNumber);
  };

  const handleChangePrice = ({ value }) => {
    setPrice(value);
  };

  const handleChangeCogs = ({ value }) => {
    setCogsValue(value);
  };

  useEffect(() => {
    const referralFeeNew = (price * referralFee) / productPrice;
    const sellingFeeNew = referralFeeNew + closingFee;
    const salesFulfillmentNew = sellingFeeNew + fulFillmentFee + storageCost;

    const netPayoutNew = formatDecimal(price - salesFulfillmentNew);
    const grossProfitNew = formatDecimal(netPayoutNew - cogsValue);
    setSellingFee(sellingFeeNew);
    setPayout(netPayoutNew);
    setSalesFulfillment(salesFulfillmentNew);
    setGrossProfit(grossProfitNew);
    setMargin(`${((grossProfitNew / price) * 100).toFixed(2)}%`);

    setRoi(`${((parseFloat(netPayoutNew) / formatDecimal(cogsValue) - 1) * 100).toFixed(2)}%`);

    onChangeValues({
      sellingFee: sellingFeeNew,
      fulFillmentFee,
      storageCost,
      cogsValue,
    });
  }, [price, cogsValue]);

  return (
    <div style={{ minHeight: '8vw' }}>
      <BorderWrapper>
        <CalculatorTitleInput
          name={'amz-price'}
          onChange={handleChangePrice}
          title={'Price'}
          help="The selling price."
          defaultValue={price}
          currency={entityCountry}
          iTitle={'Price'}
          type={'number'}
        />
      </BorderWrapper>
      <Divider margin="20px 0px" />
      <BorderWrapper>
        <CalculatorTitleValue
          title={'Selling on Amazon Fees'}
          value={parseFloat(sellingFee).toFixed(2)}
          currency={entityCountry}
          help="Amazon referral fees and closing fees."
        />
        <CalculatorTitleValue
          title={'Fulfillment by Amazon Fees'}
          value={parseFloat(fulFillmentFee).toFixed(2)}
          currency={entityCountry}
          help="Estimated Amazon fees for packing and shipping an individual product."
        />
        <CalculatorTitleValue
          title={'Storage Cost'}
          value={parseFloat(storageCost).toFixed(2)}
          currency={entityCountry}
          help="Estimated amount you will pay in storage for the product per unit sold."
        />
      </BorderWrapper>
      <Divider margin="20px 0px" />
      <BorderWrapper>
        <CalculatorTitleValue
          title={'Net Payout'}
          sign={netPayout < 0 ? '-' : ''}
          value={parseFloat(netPayout).toFixed(2)}
          currency={entityCountry}
          help="Estimated payout after all Amazon’s fees."
        />
      </BorderWrapper>
      <Divider margin="20px 0px" />
      <BorderWrapper>
        <CalculatorTitleInput
          name={'COGS-percent-value'}
          onChange={handleChangeCogs}
          title={`Landed Cost`}
          defaultValue={parseFloat(cogsValue).toFixed(2)}
          currency={entityCountry}
          iTitle={'COGS'}
          type={'number'}
          help="The costs of acquiring the product from your supplier, or
            the cost required to manufacture or produce a single unit"
        />
      </BorderWrapper>
      <Divider margin="20px 0px" />
      <BorderWrapper>
        <CalculatorTitleValue
          title={'Sales & Fulfillment'}
          sign="-"
          value={`${parseFloat(salesFulFillment).toFixed(2)}`}
          currency={entityCountry}
          help="Total estimated sales and fulfillment fees."
        />

        <CalculatorTitleValue
          title={'Gross Profit'}
          sign={isNegative(grossProfit) ? '-' : '+'}
          value={parseFloat(grossProfit).toFixed(2)}
          currency={entityCountry}
          help="Estimated gross profit per unit sold."
        />
        <CalculatorTitleValue
          title={'Profit Margin'}
          sign={isNegative(margin) ? '-' : '+'}
          value={manageIninity(margin)}
          currency=""
          help="Estimated percentage of financial
return on total investment"
        />
        <CalculatorTitleValue
          title={'ROI'}
          sign={isNegative(roi) ? '-' : '+'}
          value={manageIninity(roi)}
          currency=""
          help="Estimated percentage of financial
            return on total investment"
        />
      </BorderWrapper>
    </div>
  );
};

const BorderWrapper = ({ children }) => {
  return (
    <div
      style={{
        borderTop: '.5px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '.5px solid rgba(0, 0, 0, 0.1)',
        padding: '3px 0px 5px 0px',
      }}
    >
      {children}
    </div>
  );
};

const CalculatorTitleInput = ({
  name = '',
  title = 'Amazon Price',
  value = 2.7,
  defaultValue = defaultValue,
  currency = '$',
  iTitle = 'Price',
  onChange,
  type = 'text',
  help = '',
}) => {
  return (
    <div style={useStyles.containerRow}>
      <div style={{ flex: 1 }}>
        <Label variant="subtitle1" text={title} help={help} />
      </div>
      <div className="calc-input">
        <InputField
          onChange={value => onChange({ name, value })}
          defaultValue={defaultValue}
          isShadow={false}
          style={{ width: '5rem', textAlign: 'center' }}
          inputComponent={NumberFormatSeparatorNull}
          inputProps={{ min: 0, style: { textAlign: 'end', paddingRight: 5, color: '#F4821E' } }}
        />
      </div>
    </div>
  );
};

const CalculatorTitleValue = ({
  title = 'Amazon Selling Fees',
  value = 2.7,
  currency = '$',
  borderBottom = false,
  sign = '',
  help = '',
}) => {
  const _borderBottom = borderBottom ? useStyles.borderBottom : {};
  value = value && value.toString().search('-') > -1 ? value.split('-')[1] : value;
  sign = value === 0 ? '+' : sign;
  const stylesPrices = {
    borderRadius: 4,
    padding: '2px 0px',
    color: 'white',
    width: '5.7rem',
    marginTop: 5,
    paddingRight: 5,
    textAlign: 'end',
  };

  return (
    <div style={{ ...useStyles.containerRow, ..._borderBottom }}>
      <Label variant="subtitle1" text={title} help={help} />

      {!sign && (
        <div style={{ borderRadius: 8 }}>
          <span
            style={{ ...useStyles.textInfoValue, color: '#F4821E' }}
          >{`${currency}${value}`}</span>
        </div>
      )}

      {sign == '+' && (
        <div style={{ ...stylesPrices, background: '#65BB5D' }}>
          <span style={{ ...useStyles.textInfoValue }}>{`${currency}${value}`}</span>
        </div>
      )}

      {sign == '-' && (
        <div style={{ ...stylesPrices, background: '#E64952' }}>
          <span style={{ ...useStyles.textInfoValue }}>{`${currency}-${value}`}</span>
        </div>
      )}
    </div>
  );
};

export default NewCalculator;
