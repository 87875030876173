import React from 'react';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import CustomIcon from '../../atoms/Icons/CustomIcon';
const ResetButton = ({ onClick }) => {
  return (
    <Button type="transparent" onClick={onClick}>
      <CustomIcon path="/assets/icons/trash_grey.png" alt="trash icon" width={16} />
      <Text weight="semibold" variant="subtitle1" component="h2" className="reset">
        Reset
      </Text>
    </Button>
  );
};

export default ResetButton;
