import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
const Shadow = ({ children, style = { borderRadius: '50%' } }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={style}>
      {children}
    </div>
  );
};

Shadow.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

export default Shadow;
