import 'whatwg-fetch';
//Request
//document.querySelector('form')
export const Request = (source, data = {}, label = '') => {
  return fetch(source, {
    body: data,
    method: 'POST',
    headers: { Accept: 'application/json' },
  }).then(response => {
    if (response) {
      return response.json();
    } else {
      return response;
    }
  });
};

//Form
export const Form = data => {
  const formData = new FormData();
  if (data) {
    for (let name in data) {
      if (typeof data[name] === 'object') {
        let tmp = data[name];
        for (let x in tmp) {
          formData.append(name, JSON.stringify(tmp[x]));
        }
      } else {
        formData.append(name, data[name]);
      }
    }
  }
  formData.append('account', window.localStorage.getItem('account'));
  return formData;
};

//Request
export const GetRequest = source => {
  return fetch(source, {
    mode: 'cors',
    headers: { Accept: 'application/json' },
  }).then(response => {
    if (response) {
      return response.json();
    } else {
      return response;
    }
  });
};

export const formatNumber = (number, decimals) => {
  number = parseFloat(number);
  number = number.toFixed(decimals) + '';

  let x = number.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
};

export const splitStringBySeparator = (str, separator) => {
  return str.split(separator).map(item => item.trim());
};

// firebaseApp.database().ref(`/users/${auth.currentUser.uid}`).once('value').then(user => {
//     const group = {
//         uid: auth.currentUser.uid,
//         gid: user.val().gid
//     }
//     console.log(1, group)
// });
