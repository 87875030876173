import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Kashing from '../../../components/_kashing';
import DialogPlan from '../components/DialogPlan';
import DialogCardPayment from '../components/DialogCardPayment';

import { set, setRegion, setSubscription } from '../../../redux/actions/auth';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import SnackBar from '../../../components/Snackbar';
import md5 from 'react-native-md5';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withStyles } from '@material-ui/core';
import WistiaEmbed from '../../../utils/wistiaEmbed';
import Box from '@material-ui/core/Box';
import Header from '../../../components/Header';
import Text from '../../../components/atoms/Text';
import Divider from '../../../components/atoms/Divider';
import getFireDBService from '../../../utils/fireDBService';
import { getSubscription } from '../../../utils/fastpring';
import CustomIcon from '../../../components/atoms/Icons/CustomIcon/CustomIcon';
import ButtonCustomized from '../../../components/atoms/Button';
import { InputField } from '../../../components/molecules/InputField/InputField';
import CarHeaderCustomized from '../../../components/molecules/CardHeader/CardHeader';

const fireDBService = getFireDBService();
const sha256 = require('js-sha256');

const styles = {
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: '12px',
  },

  card: {
    minHeight: '8vw',
    flex: 1,
  },

  card_fail: {
    minHeight: '6vw',
    margin: '12px',
    boxShadow: '0px 0px 13px -4px #000000, 0px 0px 13px -4px #000000',
  },

  planCard: {
    boxShadow: 'none',
    border: '1px solid #ddd',
    minHeight: 'auto',
  },
  planCardContent: {
    borderBottom: '1px solid #ddd',
  },
  planCardActions: {
    position: 'flex',
    justifyContent: 'center',
  },
  listPlan: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },

  title: {
    fontSize: '2.4rem',
    letterSpacing: '0px',
    fontWeight: 'bold',
    color: '#474747',
  },

  subtitle: {
    fontSize: '1.8rem',
    letterSpacing: '0px',
    fontWeight: 500,
    lineHeight: '35px',
  },

  center: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },

  logo: {
    marginTop: '8px',
    height: '70px',
  },
};

const ColorButton = withStyles(theme => ({
  root: {
    marginBottom: '10px',
    marginTop: '10px',
    color: '#fff',
    width: '20rem',
    height: '3rem',
    backgroundColor: '#F4821E',
    '&:hover': {
      backgroundColor: '#F4821E',
    },
  },
}))(Button);

class MethodCardDisplay extends Kashing {
  constructor(props, context) {
    super(props, context);
    this.state = {
      subscription:
        this.props.group.payment && this.props.group.payment.subscription
          ? this.props.group.payment.subscription
          : '',
      openDialogCancel: false,
    };
    this.context = context;
    this.store = this.context.store;
    let gid = this.props.gid;
    let user = this.props.user;

    window.dataPopupWebhookReceived = async data => {
      let group = {
        payment: {
          id: data.id,
          reference: data.reference,
          account: data.account,
          product: data.items[0].product,
          subscription: data.items[0].subscription,
          totalInPayoutCurrency: data.totalInPayoutCurrency,
          cardEnding: data.payment.cardEnding || '-',
        },
      };
      await fireDBService.updateGroup(gid, group, async error => {
        user['payment'] = group.payment;
        this.store.dispatch(set(user));
        this.context.store.dispatch(set(user));
      });
      // this.getSubscription();
      this.context.store.dispatch(setSubscription(data));
      window.location.reload(true);
    };
  }

  openCancelDialog() {
    this.setState({ openDialogCancel: true });
  }

  closeDialogCancel() {
    this.setState({ openDialogCancel: false });
  }

  handleChange = e => {
    this.setState({ newPassword: e.target.value });
  };

  handleCancel() {
    this.cancelSubscription();
    this.closeDialogCancel();
    const user = this.props.user;
    this.setSessionFastSpring(user);
  }

  render() {
    if (this.props.group.payment && this.props.group.payment.subscription) {
      let nextPayment = this.props.nextChargeDateInSeconds
        ? moment.unix(this.props.nextChargeDateInSeconds).format('MM/DD/YYYY')
        : '';

      let exclaimCard = this.props.group.payment.cardEnding ? '' : 'No payment method yet';
      if (this.props.stateSubscription && this.props.stateSubscription.trim() === 'active') {
        exclaimCard = 'Upgraded Plan';
      } else if (
        !this.props.stateSubscription ||
        this.props.stateSubscription.trim() === 'deactivated' ||
        this.props.stateSubscription.trim() === 'Canceled'
      ) {
        exclaimCard = 'Deactivated Plan';
      } else {
        exclaimCard = 'Free Plan';
      }
      return (
        <React.Fragment>
          <Dialog
            open={this.state.openDialogCancel}
            onClose={this.closeDialogCancel.bind(this)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Updating Your Payment Details:</DialogTitle>
            <DialogContent>
              <DialogContentText>
                When you update your payment details it will create a new charge. Do you still want
                to proceed with updating your payment details?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialogCancel.bind(this)} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleCancel.bind(this)} color="primary">
                Update Payment Details
              </Button>
            </DialogActions>
          </Dialog>

          <InfoField label="Next Payment" value={nextPayment} />
          <div style={{ width: '60%' }}>
            <ButtonCustomized onClick={this.openCancelDialog.bind(this)} type="primary">
              Update Payment Method
            </ButtonCustomized>
          </div>
        </React.Fragment>
      );
    } else {
      let nextPayment = this.props.group.trial
        ? moment.unix(this.props.group.trial).format('MM/DD/YYYY')
        : '-';
      return (
        <React.Fragment>
          <Dialog
            open={this.state.openDialogCancel}
            onClose={this.closeDialogCancel.bind(this)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Change Payment Card</DialogTitle>
            <DialogContent>
              <DialogContentText>, Do yo want continue this operation?</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="New password"
                type="email"
                value={this.state.newPassword}
                onChange={this.handleChange.bind(this)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialogCancel.bind(this)} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleCancel.bind(this)} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Text weight="semibold" variant="subtitle2" component="span">
            No payment method yet
          </Text>
          <InfoField label="Next Payment" value={nextPayment} />
        </React.Fragment>
      );
    }
  }
}

class PlanCardDisplay extends Kashing {
  componentDidMount() {}

  constructor(props) {
    super(props);
    this.state = {
      subscription:
        this.props.group && this.props.group.payment && this.props.group.payment.subscription
          ? this.props.group.payment.subscription
          : '',
    };
  }

  getTimeZone = () => {
    let tz = moment.tz.guess();
    if (tz) {
      return tz;
    }
    return moment.tz.guess();
  };

  render() {
    let color = 'error';

    if (this.state.subscription) {
      switch (this.state.subscription.status) {
        case 'active':
        case 'trialing':
          color = 'textSecondary';
          break;
        default:
          color = 'error';
      }

      return (
        <React.Fragment>
          <InfoField
            label="Curent Plan"
            value={`${this.props.current_plan} ${
              this.state.subscription.status ? '-' + this.state.subscription.status : ''
            }`}
          />
          <InfoField label="Price" value={this.props.priceInPayoutCurrencyDisplay} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <InfoField label="Curent Plan" value="No plan" />
          <Typography variant="h5" component="h3">
            No plan
          </Typography>
          <InfoField label="Price" value="Full Plan Price" />
        </React.Fragment>
      );
    }
  }
}

class Settings extends Kashing {
  static get contextTypes() {
    return {
      router: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
    };
  }

  async manageSubscription() {
    try {
      const state = this.store.getState();

      const id =
        this.props.auth.user.group && this.props.auth.user.group.payment
          ? this.props.auth.user.group.payment.subscription
          : '';
      let { subscription: subscriptionData } = state.subscription;

      if (!subscriptionData) {
        const subscription = await getSubscription(id);
        subscriptionData = subscription.data;
      }
      this.setState({
        current_plan: subscriptionData.display,
        priceInPayoutCurrencyDisplay: subscriptionData.priceDisplay,
        free:
          subscriptionData.subtotal === 0 &&
          subscriptionData.state === 'active' &&
          subscriptionData.discount === subscriptionData.price,
        nextChargeDateInSeconds: subscriptionData.nextChargeDateInSeconds,
        stateSubscription: subscriptionData.state,
      });
      if (!subscriptionData.state) {
        this.openDialogPayment();
        return;
      }
      if (subscriptionData.state === 'deactivated' || subscriptionData.state === 'canceled') {
        this.openDialogPayment();
      }
      this.context.store.dispatch(setSubscription(subscriptionData));
    } catch (error) {
      console.error('setting-actions manageSubscription()', error);
      this.openDialogPayment();
    }
  }

  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.store = this.context.store;
    this.firebase = props.firebaseApp;
    this.state = {
      plansWindow: false,
      paymentUpdateWindow: false,
      current_plan: '',
      priceInPayoutCurrencyDisplay: '',
      free: false,
      nextChargeDateInSeconds: 0,
      openDialog: false,
      openDialogCancel: false,
      newPassword: '',
      oldPassword: '',
      stateSubscription: '',
      openVideo: false,
      openDialogPayment: false,
      video: '',
      snackbar: {
        open: false,
        message: '',
        type: 'info',
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    };
    let gid = this.props.auth.user.gid;
    let user = this.props.auth.user;
    window.dataPopupWebhookReceived = async data => {
      let group = {
        payment: {
          id: data.id,
          reference: data.reference,
          account: data.account,
          product: data.items[0].product,
          subscription: data.items[0].subscription,
          totalInPayoutCurrency: data.totalInPayoutCurrency,
          cardEnding: data.payment.cardEnding || '-',
        },
      };
      await fireDBService.updateGroup(gid, group, async error => {
        user['payment'] = group.payment;
        this.store.dispatch(set(user));
        this.context.store.dispatch(set(user));
      });
      this.context.store.dispatch(setSubscription(data));
    };
  }

  componentDidMount() {
    this.validateSuscription(this.props.auth.user);
    this.manageSubscription();
    fireDBService.subscribeVideos(snapshot => {
      const data = snapshot.val();
      this.setState({ video: data.settings });
    });
  }

  showPlanWindow(o) {
    o = typeof o === 'boolean' ? o : false;
    this.setState({ plansWindow: o });
  }

  onFinishedPurchase(o) {
    this.setState({ plansWindow: false });
  }

  onShowPayment(o) {
    o = typeof o === 'boolean' ? o : false;
    this.setState({ paymentUpdateWindow: o });
  }

  async onUpdate(result) {
    let source = result.source.id;
    let customer = this.props.auth.user.group.payment.customer.id;
    let gid = this.props.auth.user.gid;
    let user = this.props.auth.user;
    this.setSource(source, user, customer, gid).then(user => {
      this.context.store.dispatch(set(user));
      this.onShowPayment(false);
    });
  }

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  handleCancelClose() {
    this.setState({ openDialogCancel: false });
  }

  showSnackBar(message, type = 'success') {
    this.setState({
      snackbar: {
        open: true,
        type: type,
        message: message,
        position: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    });
  }

  handleClickUpdatePassword = async () => {
    // save new password
    const user = this.firebase.auth().currentUser;
    const { oldPassword, newPassword } = this.state;

    if (!newPassword || newPassword.length < 8 || !oldPassword || oldPassword.length < 8) {
      this.showSnackBar('Password required with 8 characters at least', 'warning');
      return;
    }

    if (newPassword === oldPassword) {
      this.showSnackBar('the new password must be different to the current one', 'warning');
      return;
    }

    try {
      const responseAuth = await this.firebase
        .auth()
        .signInWithEmailAndPassword(user.email, oldPassword);
      if (responseAuth && responseAuth.user) {
        await user.updatePassword(newPassword);
        this.showSnackBar('Update successful', 'success');
        this.setState({
          newPassword: '',
          oldPassword: '',
        });
      }
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        this.showSnackBar(error.message, 'error');
      }
      this.showSnackBar('Has occurried an error, try again', 'error');
    }
  };

  handleSnackbarClose(event, reason) {
    let options = Object.assign({}, this.state.snackbar, { open: false });
    this.setState({ snackbar: options });
  }

  handleChange = e => {
    this.setState({ newPassword: e.target.value });
  };

  getToken() {
    let user = this.firebase.auth().currentUser;
    let auth = this.props.auth.user;
    let hash = sha256.create();
    const token = auth.gid + '+' + user.uid;
    hash.update(token);
    const prevToken = hash.hex();
    return md5.hex_md5(prevToken);
  }

  onCopy() {
    this.showSnackBar('Api Key Copied to Clipboard!', 'info');
  }

  openDialogPayment() {
    this.setState({ openDialogPayment: true });
  }

  handleClosePayment() {
    this.setState({ openDialogPayment: false });
  }

  clickUpdateDetails() {
    this.handleClosePayment();
    this.cancelSubscription();
    const user = this.props.auth.user;
    this.setSessionFastSpring(user);
  }

  openVideo() {
    this.setState({ openVideo: true });
  }

  closeVideo() {
    this.setState({ openVideo: false });
  }

  onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  // get current timezone
  getCurrentTimeZone = () => {
    return new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1];
  };

  render() {
    const { classMain, onDrawerToggle, open } = this.props;
    let user = this.firebase.auth().currentUser;

    let auth = this.props.auth.user;
    let isPassword = auth.providerData && auth.providerData[0].providerId === 'password';
    auth.metadata = {};
    if (user.metadata) {
      auth.metadata = user.metadata;
    }

    return (
      <>
        <Header
          goTo={this.props.goTo}
          open={open}
          onChangeRegion={this.onChangeRegion}
          region={this.props.auth.region}
          onDrawerToggle={onDrawerToggle}
          isVisibleRegion={false}
          isVisibleHelp={false}
          title="Account Settings"
        />
        <main className={`${classMain} main`}>
          <div className="page-scroll">
            <Dialog
              open={this.state.openVideo}
              onClose={this.closeVideo.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'md'}
            >
              <DialogContent>
                <div className="wistia-embed-wrapper">
                  {/* You can include any Wistia embed options as props. See the full list at https://wistia.com/doc/embed-options#options_list */}
                  <WistiaEmbed hashedId={this.state.video} playerColor="#54bbff" autoPlay={true} />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeVideo.bind(this)} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openDialogPayment}
              onClose={this.handleClosePayment.bind(this)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogContent>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Card style={styles.card_fail}>
                      <CardContent>
                        <div style={styles.center}>
                          <img
                            src="/assets/imgs/logo-login.png"
                            style={styles.logo}
                            alt="kashing"
                          />
                        </div>

                        <Box textAlign="center">
                          <Typography style={styles.title}>Oh no, your payment failed.</Typography>
                        </Box>

                        <Box textAlign="center">
                          <Typography color="textSecondary" style={styles.subtitle}>
                            Don't worry though! You can update your payment information here and
                            we'll give it another try.
                          </Typography>
                        </Box>

                        <div style={styles.center}>
                          <ColorButton
                            onClick={this.clickUpdateDetails.bind(this)}
                            variant="contained"
                          >
                            UPDATE DETAILS
                          </ColorButton>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
            <SnackBar options={this.state.snackbar} onClose={this.handleSnackbarClose.bind(this)} />
            <DialogPlan
              show={this.state.plansWindow}
              onClose={this.showPlanWindow.bind(this)}
              onFinishedPurchase={this.onFinishedPurchase.bind(this)}
            />

            <DialogCardPayment
              {...this.props}
              show={this.state.paymentUpdateWindow}
              onClose={this.onShowPayment.bind(this)}
              onUpdate={this.onUpdate.bind(this)}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                width={80}
                height={80}
                bgcolor="white"
                display="flex"
                justifyContent="center"
                borderRadius={8}
              >
                <img
                  src={'/favicon/android-chrome-512x512.png'}
                  style={{ width: 60, height: 'auto', objectFit: 'contain' }}
                />
              </Box>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <Text variant="h5" weight="semibold">
                  {auth.displayName}
                </Text>
                <Divider margin="2px 0px" />
                <Text variant="h5" weight="semibold" className="text-primary-color">
                  Member since: {moment(auth.metadata.creationTime).format('Do MMMM YYYY ')}
                </Text>
              </div>
            </div>
            <Divider margin="20px 0px" />

            <Grid container spacing={2}>
              <Grid item xs={12} md={isPassword ? 6 : 12}>
                <Card style={styles.card}>
                  <CarHeaderCustomized
                    avatar={<CustomIcon path="/assets/icons/account.png" width={40} />}
                    title="Account Details"
                    style={{ borderbottom: '1px solid red' }}
                  />
                  <CardContent>
                    <InfoField label="Email" value={auth.providerData[0].email} />
                    <InfoField label="Phone" value={auth.providerData[0].phone || '-'} />
                    <InfoField label="Country" value={auth.providerData[0].country || '-'} />
                    <InfoField label="Timezone" value={this.getCurrentTimeZone()} />
                    <InfoField
                      label="Last Activity"
                      value={moment(auth.metadata.lastSignInTime).fromNow()}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Dialog
                open={this.state.openDialog}
                onClose={this.handleClose.bind(this)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Reset password</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To change your password, please enter your new password here.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="New password"
                    type="email"
                    value={this.state.newPassword}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose.bind(this)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.handleClickUpdatePassword} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              {isPassword && (
                <Grid item xs={12} md={6}>
                  <Card style={styles.card}>
                    <CarHeaderCustomized
                      avatar={<CustomIcon path="/assets/icons/password.png" width={40} />}
                      title="Change Password"
                    />
                    <CardContent>
                      <InputField
                        value={this.state.oldPassword}
                        label={'Old Password:'}
                        style={{ paddingLeft: 10 }}
                        placeholder={'Please enter your current password'}
                        onChange={oldPassword => this.setState({ oldPassword })}
                      />
                      <Divider margin="20px 0px" />

                      <InputField
                        value={this.state.newPassword}
                        label={'New Password:'}
                        style={{ paddingLeft: 10 }}
                        placeholder={'Please enter your new password'}
                        onChange={newPassword => this.setState({ newPassword })}
                      />
                      <Divider margin="10px 0px" />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <div style={{ width: 'auto' }}>
                          <ButtonCustomized onClick={this.handleClickUpdatePassword} type="primary">
                            Save
                          </ButtonCustomized>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
            <Divider margin="20px 0px" />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card style={styles.card}>
                  <CarHeaderCustomized
                    avatar={<CustomIcon path="/assets/icons/payment.png" width={40} />}
                    title="Billing Information"
                  />
                  <CardContent>
                    <PlanCardDisplay
                      nextChargeDateInSeconds={this.state.nextChargeDateInSeconds}
                      stateSubscription={this.state.stateSubscription}
                      free={this.state.free}
                      priceInPayoutCurrencyDisplay={this.state.priceInPayoutCurrencyDisplay}
                      current_plan={this.state.current_plan}
                      group={auth.group}
                      user={user}
                    />
                    <MethodCardDisplay
                      nextChargeDateInSeconds={this.state.nextChargeDateInSeconds}
                      free={this.state.free}
                      stateSubscription={this.state.stateSubscription}
                      gid={this.props.auth.user.gid}
                      group={auth.group}
                      user={auth}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card style={styles.card}>
                  <CarHeaderCustomized
                    avatar={<CustomIcon path="/assets/icons/api-development.png" width={40} />}
                    title="API Credentials"
                  />
                  <CardContent>
                    <Text weight="semibold" variant="subtitle2" component="span">
                      API Key
                    </Text>
                    <TextField
                      id="outlined-multiline-flexible"
                      multiline
                      fullWidth
                      maxRows="4"
                      value={auth.providerData.uid + '//' + this.getToken()} //{ this.getToken()}
                      margin="normal"
                      variant="outlined"
                      style={{ marginTop: '10px' }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <div style={{ width: 'auto' }}>
                        <CopyToClipboard
                          onCopy={this.onCopy.bind(this)}
                          options={{
                            message: auth.providerData.uid + '//' + this.getToken(),
                          }}
                          text={auth.providerData.uid + '//' + this.getToken()}
                        >
                          <ButtonCustomized onClick={this.onClick} type="primary">
                            Copy
                          </ButtonCustomized>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Divider margin="80px 0px" />
          </div>
        </main>
      </>
    );
  }
}

const InfoField = ({ label, value }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
      <Text weight="semibold" variant="subtitle2" component="span">
        {label}:
      </Text>
      <Divider margin="0px 2px" />
      <Text component="span" className="text-primary-color">
        {value}
      </Text>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const auth = state.auth;

  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(Settings));
