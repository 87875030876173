import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

// import { persistStore, persistReducer } from 'redux-persist';
// import storage                          from 'redux-persist/es/storage';
// const persistorConfig = {
//   key: 'root',
//   storage: storage,
//   blacklist: ['auth', 'connection', 'initialization', 'messaging', 'simpleValues']
// };
//
// const reducer = persistReducer(persistorConfig, reducers);

import reducers from './reducers';
export default createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
