import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { withFirebase } from 'firekit-provider';

import { setRegion } from '../../../redux/actions/auth';

import Box from '@material-ui/core/Box';

import Header from '../../../components/Header';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, LinearProgress, Typography } from '@material-ui/core';
import TextFieldSearch from '../../../components/TextFieldSearch';
import { useFetchBulkPost, useFetchGet } from '../../../hooks/useFetch';
import globals from '../../../config/globals';
import Shadow from '../../../components/atoms/Shadow/Shadow';
import Text from '../../../components/atoms/Text';

const domainsList = [
  { tld: 'com', domain: '' },
  { tld: 'net', domain: '' },
  { tld: 'org', domain: '' },
  { tld: 'co', domain: '' },
  { tld: 'biz', domain: '' },
  { tld: 'io', domain: '' },
  { tld: 'at', domain: '' },
  { tld: 'us', domain: '' },
  { tld: 'me', domain: '' },
  { tld: 'co.uk', domain: '' },
  { tld: 'eu', domain: '' },
  { tld: 'info', domain: '' },
  { tld: 'xyz', domain: '' },
  { tld: 'ca', domain: '' },
  { tld: 'be', domain: '' },
  { tld: 'am', domain: '' },
  { tld: 'tv', domain: '' },
  { tld: 'la', domain: '' },
  { tld: 'life', domain: '' },
  { tld: 'ch', domain: '' },
  { tld: 'ms', domain: '' },
  { tld: 'in', domain: '' },
  { tld: 'club', domain: '' },
  { tld: 'bar', domain: '' },
  { tld: 'tech', domain: '' },
  { tld: 'site', domain: '' },
  { tld: 'online', domain: '' },
  { tld: 'store', domain: '' },
  { tld: 'space', domain: '' },
  { tld: 'website', domain: '' },
  { tld: 'fun', domain: '' },
  { tld: 'host', domain: '' },
  { tld: 'press', domain: '' },
  { tld: 'digital', domain: '' },
  { tld: 'today', domain: '' },
  { tld: 'farm', domain: '' },
];

const eccomerces = ['etsy', 'wordpress', 'shopify', 'ebay'];
const socialMedia = [
  'facebook',
  'instagram',
  'pinterest',
  'pinterest',
  'twitter',
  'reddit',
  'tiktok',
];
const servicesExclude = [
  'basecamp',
  'tumblr',
  'flickr',
  'blogger',
  'pandora',
  'producthunt',
  'steam',
  'foursquare',
  'okcupid',
  'vimeo',
  'soundcloud',
  'bitbucket',
  'github',
  'myspace',
  'dailymotion',
  'disqus',
  'medium',
  'cashme',
  'aboutme',
  'behance',
  'deviantart',
  'goodreads',
  'instructables',
  'photobucket',
  'coderwall',
  'fanpop',
  'keybase',
  'livejournal',
  'kongregate',
  'mix',
  'angellist',
  'slideshare',
  'tripit',
  'lastfm',
  'dribbble',
  'imgur',
  'flipboard',
  'kragavatar',
  'vk',
  'kik',
  'codecademy',
  'gravatar',
  'trip',
  'roblox',
  'pastebin',
  'blipfm',
  'wikipedia',
  'ello',
  'streamme',
  'codementor',
  'fivver',
  'trakt',
  'hackernews',
  'pof',
  'ifttt',
  'fiverr',
  'five00px',
  'houzz',
  'contently',
  'buzzfeed',
  'tripadvisor',
  'hubpages',
  'scribd',
  'canva',
  'creativemarket',
  'venmo',
  'bandcamp',
  'wikia',
  'reverbnation',
  'wattpad',
  'designspiration',
  'eyeem',
  'kanoworld',
  'smashcast',
  'badoo',
  'newgrounds',
  'younow',
  'patreon',
  'mixcloud',
  'askfm',
  'gumroad',
  'quora',
  'telegram',
];
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  chipDomain: {
    width: 'inherit',
    backgroundColor: 'white',
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  paper: {
    width: 'inherit',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 15,
  },
  available: {
    backgroundColor: '#2ecc71',
    color: 'white',
  },
  unavailable: {
    color: 'white',
    backgroundColor: '#E64954',
  },
  invalid: {
    backgroundColor: '#1f4788',
    color: 'white',
  },
  error: {
    backgroundColor: '#ff1744',
    color: 'white',
  },
  card: {
    width: 120,
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 2,
    flexDirection: 'column',
  },
  text: {
    fontSize: '1.2rem',
    color: '#606060',
    marginBottom: 2,
    fontWeight: 600,
  },
  logoBrand: {
    height: '47px',
    width: '80px',
    objectFit: 'contain',
  },
}));

const ProductsCheckName = ({ classMain, onDrawerToggle, open, auth, goTo }) => {
  const [text, setText] = useState('');
  const [openVideo, setOpenVideo] = useState(false);

  const handleClickOpen = () => {
    setOpenVideo(true);
  };

  const onChangeRegion = e => {
    let region = e.target.value;
    this.store.dispatch(setRegion(region));
    window.localStorage.setItem('appRegion', region);
  };

  return (
    <>
      <Header
        goTo={goTo}
        open={open}
        onChangeRegion={onChangeRegion}
        region={auth.region}
        onDrawerToggle={onDrawerToggle}
        title="Brand Name Checker"
        handleClickOpen={handleClickOpen}
      />

      <main className={`${classMain} main`}>
        <div className="page-scroll">
          <Box
            flexDirection={'row'}
            display={'flex'}
            marginTop={1}
            marginBottom={3}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <TextFieldSearch
              label="Search"
              placeholder="Enter your brand name to begin"
              onHandleButtonSearch={setText}
            />
          </Box>

          <Domains text={text} />
          <Services text={text} />
        </div>
      </main>
    </>
  );
};

const Domains = ({ text }) => {
  const classes = useStyles();
  const [reqFetchCheckDomain, fetchCheckDomainBulkPost] = useFetchBulkPost();
  const getDomainCheckedData = domainChecked => {
    let classesList = classes.chipDomain;
    let status = '';
    if (domainChecked && domainChecked.available) {
      classesList = `${classesList} ${classes.available} `;
      status = 'Available';
    }
    if (domainChecked && domainChecked.unavailable) {
      classesList = `${classesList} ${classes.unavailable} `;
      status = 'Already taken';
    }

    return { classesList, status };
  };
  const [domains, setDomains] = useState(
    domainsList.map(item => ({ ...item, ...getDomainCheckedData(item) })),
  );

  const chunks = (array, size) => {
    var results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  };

  const checkUsernameByDomains = text => {
    try {
      const dataList = [];
      const url = `${globals.api}/api/namechk/domains/check`;
      const domainsChunks = chunks(domains, 5);
      for (const domainsChunk of domainsChunks) {
        const data = {
          sld: text,
          tlds: domainsChunk.map(item => item.tld),
        };
        dataList.push({ url, data });
      }
      fetchCheckDomainBulkPost(dataList);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  useEffect(() => {
    if (reqFetchCheckDomain.data) {
      const domainsList = reqFetchCheckDomain.data;
      const domainsMapped = domainsList
        .filter(domainCheck => {
          return domains.find(domain => domain.tld === domainCheck.tld);
        })
        .map(item => {
          return { ...item, ...getDomainCheckedData(item) };
        });

      setDomains(domainsMapped);
    }
  }, [reqFetchCheckDomain.data]);

  useEffect(() => {
    if (text) {
      setDomains(domainsList.map(item => ({ ...item, ...getDomainCheckedData(item) })));
      checkUsernameByDomains(text);
    }
  }, [text]);

  return (
    <>
      <Text weight="semibold" variant={'h6'}>
        Domains:
      </Text>
      {reqFetchCheckDomain.isFetching && <LinearProgress />}
      <Box mt={1} mb={3}>
        <Grid container spacing={3}>
          {domains.map(domain => {
            return <DomainItem key={domain.tld} domain={domain} />;
          })}
        </Grid>
      </Box>
    </>
  );
};

const DomainItem = ({ domain }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Shadow style={{ borderRadius: 4, backgroundColor: 'white', padding: 0 }}>
        <Box className={classes.card}>
          <Typography variant="subtitle2" className={classes.text}>{`.${domain.tld}`}</Typography>

          {domain.status && (
            <Box className={domain.classesList} paddingLeft={1}>
              <Typography variant="caption">{domain.status}</Typography>
            </Box>
          )}
        </Box>
      </Shadow>
    </Grid>
  );
};

const Services = ({ text }) => {
  const classes = useStyles();
  const [reqServices, fetchServices] = useFetchGet();
  const [services, setServices] = useState([]);
  const [reqFetchCheckUsername, fetchCheckUsernameBulkPost] = useFetchBulkPost();

  const getServiceCheckedData = service => {
    let status = '';
    let classesList = classes.paper;
    switch (service.status) {
      case 'available':
        status = 'Available';
        classesList = `${classesList} ${classes.available} `;
        break;
      case 'unavailable':
        status = 'Already taken';
        classesList = `${classesList} ${classes.unavailable} `;
        break;
      case 'invalid':
        status = `Invalid ${service.failed_reason}`;
        classesList = `${classesList} ${classes.invalid} `;
        break;
      case 'failed':
      case 500:
        status = `Error ${service.failed_reason || service.error}`;
        classesList = `${classesList} ${classes.error} `;
        break;
    }
    return { status, classesList };
  };

  const checkUsernameByService = async text => {
    try {
      const dataList = [];

      const url = `${globals.apiSecondary}/api/namechk/services/check`;
      for (const service of services) {
        const data = { site: service.short_name, username: text };
        dataList.push({ url, data });
      }

      fetchCheckUsernameBulkPost(dataList);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getIcon = service => {
    return `${service.short_name}.svg`;
  };

  const getCategoryByService = service => {
    const { short_name } = service;
    if (eccomerces.find(item => item === short_name)) {
      return 'ecommerce';
    }
    if (socialMedia.find(item => item === short_name)) {
      return 'social';
    }
    return 'other';
  };

  useEffect(() => {
    if (reqServices.data && !reqFetchCheckUsername.data) {
      let servicesMapped = reqServices.data.map(service => {
        return {
          ...service,
          ...getServiceCheckedData(service),
          icon: getIcon(service),
          category: getCategoryByService(service),
        };
      });
      servicesMapped = servicesMapped.filter(service => {
        return !servicesExclude.find(item => item === service.short_name);
      });

      setServices(servicesMapped);
    }
  }, [reqServices.data]);

  useEffect(() => {
    fetchServices(`${globals.api}/api/namechk/services/available`);
  }, []);

  useEffect(() => {
    if (text) {
      checkUsernameByService(text);
    }
  }, [text]);

  useEffect(() => {
    if (reqFetchCheckUsername.data) {
      const _services = [...services];
      const servicesCheckedList = reqFetchCheckUsername.data;
      for (let index = 0; index < _services.length; index++) {
        let service = _services[index];
        const serviceChecked = servicesCheckedList[index];
        const serviceCheckedData = getServiceCheckedData(servicesCheckedList[index]);
        service = { ...service, ...serviceChecked, ...serviceCheckedData, icon: getIcon(service) };
        _services[index] = service;
      }
      setServices(_services);
    }
  }, [reqFetchCheckUsername.data]);

  return (
    <>
      {reqFetchCheckUsername.isFetching && <LinearProgress />}
      <Text weight="semibold" variant={'h6'}>
        E-commerce:
      </Text>

      <Box mt={1} mb={3}>
        <Grid container spacing={3}>
          {services
            .filter(item => item.category === 'ecommerce')
            .map(service => {
              return <ServiceItem key={service.short_name} service={service} />;
            })}
        </Grid>
      </Box>
      <Text weight="semibold" variant={'h6'}>
        Social Media:
      </Text>
      <Box mt={1} mb={3}>
        <Grid container spacing={3}>
          {services
            .filter(item => item.category === 'social')
            .map(service => {
              return <ServiceItem key={service.short_name} service={service} />;
            })}
        </Grid>
      </Box>
      <Text weight="semibold" variant={'h6'}>
        Others:
      </Text>
      <Box mt={1} mb={3}>
        <Grid container spacing={3}>
          {services
            .filter(item => item.category !== 'social' && item.category !== 'ecommerce')
            .map(service => {
              return <ServiceItem key={service.short_name} service={service} />;
            })}
        </Grid>
      </Box>
    </>
  );
};

const ServiceItem = ({ service }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Shadow style={{ borderRadius: 4, backgroundColor: 'white', padding: 0 }}>
        <Box className={classes.card}>
          <img className={classes.logoBrand} src={`/assets/icons/brands/${service.icon}`} />

          {service.status && (
            <Box className={service.classesList} paddingLeft={1}>
              <Typography variant="caption">{service.status}</Typography>
            </Box>
          )}
        </Box>
      </Shadow>
    </Grid>
  );
};

const mapStateToProps = state => {
  const auth = state.auth;
  return { auth };
};
export default connect(mapStateToProps, {})(withFirebase(ProductsCheckName));
